.modalIntegration {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 700px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 687px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    color: #144371;
    margin-bottom: 10px;
  }

  .subtitle {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }

  .secondTitle {
    font-size: 18px;
    font-weight: 600;
  }

  .littleCheckVert {
    width: 15px;
    object-fit: contain;
    margin-left: 30px;
    margin-right: 15px;
    opacity: 0.3;
  }

  .echeance:hover {
    background-color: #daf8d2;
    color: #569F59;
  }

  .echeance {
    font-weight: 600;
    font-size: 13px;
    background-color: #D2E7F8;
    color: #4B5FB0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .dotted {
    width: 500px;
    height: 20px;
    object-fit: cover;
  }

  .imageFonct {
    width: 550px;
    height: 330px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .buttonSuivant:hover {
    opacity: 0.7;
  }

  .buttonSuivant {
    width: 550px;
    height: 35px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #4B5FB0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

}

.modalEtapesIntegration {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 700px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 687px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .templateList {
    display: flex;
    margin-bottom: 20px;
    margin-top: 25px;
    flex-wrap: wrap;

    .template {
      object-fit: cover;
      width: 152px;
      height: 120px;
      border-radius: 10px;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      cursor: pointer;
    }

    .templateSelected {
      object-fit: cover;
      width: 152px;
      height: 120px;
      border-radius: 10px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      cursor: pointer;
    }
  }

  .etapeNormale {
    width: 590px;
    transition: margin-left 0.5s ease-in-out;

    .titleEtape {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 600;
      margin-left: 20px;
      color: #144371;
    }

    .progressBar {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 15px;
      width: 97px;
      height: 5px;
      border-radius: 20px;
      background-color: #eeeeee;
    }

    .frontProgressionBar {
      height: 5px;
      background-color: #5c9d5f;
      border-radius: 20px;
      transition: width 0.3s ease-in-out;
    }

    .littleCheckVert {
      transition: opacity 0.3s ease-in-out;
    }

    .designantInput {
      margin-left: 5px;
      margin-right: -22px;
      font-size: 15px;
      font-weight: 600;
      margin-top: 27px;
    }

    .subtitleInput {
      margin-left: 5px;
      margin-top: -10px;
      font-size: 14px;
      opacity: 0.7;
      margin-right: 20px;
    }

    .littleInput {
      width: 250px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }

    .bigInput {
      width: 536px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }
  }

  .albumPhoto {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-right: 7px;
    .photoAlbum {
      width: 150px;
      height: 115px;
      border-radius: 10px;
      object-fit: cover;
      background-color: #F8F8F8;
    }

    .filterPhoto {
      display: flex;
      position: absolute;
      width: 150px;
      height: 150px;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.09);
    }
  }

  .buttonSuivant:hover {
    opacity: 0.7;
    background-color: #daf8d2;
    color: #3f8042;
  }

  .buttonSuivant {
    width: 550px;
    height: 35px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #4B5FB0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

}
