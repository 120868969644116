.contributorAccountsContainer {
  width: calc(100vw - 835px);
  height: calc(100vh - 186px);
  overflow: scroll;
  min-width: 550px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 26px;

  .warningNoOne {
    display: flex;
    background-color: rgba(255, 216, 118, 0.21);
    border-radius: 10px;
    padding-right: 15px;
    height: 50px;
    align-items: center;
    margin-right: 25px;

    .warningIcon {
      width: 20px;
      object-fit: contain;
      margin-left: 15px;
    }

    .messageWarning {
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px;
      color: #ffb100;
    }
  }

  .containerGroupContributors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #D1D1D1;
    border-radius: 8px;
    padding: 24px 25px;
    cursor: pointer;

    .freeSeat {
      display: flex;
      margin-right: 10px;
      background-color: #7FA3FC;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 100px;
      border: solid 2px #FFFFFF;
    }

    .designatePart {
      .name {
        font-size: 15px;
        font-weight: 600;
        margin: 0;
      }

      .diminutif {
        color: #5066E4;
        font-size: 14px;
        font-weight: 800;
        margin-left: 5px;
      }

      .vous {
        color: #5066E4;
        font-size: 12px;
        font-weight: 800;
        background-color: rgba(28, 87, 221, 0.11);
        padding: 4px 7px;
        border-radius: 15px;
        margin-left: 10px;
      }

      .buttonGroupContributors {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 600;
        color: #55A72A;
        margin-top: 16px;

        .icon {
          width: 22px;
        }
      }
    }

    .accessPart {
      display: flex;
      align-items: center;

      .containerProfilePicture {
        display: flex;
        align-items: center;
        margin-right: 20px;
        height: 20px;

        .photoProfilBleu {
          margin-left: -15px;
          width: 40px;
          height: 40px;
          border-radius: 25px;
          object-fit: cover;
          background-color: #7FA3FC;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-weight: 600;
          border: solid 2px #FFFFFF;
        }
      }

      .rightIconAccess {
        width: 8px;
        opacity: 0.3;
        transform: rotate(180deg);
      }
    }
  }

  .bar {
    margin-top: 15px;
    margin-bottom: -15px;
    background-color: #eeeeee;
    height: 1px;
    width: calc(100vw - 700px);
  }

  .noAccounts {
    opacity: 0.7;
    margin-left: 5px;
  }

  .secondaryTitle {
    margin-left: 5px;
    margin-top: 22px;
    margin-bottom: 15px;
    font-weight: 600;
    color: #1C57DD;
    white-space: nowrap;
  }

  .buttonAddEntreprise {
    margin-right: 5px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #55A72A;
    margin-top: 10px;
    white-space: nowrap;

    .icon {
      width: 22px;
    }
  }
}

.modalProjectAccounts {
  position: absolute;
  align-items: center;
  flex-direction: column;
  margin-left: calc((100vw - 700px)/2);
  margin-top: calc((100vh - 650px)/2);
  top: 0;
  width: 700px;
  height: 650px;
  background-color: #FFFFFF;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .titleModal {
    font-size: 25px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 22px;
    margin-bottom: 20px;
  }

  .roleTitle {
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    width: 630px;
  }

  select {
    width: 640px;
    margin-bottom: 0px;
    max-width: calc(100vw - 45px);
    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    /* Supprime le style par défaut sous Chrome, Safari */
    -moz-appearance: none;
    /* Supprime le style par défaut sous Firefox */
    appearance: none;
    /* Supprime le style par défaut pour les autres navigateurs */
    background-color: white;
    cursor: pointer;
    /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
  }

  .leftArrow {
    margin-left: -25px;
    margin-right: 17px;
    margin-top: 10px;
    width: 7px;
    transform: rotate(90deg);
  }

  .containerSearchButton {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;


    .searchContainer {
      display: flex;
      flex: 1;

      .searchIconAccounts {
        width: 20px;
        margin-left: 10px;
        margin-right: -30px;
        object-fit: contain;
        opacity: 0.5;
      }

      .searchInput {
        width: 100%;
        background-color: #F7F7F7;
        border-radius: 8px;
        border: solid 0px;
        height: 37px;
        padding-left: 35px;
        font-size: 15px;
        font-weight: 500;
        outline: none;
      }

      .crossIconSearch {
        width: 20px;
        margin-left: -25px;
        margin-right: 5px;
        object-fit: contain;
      }
    }

    .buttonManagePermissions {
      background-color: #F7F7F7;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #414141;
      font-size: 14px;
      font-weight: 700;
      padding: 8px 11px;
      border-radius: 8px;
      margin-left: 15px;
      border: 0px;
    }

    .buttonNew {
      background-color: #5FBA2F;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 700;
      padding: 8px 11px;
      border-radius: 8px;
      margin-left: 15px;

      .circlePlus {
        width: 20px;
      }
    }
  }

  .barModalAccounts {
    display: flex;
    margin-top: 17px;
    height: 1px;
    margin-left: 30px;
    margin-right: 30px;
    width: 640px;
    background-color: #E3E3E3;
  }

  .addWhat {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
  }

  .scrollableList {
    display: flex;
    flex-direction: column;
    height: 522px;
    align-items: center;
    overflow: scroll;

    .roleTitle {
      font-weight: 600;
      font-size: 14px;
      margin-top: 20px;
      width: 630px;
    }

    select {
      width: 640px;
      margin-bottom: -10px;
      max-width: calc(100vw - 45px);
      height: 38px;
      border-radius: 8px;
      padding-left: 10px;
      border: solid 1px #EAEAEA;
      font-size: 14px;
      font-weight: 500;
      outline: none;
      -webkit-appearance: none;
      /* Supprime le style par défaut sous Chrome, Safari */
      -moz-appearance: none;
      /* Supprime le style par défaut sous Firefox */
      appearance: none;
      /* Supprime le style par défaut pour les autres navigateurs */
      background-color: white;
      cursor: pointer;
      /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
    }

    .leftArrow {
      margin-left: -25px;
      margin-right: 17px;
      margin-top: 10px;
      width: 7px;
      transform: rotate(90deg);
    }

    .placeholderMo {
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      opacity: 0.7;
      font-size: 14px;
    }

    .comble {
      width: 600px;
      height: 40px;
    }

    .noResult {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      .noResultFound {
        font-size: 15px;
        opacity: 0.5;
        margin-bottom: 0px;
      }
    }
  }

  .itemListUser {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 3px;
    justify-content: space-between;

    .leftPartItemUser {
      display: flex;
      align-items: center;
      width: 310px;

      .profilePicture {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin-right: 15px;
      }

      .profilePictureBleu {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 15px;
        object-fit: cover;
        background-color: #7FA3FC;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: 600;
      }

      .nameEmail {

        .name {
          margin: 0;
          font-weight: 600;
          display: flex;
        }

        .diminutif {
          color: #1C57DD;
          font-size: 13px;
          font-weight: 700;
          margin-left: 5px;
        }

        .email {
          margin: 2px 0 0;
          font-size: 14px;
          opacity: 0.6;
        }
      }
    }

    .rightPartItemUser {
      width: 310px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .phoneNumber {
        font-size: 13px;
        font-weight: 600;
        opacity: 0.8;
        background-color: #f6f6f6;
        padding: 5px 10px;
        border-radius: 5px;
      }

      .clientChat {
        font-size: 13px;
        font-weight: 600;
        opacity: 0.8;
        background-color: #f6f6f6;
        padding: 8px 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .retirer {
        color: #EA4342;
        font-size: 13px;
        font-weight: 700;
      }

      .ajouter {
        color: #5FBA2F;
        font-size: 13px;
        font-weight: 700;
      }

      .status {
        font-size: 13px;
        font-weight: 600;
        opacity: 0.5;
      }
    }
  }
}

.modalPartnerCompany {

  .containerPartnerCompanyAuthorization {
    display: flex;
    flex-direction: column;
    align-items: center;

    .containerChoice {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 30px;

      .choiceStatusActive,
      .choiceStatus {
        width: 270px;
        height: 100px;
        border: solid 2px #E3E3E3;
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .recommendContainer {
          display: flex;
          position: relative;
          margin-bottom: -10px;
          width: 300px;
          margin-top: -11px;
          justify-content: flex-end;

          .recommend {
            padding: 3px 8px;
            border-radius: 20px;
            background-color: #EFF1FD;
            color: #5369E4;
            font-weight: 700;
            font-size: 12px;
          }
        }

        .icon {
          margin-top: 15px;
          width: 24px;
        }

        .statusName {
          margin-top: 12px;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 0px;
        }

        .statusExplanation {
          font-size: 13px;
          margin-top: 5px;
          opacity: 0.6;
        }
      }

      .choiceStatusActive {
        border-color: #5369E4;

        .statusName {
          color: #5369E4;
        }

        .statusExplanation {
          color: #5369E4;
          opacity: 0.8;
        }
      }
    }

    .canDiscussWithClients {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 600;
      opacity: 0.8;
      background-color: #f6f6f6;
      padding: 8px 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 12px;

    }
  }

  .buttonRemovePartnerCompany {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 0, 0, 0.06);
    color: #F42C2C;
    gap: 5px;
    width: 650px;
    height: 45px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
  }
}

.modalAdd {

  .containerInviteSent {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;

    .inviteSentIcon {
      width: 55px;
    }

    .infosEmailSent {
      margin-left: 20px;

      .title {
        margin: 0;
        font-weight: 700;
      }

      .email {
        margin: 0;
        margin-top: 4px;
        font-size: 15px;
        opacity: 0.7;

      }
    }
  }

  .buttonInviteOtherClient {
    background-color: rgba(95, 186, 47, 0.13);
    color: #3CA504;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 8px;
    margin-bottom: 20px;

  }

  .addWhat {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
  }

  .containerAddNewUser {
    width: 620px;

    .containerInputs {
      display: flex;
      align-items: center;
      gap: 20px;

      .containerInput {
        flex: 1;
        display: flex;
        align-items: center;

        img {
          opacity: 0.4;
          width: 14px;
          max-height: 13px;
          object-fit: contain;
          margin-right: -25px;
          margin-left: 10px;
        }

        input {
          width: 100%;
          border: solid 1px #D1D1D1;
          border-radius: 8px;
          height: 35px;
          padding-left: 33px;
          font-size: 15px;
          font-weight: 500;
          outline: none;
        }
      }
    }

    .buttonAddThisUser {
      border-radius: 5px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      height: 37px;
      width: 620px;
      font-weight: 600;
      font-size: 15px;
      background-color: #5FBA2F;
      margin-bottom: 10px;
      border: solid 0px;
    }

  }
}

.modalDecale {
  margin-left: calc((100vw - 665px)/2);
  margin-top: calc((100vh - 615px)/2);
}

.overlayModalProjectAccounts {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.addEntreprisesComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #EEEDF0;
  border-radius: 5px;
  padding: 24px 25px;
  background-color: #F6F8FA;

  .firstLine {
    margin-top: 0px;
    font-weight: 700;
    font-size: 15px;
  }

  .secondLine {
    font-weight: 300;
    font-size: 15px;
    margin-top: 0px;
  }

  .avatarOpus {
    margin-left: -11px;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    object-fit: cover;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 600;
    border: solid 2px #FFFFFF;
  }

  .containerButtonAddCompany {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #55A72A;

    .icon {
      width: 22px;
    }
  }

  .addMore {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #08090D;
    margin-top: 15px;
    margin-bottom: 0px;

    .questionCircle {
      width: 13px;
      margin-right: 5px;
    }
  }
}