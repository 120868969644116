
.pagePortfolio {
  width: 1300px;
  margin-left: 2px;
  height: 748px;
  overflow: hidden;
  padding-left: 30px;

  .goBackButton {
    margin-top: 10px;
    background-color: #eeeeee;
    padding: 3px 6px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    height: 20px;
    margin-left: 40px;
    cursor: pointer;

    .returnIcon {
      margin-top: 1px;
      width: 8px;
      object-fit: contain;
      opacity: 0.8;
      margin-right: 5px;
    }

    .retour {
      font-size: 11px;
      font-weight: 600;
      margin: 0px;
      margin-right: 5px;
    }
  }

  .title {
    margin-top: 10px;
    margin-bottom: 12.7px;
    margin-left: 15px;
    font-size: 19.5px;
    font-weight: 600;
  }

  .Icon {
    opacity: 0.4;
    width: 8px;
    object-fit: contain;
    margin-left: 8.5px;
    margin-right: 8.5px;
    margin-top: -5px;
    transform: rotate(180deg);
  }

  .parametresAlbum {
    margin-left: 5px;
    margin-top: 0px;
    width: 23px;
    object-fit: contain;
    opacity: 0.8;
    margin-right: 5px;
  }

  .searchIconPortfolio {
    margin-top: 4px;
    height: 22px;
    margin-left: -178px;
    margin-right: 150px;
    object-fit: contain;
  }

  .research {
    border: solid 1px #eeeeee;
    background-color: #fff;
    padding-left: 30px;
    padding-top: 2px;
    font-weight: 500;
    padding-bottom: 2px;
    border-radius: 5px;
    outline: none;
    width: 150px;
    font-size: 12px;
    height: 20px;
  }

  .bar {
    display: flex;
    height: 1px;
    background-color: #eeeeee;
    width: 1240px;
    margin-bottom: -5px;
  }

  .listAlbumsPortfolio {

    height: 657px;
    overflow: scroll;

    .goBackButton {
      margin-top: 10px;
      background-color: #eeeeee;
      padding: 3px 6px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      height: 20px;
      margin-left: 40px;
      cursor: pointer;

      .returnIcon {
        margin-top: 1px;
        width: 8px;
        object-fit: contain;
        opacity: 0.8;
        margin-right: 5px;
      }

      .retour {
        font-size: 11px;
        font-weight: 600;
        margin: 0px;
        margin-right: 5px;
      }
    }

    .title {
      margin-top: 10px;
      margin-bottom: 12.7px;
      margin-left: 15px;
      font-size: 19.5px;
      font-weight: 600;
    }

    .parametresAlbum {
      margin-left: 5px;
      margin-top: 0px;
      width: 23px;
      object-fit: contain;
      opacity: 0.8;
      margin-right: 5px;
    }

    .inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 1265px;
      padding-top: 5px;
      overflow: hidden;

      .albumCardPortfolio {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 12.75px;
        cursor: pointer;
        margin-left: 5.9px;
        margin-right: 25px;
        margin-top: 10px;
        margin-bottom: 15px;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;

        .filter {
          position: absolute;
          width: 100%;
          height: 200px;
          border-radius: 12.75px;
          background-color: rgba(0, 0, 0, 0.23);
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          opacity: 1;
        }

        img {
          width: 100%;
          height: 200px;
          border-radius: 12.75px;
          object-fit: cover;
        }

        .notif {
          margin-top: -29.7px;
          margin-left: 280.5px;
          width: 8.5px;
          height: 8.5px;
          border-radius: 25.5px;
          background-color: #e8c0a6;
        }

        .text {
          margin-top: -45px;
          position: absolute;
          width: 100%;
          height: 77.3px;
          align-items: center;

          .title {
            margin-left: 15px;
            font-size: 16px;
            font-weight: 600;
            color: #FFFFFF;
          }

          .subTitle {
            margin-left: 15px;
            font-size: 12px;
            margin-top: -10px;
            font-weight: 300;
            color: #FFFFFF;
            margin-bottom: 0px;
          }
        }
      }

    }
  }

}

.categoryCardPortfolio {
  position: relative;
  height: 200px;
  border-radius: 12.75px;
  cursor: pointer;
  margin-left: 5.9px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  //transition: all 0.3s;
  //-webkit-transition: all 0.3s;

  .filter {
    position: absolute;
    width: 100%;
    height: 200px;
    border-radius: 12.75px;
    background-color: rgba(0, 0, 0, 0.23);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  img {
    width: 100%;
    height: 200px;
    border-radius: 12.75px;
    object-fit: cover;
  }

  .notif {
    margin-top: -29.7px;
    margin-left: 280.5px;
    width: 8.5px;
    height: 8.5px;
    border-radius: 25.5px;
    background-color: #e8c0a6;
  }

  .text {
    margin-top: -80px;
    position: absolute;
    width: 100%;
    height: 77.3px;
    display: flex;
    align-items: flex-end;

    .title {
      margin-left: 15px;
      margin-right: 15px;
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .subTitle {
      margin-left: 15px;
      font-size: 12px;
      margin-top: -10px;
      font-weight: 300;
      color: #FFFFFF;
      margin-bottom: 0px;
    }
  }
}
