.modalAddVersion {
	background-color: rgb(255, 255, 255);
	position: absolute;
	margin-left: calc((100vw - 550px) / 2);
	margin-top: calc((100vh - 700px) / 2);
	width: 550px;
	height: 700px;
	border-radius: 11px;
	box-shadow: 0px 0px 25.5px 5.2px rgba(0, 0, 0, 0);
	outline: none;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	.crossIcon {
		display: flex;
		position: absolute;
		margin-top: 10px;
		right: 10px;
		width: 22px;
		cursor: pointer;
	}

	.title {
		font-weight: 600;
		font-size: 22px;
		margin-left: 20px;
	}

	.importFile {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f8f8f8;
		height: 70px;
		border: dashed 2px #eeeeee;
		border-radius: 5px;
		margin-top: 0px;
		gap: 10px;
		margin-left: 20px;
		margin-right: 20px;
		cursor: pointer;
		transition: transform ease-in-out 0.2s;
		font-weight: 600;
		font-size: 14px;

		.plusSquare {
			width: 15px;
		}
	}

	.importFile:hover {
		transform: scale(1.02);
	}

	.documentJustImported {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 20px;
		margin-top: 40px;
		margin-bottom: 40px;
		margin-right: 20px;
		padding-left: 10px;
		padding-right: 10px;

		.inputFileName {
			border: none;
			background-color: transparent;
			font-weight: 600;
			font-size: 14px;
			width: 200px;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.pdfIcon {
			width: 30px;
			margin-right: 10px;
		}

		.custom-select-container {
			position: relative;
			width: fit-content;
			margin-left: 20px;
		}

		.custom-select {
			appearance: none;
			-webkit-appearance: none;
			width: 100%;
			font-size: 14px;
			padding: 5px;
			background-color: #ffffff;
			border: 1px solid #ccc;
			border-radius: 4px;
			cursor: pointer;
			outline: none;
			color: #3ca504;
			font-weight: 700;
		}

		.custom-arrow {
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #333;
			pointer-events: none;
		}

		.custom-select:hover {
			background-color: #e8e8e8;
		}
	}

	.littleTitle {
		margin-left: 24px;
		font-size: 14px;
		font-weight: 600;
	}

	.noteDescriptive {
		margin-left: 20px;
		width: 488px;
		background-color: #f8f8f8;
		border: solid 0px;
		border-radius: 10px;
		outline: none;
		font-size: 14px;
		font-weight: 400;
		padding: 10px 10px;
		margin-bottom: 20px;
	}

	.modalAddVersion__accessContainer {
		margin-left: 8px;
		margin-right: 8px;

		.modalAddVersion__accessTitle {
			margin-left: 16px;
			font-size: 14px;
			font-weight: 600;
		}

		.modalAddVersion__noPartners {
			font-weight: 600;
			font-size: 14px;
			color: #1c57dd;
			margin-left: 25px;
			margin-right: 25px;
			text-align: center;
		}
	}

	.buttonSave {
		background-color: #1c57dd;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		height: 40px;
		margin-left: 20px;
		margin-top: 0px;
		margin-bottom: 40px;
		margin-right: 20px;
		cursor: pointer;
		border: none;
		font-size: 14px;
		font-weight: 600;
		width: 510px;
	}
}
