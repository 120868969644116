.modalMoveItem {
  background-color: rgb(255, 255, 255);
  position: absolute;
  margin-left: calc((100vw - 550px)/2);
  margin-top: calc((100vh - 700px)/2);
  width: 550px;
  height: 700px;
  border-radius: 11px;
  box-shadow: 0px 0px 25.5px 5.2px rgba(0, 0, 0, 0);
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  .crossIcon {
    display: flex;
    position: absolute;
    margin-top: 10px;
    right: 10px;
    width: 22px;
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .bar {
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
  }

  .arborescence {
    height: 475px;
    overflow: scroll;
    width: 520px;
    padding-top: 15px;
    padding-bottom: 20px;

    .folderArborescence:hover {
      background-color: #F5F5F5;
    }

    .folderArborescence, .folderArborescenceSelected {
      display: flex;
      align-items: center;
      height: 32px;
      width: 520px;
      cursor: pointer;
      border-radius: 5px;

      .arrowIcon {
        width: 5px;
        object-fit: contain;
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.6;
      }

      .folderIcon {
        width: 20px;
        margin-right: 10px;
      }

      .folderName {
        font-size: 14px;
        margin: 0;
      }
    }

    .folderArborescenceSelected {
      background-color: #1C57DD;

      .arrowIcon {
        width: 5px;
        object-fit: contain;
        margin-left: 10px;
        margin-right: 10px;
        opacity: 1;
      }

      .folderName {
        color: #FFFFFF;
      }
    }
  }

  .containerElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    .elements {
      display: flex;
      align-items: center;
      gap: 20px;

      .elementToMove {
        width: 230px;
        display: flex;
        align-items: center;

        .icon {
          width: 25px;
          margin-right: 10px;
        }

        .elementName {
          font-size: 13px;
          margin: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .strongArrowIcon {
        width: 6px;
        object-fit: contain;
        opacity: 0.3;
      }
    }

    .buttonMove {
      margin-top: 20px;
      color: #1C57DD;
      font-weight: 600;
      font-size: 15px;
      cursor: pointer;
    }

    .loadingContainer {
      margin-top: 20px;
      display: flex;
      color: #1C57DD;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
    }

    .checkVert {
      width: 15px;
      margin-left: 10px;
      margin-top: 2px;
    }

    .buttonLocate {
      width: 23px;
      height: 23px;
      border-radius: 30px;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;
      margin-right: -20px;

      .searchIconItem {
        width: 11px;
        height: 11px;
        opacity:0.5;
      }
    }
  }
}
.overlayModalMoveItem {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.29);
}


.modalActivateFolderAuth {
  background-color: rgb(255, 255, 255);
  position: absolute;
  margin-left: calc((100vw - 550px) / 2);
  margin-top: calc((100vh - 180px) / 2);
  width: 550px;
  border-radius: 11px;
  box-shadow: 0px 0px 25.5px 5.2px rgba(0, 0, 0, 0);
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  .crossIcon {
    display: flex;
    position: absolute;
    margin-top: 10px;
    right: 10px;
    width: 22px;
    cursor: pointer;
  }

  .title {
    width: 500px;
    font-weight: 600;
    font-size: 17px;
    margin-top: 22px;
    margin-bottom: 0px;
  }

  .subtitle {
    width: 500px;
    font-size: 13px;
    opacity: 0.7;
    margin-top: 5px;
  }

  .containerButtons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;

    .button {
      margin-top: 10px;
      width: 210px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      text-align: center;
      font-size: 14px;
    }

    .gris {
      background-color: #eeeeee;
    }

    .vert {
      background-color: rgba(88,194,75,0.25);
      color: #25a414;
    }

    .rouge {
      background-color: rgba(203,41,41,0.16);
      color: #f30000;
    }
  }

  .loading {
    display: flex;
    margin-bottom: 29px;
    font-weight: 600;
  }


}
