.progressionPage {
  flex: 1;
  height: calc(100vh - 55px);
  overflow: scroll;

  .blurDiv {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 240px);
    height: calc(100vh - 100px);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); /* Pour la compatibilité Safari */
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px;
    border-radius: 10px;

    .widgetNoAccess {
      width: 400px;
      height: 160px;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
      background-color: #FFF;
      border: solid 1px #f8f8f8;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 10px;
      padding-right: 10px;

      .firstLine {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .secondLine {
        text-align: center;

      }
    }
  }

  .containerGlobalPercentage {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 20px;
    display: flex;

    p {
      font-size: 70px;
      font-weight: 100;
      margin: 0;
    }

    img {
      width: 18px;
      height: 18px;
      margin-top: 51px;
      margin-left: 10px;
      opacity: 0.7;
    }
  }

  .progressionGlobalContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-left: 30px;
    padding-right: 30px;
    margin-top: -18px;

    .progressionGlobalBackBar {
      width: 100%;
      background-color: #EFF2FE;
      border-radius: 20px;
      height: 5px;

      .progressionGlobalFrontBar {
        max-width: 100%;
        border-radius: 20px;
        height: 5px;
        background-color: #4867EC;
      }
    }

    .progressionPhaseCountContainer {
      justify-self: end;
      display: flex;
      justify-content: flex-end;
      white-space: nowrap;

      p {
        margin-top: 0;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .progressionPhasesContainer {
    padding-top: 20px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 5px;

    .progressionPlaceholder {
      flex: 1;
      height: calc(100vh - 400px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .imgAucunePhase {
        width: 45px;
        object-fit: contain;
        border-radius: 10px;
      }

      .title {
        margin-top: 14px;
        font-size: 25px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 500;
        margin-top: 6px;
        text-align: center;
        opacity: 0.7;
      }
    }
  }

  .progressionBottomRef {
    flex: 1;
    height: 50px;
  }
}

.progressionPage::-webkit-scrollbar {
  display: none;
}

.progressionButton {
  display: flex;
  width: fit-content;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  height: 30px;
  align-items: center;
  gap: 3px;
  background-color: #eeeeee;

  img {
    object-fit: contain;
    margin-right: 5px;
    width: 13px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
}

.progressionInput {
  flex: 1;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 5px;
}


