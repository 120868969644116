.context-menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 100px;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    white-space: nowrap;

    .itemIcon {
        margin-left: 0px;
        width: 16px;
        max-height: 16px;
        opacity: 0.5;
        margin-right: 6px;
    }
}


.context-menu-item {
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
}

.context-menu-item:hover {
    background-color: #eaeaea;
}

.context-menu-item.disabled {
    color: #aaa;
    cursor: not-allowed;
}
