/* Shared modal styles */
.modalTyping,
.bigModalTyping {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 8px;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-top: 0px;
}

.modalTyping {
	width: 450px;
}

.bigModalTyping {
	width: 600px;
}

/* Shared inner elements */
.modalTyping__title {
	margin-left: 20px;
	margin-bottom: 7px;
	font-weight: 600;
}

.modalTyping__crossButton {
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	margin: 8px 8px 0 0;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 0.7;
	}

	img {
		width: 22px;
		object-fit: contain;
	}
}

/* Input styles */
.modalTyping__input {
	margin: 10px 0 20px 20px;
	height: 29px;
	border: solid 1.5px #eee;
	background-color: white;
	padding: 2px 0 2px 10px;
	border-radius: 5px;
	outline: none;
	font-size: 14px;
	font-weight: 500;
}

.modalTyping__input {
	width: 395px;
}

.bigModalTyping .modalTyping__input {
	width: 547px;
}

/* Button access autorisations */

.modalTyping__buttonChooseAutorisations {
	font-size: 13px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7a7a7a;
	margin-bottom: 20px;
}

.modalTyping__autorisationsList {
	border-top: solid 1px #eeeeee;
	flex-direction: column;
	align-items: center;
	transition: height ease-in-out 0.2s;
	width: 100%;
	padding: 0 20px 0px 20px;
	gap: 15px;
	overflow: scroll;

	.modalTyping__title {
		margin-left: 3px;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.modalTyping__bar {
		display: flex;
		height: 1px;
		background-color: #eeeeee;
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 5px;
		margin-right: 5px;
	}
}

/* Button styles */
.modalTyping__button {
	margin: 0 0 20px 20px;
	height: 35px;
	font-size: 14px;
	font-weight: 700;
	width: 410px;
	background-color: rgba(28, 87, 221, 0.1);
	color: #1c57dd;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;
}

.modalTyping__button:hover {
	opacity: 0.7;
}

/* Overlay */
.overlayModalTyping {
	position: fixed;
	inset: 0;
	background-color: rgba(217, 217, 217, 0.7);
}
