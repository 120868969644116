.dropDownPropertyItem {
  z-index: 1000;
  display: flex;
  position: absolute;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.19);
  white-space: nowrap;
  border-radius: 7px;
  width: fit-content;
  background-color: #FFFFFF;
  min-height: 32px;
  border: solid 1px #E0E0E0;
  margin-top: 30px;
  padding-bottom: 5px;
  padding-top: 5px;
  transform: translateX(-100%);

  .containerOption {
    padding: 4px 5px;
    border-radius: 5px;
    margin: 0px 5px 0px 5px;
    cursor: pointer;
    flex: 1;

    .option {
      border-radius: 5px;
      padding: 2px 2px;
      font-size: 13px;
      font-weight: 600;
      display: flex;
      cursor: pointer;
    }
  }

  .containerOption:hover {
    background-color: #f3f3f3;

  }
}
