.companyOrPersonItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;

  .buildingIcon {
    width: 14px;
  }

  .initiales {
    background-color: #dee8ff;
    color: #1C57DD;
    width: 30px;
    height: 30px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;

  }
}
.companyOrPersonItemDropDown, .companyOrPersonItemDropDownUnclickable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  padding: 5px;
  border-radius: 5px;
  margin-left: -5px;
  margin-right: 5px;

  &.selected {
    color: #3CA504;
  }
  .checkVert {
    width: 14px;
    margin-right: 5px;
  }


  .buildingIcon {
    width: 12px;
  }


  .initiales {
    width: 24px;
    height: 24px;
    font-size: 12px;
    background-color: #dee8ff;
    color: #1C57DD;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-right: 7px;
  }
}

.companyOrPersonItemDropDown:hover {
  background-color: #f3f3f3;
  cursor: pointer;

}

