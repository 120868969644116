.partList {
  display: flex;
  margin: 0px 0 25px 0px;
  margin-top: -25px;
  display: flex;
  flex-direction: row;
  min-width: 315px;
  border-bottom: 1px solid #eeeeee;
  height: 44px;
  width: calc(100vw - 221px);
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 6px;
  overflow: scroll;
  gap: 12px;
  align-items: center;

  .rechercheFichiers {
    border: solid 1px #eeeeee;
    background-color: #f8f8f8;
    padding-left: 29px;
    padding-top: 3px;
    padding-bottom: 4px;
    font-weight: 500;
    border-radius: 60px;
    outline: none;
    width: 155px;
    font-size: 14px;
    height: 20px;
    transition: width ease-in-out 0.2s;
    margin-right: 0px;
  }

  .searchIconRechercheFichiers {
    margin-left: 5px;
    margin-right: -27px;
    margin-top: 1px;
    height: 20px;
    object-fit: contain;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    margin-right: 3px;

  }

  .littleBar {
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    width: 1px;
    background-color: #d5d5d5;
  }

  .noPartners {
    font-weight: 600;
    font-size: 14px;
    color: #1C57DD;
  }

  .partItem:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .partItem, .partItemSelected {
    background-color: rgba(168, 168, 168, 0.1);
    border: solid 1px #a2a2a2;
    border-radius: 99px;
    padding: 4px 8px;
    font-size: 13px;
    color: #4b4b4b;
    white-space: nowrap;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    align-items: center;

    .warning {
      width: 12px;
      margin-left: 6px;
      margin-right: 4px;
    }
  }

  .partItemSelected {
    background-color: rgba(28, 87, 221, 0.1);
    color: #1C57DD;
    border: solid 1px #1C57DD;
  }
}
