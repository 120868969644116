.widgetUpload,
.widgetUploadOpen {
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 1000;
	bottom: 0;
	right: 0;
	height: 61px;
	width: 400px;
	margin-right: 75px;
	margin-bottom: 10px;
	border-radius: 10px;

	.headerUpload {
		width: 400px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.imports {
			font-size: 15px;
			font-weight: 600;
			margin-left: 20px;
		}

		.containerButtons {
			display: flex;
			margin-right: 10px;

			.buttonCross {
				width: 30px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				.bottomArrow {
					width: 18px;
					object-fit: contain;
				}

				.blackCrossIcon {
					width: 12px;
					object-fit: contain;
				}
			}
		}
	}

	.listDocument {
		height: 391px;
		overflow: scroll;
	}

	.footerUploadInProgress {
		margin-left: 10px;
		margin-right: 10px;
		height: 50px;
		margin-top: 0px;
		border-radius: 7px;
		background-color: #1741bf;
		display: flex;
		align-items: center;

		.iconPart {
			height: 60px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			.uploadIcon {
				width: 15px;
				object-fit: contain;
			}
		}

		.progressPart {
			height: 50px;
			display: flex;
			align-items: center;

			.textButton {
				width: 310px;
				padding-left: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.text {
					margin: 0;
					color: #ffffff;
					font-weight: 600;
					font-size: 13px;
				}

				.buttonUp {
					width: 25px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					.whiteUp {
						width: 15px;
						object-fit: contain;
					}

					.whiteCross {
						width: 12px;
						object-fit: contain;
					}
				}
			}

			.progressBar {
				display: flex;
				position: absolute;
				height: 50px;
				background-color: #2152fd;
				transition: width ease-in-out 0.2s;
				border-top-right-radius: 7px;
				border-bottom-right-radius: 7px;
			}
		}
	}

	.footerUploadDone {
		margin-left: 10px;
		margin-right: 10px;
		height: 50px;
		margin-top: 0px;
		border-radius: 7px;
		background-color: #3b8441;
		display: flex;
		align-items: center;

		.iconPart {
			height: 60px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			.uploadIcon {
				width: 17px;
				object-fit: contain;
			}
		}

		.progressPart {
			height: 50px;
			display: flex;
			align-items: center;

			.progressBar {
				display: flex;
				position: absolute;
				height: 50px;
				background-color: #5aa260;
				transition: width ease-in-out 0.2s;
				border-top-right-radius: 7px;
				border-bottom-right-radius: 7px;
				width: 330px;
			}
		}
	}
}

.widgetUploadOpen {
	height: 500px;
	box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.21);
	background-color: #ffffff;
}

.itemWidgetUpload {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 55px;
	margin-left: 45px;
	border-bottom: solid 1px #eeeeee;

	.checkVert {
		width: 15px;
		margin-left: 10px;
		margin-top: 2px;
	}

	.leftPart {
		.title {
			margin: 0;
			font-size: 13px;
			font-weight: 600;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 300px;
		}

		.backBar {
			width: 280px;
			height: 5px;
			background-color: #eeeeee;
			border-radius: 10px;
			margin-top: 10px;
			margin-bottom: 3px;

			.frontBar {
				border-radius: 10px;
				height: 5px;
				background-color: #1c57dd;
				width: 100px;
			}
		}

		.greyFolder {
			margin-top: 5px;
			width: 17px;
			margin-right: 6px;
			object-fit: contain;
		}

		.location {
			margin: 0;
			margin-top: 5px;
			font-size: 11px;
			opacity: 0.6;
		}
	}

	.buttonItem {
		width: 23px;
		height: 23px;
		border-radius: 30px;
		background-color: #eeeeee;
		margin-right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.blackCrossIcon {
			width: 11px;
			height: 11px;
			opacity: 0.5;
		}

		.searchIconItem {
			width: 11px;
			height: 11px;
			opacity: 0.5;
		}
	}
}
