.clientWidgets {

  position: absolute;
  height: 770px;
  width: 1500px;
  margin-top: 121px;
  margin-left: 366px;
  align-items: center;
  border: solid;
}
