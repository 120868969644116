.containerPhotoVideo {

  .containerVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    position: absolute;
    height: 100vh;
  }

  .containerPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 0;
  }

  .photoOuvert {
    max-width: calc(100vw - 500px);
    height: calc(100vh - 170px);
    object-fit: contain;
    cursor: pointer;
  }


  .player-wrapper {
    position: relative;
    padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
