.buttonNew {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #2483e2;
	border-radius: 5px;
	font-weight: 600;
	padding: 6px 0px;
	padding-right: 10px;
	font-size: 13px;
	color: #ffffff;

	.whitePlus {
		width: 16px;
		object-fit: contain;
		margin-right: 5px;
		margin-left: 6px;
	}

	&:hover {
		cursor: pointer;
		background-color: #2178cf;
	}

	&:active {
		background-color: #1f6ab4;
	}
}
