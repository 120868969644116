.item,
.itemSelected {
	border: solid 1px #e2e2e2;
	border-radius: 99px;
	padding: 3px 8px 4px 8px;
	font-size: 14px;
	color: #7c7c7c;
	white-space: nowrap;
	transition: opacity ease-in-out 0.2s;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e2e2e2;
		border: solid 1px #c2c2c2;
	}
}

.itemSelected {
	background-color: rgba(28, 87, 221, 0.05);
	color: #1c57dd;
	border: solid 1px #1c56dd58;

	&:hover {
		background-color: rgba(28, 87, 221, 0.1);
	}

	&:active {
		background-color: rgba(28, 87, 221, 0.17);
		border: solid 1px #1c56dd78;
	}
}

.arrowDown,
.arrowDownSelected {
	width: 9px;
	margin-left: 5px;
	object-fit: contain;
	opacity: 0.3;
	margin-top: 3px;
}

.arrowDownSelected {
	opacity: 1;
}

.dropDown,
.dropDownHidden {
	position: absolute;
	z-index: 1000;
	margin-top: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	border: solid 1px #e1e1e1;
	border-radius: 10px;
	width: fit-content;
	white-space: nowrap;
	opacity: 1;
	transition: all ease-in-out 0.2s;
}

.dropDownHidden {
	pointer-events: none;
	opacity: 0;
	transform: translateY(-5px);
}

.dropDownHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 4px 6px 0 6px;

	p {
		font-size: 12px;
		color: #909090;
		margin: 0;

		span {
			color: #656565;
		}
	}
}

.dropDownList {
	padding: 4px;
	gap: 2px;
	margin: 0;
	min-width: 220px;

	li {
		list-style: none;
		display: flex;
		align-items: center;
		border-radius: 6px;
		cursor: pointer;
		padding: 4px 40px 4px 10px;
		gap: 10px;

		&:hover {
			background-color: #f2f2f2;
		}

		p {
			margin: 0;
			font-size: 14px;
			color: #494949;
		}
	}
}

.subOptions {
	display: flex;
	flex-direction: column;

	li {
		padding: 4px 30px 4px 28px;
		gap: 10px;
	}
}

.checkbox,
.checkboxChecked {
	margin: 3px 0px 3px 0px;
	width: 8px;
	height: 8px;
	cursor: pointer;
	border: solid 1px #bababa;
	padding: 2px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #e6e6e6;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		pointer-events: none;
	}
}

.checkboxChecked {
	background-color: #2483E2;
	border: solid 1px #2483E2;

	&:hover {
		background-color: #2483E2;
	}
}

.clearSelectionContainer {
	border-top: solid 1px #e1e1e1;
	padding: 4px;
}

.clearSelection {
	width: calc(100% - 12px);
	border-radius: 6px;
	padding: 6px 0px 6px 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	cursor: pointer;
	color: #494949;

	&:hover {
		background-color: #f2f2f2;
	}
}
