.nouvAgence {

  margin-top: 60px;
  margin-bottom: min(60px); ;
  margin-left: min(429px);
  margin-right: min(60px);
  height: 100%;
  border-radius: 20px;
  min-width: 780px;
  padding-right: 30px;

  box-shadow: 0px 0px 5px 4px rgba(51, 51, 51, 0.1);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .leftPart {

    display: flex;
    flex-direction: column;


    .head {

      display: flex;
      flex-direction: row;

      h3 {
        margin-top: 27px;
        margin-left: 50px;
        font-size: 23px;
      }

      .statut {
        margin-top: 24px;
        margin-left: 40px;
        margin-right: 30px;
        width: 145px;
        height: 35px;
        padding-left: 15px;
        color: #000000;
        outline: none;
        font-size: 15px;
        font-family: $font-1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        font-weight: bolder;
        text-align: center;
      }

    }
  }



  .infos {

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 41vw;
    min-width: 720px;

    margin-top: 60px;
    margin-left: 75px;
    margin-right: 50px;


    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .firstInputs {

        display: flex;
        flex-direction: row;
        width: 100%;

        .inputNomEntreprise {
          margin-top: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

        .inputNomDirigeant {
          margin-top: 20px;
          margin-left: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

      }

      .secondInputs {

        display: flex;
        flex-direction: row;

        .inputEmail {
          margin-top: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

        .inputTelephone {
          margin-top: 20px;
          margin-left: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

      }

      .thirdInputs {

        display: flex;
        flex-direction: row;

        .inputmdp {
          margin-top: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

        .inputVerifmdp {
          margin-top: 20px;
          margin-left: 20px;
          width: 19vw;
          min-width: 350px ;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 9px;
          padding-left: 16px;
          outline: none;
          font-size: 15px;
        }

      }

      .ligne2 {

        width: 40.6vw;
        min-width: 750px;
        border-bottom: 0.5px solid #C4C4C4;
        margin-top: 30px;
        margin-bottom: 30px;

      }

      .inputNote {
        width: 40vw;
        min-width: 734px;
        height: 250px;
        background-color: #EAEAEA;
        border-width: thin;
        border: 0px;
        border-radius: 9px;
        padding-top: 9px;
        padding-left: 16px;
        outline: none;
        font-size: 15px;
        font-family: $font-1;
      }

      .bouton {
        margin-top: 70px;
        width: 230px;
        height: 35px;
        border-radius: 10px;
        font-family: $font-1;
        font-size: 14px;
        color: #FFFFFF;
        background-color: #2E507E;
        border: #2E507E;
        outline: none;
        font-weight: bolder;
        cursor: pointer;
      }

    }


  }

}
