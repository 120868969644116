.modalNameVersion {
    position: absolute;
	width: 410px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgb(255, 255, 255);
	border-radius: 11px;
	box-shadow: 0px 0px 25.5px 5.2px rgba(0, 0, 0, 0);
	outline: none;
    padding-left: 20px;
    padding-right: 20px;

	.modalNameVersion__crossButton {
		display: flex;
		position: absolute;
		right: 0;
		top: 0;
		margin-top: 10px;
		margin-right: 10px;
		cursor: pointer;
        transition: opacity 0.3s ease-in-out;

		&:hover {
			opacity: 0.7;
		}

		img {
            width: 22px;
            object-fit: contain;
        }
    }

    .modalNameVersion__title {
        margin-left: 4px;
		margin-bottom: 7px;
		font-weight: 600;
    }
  
    input {
        display: flex;
        flex: 1;
        height: 29px;
        border: solid 1.5px #eeeeee;
        background-color: #fff;
        padding-left: 10px;
        margin-top: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 5px;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }
  
    .modalNameVersion__selectContainer {
        position: relative;
        margin-left: 10px;
        margin-top: 10px;

        select {
            height: 36px;
            appearance: none;
            -webkit-appearance: none;
            width: 100%;
            font-size: 14px;
            padding: 7px;
            background-color: #ffffff;
            border: 1.5px solid #eeeeee;
            border-radius: 5px;
            cursor: pointer;
            outline: none;
            color: #3CA504;
            font-weight: 700;

			&:hover {
				background-color: #eeeeee;
			}
		}
	}

	.modalNameVersion__button {
        width: 100%;
        margin-bottom: 20px;
        height: 35px;
        font-size: 14px;
        font-weight: 600;
        background-color: #D2E7F8;
        color: #4B5FB0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;

		&:hover {
			opacity: 0.7;
		}
	}
}

.overlayModalNameVersion {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(217, 217, 217, 0.7);
}
