.profilePictureRight {

  margin-left: 94vw;
  display: flex;
  margin-top: 20px;

  img {

    height: 40px;
    width: 40px;
    object-fit: cover;

  }
}
