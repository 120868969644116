.vierge {

  .ligne1 {
    width: 600px;
    height: 7px;
    background-color: #c4c4c4;
    position: absolute;
    margin-left: 500px;
    margin-top: 200px;
    border-radius: 20px;
  }

  .ligne2 {
    width: 350px;
    height: 7px;
    border-radius: 20px;
    background-color: #2e507e;
    position: absolute;
  }
}

.test {

  .firstBar {

    margin-top: 200px;
    margin-left: 500px;
    width: 50em;
  }

  .pourcentage {
    margin-top: 10px;
    margin-left: 500px;
  }

  h3 {
    position: absolute;
    margin-top: 100px;
    margin-left: 500px;
  }

}

.todolist1 {
  position: absolute;
  margin-left: 500px;
  margin-top: 100px;
  width: 581px;
  height: 464px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 4px rgba(51, 51, 51, 0.1);

  h3 {
    margin-left: 40px;
    margin-top: 30px;
    font-size: 20px;
  }

  .input {
    margin-top: 20px;
    margin-left: 40px;
    width: 490px;
    height: 35px;
    background-color: #EAEAEA;
    border-width: thin;
    border: 0px;
    border-radius: 9px;
    padding-left: 16px;
    outline: none;
    font-size: 15px;
  }

  .bouton {
    margin-left: 30px;
    margin-top: 20px;
    width: 1px;
    height: 1px;
    opacity: 0%;
  }

  .liste {
    margin-top: 25px;
    margin-left: 40px;
  }

  .tache {
    margin-top: 15px;
  }

  .supprimer {
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    padding-bottom: 10px;
    border-width: thin;
    border-color: #000000;
    background-color: #FFFFFF;
    outline: none;


  }

}
