.subPageSettingsProject {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 192px);
	overflow: scroll;
	padding-top: 10px;
	padding-bottom: 30px;

	.titleSubPage {
		font-size: 16px;
		font-weight: 700;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 30px;
	}

	.subtitleSubPage {
		margin-top: 0px;
		font-size: 14px;
		color: #84837f;
		margin-bottom: 20px;
		margin-left: 30px;
	}

	.inputSettings {
		margin-bottom: 17px;
		margin-left: 30px;

		.titleInput {
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 10px;
			color: #84837f;
		}

		.inputTextSettings {
			border: solid 1px #dfdfdf;
			border-radius: 7px;
			font-size: 14px;
			padding: 11px 11px;
			outline: none;
			width: 400px;

			&:disabled {
				background-color: #f6f8fa;
				border-color: #f0f0f1;
			}
		}

		.copyIcon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			margin-left: 5px;
			border-radius: 8px;
			transition: transform ease-in-out 0.2s;

			.checkVertIcon,
			.copyIcon {
				width: 15px;
			}

			&:hover {
				cursor: pointer;
				opacity: 0.5;
			}
		}

		.id {
			font-family: Menlo, Consolas, monospace;
		}

		.projectPicture {
			width: 300px;
			height: 300px;
			border-radius: 10px;
			transition: transform ease-in-out 0.2s;
			background-color: #f8f8f8;

			&:hover {
				transform: scale(0.98);
				cursor: pointer;
			}
		}

		.containerReplaceIcon {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f6f8fa;
			height: 30px;
			width: 30px;
			margin-left: 20px;
			border-radius: 99px;
			transition: transform ease-in-out 0.2s;

			.replaceIcon {
				width: 22px;
				opacity: 0.7;
			}

			&:hover {
				transform: scale(1.1);
				cursor: pointer;
			}
		}

		.switchContainer {
			display: flex;
			align-items: center;
			gap: 10px;

			.switch {
				position: relative;
				width: 200px;
				height: 37px;
				background-color: #f6f8fa;
				border-radius: 13px;
				padding: 3px;
				cursor: pointer;

				.switchLabels {
					position: relative;
					z-index: 1;
					display: flex;
					height: 100%;

					.label {
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 14px;
						font-weight: 600;
						color: #84837f;
						transition: color 0.3s ease;
						user-select: none;

						&.active {
							color: #1c57dd;
						}
					}
				}

				.switchButton {
					position: absolute;
					top: 3px;
					left: 3px;
					width: calc(50% - 3px);
					height: calc(100% - 6px);
					background-color: white;
					border-radius: 10px;
					box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
					transition: transform 0.3s ease;

					&.right {
						transform: translateX(100%);
					}
				}
			}
		}
	}
}

.informationsGroup {
	display: flex;
	flex-direction: column;
	position: relative;
	border: solid 1px #dfdfdf;
	margin-right: 40px;
	border-radius: 7px;
	padding: 10px 20px;
	margin-top: 6px;
	margin-bottom: 15px;
	margin-left: 30px;

	.editButton {
		display: flex;
		position: absolute;
		right: 0;
		margin-right: 20px;
		font-weight: 700;
		font-size: 14px;
		color: #1c57dd;
	}

	p {
		margin: 10px 0px;
	}

	.informationSubGroup {
		.nameInformationSubGroup {
			font-weight: 600;
			font-size: 14px;
		}

		.itemInformation {
			display: flex;
			color: #596171;
			font-size: 14px;

			.labelInformation {
				width: 250px;
			}

			.contentInformation {
				white-space: pre-line;
			}
		}
	}
}

.itemActivate {
	display: flex;
	align-items: center;
	margin-left: 30px;

	.nameItemActivate {
		width: 200px;
	}

	.choiceAcces {
		margin-top: 10px;
		width: 40px;
		height: 22px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;

		.buttonChoice:hover {
			opacity: 0.9;
			cursor: pointer;
		}

		.buttonChoice {
			width: 19px;
			height: 19px;
			border-radius: 99px;
			background-color: #ffff;
			box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
			transition: all 0.3s;
			-webkit-transition: all 0.3s;
		}
	}
}

.modalEditProjectInformations {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 480px;
	min-height: 535px;
	max-height: calc(100vh - 240px);
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

	.headerModalProjectInformations {
		height: 55px;
		border-bottom: solid 1px #dfdfdf;
		display: flex;
		align-items: center;

		.titleHeaderModalProjectInformations {
			font-weight: 700;
			margin: 0;
			margin-left: 20px;
			font-size: 19px;
		}

		.crossIcon {
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			width: 23px;
			margin-top: 10px;
			margin-right: 10px;
		}
	}

	.containerInputs {
		height: calc(100% - 56px);
		overflow: scroll;

		.inputModalEditProjectInformations {
			margin-left: 20px;
			margin-right: 38px;
			margin-bottom: 30px;

			.nameInputModalEditProjectInformations {
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 10px;
			}

			.inputTextModalEditProjectInformations {
				width: 100%;
				border: solid 1px #dfdfdf;
				border-radius: 7px;
				font-size: 14px;
				padding: 7px 8px;
				outline: none;

				&:disabled {
					background-color: #f6f8fa;
					border-color: #f0f0f1;
					color: #74736f;
				}
			}

			.subtitleInputModalEditProjectInformations {
				color: #84837f;
				font-size: 13.5px;
				line-height: 18px;
				margin-top: 9px;
			}
		}

		.containerButtonsSave {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-right: 20px;
			margin-top: -5px;
			gap: 10px;
			margin-bottom: 25px;

			.cancel,
			.save {
				font-weight: 600;
				font-size: 14px;
				border: solid 1px #dfdfdf;
				border-radius: 7px;
				padding: 6px 7px;
			}

			.save {
				background-color: #1c57dd;
				color: #ffffff;
				border-color: #1c57dd;
			}
		}
	}
}
