
.login {

  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;

  .rightPart {
    display: flex;
    height: 100vh;
    width: 50vw;
    background-color: #F4F9FF;
    align-items: center;
    transition: width 1s;
    -webkit-transition: width 1s;

    .imgConnexion {
      height: 100vh;
      object-fit: contain;
    }
  }

  .leftPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50vw;
    min-width: 500px;
    transition: width 1s;
    -webkit-transition: width 1s;

    .firstPart {
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      height: calc(100vh - 100px);
      transition: all 1s;
      -webkit-transition: all 1s;

      .title {
        margin-top: 10px;
        font-size: 30px;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 15px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.51);
      }

      .inputGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .eye {
          width: 20px;
          object-fit: contain;
          margin-left: -33px;
          margin-right: 33px;
          opacity: 0.5;
        }

        .eye:hover {
          cursor: pointer;
          opacity: 1;
        }

        .inputTitle {
          font-size: 13px;
          font-weight: 600;
          margin-top: 25px;
        }

        .input, .inputRouge {
          padding-left: 15px;
          outline: none;
          width: 310px;
          height: 45px;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.5px;
          border-radius: 6px;
          border: solid 1px #B9BABD;
          padding-right: 40px;
        }

        .inputRouge {
          border: solid 1px #E95D66;
        }

        input::-webkit-input-placeholder {
          color: black; /*Change the placeholder color*/
          opacity: 0.5; /*Change the opacity between 0 and 1*/
        }

        .choiceNombre:hover {
          opacity: 0.7;
        }

        .choiceNombre {
          height: 35px;
          width: 120px;
          border: solid 1px #eaeaea;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
          cursor: pointer;

          .nombreChoice {
            font-size: 14px;
            font-weight: 500;

          }
        }
      }

      .motDePasseOublie:hover {
        opacity: 0.8;
      }

      .motDePasseOublie {
        font-size: 14px;
        font-weight: 600;
        margin-top: 24px;
        color: #4867EC;
        cursor: pointer;
      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 0px;
        width: 367px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 0px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .pasCompte {
        font-size: 14px;
        opacity: 0.7;
      }

      .inscription:hover {
        opacity: 0.7;
      }

      .inscription {
        margin-top: 14px;
        margin-left: 7px;
        font-size: 14px;
        color: #4867EC;
        font-weight: 600;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .loginComponent {
        height: calc(100vh - 100px);
        display: flex;
        align-items: center;
        flex-direction: column;
        vertical-align: middle;
        justify-content: center;
        transition: width, opacity 0.5s;
        -webkit-transition: width, opacity 0.5s;

        .emailInfo {
          border: solid 1px #D0D1D3;
          padding-left: 8px;
          padding-right: 20px;
          border-radius: 20px;
          padding-top: 7px;
          padding-bottom: 7px;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .firstLetter {
            width: 22px;
            height: 22px;
            color: #FFFFFF;
            background-color: #7FA3FC;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            margin-right: 10px;
            font-size: 13px;
          }
        }

        .logo1 {
          width: calc(50vw - 500px);
          object-fit: cover;
        }

        h3 {
          margin-top: -5%;
          font-size: 40px;
          font-weight: 100;
        }

        .errorMessage {
          font-size: 14px;
          font-weight: 300;
          color: #ea2121;
          letter-spacing: 0.5px;
          width: 300px;
        }

        .tropDeTentatives {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .inputEmail {
          margin-top: 30px;
          width: calc(50vw - 300px);
          height: 35px;
          background-color: #f8f8f8;
          border: solid;
          border: 1px;
          border-radius: 9px;
          padding-left: 16px;
          align-items: center;
          outline: none;
          font-size: 15px;
        }

        .group {
          width: calc(50vw - 284px);
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          .inputmdp {
            margin-top: 30px;
            width: calc(50vw - 300px);
            height: 35px;
            background-color: #f8f8f8;
            border: 0px;
            border-radius: 9px;
            padding-left: 16px;
            align-self: center;
            outline: none;
            font-size: 15px;
          }

          .noAccount {
            margin-top: 10px;
            height: 30px;
            border-radius: 10px;
            font-family: $font-1;
            font-size: 13px;
            color: #777777;
            background-color: #ffffff;
            border: #2E507E;
            outline: none;
            font-weight: bolder;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .or {
          display: flex;
          flex-direction: row;
          margin-top: 60px;

          ou {
            margin-top: -7.5px;
            margin-left: 15px;
            margin-right: 15px;
            color: #777777;
            font-weight: 600;
            font-size: 13px;
          }

          .bar {
            width: calc((50vw - 284px)/2 - 30px);
            border-radius: 20px;
            height: 2.5px;
            background-color: #eeeeee;
          }
        }
      }
    }

    .thirdPart {
      padding-left: 20px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      display: flex;
      min-width: 500px;



      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

    .firstLog {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .firstLogTitle {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
      }

      .firstLogTitle2 {
        font-weight: bold;
        font-size: 23px;
        color: #9a9a9a;
        margin-top: 0px;
        letter-spacing: 0.5px;
      }

      .informations {
        margin-top: 0px;
        margin-left: 10px;
        font-weight: 400;
        color: #727272;
        font-size: 13px;
        letter-spacing: 0.5px;
        width: 320px;
        margin-bottom: 30px;
      }

      .profilePicture {
        margin-top: 10px;
        width: 190px;
        height: 190px;
        border-radius: 200px;
      }

      .bar {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 330px;
        height: 2px;
        border-radius: 20px;
        background-color: #eeeeee;
      }

      .retour {
        font-weight: 400;
        color: #939393;
        font-size: 13px;
        cursor: pointer;
      }

      .retour:hover {
        opacity: 0.7;
      }
    }

  }

}

.loadingPageO {
  font-size: 100px;
  font-weight: 100;
  transition: font-size 3s;
}

.loadingPageO.big-width {
  font-size: 5000px;
}
