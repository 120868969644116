.headerNavigation {

  display: flex;
  flex-direction: row;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;


  .navigation {
    margin-left: 30.5px;
    margin-top: 15.2px;

    .first {
      margin-right: 107px;
    }

    a {
      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;
    }

    .nav-active {
      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;
      border-bottom: 3px solid $color-1;
    }

  }

}

.headerNavigationProjets {

  display: flex;
  flex-direction: row;
  width: calc(100vw - 350px);
  height: 50px;
  margin-bottom: -10px;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;



  .navigation {

    min-width: 200px;
    width: calc(100vw - 320px);
    max-width: 255px;
    flex-direction: column;
    margin-left: 30.5px;
    margin-top: 15.2px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .first {
      margin-right: 107px;

    }

    a {
      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;
    }

    a:hover {
      font-weight: 500;
      color: #4A4A4A;
    }

    .bar {
      width: 100%;
      height: 3px;
      margin-top: 7px;
      margin-left: 0px;
      border-radius: 20px;
      opacity: 0;
      background-color: $color-1;
    }

    .nav-active {

      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;

      .bar {
        opacity: 1;
      }
    }

  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}

.headerNavFicheProjet {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 350px);
  height: 50px;
  margin-bottom: -10px;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;



  .navigation {

    min-width: 200px;
    width: calc(100vw - 320px);
    max-width: 720px;
    flex-direction: column;
    margin-left: 30.5px;
    margin-top: 15.2px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .first {
      margin-right: 107px;

    }

    a {
      letter-spacing: 0.3px;
      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;

    }

    a:hover {
      font-weight: 500;
      color: #4A4A4A;
    }

    .bar {
      width: 100%;
      height: 2px;
      margin-top: 7px;
      margin-left: 0px;
      border-radius: 20px;
      opacity: 0;
      background-color: $color-1;
    }

    .nav-active {

      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;


      .bar {
        opacity: 1;
      }
    }

  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}

.headerNavComptesLies {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 350px);
  height: 50px;
  margin-bottom: -10px;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;


  .navigation {

    min-width: 200px;
    width: calc(100vw - 320px);
    max-width: 470px;
    flex-direction: column;
    margin-left: 30.5px;
    margin-top: 15.2px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .first {
      margin-right: 107px;

    }

    a {

      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;
    }

    a:hover {
      font-weight: 500;
      color: #4A4A4A;
    }

    .bar {
      width: 100%;
      height: 3px;
      margin-top: 7px;
      margin-left: 0px;
      border-radius: 20px;
      opacity: 0;
      background-color: $color-1;
    }

    .nav-active {

      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;

      .bar {
        opacity: 1;
      }
    }

  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}

.headerNavServicesOpus {

  display: flex;
  flex-direction: row;
  width: calc(100vw - 350px);
  height: 50px;
  margin-bottom: -10px;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;



  .navigation {

    min-width: 200px;
    width: calc(100vw - 320px);
    max-width: 500px;
    flex-direction: row;
    margin-left: 30.5px;
    margin-top: 15.2px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .first {
      margin-right: 107px;

    }

    a {

      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;
    }

    a:hover {
      font-weight: 500;
      color: #4A4A4A;
    }

    .bar {
      width: 100%;
      height: 3px;
      margin-top: 7px;
      margin-left: 0px;
      border-radius: 20px;
      opacity: 0;
      background-color: $color-1;
    }

    .nav-active {

      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;

      .bar {
        opacity: 1;
      }
    }

  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}

.headerNavParametres {

  display: flex;
  flex-direction: row;
  width: calc(100vw - 350px);
  height: 50px;
  margin-bottom: -10px;
  font-size: 14.5px;
  color: #676767;
  margin-left: 237px;
  margin-right: 305.8px;
  margin-top: 24.4px;



  .navigation {

    min-width: 200px;
    width: calc(100vw - 320px);
    max-width: 200px;
    flex-direction: row;
    margin-left: 30.5px;
    margin-top: 15.2px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;

    .first {
      margin-right: 107px;

    }

    a {
      margin-right: 40.5px;
      font-weight: 500;
      font-size: 13px;
      color: #9A9A9A;
      margin-bottom: 39.7px;
    }

    a:hover {
      font-weight: 500;
      color: #4A4A4A;
    }

    .bar {
      width: 100%;
      height: 3px;
      margin-top: 7px;
      margin-left: 0px;
      border-radius: 20px;
      opacity: 0;
      background-color: $color-1;
    }

    .nav-active {

      font-weight: 500;
      color: #4A4A4A;
      padding-bottom: 7.6px;

      .bar {
        opacity: 1;
      }
    }

  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}
