.documentInfos {
	margin: -25px 0 0;
	border-left: solid 1px #eeeeee;
	width: 389px;
	height: calc(100vh - 180px);
	overflow: scroll;

	.itemInfos {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.separator {
			height: 50px;
		}

		.containerIconOpening:hover {
			.containerOpenIcon {
				opacity: 1;
			}
		}

		.containerOpenIcon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f8f8f8;
			width: 50px;
			height: 50px;
			border-radius: 99px;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;

			.openIcon {
				width: 20px;
				object-fit: contain;
				opacity: 0.7;
			}
		}

		.seeVersions {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0px 12px;
			margin-top: 20px;
			box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0);
			background-color: #ffffff;
			border: solid 1px #eeeeee;
			border-radius: 10px;
			font-size: 13px;
			font-weight: 700;
			transition: all ease-in-out 0.2s;
			overflow: hidden;

			.headerVersionViewer {
				display: flex;
				align-items: center;
				height: 40px;
				justify-content: space-between;
				width: 100%;
				white-space: nowrap;

				.historyGreen {
					width: 15px;
					margin-left: 5px;
				}
			}

			.barListVersions {
				width: 322px;
				height: 1px;
				background-color: #eeeeee;
				margin-top: 1px;
			}

			.docList {
				display: flex;
				flex-direction: column;
				margin-top: 10px;
				gap: 5px;

				.itemVersionDoc {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 310px;
					padding: 5px 6px;
					border-radius: 5px;
					cursor: pointer;

					.pdfIcon {
						width: 25px;
						object-fit: contain;
						margin-right: 15px;
					}

					.docName {
						margin: 0;
						font-size: 14px;
						font-weight: 600;
					}

					.version {
						color: #3ca504;
						font-weight: 700;
						margin-right: 5px;
					}
				}

				.itemVersionDoc:hover {
					background-color: #f8f8f8;
				}
			}

			.buttonPlusSquare {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #f8f8f8;
				width: 100%;
				height: 40px;
				border: dashed 2px #eeeeee;
				border-radius: 5px;
				margin-top: 15px;
				gap: 10px;
				cursor: pointer;
				transition: transform ease-in-out 0.2s;
				margin-bottom: 11px;

				.plusSquare {
					width: 15px;
				}
			}

			.buttonPlusSquare:hover {
				transform: scale(1.02);
			}
		}

		.multipleSelection {
			margin-top: 35px;
			width: 180px;
		}

		.folderIcon {
			width: 100px;
			object-fit: contain;
		}

		.itemName {
			margin-top: 25px;
			font-size: 15px;
			font-weight: 700;
			text-align: center;
			width: 300px;
			margin-bottom: 0;
		}

		.complementaryInfo {
			font-size: 13px;
			margin-top: 10px;
			opacity: 0.5;
		}

		.adder {
			margin: 0;
			font-size: 14px;
			font-weight: 700;
			color: #1c57dd;
		}
	}

	.containerButtons {
	}

	.containerButtons {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;

		div {
			display: flex;
			margin-top: 10px;
			margin-bottom: 10px;

			.hover {
				width: 45px;
				height: 45px;
				background-color: #f8f8f8;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 15px; // Except the first child

				&:first-child {
					margin-left: 0;
				}

				img {
					width: 16px;
					object-fit: contain;
				}
			}
		}
	}

	.containerAccess {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;

		.accessibilityTitle {
			font-weight: 600;
			font-size: 13px;
			width: 320px;
			margin-bottom: 15px;
		}

		.noteDescriptive {
			width: 325px;
			background-color: #f8f8f8;
			border: solid 0px;
			border-radius: 10px;
			outline: none;
			font-size: 14px;
			font-weight: 400;
			padding: 10px 10px;
			margin-bottom: 20px;
		}
	}

	.noPartners {
		font-weight: 600;
		font-size: 14px;
		color: #1c57dd;
		margin-left: 25px;
		margin-right: 25px;
		text-align: center;
	}

	.restoreButton {
		background-color: #f6f6f6;
		border: solid 1px #ffffff;
		height: 40px;
		font-weight: 600;
		border-radius: 10px;
		margin-left: 20px;
		width: 350px;
		font-size: 13px;
		margin-bottom: 5px;
	}

	.containerConfirmLecture {
		.bar {
			width: 100%;
			height: 1px;
			background-color: #eeeeee;
			margin-top: 15px;
			margin-bottom: 10px;
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			.title {
				margin-left: 20px;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 25px;
			}

			.buttonPlusMoins {
				margin-top: 3px;
				font-size: 13px;
				font-weight: 600;
				margin-right: 20px;
				color: #1c57dd;
				cursor: pointer;
			}
		}

		.seenLength {
			color: #1c57dd;
			font-size: 12px;
			margin-left: 5px;
			font-weight: 700;
		}

		.listConsult {
			overflow: hidden;
		}

		.consultConfirmItem {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			.left {
				display: flex;
				align-items: center;
				padding-left: 20px;

				.profilePicture {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					margin-right: 10px;
					object-fit: cover;
				}

				.placehodlerProfilePicture {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background-color: #eeeeee;
					margin-right: 10px;
				}

				.nameSurname {
					font-size: 14px;
					font-weight: 600;
					margin: 0;
				}
			}

			.right {
				margin-right: 20px;

				.dateHour {
					font-size: 12px;
					font-weight: 600;
					color: rgba(0, 0, 0, 0.41);
					margin: 0;
				}
			}
		}
	}
}

.modalAccessibilityFolder,
.modalSetAccessDuringImport {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 540px) / 2);
	margin-left: calc((100vw - 770px) / 2);
	width: 770px;
	max-height: 535px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
	}

	.titleModal {
		font-size: 20px;
		font-weight: 600;
		width: 100%;
		margin-left: 50px;
	}

	.containerFirstPart {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 720px;
		padding-left: 50px;
		padding-right: 50px;
	}

	.folderSelected {
		display: flex;
		align-items: center;

		.folderIcon {
			height: 50px;
			object-fit: contain;
			margin-right: 16px;
		}

		.folderName {
			margin: 0;
			font-weight: 600;
		}

		.folderElems {
			margin: 0;
			margin-top: 3px;
			opacity: 0.7;
			font-size: 14px;
		}
	}

	.itemChoiceNotif:hover {
		transform: scale(1.02);
	}

	.itemChoiceNotif {
		display: flex;
		background-color: #eaeefb;
		margin-left: 13px;
		margin-right: 13px;
		border-radius: 10px;
		flex-direction: column;
		opacity: 1;
		margin-bottom: 15px;
		padding-right: 10px;
		cursor: pointer;
		transition: all ease-in-out 0.2s;

		.titleAccessibility {
			margin-left: 15px;
			font-size: 13px;
			font-weight: 600;
			display: flex;
			color: #2d56d5;
		}

		.roundedButton {
			margin-right: 2px;
			margin-left: 30px;
			margin-top: 10px;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: solid 2.5px #0057fe;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all ease-in-out 0.2s;

			.littleCircle {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #0057fe;
				transition: all ease-in-out 0.2s;
			}
		}
	}

	.bar {
		margin-top: 20px;
		width: 100%;
		margin-left: 20px;
		margin-right: 20px;
		background-color: #e5e5e5;
		height: 1px;
	}

	.doubleChoice {
		.containerChoices {
			display: flex;
		}

		.notificationIcons {
			img {
				margin-top: 9px;
				height: 22px;
				object-fit: contain;
				margin-right: 2px;
				margin-left: 28px;
			}

			@keyframes shakeRotate {
				0% {
					transform: rotate(0);
				}
				14% {
					transform: rotate(-10deg);
				}
				28% {
					transform: rotate(10deg);
				}
				42% {
					transform: rotate(-10deg);
				}
				57% {
					transform: rotate(10deg);
				}
				71% {
					transform: rotate(-10deg);
				}
				85% {
					transform: rotate(10deg);
				}
				100% {
					transform: rotate(0);
				}
			}

			.shake-animation {
				animation: shakeRotate 0.6s; /* Durée de l'animation */
				animation-iteration-count: infinite; /* Répéter l'animation indéfiniment */
			}
		}
	}

	.choiceActorsComponent,
	.doubleChoice {
		display: flex;
		flex-direction: column;
		padding-left: 12px;
		padding-right: 12px;

		.question {
			margin-top: 25px;
			margin-left: 15px;
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 0px;
		}

		.subtitle {
			margin-left: 15px;
			opacity: 0.7;
			font-size: 14px;
			margin-top: 5px;
			margin-bottom: 25px;
		}

		.listActors {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.largeButton:hover {
		opacity: 0.7;
	}
	.largeButtonUpload {
		margin-top: 20px;
		background-color: #0057fe; /* Couleur de fond par défaut, remplacée par le dégradé */
		width: 720px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		color: #ffffff;
		font-weight: 600;
		font-size: 15px;
		cursor: pointer;
		margin-bottom: 25px;
		/* Définir l'arrière-plan dégradé initial */
		background: linear-gradient(270deg, rgba(0, 87, 254, 0.44), rgba(0, 198, 255, 0.43));
		background-size: 400% 400%;
		/* Animation */
		animation: AnimationName 8s ease infinite;
	}

	/* Définition des keyframes pour l'animation */
	@keyframes AnimationName {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}

	.largeButton {
		margin-top: 20px;
		background-color: #0057fe;
		width: 720px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		color: #ffffff;
		font-weight: 600;
		font-size: 15px;
		transition: all ease-in-out 0.2s;
		cursor: pointer;
		margin-bottom: 25px;
	}
}

.modalSetAccessDuringImport {
	max-height: 750px;
	overflow: scroll;
}

.itemAccessibility,
.itemAccessibilityActivated {
	display: flex;
	background-color: #f8f8f8;
	margin-left: 13px;
	margin-right: 13px;
	border-radius: 10px;
	flex-direction: row;
	opacity: 1;
	margin-bottom: 15px;
	padding-right: 10px;
	transition: all ease-in-out 0.2s;
	justify-content: space-between;

	.titleAccessibility,
	.littleTitleAccessibility {
		margin-left: 15px;
		font-size: 13px;
		font-weight: 600;
		display: flex;
		width: 268px;
	}

	.littleTitleAccessibility {
		display: none;
		width: 40px;
	}

	.roundedButton {
		margin-right: 2px;
		margin-left: 30px;
		margin-top: 10px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: solid 2.5px #a2a2a2;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all ease-in-out 0.2s;
		animation: spin 1s linear infinite;

		.littleCircle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: rgba(238, 238, 238, 0);
			transition: all ease-in-out 0.2s;
		}
	}

	.containerChoiceRounded {
		display: none;
		min-width: 275px;
		gap: 12px;
		justify-content: flex-end;
		padding-right: 5px;
		width: fit-content;
	}

	.roundedButtonChoice {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: solid 2.5px #a2a2a2;
		display: none;
		align-items: center;
		justify-content: center;
		transition: all ease-in-out 0.2s;

		.littleCircle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: rgba(238, 238, 238, 0);
			transition: all ease-in-out 0.2s;
		}
	}

	.designantChoice {
		white-space: nowrap;
		font-size: 13px;
		font-weight: 600;
		margin: 0;
	}
}

.itemAccessibilityActivated {
	background-color: #eaeefb;

	&.hidden {
		background-color: #f3f3f3;
	}

	&.readonly {
		background-color: #fee7d1;
	}

	&.all {
		background-color: #eaeefb;
	}

	&.adminOnly {
		background-color: #ece9fb;
	}
}

.itemAccessibility:hover,
.itemAccessibilityActivated:hover {
	background-color: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.21);

	.titleAccessibility {
		display: none;
	}

	.littleTitleAccessibility {
		display: flex;
	}

	.roundedButton {
		display: none;
	}

	.containerChoiceRounded {
		display: flex;
	}

	.roundedButtonChoice {
		margin-right: 2px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: solid 0px #a2a2a2;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all ease-in-out 0.2s;

		.littleCircle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: rgba(238, 238, 238, 0);
			transition: all ease-in-out 0.2s;
		}
	}
}
