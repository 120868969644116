.modalDelete {
  position: relative;
  display: flex;
  width: 500px;
  max-height: 95vh;
  background-color: #fff;
  border-radius: 8px;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 0px;
  flex-direction: column;


  .titleModalDelete {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
  }

  .buttonDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 5px;
    flex: 1;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
  }

}
