.modalResiliation {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 590px)/2);
  margin-left: calc((100vw - 690px)/2);
  width: 690px;
  height: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin-top: 20px;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0.4px;
    transition: all 1s;
  }

  .reacap {
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 1s;
  }

  .corps {
    margin-left: 30px;
    margin-right: 30px;
    line-height: 27px;
    letter-spacing: 0.5px;
    text-align: justify;
  }

  .joursRestant {
    margin-top: 10px;
    width: 220px;
    height: 200px;
    background-color: #F6F9FC;
    border-radius: 15px;
    display: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nombreJours {
      margin: 0px;
      font-size: 100px;
      font-weight: 200;
      color: #4B5FB0;
    }

    .restant {
      margin-top: 10px;
      font-weight: 500;
      opacity: 0.8;
      letter-spacing: 0.6px;
    }
  }

  .buttonAnnuler:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  .buttonAnnuler {
    margin-top: 55px;
    width: 400px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4B5FB0;
    opacity: 0.8;
    transition: opacity 0.3s;
    border-radius: 8px;

    .textButtonAnnuler {
      margin: 0;
      color: #FFFFFF;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .contact:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  .contact {
    margin-top: 15px;
    font-weight: 500;
    opacity: 0.8;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #4B5FB0;
    transition: opacity 0.3s;
  }

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

}
