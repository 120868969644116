.modalAddProjetCollaborateur {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 717px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 717px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 22px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 0px;
  }

  .subtitle {
    margin-top: 8px;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 30px;
    text-align: justify;
    margin-bottom: -10px;
    opacity: 0.7;
  }

  .listProjetAjoute {

    .innerListProjetAjoute {
      margin-top: -20px;
      overflow: scroll;
      max-height: 562px;
      padding-top: 10px;
    }

    .innerListProjetAjoute::-webkit-scrollbar {
      display: none;
    }
  }
}

.cardProjetListItem {
  width: 530px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 110px;

  .imageProjet {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 25px;
  }

  .titleProjet {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .subtitleProjet {
    margin-top: 0px;
    font-size: 14px;
  }

  .buttonAjouterVert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -76.5px;
    height: 28px;
    width: 85px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #CCF3CA;
    border: solid 0px;
    color: #328536;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;

    p {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .buttonAjouterRouge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -76.5px;
    height: 28px;
    width: 85px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 9.35px;
    font-weight: 500;
    border-radius: 6px;
    background-color: rgba(178, 34, 34, 0.13);
    border: solid 0px;
    color: firebrick;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;

    p {
      font-size: 13px;
    }
  }


}
