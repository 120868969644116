.phase {
	position: relative;
	flex: 1;
	margin-top: 10px;
	margin-left: 30px;
	margin-bottom: 20px;
	display: flex;

	.phaseName {
		margin: 0 20px 0 0;
		font-size: 20px;
		font-weight: 700;
	}

	.phaseStatus {
		width: fit-content;
		height: 30px;
		margin: -10px -10px 0 10px;
		border-radius: 5px;
		background-color: #eeeeee;
		display: flex;
		align-items: center;
		padding-right: 10px;
		padding-left: 10px;

		p {
			font-size: 12px;
			font-weight: 600;
			white-space: nowrap;
		}
	}

	.phaseStickyContainer {
		position: sticky;
		top: 35px;
		z-index: 10;
		width: fit-content;
		height: fit-content;

		.phaseDetail {
			display: flex;
			flex-direction: column;
			width: 500px;
			min-width: 300px;
			height: fit-content;
			padding: 20px;
			border-radius: 10px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
			margin-bottom: 10px;
			gap: 15px;

			.phaseDatesContainer {
				display: flex;
				gap: 5px;
				margin: -10px 0 0 0;
				align-items: center;

				.phaseSelectDateContainer {
					border: 1px solid #eeeeee;
					border-radius: 5px;
					padding: 5px;
					display: flex;
					align-items: center;

					.phaseSelectDate {
						appearance: none;
						-webkit-appearance: none;
						-moz-appearance: none;
						background: none;
						border: none;
						font-size: 13px;
						color: #4867ec;
						font-weight: 700;
						padding: 0;
						cursor: pointer;
						height: fit-content;

						&:focus {
							outline: none;
						}
					}
				}

				p {
					font-size: 13px;
					font-weight: 700;
					color: #4867ec;
					margin: 0;
				}
			}

			.phaseDescription {
				font-size: 13px;
				max-width: 600px;
				text-align: justify;
				margin: 0;
				overflow: hidden;
			}

			.phaseTagsList {
				display: flex;
				flex-direction: row;
				align-items: center;
				overflow-x: scroll;
				gap: 8px;

				.phaseTagInputContainer {
					background-color: rgba(168, 168, 168, 0.1);
					border: solid 1px #a2a2a2;
					border-radius: 99px;
					font-size: 13px;
					color: #4b4b4b;
					white-space: nowrap;
					transition: opacity ease-in-out 0.2s;
					display: flex;
					align-items: center;
					width: fit-content;
					padding: 4px 7px;

					img {
						width: 11px;
					}

					input {
						border: none;
						background: none;
						font-size: 13px;
						color: #4b4b4b;
						margin-left: 5px;
						width: 100px;
						height: 14px;
						outline: none;
					}
				}
			}
		}

		.phaseButtonsContainer {
			display: flex;
			padding: 3px 10px 0 10px;
			gap: 10px;
			margin-bottom: 20px;
		}
	}

	.phaseStepsContainer,
	.phaseStepsContainerCollapsed {
		margin-left: 20px;
		margin-top: -8px;
		flex: 1;
		transition: height 0.3s;

		.addStep {
			display: flex;
			align-items: center;
			flex: 1;
			opacity: 0;
			height: 27px;
			margin-left: 3px;
			transition: opacity 0.3s;
			padding-right: 10px;

			.barAddStep {
				max-width: 380px;
				flex: 1;
				height: 6px;
				background-color: #eff2fe;
				margin-left: 25px;
				border-radius: 10px;
			}

			img {
				width: 20px;
				margin-left: 8px;
			}

			p {
				font-size: 13px;
				font-weight: 700;
				color: #1c57dd;
				margin-left: 6px;
				text-wrap: nowrap;
			}

			&:hover {
				opacity: 1;
			}
		}

		.phasePlaceholder {
			margin: 50px 0 0 125px;

			.phasePlaceholderText {
				font-size: 14px;
				font-weight: 700;
				color: #919191;
			}
		}
	}

	.phaseStepsContainerCollapsed {
		height: 150px;
		overflow-y: hidden;
		pointer-events: none;

		.phaseCompleted {
			position: absolute;
			z-index: 1;
			width: fit-content;
			height: 30px;
			display: flex;
			align-items: center;
			color: #49a93e;
			margin: 50px 0 0 130px;

			p {
				margin-left: 10px;
				font-size: 16px;
				font-weight: 700;
			}

			.phaseCircle {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 23px;
				height: 23px;
				background-color: #49a93e;
				border-radius: 20px;

				img {
					margin-top: 2px;
					width: 12px;
					height: 12px;
				}
			}
		}

		.phaseShowMore {
			position: absolute;
			z-index: 1;
			cursor: pointer;
			transition: opacity 0.3s ease-in-out;
			margin: 100px 0 0 180px;
			display: flex;
			align-items: center;

			&:hover {
				opacity: 0.7;
			}

			p {
				font-size: 12px;
				font-weight: 600;
				color: #919191;
			}

			img {
				width: 11px;
				margin-left: 5px;
				margin-top: 2px;
				opacity: 0.5;
			}
		}
	}

	.phaseStepsContainerCollapsed::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 150px;
		background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
		pointer-events: none;
	}
}

.phaseReorganize {
	display: flex;
	flex-direction: row;
	align-items: center;

	.phaseReorganizeContainer {
		display: flex;
		flex-direction: column;
		margin-left: -25px;
		margin-right: 11px;
		gap: 5px;
		margin-top: -6px;

		.phaseReorganizeArrow {
			width: 14px;
			height: 14px;
			opacity: 0.5;
			transition: opacity 0.3s ease-in-out;
			cursor: pointer;

			&:hover {
				opacity: 0.4;
			}
		}
	}
}
