.tableComponent {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-bottom: 25px;
	margin-left: 30px;

	.backgroundContainerOptionsSelect {
		display: flex;
		position: absolute;
		margin-top: -40px;
		width: calc(100vw - 270px);
		background-color: #ffffff;

		.containerOptionsSelect {
			display: flex;
			align-items: center;
			height: 30px;
			border: solid 1px #d6d6d6;
			border-radius: 8px;
			background-color: #ffffff;
			box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
			overflow: hidden;

			.elemOptionSelect,
			.buttonOptionSelect {
				padding-left: 8px;
				padding-right: 8px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					background-color: #f3f3f3;
				}
			}

			p {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
			}

			.littleBar {
				height: 100%;
				width: 1px;
				background-color: #d6d6d6;
			}

			.trashIcon {
				width: 12px;
				margin-right: 6px;
			}
		}
	}

	.headerGroup {
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		.containerTriangle {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			width: 21px;
			height: 21px;
			margin-right: 0px;
			.triangle {
				width: 9px;
				transition: transform ease-in-out 0.2s;
			}

			&:hover {
				background-color: #f8f8f8;
				cursor: pointer;
			}
		}

		.titleHeader {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
		}

		.countElems {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			color: #84837f;
			margin-left: 7px;
			margin-right: 5px;
		}

		.containerButtonGroup {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-radius: 5px;
			opacity: 0;

			.pencilBlack {
				width: 12px;
			}
		}

		&:hover {

			.containerButtonGroup {
				opacity: 0.4;
			}

		}

		.containerButtonGroup:hover {
			cursor: pointer;
			background-color: #f8f8f8;
			opacity: 1;
		}

	}

	.barTable {
		display: flex;
		background-color: #e9e9e7;
		height: 1px;
	}

	.headerTable {
		display: flex;
		position: relative;

		&:hover {
			.containerSquareBlack {
				opacity: 0.4;
			}
		}
	}

	.itemCell {
		min-width: 230px;
		width: fit-content;
		border-right: solid 1px #e9e9e7;

		input,
		.itemCell__input {
			font-size: 14px;
			min-height: 29px;
			font-weight: 500;
			border: solid 2px rgba(255, 255, 255, 0);
			padding-left: 5px;
			width: 218px;
			border-radius: 5px;
			outline: none;
			margin-left: 2px;
			margin-right: 0px;
			margin-top: 1px;
			margin-bottom: 0px;
			display: flex;
			align-items: center;
			background-color: rgba(224, 224, 224, 0);

			&:disabled {
				color: #84837f;
			}
		}

		input {
			margin-top: 0px;
		}

		.itemCell__input {
			font-weight: 600;
			p {
				margin-top: 3px;
				margin-bottom: 3px;
				line-height: 22px;
			}
		}

		.inputDropDownVisible {
			background-color: #E2EDFB;
			border: solid 2px #63A3EA;
		}

		.inputDropDownTable {
			z-index: 1000;
			display: flex;
			flex-direction: column;
			position: absolute;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.19);
			white-space: nowrap;
			border-radius: 7px;
			width: 300px;
			background-color: #ffffff;
			min-height: 32px;
			border: solid 1px #e0e0e0;
			margin-top: 4px;
			margin-left: 2px;

			.inputSimple {
				outline: none;
				border: 0;
				margin-left: 5px;
				font-size: 14px;
				width: 100%;
				border-radius: 7px;
			}

			.containerInputDropDown {
				display: flex;
				justify-content: center;
				background-color: #f6f6f6;
				border-radius: 7px 7px  0px 0px;
				height: 30px;
				border-bottom: 1px solid #e0e0e0;

				.inputDropDown {
					border-radius: 7px 7px 0px 0px;
					outline: none;

					border: 0;
					margin-left: 5px;
					padding-left: 5px;
					font-size: 14px;
					width: 306px;
				}
			}

			.list {
				display: flex;
				flex-direction: column;
				padding-top: 10px;
				padding-left: 10px;
				padding-bottom: 5px;
				max-height: 315px;
				overflow: scroll;

				.subtitleDropDown {
					font-size: 13px;
					font-weight: 600;
					color: #727272;
					margin: 0;
					margin-bottom: 5px;
				}


				.enterToCreate {
					margin-top: -5px;
					background-color: #f8f8f8;
					margin-left: -5px;
					font-size: 13px;
					font-weight: 600;
					color: #727272;
					margin-bottom: 0px;
					margin-right: 5px;
					padding: 7px 5px;
					border-radius: 5px;
				}

				.editButton {
					font-size: 13px;
					font-weight: 600;
					margin: 0;
					margin-bottom: 5px;
					margin-right: 10px;
					color: #1c57dd;
				}

				.buttonAddLot {
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #f8f8f8;
					border: dashed 2px #eeeeee;
					border-radius: 10px;
					margin-right: 10px;
					margin-bottom: 10px;
					margin-top: 10px;
					font-weight: 600;
					font-size: 13px;
					height: 35px;
				}

				.containerType {
					padding: 4px 5px;
					border-radius: 5px;
					margin: 2px 5px 1px -5px;
					cursor: pointer;
					width: 297px;

					.type {
						border-radius: 5px;
						padding: 4px 7px;
						font-size: 13px;
						font-weight: 600;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}

				.containerType:hover {
					background-color: #f3f3f3;
				}

				.containerItemDropDown,
				.containerItemDropDownRename {
					padding: 4px 0px;
					border-radius: 5px;
					margin: 2px 5px 1px -5px;
					width: 307px;
					justify-content: space-between;

					&.selected {
						color: #3ca504;
					}

					.checkVert {
						width: 14px;
						margin-right: 10px;
					}

					.plusCircle {
						width: 14px;
						margin-right: 10px;
						opacity: 0.4;
						transition: opacity 0.3s ease;

						&:hover {
							cursor: pointer;
						}
					}

					.type {
						padding: 4px 7px;
						font-size: 13px;
						font-weight: 600;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}

					.formRenameOption {
						display: flex;
						align-items: center;
						border-bottom: 1px solid #e0e0e0;
						margin-left: 7px;
						padding-bottom: 5px;

						.inputRenameOption {
							outline: none;
							font-size: 13px;
							font-weight: 500;
							border: 0;
							width: 245px;
						}

						.containerEnterIcon {
							border: 0;
							background-color: #ffffff;
							padding: 0;
							.enterIcon {
								opacity: 0.4;
								width: 15px;
							}

							.enterIcon:hover {
								opacity: 1;
								cursor: pointer;
							}
						}
					}
				}

				.containerItemDropDown:hover {
					background-color: #f3f3f3;
					cursor: pointer;

					.plusCircle {
						opacity: 1;
					}
				}

				.containerAllLevel {
					.itemLevel {
						display: flex;
						align-items: center;
						position: relative;
						padding: 6px 0px;
						border-radius: 5px;
						margin: 2px 5px 1px -5px;
						width: 305px;

						.triangle {
							margin-left: 6px;
							width: 8px;
							transition: transform ease-in-out 0.2s;
						}

						.nameLevel {
							font-size: 14px;
							font-weight: 600;
							margin: 0;
							margin-left: 5px;
						}

						&:hover {
							background-color: #f3f3f3;
							cursor: pointer;
						}

						.checkVert {
							display: flex;
							position: absolute;
							right: 0;
							margin-right: 12px;
							width: 14px;
						}
					}

					.locationsList {
						.itemLocation {
							display: flex;
							align-items: center;
							position: relative;
							margin-left: 10px;
							padding: 6px 5px;
							margin-right: 8px;
							font-size: 14px;
							border-radius: 5px;
							font-weight: 600;

							.checkVert {
								display: flex;
								position: absolute;
								right: 0;
								margin-right: 12px;
								width: 14px;
							}

							&:hover {
								background-color: #f3f3f3;
								cursor: pointer;
							}
						}
					}
				}
			}
		}

	}

	.itemTable {
		display: flex;
		position: relative;
		flex-direction: row;
		border-bottom: solid 1px #e9e9e7;
		background-color: #ffffff;
		border-radius: 2px;

		&:hover {
			background-color: #f8f8f8;
			.containerSquareBlack {
				opacity: 0.4;
			}
		}
	}

	.containerSquareBlack {
		height: 37px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		margin-left: -30px;
		opacity: 0;
		.squareBlack {
			width: 15px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.newElem {
		display: flex;
		align-items: center;
		height: 29px;
		border-bottom: solid 1px #e9e9e7;
		font-size: 13px;
		color: #969692;

		.plusIcon {
			width: 12px;
			margin-left: 8px;
			margin-right: 8px;
			opacity: 0.35;
		}

		&:hover {
			cursor: pointer;
			background-color: #f8f8f8;
		}
	}
}

.newGroup {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 29px;
	width: fit-content;
	font-size: 13px;
	color: #969692;
	padding: 0px 8px;
	border-radius: 7px;
	margin-left: 30px;

	&:hover {
		cursor: pointer;
		background-color: #f8f8f8;
	}

	img {
		width: 12px;
		margin-right: 8px;
		opacity: 0.35;
	}
}
