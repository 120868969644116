.ticketTodolist {
  margin-left: 25px;
  height: 520px;
  overflow-x: scroll;
  padding-top: 20px;
  padding-bottom: 100px;
  max-height: calc(100vh - 208px);
  margin-right: 1px;

  .titleTodolist{
    font-size: 22px;
    font-weight: 600;
    margin-top: 40px;
  }

  .checkBox {
    width: 16px;
    margin-right: 7px;
    cursor: pointer;
  }

  .checkBox:hover {
    opacity: 0.5;
  }

  .addANewTodo {
    font-size: 14px;
    font-weight: 500;
    border: 0px;
    outline: none;
    width: 325px;
  }

  .addButton {
    background-color: #1C57DD;
    color: #FFFFFF;
    border: 0px;
    border-radius: 99px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .itemSubStep {
    align-items: center;
    margin-bottom: 10px;

    .chevron {
      border: 0px;
      margin-left: -8px;
      background-color: #FFFFFF;
      margin-bottom: -2px;
    }

    .todoText {
      font-size: 14px;
      font-weight: 500;
      margin-left: 2px;
      width: 200px;
    }

    .trashIcon {
      width: 20px;
      object-fit: contain;
      opacity: 0.5;
      cursor: pointer;
    }

    .trashIcon:hover {
      opacity: 1;
    }

    .addANewSubTodo {
      margin-top: 0px;
      font-size: 14px;
      font-weight: 500;
      border: 0px;
      outline: none;
      width: 205px;
    }

    .subTodoText {
      height: 17px;
      width: 450px;
      font-size: 14px;
      border: 0;
      font-weight: 500;
      outline: none;
    }

  }
}
