.displayPicturesVisit {
	width: 100%;
	height: calc(100vh - 119px);
	overflow: scroll;
	margin-top: -1px;

	.headerVisit {
		display: flex;
		margin-top: 20px;
		gap: 35px;

		.descriptionPart {
			display: flex;
			flex-direction: column;
			flex: 1;

			.titleDescription {
				font-size: 22px;
				font-weight: 700;
				margin-bottom: 10px;
			}

			.generalDescription {
				display: flex;
				width: 100%;
				margin-top: 0px;
				font-size: 14px;
				font-weight: 500;
				border: 0;
				outline: none;
				line-height: 18px;

				&:disabled {
					background-color: #fff;
				}
			}
		}

		.threeSixtyPartVisit,
		.largeThreeSixtyPartVisit {
			width: 300px;

			.placeholderMatterport {
				border-radius: 10px;
				border: solid 1px #eeeeee;
				margin-right: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 20px;
				padding: 15px 0px;

				.titleMatterport {
					font-size: 13px;
					font-weight: 700;
					margin-bottom: -5px;
				}

				.inputMatterportLink {
					border: solid 1px #eeeeee;
					border-radius: 5px;
					font-size: 14px;
					outline: none;
					height: 25px;
					width: calc(100% - 30px);
					padding-left: 8px;
					padding-right: 8px;
					margin-bottom: -10px;
				}

				.buttonImportVisit {
					font-weight: 700;
					font-size: 14px;
					color: #1c57dd;
				}
			}

			.previewVisit {
				border-radius: 10px;
				border: solid 1px #eeeeee;
				margin-right: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 20px;
				height: 100%;
			}
		}

		.largeThreeSixtyPartVisit {
			width: calc((100vw - 240px) / 2);
			height: calc((100vw - 240px) / 2);
			margin-right: 20px;

			.previewVisit {
				height: calc((100vw - 240px) / 2);
			}
		}
	}

	.largeBarVisit {
		margin-top: 20px;
		background-color: #eeeeee;
		height: 1px;
		margin-bottom: 0px;
	}
}
