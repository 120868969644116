.button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.button {
  padding-top: -1px;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background-color: #D2E7F8;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;

  .textButton {
    color: #3E51A9;
    margin: 0;
    font-size: 13px;
    font-weight: 600;
  }
}

.petitMenu:hover {
  background-color: #D2E7F8;

  .buttonPetitMenu {
    color: #4B5FB0;
  }
}

.petitMenu {
  cursor: pointer;
  position: absolute;
  right: 0;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-right: 45px;
  margin-top: -81px;
  transition: all ease-in-out 0.2s;

  .buttonPetitMenu {
    font-size: 13px;
    font-weight: 600;
  }

}


.fichiers {

  display: flex;
  flex-direction: row;
  margin-left: 38px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

  #inputFolder[type="file"] {
    display: none;
  }

  #inputFile[type="file"] {
    display: none;
  }

  .navigateur {
    width: calc(100vw - 585px);
    min-width: 569px;
    max-height: calc(100vh - 152px);
    overflow: scroll;
    margin-right: 0px;
    padding-top: 30px;
    margin-top: -25px;

    .tri {
      background-color: #F8F8F8;
      margin-top: -8.5px;
      margin-left: -1px;
      display: flex;
      align-items: center;
      width: calc(100vw - 671px);
      min-width: 544px;
      flex-direction: row;
      border-radius: 8.5px;
      height: 30px;

      .triOption1 {
        margin-left: 17px;
        margin-right: 51px;
        font-size: 11px;
        font-weight: bold;
        color: #000000;
        opacity: 0.7;
      }

      .repartitionOptionTriDate {
        width: calc(100vw - 1092px);
        min-width: 200px;
        display: flex;

        .triOption2 {
          width: calc((100vw - 1070px)/2);
          display: flex;
          justify-content: center;
          font-size: 11px;
          font-weight: bold;
          color: #000000;
          opacity: 0.7;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      .triOption3 {
        display: flex;
        justify-content: flex-end;
        flex-grow: calc(1/3);
        font-size: 11px;
        font-weight: bold;
        color: #000000;
        margin-right: 30px;
        opacity: 0.7;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    .bar {
      width: calc(100vw - 671px);
      min-width: 544px;
      height: 3px;
      background-color: rgba(238, 238, 238, 0);
      border-radius: 17px;
    }

    .itemList {


      .inner {
        margin-top: -2.5px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
        width: calc(100vw - 601px);
        min-width: 544px;
        padding-top: 8.5px;


        .blankListFolder {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: -25.5px;
          width: calc(100vw - 671px);
          min-width: 544px;
          height: calc(100vh - 149px);
        }
      }

      .inner::-webkit-scrollbar {
        display: none;
      }
    }

  }

  .options {
    margin-top: 25.5px;
    margin-bottom: 17px;
    display: flex;
    align-items: center;

    .importEnCours {
      height: 24px;
      margin-top: 0px;
      margin-bottom: -3px;
    }

    .beigeCircle {
      height: 12.7px;
      margin-left: -22px;
      margin-right: 12px;
      margin-top: 4px;
    }

    @keyframes rotate360 {
      to { transform: rotate(360deg); }
    }
    .beigeCircle { animation: 1s rotate360 infinite linear; }

    .importIcon {
      margin-top: 0px;
      height: 23px;
      cursor: pointer;
    }

    .importIcon:hover {
      opacity: 0.7;
    }

    .littleBar {
      margin-top: -3px;
      width: 2px;
      height: 25.5px;
      margin-left: 8.5px;
      margin-right: 8.5px;
      background-color: #eeeeee;
      border-radius: 17px;
    }

    #input[type="file"] {
      display: none;
    }

    .creerIcon {
      margin-top: -3px;
      height: 25.5px;
      cursor: pointer;
    }

    .creerIcon:hover {
      opacity: 0.7;
    }

    .points {
      margin-left: 5px;
      height: 4px;
      cursor: pointer;
      opacity: 0.3;
      transform: rotate(90deg);
    }

    .points:hover {
      opacity: 0.7;
    }
  }

  .dossier {
    display: flex;
    margin-top: 0px;
    width: 365px;
    background-color: #F8F8F8;
    border-radius: 17px;
    align-items: center;
    flex-direction: column;

    .openComponent:hover {
      opacity: 1;
    }

    .openComponent {
      opacity: 0;
      transition: opacity ease-in-out 0.2s;
      cursor: pointer;
    }

    .choixTypeImportation {
      flex-direction: column;
      margin-top: 13px;
      margin-left: 30px;
      width: 230px;
      height: 80px;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      position: absolute;
      background-color: #FFFFFF;
      border-radius: 5px;
      display: flex;
      justify-content: center;

      #inputFolder[type="file"] {
        display: none;
      }

      #inputFile[type="file"] {
        display: none;
      }

      .choiceImportation:hover {
        background-color: #F8F8F8;
      }

      .choiceImportation {
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;

      }
    }

    #inputFiles[type="file"] {
      display: none;
    }

    .inputNameDossier {
      margin-left: 5px;
      marginTop: 22px;
      font-size: 12px;
      font-weight: 500;
      color: #505050;
      margin-bottom: 0px;
      width: 250px;
      padding-left: 7px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-radius: 8.5px;
      border: solid 2px #F8F8F8;
      background-color: #F8F8F8;
      outline: none;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    .inputNameDossier:hover {
      border: solid 2px #eeeeee;
    }

    .folderIcon {
      margin-left: 25px;
      width: 76px;
      height: 59px;
    }

    .name {
      margin-left: 20px;
      margin-top: 15px;
      font-size: 15px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 4px;
    }

    .date {
      margin-left: 21px;
      margin-top: 0px;
      font-size: 13px;
      font-weight: 500;
      color: #797979;
    }
  }

  .dragAndDrop {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 35px;
    background-color: #fff;
    border-radius: 8.5px;
    align-items: center;
    justify-content: center;

    .detail {
      font-size: 11px;
      font-weight: 500;
      color: #afafaf;
      margin: 0px 25.5px;
      text-align: center;
    }
  }
}

.item:hover {

  .rightIconItem {
    opacity: 1;
  }
}

.item {
  margin-bottom: 5px;
  margin-left: 10px;
  display: flex;
  width: calc(100vw - 633px);
  min-width: 518px;
  height: 45px;
  cursor: pointer;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;

  .reorganizeDrag {
    -webkit-transition: width 0.3s;
    transition: width ease-in-out 0.2s;
  }

  .itemIcon {
    object-fit: contain;
    max-width: 32px;
  }

  .rightIconItem {
    margin-left: 20px;
    margin-top: 17px;
    transform: rotate(180deg);
    width: 7px;
    object-fit: contain;
    display: flex;
    align-self: flex-end;
    opacity: 0.3;
  }

  .rightIconItem_active {
    margin-left: 20px;
    margin-top: 17px;
    transform: rotate(180deg);
    width: 7px;
    object-fit: contain;
    display: flex;
    align-self: flex-end;
    opacity: 1;
  }

  .fichierIcon {
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 12.7px;
    max-width: 34px;
  }

  .itemBar {
    width: calc(100vw - 756px);
    min-width: 548px;
    margin-left: 59px;
    height: 1px;
    border-radius: 17px;
    background-color: #eeeeee;
  }

  .nomItem_active {
    margin-left: 25.5px;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    opacity: 0.7;
    margin-top: 15px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
  }

  .nomItem {
    margin-left: 25.5px;
    font-size: 13px;
    font-weight: 600;
    color: #000000;
    opacity: 0.7;
    margin-top: 15px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 220px;
  }

  .nomItem:hover {
    color: #000000;
  }

  .dateItem {
    display: flex;
    justify-content: center;
    margin-top: -31px;
    margin-left: calc((100vw - 646px)*(-0.5));
    font-size: 12px;
    font-weight: 500;
    color: #343434;
    opacity: 0;
  }

  .dateItem_active {
    display: flex;
    justify-content: center;
    margin-top: -31px;
    margin-left: calc((100vw - 646px)*(-0.5));
    font-size: 10px;
    font-weight: 500;
    color: #000000;
    opacity: 0;
  }

  .star {
    margin-left: -85px;
    object-fit: contain;
    height: 17px;
  }

  .star:hover {
    opacity: 0.7;
  }
}

.repartitionDateFichiersReorganize {
  width: calc(100vw - 1040px);
  min-width: 200px;
  display: flex;
  align-items: center;

  .testDate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100vw - 1070px)/2);

    .date {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      opacity: 0.7;
    }

    .date_active {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      opacity: 0.7;
    }
  }
}

.repartitionDateFichiers {
  width: calc(100vw - 1006px);
  min-width: 140px;
  display: flex;
  align-items: center;

  .testDate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100vw - 1070px)/2);
    min-width: 100px;

    .date {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      opacity: 0.7;
    }

    .date_active {
      margin: 0px;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      opacity: 0.7;
    }
  }
}

.importantFolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 17px;
  padding-bottom: 0px;
  height: 127px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.importantFolder_active {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 17px;
  padding-bottom: 0px;
  height: 127px;
  background-color: #F8F8F8;
}

.importantFolder:hover {
  background-color: #F8F8F8;
}

.messageFileName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 127px;
}

.choiceAcces {
  margin-top: 10px;
  width: 45px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;

  .buttonChoice:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  .buttonChoice {
    width: 21px;
    height: 21px;
    border-radius: 99px;
    background-color: #ffff;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;

  }
}

.buttonNouveauDossier {
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.buttonNouveauDossier:hover {
  cursor: pointer;
  opacity: 0.8;
}

.importButtonFichiers {
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.importButtonFichiers:hover {
  cursor: pointer;
  opacity: 0.8;
}

.importFileArea {
  input {
    display: none;
  }
}

.pathDossiers {
  .inner {
    margin-top: 6px;
    margin-left: 55px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 315px;
    border-bottom: solid;
    overflow: hidden;
    border-bottom-width: 1px;
    border-bottom-color: #eeeeee;
    height: 60px;
    margin-bottom: 25px;
    width: calc(100vw - 230px);

    .Icon {
      opacity: 0.4;
      width: 8px;
      object-fit: contain;
      margin-left: 8.5px;
      margin-right: 8.5px;
      margin-top: -5px;
      transform: rotate(180deg);
    }

    .nav {
      margin-top: 12px;
      font-weight: bold;
      font-size: 18px;
      color: #989898;
    }

    .nav-active {
      margin-top: 12px;
      font-weight: bold;
      font-size: 18px;
      color: #000000;
    }

  }

  .inner::-webkit-scrollbar {
    display: none;
  }
}

.modalMoveFiles {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 805px)/2);
  margin-left: calc((100vw - 500px)/2);
  width: 500px;
  height: 700px;
  border-radius: 10px;
  outline: none;

  .secondModal {
    margin-top: 15px;
    width: 440px;
    height: 65px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    padding-left: 30px;
    padding-right: 30px;
    color: #565656;
  }

  .firstModal {
    width: 500px;
    height: 700px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

    .crossIcon:hover {
      opacity: 0.8;
    }

    .crossIcon {
      width: 23px;
      object-fit: contain;
      position: absolute;
      right: 0;
      margin-top: 5px;
      margin-right: 5px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }


    .leftBleu {
      margin-left: 25px;
      width: 10px;
      object-fit: contain;
    }

    .header {
      width: 500px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .buttonEnregistrer:hover {
        opacity: 0.6;
      }

      .buttonEnregistrer {
        height: 35px;
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-top: 11px;
        font-weight: 600;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
      }

      .titleDossierIn {
        margin-left: 10px;
        font-size: 18px;
        font-weight: 600;
      }

      .rechercher {
        background-color: #eeeeee;
        border: 0px;
        height: 28px;
        font-size: 13px;
        font-weight: 500;
        outline: none;
        border-radius: 7px;
        width: 440px;
        padding-left: 10px;
      }

      .bar {
        margin-top: 15px;
        width: 500px;
        height: 1px;
        background-color: #eeeeee;
      }
    }

    .footer {
      width: 500px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .bar {
        width: 500px;
        height: 1px;
        background-color: #eeeeee;
      }

      .pdfIcon {
        width: 40px;
        object-fit: contain;
      }

      .subtitleElement {
        font-size: 14px;
        margin: 0px;
      }
    }

    .containerListDossier {
      height: 525px;
      .listDossier {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        width: 460px;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 27px;
        max-height: 500px;
        overflow: scroll;

        .dossierItem:hover {
          background-color: #eeeeee;
        }

        .dossierItem {

          width: 440px;
          padding-left: 10px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          flex-direction: row;
          cursor: pointer;


          .dossier {
            width: 40px;
            object-fit: contain;
          }

          .nomDossier {
            margin-left: 15px;
            font-size: 13px;
          }

          .date {
            margin-top: -10px;
            margin-left: 15px;
            font-size: 12px;
            opacity: 0.7;
          }
        }
      }
    }


  }

}

.dragComponent:focus {
  cursor: grabbing;
}

.dragComponent:hover {
  background-color: #F8F8F8;
  opacity: 1;
}

.dragComponent {
  background-color: rgba(255, 255, 255, 0);
  margin-left: -30px;
  padding: 4px;
  height: 22px;
  border-radius: 4px;
  cursor: grab;
  margin-right: 10px;
  opacity: 0.5;

  .dragDot {
    width: 4px;
    height: 4px;
    background-color: #d7d7d7;
    border-radius: 20px;
    opacity: 1;
  }
}

.modalUploading {
  background-color: rgb(255, 255, 255);
  position: absolute;
  margin-left: calc((100vw - 450px)/2);
  margin-top: calc((100vh - 160px)/2);
  width: 450px;
  border-radius: 11px;
  box-shadow: 0px 0px 25.5px 5.2px rgba(0, 0, 0, 0);
  outline: none;

  .importation {
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: 8.5px;
    margin-left: 25px;
  }

  .percentage {
    font-size: 12px;
    font-weight: 600;
    margin-left: -30px;
    margin-top: -23px;
  }

  .backBarUploadingFile {
    margin-left: 25px;
    width: 400px;
  }

  .frontBarUploadingFile {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;
  }

  .crossButton:hover {
    opacity: 0.7;
  }

  .crossButton {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    .crossIcon {
      width: 22px;
      object-fit: contain;
    }
  }

  .valider:hover {
    opacity: 0.7;
  }

  .valider {
    margin-left: 20px;
    margin-bottom: 20px;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
    width: 410px;
    background-color: #D2E7F8;
    color: #4B5FB0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
}

.overlayModalUploading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}
