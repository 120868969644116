.modalChooseLanguages {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: 95vh;
  background-color: #fff;
  border-radius: 8px;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;

  .crossIcon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .titleModal {
    margin-top: -5px;
    font-weight: 600;
    font-size: 22px;
  }

  .subtitleModal {
    margin-top: 5px;
    opacity: 0.7;
    font-size: 14px;
  }

  .listItemsLanguage {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;

    .itemLanguage, .itemLanguageSelected {
      display: flex;
      border: solid 1px #eeeeee;
      padding: 7px 10px;
      width: 220px;
      height: 18px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      justify-content: space-between;
      transition: all ease-in-out 0.2s;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.11);
      }
    }

    .itemLanguageSelected {
      background-color: rgba(60, 165, 4, 0.1);
      color: #3CA504;

      .checkVert {
        width: 17px;
        object-fit: contain;
      }
    }
  }

  .moreComingSoon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 20px;
    opacity: 0.7;
  }
}
