.locationItemVisit {
	position: relative;
	flex: 1;
	margin-top: 30px;
	margin-left: 30px;
	display: flex;
	margin-bottom: 100px;
	gap: 30px;

	.stickyPart {
		position: sticky;
		top: 65px;
		z-index: 10;
		width: 300px;
		height: fit-content;

		.titleLocation {
			display: flex;
			align-items: center;
			font-weight: 700;
			font-size: 20px;
			margin-bottom: 10px;

			.mappin {
				width: 14px;
				opacity: 0.7;
				object-fit: contain;
				margin-right: 10px;
			}
		}

		.numberPictures {
			font-size: 14px;
			font-weight: 600;
		}

		.locationComment {
			display: flex;
			width: 100%;
			margin-top: 10px;
			font-size: 14px;
			font-weight: 500;
			border: 0;
			outline: none;
			line-height: 18px;

			&:disabled {
				background-color: #fff;
			}
		}

		.addPicturesButton {
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: 600;
			background-color: #eeeeee;
			border-radius: 99px;
			padding: 5px 10px;
			gap: 5px;
			margin-top: 20px;

			.plusIcon {
				width: 11px;
			}
		}
	}

	.photosPart {
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		gap: 20px;

		.containerPicture {
			width: 130px;
			height: 130px;

			.picture {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}
	}
}
