.containerZoomNav {
  width: calc(100vw - 400px);
  display: flex;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  height: 90px;
  gap: 100px;

  .previousNext:hover {
    background-color: #232323;
    cursor: pointer;
  }

  .previousNext {
    background-color: #0C0C0C;
    width: 40px;
    height: 40px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease-in-out 0.1s;

    .leftIcon,
    .rightIcon {
      width: 8px;
    }

    .rightIcon {
      transform: rotate(180deg);
    }
  }

  .zoomInOut {
    background-color: #0C0C0C;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 2px;

    .buttonZoom:hover {
      opacity: 1;
      background-color: #232323;
      cursor: pointer;
    }

    .buttonZoom,
    .buttonZoomDisable {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 99px;
      border: solid 0px;
      background-color: rgba(0, 0, 0, 0);
      color: #FFFFFF;
      font-size: 25px;
      transition: background-color ease-in-out 0.1s;

      .zoomOutIcon {
        width: 17px;
      }
    }

    .buttonZoomDisable {
      opacity: 0.5;
    }
  }
}
