.itemAccessibilityPhotos {
	display: flex;
	background-color: #f8f8f8;
	border-radius: 10px;
	flex-direction: column;
	opacity: 1;
	padding-right: 10px;
	transition: all ease-in-out 0.2s;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}

	.titleAccessibilityPhotos {
		margin-left: 15px;
		font-size: 13px;
		font-weight: 600;
		display: flex;
		width: 250px;
	}

	.roundedButton {
		margin-right: 2px;
		margin-left: 10px;
		margin-top: 10px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: solid 2.5px #a2a2a2;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all ease-in-out 0.2s;

		.littleCircle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: rgba(238, 238, 238, 0);
			transition: all ease-in-out 0.2s;
		}
	}
}