.album-card-reorganize {
    position: relative;
    width: 270px;
    height: 220px;
    border-radius: 8px;

    .album-card-reorganize-image {
        height: 200px;
        width: 200px;
        margin-top: 35px;
        margin-left: 35px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
            transition: transform ease-in-out 0.2s;
        }

        .album-card-reorganize-chevrons {
            position: absolute;
            top: 0;
            left: 0;
            height: 200px;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;
            padding: 0 10px;

            img {
                width: 25px;
                height: 25px;
                object-fit: contain;
                cursor: pointer;
                background-color: #FFFFFF;
                border-radius: 50%;
                padding: 10px;
                box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
                transition: transform ease-in-out 0.2s;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    .album-card-reorganize-name {
        display: flex;
        margin-left: 35px;

        p {
            margin-left: 0px;
            margin-right: 10px;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin-top: 5.5px;
        }
    }

    .album-card-reorganize-top-button,
    .album-card-reorganize-bottom-button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        background-color: #FFFFFF;
        border-radius: 50%;
        padding: 7px;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
        width: 20px;
        height: 20px;
    }

    .album-card-reorganize-top-button {
        top: -17px;
    }

    .album-card-reorganize-bottom-button {
        bottom: 5px;
    }

    .album-card-reorganize-top-button img,
    .album-card-reorganize-bottom-button img {
        width: 20px;
        height: 20px;
    }
}

.album-card.animation {
    transition: transform 0.5s ease-in-out;
}

.album-card.slide-left {
    transform: translateX(-295px);
}

.album-card.slide-right {
    transform: translateX(295px);
}