.singlePage {

  .headerPage {
    display: flex;
    justify-content: space-between;

    .generalProjectInfos {
      display: flex;
      flex-direction: column;

      .dayDate {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 13px;
        opacity: 0.7;
      }

      .projectName {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      .itemGeneralInfos {
        font-size: 13px;
        font-weight: 500;
        margin-top: 5px;
        margin-right: 20px;
        .bold {
          font-weight: 700;
        }
      }
    }

    .projectPictureReport {
      max-width: 500px;
      max-height: 130px;
      object-fit: contain;
      margin-bottom: 0px;
      border-radius: 10px;
    }
  }

  .largeBar {
    display: flex;
    height: 1px;
    background-color: #eeeeee;
    width: 90%;
  }

  .tables-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;

    .table-wrapper {
      flex: 1;
      min-width: 0;

      table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        color: #787878;

        &.table-custom-widths {
          .col-large {
            width: 45%; // Colonne 1 plus large
          }

          .col-small {
            width: 13.75%; // Colonnes 2 et 3 plus étroites
          }
        }

        th, td {
          padding: 8px;
          border: 1px solid #ddd;
          overflow: hidden;
          font-size: 12px;
          white-space: nowrap;
        }

        th {
          background-color: #f5f5f5;
          font-weight: bold;
          text-align: left;
        }

        tr {
          &:nth-child(even) {
            background-color: #f9f9f9;
          }
        }
      }
    }
  }

  .infosBlock {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
    gap: 10px;
    border: solid 1px #eeeeee;

    .titleInfosBlock {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }

}
