.messaging {
	display: flex;

	.convsList {
		width: 390px;
		margin-top: -10px;
		height: calc(100vh - 56px);
		overflow: scroll;
		border-right: solid 1px #eef1f5;

		.loadingContainer {
			display: flex;
			width: 390px;
			justify-content: center;
			align-items: center;
			height: 200px;
			opacity: 0.3;

			@keyframes rotate360 {
				to {
					transform: rotate(360deg);
				}
			}
			.chargement {
				width: 20px;
				object-fit: contain;
				animation: 1s rotate360 infinite linear;
			}
		}

		.containerLeftArrow {
			transition: all ease-in-out 0.2s;
			overflow: hidden;

			.leftBigArrow {
				display: flex;
				width: 18px;
				object-fit: contain;
				margin-left: 15px;
				margin-top: 29px;
			}
		}

		.titleDiscussions {
			font-weight: 700;
			font-size: 27px;
			margin-left: 15px;
			margin-top: 20px;
			margin-bottom: 15px;
		}

		.initConv {
			width: 20px;
			margin-top: 5px;
			margin-right: 15px;
			transition: opacity ease-in-out 0.2s;
		}

		.sortOptions {
			display: flex;
			margin-left: 20px;
			margin-top: 15px;
			gap: 7px;
			margin-bottom: 15px;
			overflow-x: scroll;
			overflow-y: hidden;

			.sortOption:hover,
			.sortOptionActive:hover {
				background-color: #e5ebfd;
				color: #5a83e8;
				transform: scale(1.05);
			}

			.sortOption,
			.sortOptionActive {
				border-radius: 99px;
				font-weight: 600;
				font-size: 13px;
				padding: 5px 10px;
				color: #555555;
				cursor: pointer;
				transition: all ease-in-out 0.2s;
			}

			.sortOptionActive {
				background-color: #e5ebfd;
				color: #5a83e8;
			}
		}
		.itemConv:hover {
			background-color: #f8f8f8;
		}

		.itemConv,
		.itemConvActive {
			display: flex;
			align-items: center;
			padding: 13px 15px;
			cursor: pointer;
			transition: all ease-in-out 0.2s;
			background-color: #ffffff;

			.projectPicture {
				width: 48px;
				height: 48px;
				border-radius: 5px;
				background-color: #7fa2fd;
			}

			.agencyPicture {
				background-color: #7fa2fd;
				color: #ffffff;
				font-weight: 600;
				width: 48px;
				height: 48px;
				border-radius: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				letter-spacing: 1px;
				padding-right: 2px;
				padding-left: 2px;
			}

			.rightPartItemConv {
				padding-left: 15px;
				width: 290px;
				display: flex;
				flex-direction: column;
				gap: 6px;

				p {
					margin: 0;
				}

				.convName {
					font-size: 15px;
					font-weight: 600;
					overflow: hidden;
					text-overflow: ellipsis;
					max-lines: 1;
					white-space: nowrap;
				}

				.diminutif {
					font-size: 14px;
					font-weight: 600;
					color: #1c57dd;
					margin-left: 4px;
					margin-top: 1px;
					max-lines: 1;
					white-space: nowrap;
				}

				.lastMessage {
					font-size: 13px;
					color: #888888;
					font-weight: 500;
					white-space: nowrap;
					width: 300px;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.dateConv {
					font-size: 11px;
					color: #888888;
					font-weight: 500;
					margin-bottom: 7px;
					white-space: nowrap;
				}
			}

			.rightIconMessaging {
				width: 7px;
				opacity: 0.3;
				transform: rotate(180deg);
			}
		}

		.itemConvActive {
			background-color: #f8f8f8;
		}

		.newGroup {
			display: flex;
			align-items: center;
			margin-left: 30px;
			margin-bottom: 20px;
			margin-top: 5px;

			.containerPlusIconNewGroup {
				width: 48px;
				height: 48px;
				border-radius: 100px;
				background-color: #f6f6f6;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 10px;
				margin-right: 15px;

				.plusIcon {
					width: 17px;
					height: 17px;
					opacity: 0.4;
				}
			}

			.titleButtonNewGroup {
				font-size: 13px;
				opacity: 0.6;
				width: 240px;
			}
		}

		.groupList {
			height: 0;
			overflow: hidden;
			transition: height 0.3s ease-out;

			&.expand {
				transition: height 0.3s ease-out;
			}
		}

		.barItemMessaging {
			height: 1px;
			background-color: #eeeeee;
			margin-left: 84px;
		}
	}

	.convContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100vw - 390px);
		margin-top: -10px;

		.headerConv {
			width: calc(100vw - 487px);
			height: 75px;
			border-radius: 10px;
		}

		.widgetInfoConv:hover {
			transform: scale(1.03);
		}

		.widgetInfoConv {
			display: flex;
			flex-direction: column;
			overflow: scroll;
			flex-wrap: nowrap;
			position: absolute;
			top: 0;
			margin-top: 70px;
			height: 55px;
			background-color: #ffffff;
			border: solid 1px #e3e7ec;
			border-radius: 99px;
			transition: all ease-in-out 0.2s;
			padding-left: 5px;
			max-height: calc(100vh - 110px);

			.crossIcon {
				width: 23px;
				object-fit: contain;
				position: absolute;
				right: 0;
				margin-top: 10px;
				margin-right: 10px;
				cursor: pointer;
				transition: opacity 0.3s;
				-webkit-transition: opacity 0.3s;
				opacity: 1;
			}

			.buttonEditGroup {
				padding: 7px 15px;
				background-color: #f8f8f8;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 30px;
				margin-top: -20px;
				border-radius: 99px;
			}

			.containerIdentity {
				display: flex;
				align-items: center;
				height: 55px;
				cursor: pointer;

				.contactName {
					margin: 0;
					font-size: 14px;
					font-weight: 600;
					transition: opacity ease-in-out 0.8s;
					white-space: nowrap;
				}

				.contactType {
					margin: 0;
					margin-top: 2px;
					font-size: 12px;
					color: #1c57dd;
					font-weight: 600;
					transition: opacity ease-in-out 0.8s;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 130px;
				}
			}

			.containerIdentityLarge {
				display: flex;
				align-items: center;
				height: 200px;
				cursor: pointer;
				width: 400px;
				margin-top: 20px;
				margin-right: 5px;

				.containerName {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 15px;
					.contactName {
						margin: 0;
						font-size: 21px;
						font-weight: 600;
						transition: opacity ease-in-out 0.8s;
						white-space: nowrap;
					}

					.contactType {
						margin: 0;
						margin-top: 5px;
						font-size: 14px;
						color: #1c57dd;
						font-weight: 600;
						transition: opacity ease-in-out 0.8s;
					}
				}
			}

			.containerButtonsInfoConv {
				display: flex;
				margin-left: 15px;
				margin-right: 25px;
				margin-bottom: 25px;
				gap: 15px;

				.buttonInfoConv {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					font-size: 12px;
					color: #287aff;
					font-weight: 600;
					flex: 1;
					background-color: #f8f8f8;
					padding: 15px 15px;
					border-radius: 10px;
					gap: 10px;

					img {
						height: 18px;
					}
				}
			}

			.containerPicturesSent,
			.containerFilesSent,
			.containerParticipants {
				display: flex;
				flex-direction: column;
				background-color: #f8f8f8;
				min-height: 285px;
				margin-right: 20px;
				margin-left: 15px;
				border-radius: 10px;
				margin-bottom: 25px;
				width: 365px;
				overflow: hidden;

				.containerHeader {
					display: flex;
					justify-content: space-between;
					padding: 12px 15px 12px 15px;
					font-size: 14px;
					p {
						margin: 0;
					}

					.all {
						color: #1c57dd;
						font-weight: 600;
					}
				}

				.listPictures {
					display: flex;
					flex-wrap: wrap;

					img {
						width: 33.33%;
						height: 121px;
						object-fit: cover;
					}
				}

				.listFiles {
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					gap: 8px;
					height: 195px;

					.fileSent {
						display: flex;
						align-items: center;
						font-size: 13px;
						gap: 10px;
						.pdfIcon {
							width: 27px;
							margin-left: 15px;
						}
					}

					.barFile {
						height: 1px;
						background-color: #eeeeee;
						margin-left: 53px;
						margin-right: 0px;
						margin-top: 5px;
					}
				}

				.seeMore {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #1c57dd;
					font-weight: 600;
					font-size: 14px;
				}

				.containerPlaceholderSent {
					display: flex;
					flex: 1;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					font-weight: 600;
					font-size: 13px;
					color: #969696;

					.pdfIcon {
						width: 40px;
					}
				}

				.itemParticipant {
					margin-bottom: 5px;
					display: flex;
					align-items: center;
					font-size: 14px;
					margin-left: 10px;
					gap: 8px;
				}
			}

			.containerCommonProjects {
				background-color: #f8f8f8;
				margin-left: 15px;
				border-radius: 10px;
				margin-bottom: 25px;
				width: 365px;
				height: auto;

				.containerHeader {
					display: flex;
					justify-content: space-between;
					flex: 1;
					padding: 12px 15px 12px 15px;
					font-size: 14px;

					p {
						margin: 0;
					}
				}

				.projectItem {
					width: 350px;
					height: 30px;
					padding: 12px 0 12px 12px;
					font-size: 14px;

					img {
						width: 30px;
						height: 30px;
						border-radius: 5px;
						object-fit: cover;
					}

					.bar {
						height: 1px;
						background-color: #eeeeee;
						margin-left: 45px;
						margin-right: 0px;
						margin-top: 12px;
					}
				}

				.modify {
					border: solid 0px;
					background-color: #ffffff;
					border-radius: 99px;
					font-size: 12px;
					font-weight: 600;
					color: #595959;
					padding: 5px 10px;
					margin-right: 10px;
				}
			}

			.buttonDeleteConv {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 13px;
				color: #ef3232;
				font-weight: 600;
				background-color: #f8f8f8;
				height: 45px;
				margin-right: 20px;
				margin-left: 15px;
				border-radius: 10px;
				margin-bottom: 25px;
				width: 365px;
			}
		}

		.loadingContainer {
			width: 750px;
			height: calc(100vh - 50px);
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 0.3;

			@keyframes rotate360 {
				to {
					transform: rotate(360deg);
				}
			}
			.chargement {
				width: 20px;
				object-fit: contain;
				animation: 1s rotate360 infinite linear;
			}
		}

		.containerMessages {
			width: 750px;
			height: calc(100vh - 56px);
			overflow: scroll;
			display: flex;
			flex-direction: column-reverse;

			.comble {
				font-size: 20px;
				margin-top: 50px;
				color: #ffffff;
			}
			.messageDate {
				width: 750px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
				margin-top: 25px;
				font-size: 12px;
				opacity: 0.6;
				font-weight: 600;
			}

			.containerMessage {
				margin-top: 2px;
				position: relative;
				display: flex;
				flex-direction: column;
				border-radius: 20px;

				.textMessage {
					max-width: 500px;
					padding: 10px 15px 10px 15px;
					line-height: 22px;
					font-size: 14px;
					display: flex;
					flex-direction: column;

					.responseMessage {
						border-radius: 5px;
						border-left: solid 5px rgba(28, 87, 221, 0.51);
						background-color: #ffffff;
						margin-top: 5px;
						margin-bottom: 5px;

						.senderName {
							font-size: 11px;
							font-weight: 700;
							margin: 10px 15px 1px;
							color: #1c57dd;
						}

						.textMessage {
							padding: 0px 15px 10px 15px;
							line-height: 18px;
							font-size: 12px;
							display: flex;
							max-lines: 5;
							text-overflow: ellipsis;
						}

						.imageMessage {
							width: 100px;
							aspect-ratio: 1;
							border-radius: 10px;
							cursor: pointer;
							object-fit: cover;
							padding: 4px;
							margin-left: 10px;
							margin-right: 10px;
						}

						.fileMessage {
							padding: 9px 18px 18px;
							line-height: 18px;
							font-size: 13px;
							display: flex;
							align-items: center;
							cursor: pointer;

							.fileTypeIcon {
								width: 30px;
								margin-right: 15px;
							}
						}
					}
				}

				.imageMessage {
					width: 400px;
					aspect-ratio: 1;
					border-radius: 20px;
					cursor: pointer;
					object-fit: cover;
					padding: 4px;
				}

				.fileMessage {
					max-width: 500px;
					padding: 18px 18px;
					line-height: 22px;
					font-size: 15px;
					display: flex;
					align-items: center;
					cursor: pointer;

					.fileTypeIcon {
						width: 30px;
						margin-right: 15px;
					}
				}

				.optionsContainer {
					display: none;
				}
			}

			.senderName {
				font-size: 12px;
				font-weight: 700;
				margin: 10px 15px 1px;
				color: #1c57dd;
			}

			.distributedOrSeen {
				font-weight: 600;
				font-size: 11px;
				margin-top: 8px;
				opacity: 0.6;
				align-self: end;
				margin-right: 5px;
			}

			.received {
				align-self: start;
				background-color: #fff;
				border: solid 1px #eeeeee;
			}

			.sent {
				align-self: end;
				background-color: #f8f8f8;
			}

			.containerMessage:hover {
				.optionsContainer {
					position: absolute;
					display: flex;
					width: 17px;
					height: 17px;
					border-radius: 100%;
					box-shadow: 0 0 5px 0 rgba(51, 51, 51, 0.17);
					align-items: center;
					justify-content: center;
					align-self: end;
					margin-top: 10px;
					margin-right: 10px;
					background: white;
					cursor: pointer;

					.optionsIcon {
						margin-top: 1px;
						width: 7px;
						height: 7px;
						opacity: 0.7;
					}
				}
			}
		}

		.placeholderConversation {
			height: calc(100vh - 0px);
			min-height: 300px;
			display: flex;
			justify-content: center;
			transition: all 0.5s;
			-webkit-transition: all 0.5s;

			.PlaceholderPhotoMessages {
				margin-left: 15px;
				width: 850px;
				object-fit: contain;
			}

			.LockIcon {
				height: 13px;
				object-fit: contain;
				margin-top: 18px;
				opacity: 0.3;
			}

			.textPlaceholder {
				width: 220px;
				font-size: 12px;
				font-weight: 500;
				color: #969696;
			}
		}

		.placeholderNoConvSelected {
			height: calc(100vh - 55px);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.containerInputConv {
			position: absolute;
			display: flex;
			flex-direction: column;
			width: 750px;
			background-color: #f8f8f8;
			border: solid 1px #e3e7ec;
			border-radius: 28px;
			min-height: 50px;
			opacity: 0.9;
			transition: opacity 0.2s ease-in-out;
			max-height: calc(100vh - 200px);
			bottom: 0;
			margin-bottom: 20px;
			justify-content: flex-end;

			&.opaque {
				opacity: 1;
			}

			.linkIcon {
				width: 28px;
				height: 28px;
				cursor: pointer;
				z-index: 2;
				margin-bottom: 8px;
			}

			.inputConv {
				width: 670px;
				font-size: 15px;
				outline: none;
				max-height: calc(100vh - 470px);
				margin-bottom: 9px;
				margin-top: 13px;
				border: solid 0px;
				-left: 2px;
				background-color: transparent;
			}

			.sendIcon {
				width: 30px;
				height: 30px;
				cursor: pointer;
				margin-bottom: 10.5px;
				transition: transform 0.2s;
			}

			.responseMessage {
				border-radius: 5px;
				border-left: solid 5px rgba(28, 87, 221, 0.51);
				background-color: #ffffff;
				margin-top: 10px;
				opacity: 1;

				.senderName {
					font-size: 11px;
					font-weight: 700;
					margin: 10px 15px 1px;
					color: #1c57dd;
				}

				.textMessage {
					padding: 5px 15px 10px 15px;
					line-height: 18px;
					font-size: 12px;
					display: flex;
					max-lines: 5;
					text-overflow: ellipsis;
				}

				.imageMessage {
					width: 100px;
					aspect-ratio: 1;
					border-radius: 10px;
					cursor: pointer;
					object-fit: cover;
					padding: 4px;
					margin-left: 10px;
					margin-right: 10px;
				}

				.fileMessage {
					padding: 9px 18px 18px;
					line-height: 18px;
					font-size: 13px;
					display: flex;
					align-items: center;
					cursor: pointer;

					.fileTypeIcon {
						width: 30px;
						margin-right: 15px;
					}
				}
			}

			.cross {
				width: 13px;
				height: 13px;
				cursor: pointer;
				margin-top: 10px;
				margin-right: 10px;
				margin-left: 10px;
				opacity: 0.7;
			}

			.temporaryFiles {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				overflow-x: scroll;
				overflow-y: hidden;
				margin-top: 16px;
				padding-right: 26px;
				margin-bottom: 5px;

				.picturesList {
					display: flex;

					.pictureContainer {
						position: relative;
						padding-left: 10px;
						margin-top: 10px;

						&.firstPicture {
							padding-left: 26px;
						}

						&:hover {
							.removePicture {
								opacity: 1;
							}
						}

						.previewImage {
							width: 150px;
							height: 150px;
							object-fit: cover;
							border-radius: 10px;
						}

						.removePicture {
							position: absolute;
							top: 4px;
							right: 4px;
							width: 11px;
							opacity: 0.7;
							background-color: white;
							border-radius: 6px;
							padding: 5px;
							cursor: pointer;
							transition: opacity ease-in-out 0.2s;
						}
					}
				}

				.filesList {
					display: flex;

					.fileContainer {
						margin-top: 10px;
						padding-left: 10px;

						&.firstFile {
							padding-left: 26px;
						}

						.filePreview {
							display: flex;
							background-color: white;
							border-radius: 10px;
							padding: 10px;
							align-items: center;
							justify-content: center;

							.fileTypeIcon {
								width: 30px;
								margin-right: 10px;
							}

							.fileInfo {
								display: flex;
								flex-direction: column;

								.fileName {
									font-size: 13px;
									font-weight: 600;
									margin: 0;
									white-space: nowrap;
								}

								.fileSize {
									font-size: 11px;
									color: #767676;
									margin: 0;
									margin-top: 2px;
								}
							}

							.removeFile {
								margin-left: 15px;
								width: 12px;
								margin-right: 5px;
								opacity: 0.8;
								cursor: pointer;
								transition: opacity ease-in-out 0.2s;
							}
						}
					}
				}
			}

			.inputActions {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-left: 10px;
				padding-right: 10px;
			}

			.messageInput {
				display: flex;
				flex-direction: column;
			}

			.responsePreview {
				display: flex;
				align-items: center;
			}

			.sendIcon {
				&.disabled {
					opacity: 0.5;
					transform: scale(0.8);
				}
			}

			.retryFailedMessages {
				align-self: center;
				padding-top: 14px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.retryFailedMessagesText {
					margin: 0;
					font-size: 14px;
				}

				.retryButton {
					margin: 0;
					margin-left: 3px;
					font-size: 14px;
					cursor: pointer;
					font-weight: 600;
				}
			}
		}

		.messaging-dropzone-overlay {
			position: absolute;
			top: 0;
			left: 390;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100vh;
			width: calc(100vw - 390px);
			margin-left: 6px;

			p {
				color: white;
				font-size: 24px;
				text-align: center;
			}
		}

		.progress-bar {
			position: absolute;
			height: 2px;
			width: calc(100vw - 390px);
			opacity: 1;
			z-index: 2;
			align-self: flex-start;

			.progress-bar-fill {
				height: 100%;
				width: 0;
				background-color: #007bff;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				opacity: 0.9;
			}
		}

		input[type='file'] {
			display: none;
		}
	}
}

.modalNewGroup {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 700px) / 2);
	margin-left: calc((100vw - 500px) / 2);
	width: 500px;
	height: 700px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
	display: flex;
	flex-direction: column;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
	}

	.titleModalNewGroup {
		margin: 0;
		font-size: 21px;
		font-weight: 700;
	}

	.subtitleModalNewGroup {
		font-size: 14px;
		margin: 0;
		opacity: 0.7;
	}

	.groupPicture {
		margin-left: 20px;
		font-weight: 600;
		width: 48px;
		height: 48px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #7fa2fd;
		margin-right: 15px;
	}

	.barModalNewGroup {
		height: 1px;
		background-color: #eeeeee;
		margin-top: 20px;
	}

	.scrollView {
		height: 550px;
		margin-bottom: -20px;
		display: flex;
		flex-direction: column;
		overflow: scroll;
		padding-bottom: 20px;
	}

	.indicator {
		font-weight: 600;
		margin-left: 25px;
		font-size: 15px;
		margin-bottom: 10px;
	}

	.inputGroupName {
		display: flex;
		align-items: center;
		height: 29px;
		border: solid 2px #eeeeee;
		margin-left: 15px;
		margin-right: 15px;
		border-radius: 6px;
		outline: none;
		font-size: 14px;
		padding-left: 10px;
		padding-right: 10px;
		font-weight: 500;
		margin-bottom: 10px;
		width: 450px;
	}

	.listParticipants {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 10px;
		min-height: 100%;

		.itemParticipantGroup {
			margin-left: 20px;
			display: flex;
			align-items: center;
			font-weight: 600;
			justify-content: space-between;

			.buttonAdd,
			.buttonDelete {
				margin-right: 20px;
				font-size: 13px;
				background-color: #3ca504;
				color: #ffffff;
				padding: 5px 10px;
				border-radius: 99px;
			}

			.buttonDelete {
				background-color: #ff0000;
			}
		}
	}

	.createGroup {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		color: #3ca504;
		font-weight: 600;
		font-size: 15px;
	}
}

.profilePictureBleu,
.profilePictureBleuLarge,
.profilePictureBleuClair,
.profilePictureBleuClairLarge,
.profilePictureVert,
.profilePictureVertLarge,
.profilePictureBleuLittle,
.profilePictureBleuClairLittle,
.profilePictureVertLittle {
	font-weight: 600;
	width: 48px;
	height: 48px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	letter-spacing: 1px;
}

.profilePictureBleu,
.profilePictureBleuLarge,
.profilePictureBleuLittle {
	background-color: #7fa2fd;
	color: #ffffff;
}

.profilePictureBleuClair,
.profilePictureBleuClairLarge,
.profilePictureBleuClairLittle {
	background-color: rgba(127, 163, 252, 0.2);
	color: #5b84e8;
}

.profilePictureVert,
.profilePictureVertLarge,
.profilePictureVertLittle {
	background-color: #83d98a;
	color: #ffffff;
}

.profilePictureBleuLarge,
.profilePictureBleuClairLarge,
.profilePictureVertLarge {
	width: 100px;
	height: 100px;
	font-size: 35px;
	margin-left: 9px;
}

.profilePictureBleuLittle,
.profilePictureBleuClairLittle,
.profilePictureVertLittle {
	width: 45px;
	height: 45px;
	font-size: 18px;
}

.searchBarMessaging {
	display: flex;
	align-items: center;
	background-color: #f8f8f8;
	margin-left: 15px;
	margin-right: 15px;
	border-radius: 5px;
	height: 33px;

	.searchIconMessaging {
		width: 14px;
		opacity: 0.4;
		height: 14px;
		margin-left: 10px;
		margin-right: 5px;
	}

	input {
		background-color: #f8f8f8;
		border: none;
		font-size: 14px;
		width: 200px;
		outline: none;
		font-weight: 500;
	}
}

.listProjectChoice {
	display: flex;
	flex-direction: column;
	height: 590px;
	overflow: scroll;
	margin-top: -20px;

	.indicator {
		margin-left: 20px;
		font-size: 15px;
		font-weight: 600;
		margin-top: 20px;
	}

	.itemProjectToChoose {
		margin-left: 20px;
		font-weight: 600;
		font-size: 15px;

		img {
			width: 45px;
			height: 45px;
			object-fit: cover;
			border-radius: 5px;
			margin-right: 15px;
		}

		.agencyIcon {
			width: 45px;
			height: 45px;
			border-radius: 5px;
			margin-right: 15px;
			background: #7fa2fd;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: white;
				font-weight: 600;
				font-size: 15px;
			}
		}

		.containerIndicChoose {
			width: 20px;
			height: 20px;
			border-radius: 100px;
			border: solid 1px #eeeeee;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 25px;

			.indicChoose {
				width: 18px;
				height: 18px;
				border-radius: 100px;
				background-color: #7fa2fd;
			}
		}
	}
}

.barProjectToChoose {
	display: flex;
	height: 1px;
	background-color: #eeeeee;
	margin-left: 80px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.messageProgress {
	position: relative;
	font-size: 12px;
	color: #666;

	&.uploading {
		color: #2196f3;
	}

	&.sent {
		color: #4caf50;
	}

	&.failed {
		color: #ff4444;
	}
}
