.choosePlanPage {

  width: 1350px;
  max-width: calc(100vw - 70px);
  height: calc(100vh - 56px);
  overflow: scroll;
  margin-top: -10px;

  .buttonGoBack:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .buttonGoBack {
    display: flex;
    align-items: center;
    padding: 30px 20px 20px 0px;
    font-weight: 700;
    font-size: 15px;
    gap: 5px;
    transition: opacity ease-in-out 0.2s;

    .rightIcon {
      width: 7px;
      object-fit: contain;
    }
  }

  .containerPlans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;

    .containerSales {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: -10px;
      margin-bottom: 25px;

      .profilePicture {
        width: 80px;
        height: 80px;
        border-radius: 200px;
        object-fit: cover;
      }

      .imagineBy {
        opacity: 0.7;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        margin-bottom: 5px;
      }

      .salesName {
        font-weight: 600;
        font-size: 18px;
        margin: 0;
      }

      .salesTitle {
        color: #1C57DD;
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 0;
      }
    }
  }

  @media(max-width: 1355px) {

    .containerPlans {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }

  .planTarifs {
    color: #5667F6;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .mainTitle {
    margin-top: 0px;
    width: 700px;
    max-width: 100vw;
    font-size: 42px;
    font-weight: 700;
  }

  .planTypeChoices {
    margin-top: 30px;

    .title {
      font-weight: 700;
      font-size: 25px;
    }

    .planTypeChoice:hover {
      opacity: 0.7;
    }

      .planTypeChoice {
        display: flex;
        cursor: pointer;
        transition: all ease-in-out 0.2s;

      .containerRond {
        margin-right: 15px;
        width: 26px;
        height: 26px;
        border-radius: 99px;
        border: 1px solid #5667F6;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;

        .rond {
          background-color: #5667F6;
          width: 14px;
          height: 14px;
          border-radius: 99px;
          transition: all ease-in-out 0.2s;

        }
      }

      .planType {
        margin-top: 5px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .recomand {
        margin-top: 0px;
        font-weight: 700;
        color: #1C57DD;
      }
    }
  }

  @media(max-width: 1355px) {

    .planTypeChoices {
      display: flex;
      flex-direction: row;
      gap: 40px;

      .title {
        margin-top: 0px;
      }
    }

  }
}

.planChoices {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  margin-top: 30px;
  justify-content: center;

  .currencies {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 12px;
    margin-left: 10px;

    .widgetCurrency, .widgetCurrencySelected {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 200px;
      height: 120px;
      border-radius: 10px;
      border: solid 2px #eeeeee;
      text-align: center;
      transition: all 0.2s ease-in-out;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 600;


    }

    .save {
      border: solid 1px #1C57DD;
      border-radius: 20px;
      padding: 4px 10px;
      color: #1C57DD;
      background-color: rgba(28, 87, 221, 0.1);
      font-size: 14px;
      margin-top: 17px;
      transition: background-color ease-in-out 0.2s ;
    }

    .widgetCurrencySelected {
      border: solid 2px #1C57DD;
      background-color: rgba(86, 103, 246, 0.1);
      color: #1C57DD;
      .save {
        background-color: #FFFFFF;
      }
    }

    .widgetCurrency:hover {
      border: solid 2px #5667F6;
      background-color: rgba(86, 103, 246, 0.1);
      color: #1C57DD;
      font-weight: 600;
      cursor: pointer;
    }
  }

}

.itemPlanChoiceAdvice {
  width: 270px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 30px;

  .contactAdvice {
    background-color: #3CA504;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    border-radius: 5px;
  }

  .avatarAdvice {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
    margin-right: 10px;
  }

  .nameInfoAdvice {
    font-size: 14px;
  }

  .citation {
    margin-top: 25px;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 25px;
  }
}

.itemPlanChoiceWhite, .itemPlanChoiceLightBlue, .itemPlanChoiceBlue {
  border-radius: 20px;
  width: 270px;
  padding-left: 25px;
  padding-right: 25px;

  .titlePlan {
    margin-top: 20px;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 0px;
  }

  .subtitlePlan {
    font-weight: 600;
    font-size: 15px;
    margin-top: 5px;
  }

  .bar {
    height: 1px;
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #C2C2C3;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .checkOption {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;

    .simpleCheck {
      width: 12px;
      height: 12px;
    }
  }

  .price {
    font-weight: 700;
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .subtitlePrice {
    font-size: 15px;
    opacity: 0.7;
    margin-top: 5px;
  }

  .buttonPlan {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
    border: solid 1px #D9DCF9;
    margin-bottom: 25px;
  }

  .currentPlan {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
  }

  .numberSeats {
    display: flex;
    gap: 20px;
    margin-left: 10px;
    margin-top: 30px;

    .paidSeats, .freeSeats {
      border: solid 1px #FFFFFF;
      border-radius: 10px;
      background-color: rgb(214, 226, 255);
      color: #1C57DD;
      text-align: center;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 143px;
      font-size: 14px;
      font-weight: 600;
      gap: 10px;

      .number {

        font-size: 34px;
        margin: 0;

      }
    }

  }
}

.itemPlanChoiceWhite {
  background-color: #FFFFFF;
  border: solid 1px #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

}

.itemPlanChoiceLightBlue {
  background-color: #EBEDFD;
  border: solid 1px #D9DCF9;
  color: #5667F6;
}

.itemPlanChoiceBlue {
  width: 630px;
  display: flex;
  background-color: #5667F6;
  border: solid 1px #5667F6;
  color: #FFFFFF;

   .illustration {
    margin-left: 30px;
    margin-right: -10px;
    width: 280px;
    height: 413px;
    border-radius: 10px;
    object-fit: cover;
    margin-top: 12px;
     margin-bottom: 12px;
  }
}

.tableauFunctionalities {
  margin-top: 100px;
  min-width: 1000px;

  .categoryFunctionality {
    width: 100%;
    margin-bottom: 55px;

    .headerCategoryFunctionality {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #EBEBEB;

      .firstPart {
        width: 500px;

        .title {
          font-size: 17px;
        }
      }

      .secondaryPart {
        width: calc((100% - 500px)/2);
        font-weight: 700;

      }
    }

    .listFunctionalities {

      .ItemOptionCategoryFunctionality {
        width: 100%;
        border-bottom: solid 1px #EBEBEB;
        display: flex;
        align-items: center;

        .firstPart {
          width: 500px;

          .title {
            font-size: 15px;
            font-weight: 500;
            color: #666666;
          }
        }

        .secondaryPart {
          width: calc((100% - 500px)/2);
          font-size: 15px;

          .simpleCheck {
            width: 12px;
            height: 12px;
          }

        }
      }
    }
  }
}
