.snagPage {
  display: flex;
  flex-direction: column;

  .statusBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 99px;
    padding: 5px 10px;
    background-color: rgba(28, 87, 221, 0.1);
    color: #1C57DD;
    font-weight: 600;
    font-size: 14px;

    .ticketNameWebkit {
      display: flex;
      white-space: nowrap;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .containerSnagNameCreator {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .snagName {
      font-weight: 700;
      font-size: 25px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .creator {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
  }


  .snagDescription {

    .generalDatas {
      display: flex;
      align-items: center;
      gap: 15px;

      .itemGeneralData {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 5px;
        border: solid 1px #dddddd;
        border-radius: 99px;
        padding: 2px 7px;

        .icon {
          height: 13px;
        }
      }
    }

    .containerPictures {
      display: flex;
      align-items: flex-start;
      margin-top: 20px;
      width: 100%;
      gap: 10px;

      img {
        width: calc((100% - 20px)/3);
        object-fit: contain;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      height: 200px;

      .titleDescription {
        margin-top: 10px;
        font-weight: 700;
        font-size: 20px;
        width: 320px;
      }

      .corpsDescription {
        margin-top: 10px;
        font-size: 14px;
        opacity: 0.7;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  .containerResolution {
    display: flex;
    flex-direction: column;
    border-top: solid 1px #dddddd;
    height: 410px;

    .timingItem {
      background-color: #f2f3f4;
      display: flex;
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      margin-right: 10px;
    }

    .timingItemOverdue {
      background-color: #f4e0e9;
      color: #b41111;
    }

    .tagResolutionStatus {
      display: flex;
      padding: 5px 10px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
    }

  }

  .containerSolutionProposal {
    display: flex;
    border: solid 1px #dddddd;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 240px;
    border-radius: 5px;

    .waitingForSolving {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 13px;
      font-size: 13px;

      .hourglass {
        width: 11px;
        opacity: 0.5;
      }
    }

    .containerSolution {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .resolutionSubmitted {
        border-radius: 10px;
        padding: 0px 10px;
        padding-top: 5px;
        width: 360px;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 10px;

        .whoPropos {
          font-size: 14px;

          .boldBlue {
            color: #1c57dd;
            font-weight: 700;
          }
        }
        .date {
          margin-top: 5px;
          font-size: 13px;
          opacity: 0.7;
        }
        .titleResolution {
          margin-top: 10px;
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 0px;
          width: 320px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .descriptionResolution {
          margin-top: 10px;
          width: 360px;
          font-size: 14px;
          opacity: 0.7;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }

      .img {
        width: 180px;
        object-fit: cover;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
  }


  .containerApproval {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 99px;
    padding: 5px 10px;
    background-color: rgba(28, 87, 221, 0.1);
    color: #1C57DD;
    font-weight: 600;
    font-size: 14px;
  }
}
