.containerSelectLocation {
	margin: 30px;
	margin-bottom: 20px;
	border-radius: 10px;
	transition: all ease-in-out 0.2s;
	background-color: #ffffff;

	.headerSelect {
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 10px;
		font-weight: 600;
		font-size: 14px;
		padding-right: 10px;
		cursor: pointer;

		.mappin {
			width: 12px;
			opacity: 0.4;
			margin-right: 8px;
		}

		.upDown {
			width: 5px;
		}
		.crossIcon {
			width: 20px;
			margin-top: 4px;
		}
	}

	.containerInputLocationOnSite {
		z-index: 1000;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.19);
		white-space: nowrap;
		border-radius: 7px;
		width: 335px;
		background-color: #ffffff;
		min-height: 32px;
		border: solid 1px #e0e0e0;
		position: absolute;

		.boxInputLocationOnSite {
			display: flex;
			justify-content: center;
			background-color: #f6f6f6;
			border-radius: 7px 7px 0px 0px;
			height: 30px;
			border-bottom: 1px solid #e0e0e0;

			.inputLocationOnSite {
				border-radius: 7px 7px 0px 0px;
				outline: none;
				border: 0;
				margin-left: 5px;
				padding-left: 5px;
				font-size: 14px;
				background-color: #f6f6f6;
				width: 326px;
			}
		}

		.optionList {
			margin-left: 10px;
			padding-bottom: 4px;
			padding-top: 4px;

			.containerItemDropDown,
			.containerItemDropDownRename,
			.containerItemDropDownUnHover {
				padding: 4px 0px;
				border-radius: 5px;
				margin: 2px 5px 1px -5px;
				width: 280px;
				justify-content: space-between;
				cursor: pointer;

				&.selected {
					color: #3ca504;
				}

				.checkVert {
					width: 14px;
					margin-right: 10px;
				}

				.plusCircle {
					width: 14px;
					margin-right: 10px;
					opacity: 0.4;
					transition: opacity 0.3s ease;

					&:hover {
						cursor: pointer;
					}
				}

				.type {
					padding: 4px 7px;
					font-size: 13px;
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.formRenameOption {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #e0e0e0;
					margin-left: 7px;
					padding-bottom: 5px;

					.inputRenameOption {
						outline: none;
						font-size: 13px;
						font-weight: 500;
						border: 0;
						width: 245px;
					}

					.containerEnterIcon {
						border: 0;
						background-color: #ffffff;
						padding: 0;
						.enterIcon {
							opacity: 0.4;
							width: 15px;
						}

						.enterIcon:hover {
							opacity: 1;
							cursor: pointer;
						}
					}
				}
			}

			.containerItemDropDown:hover {
				background-color: #f3f3f3;
				cursor: pointer;

				.plusCircle {
					opacity: 1;
				}
			}
		}
	}
}
