.ticketDocument {
	.titleDocument {
		font-size: 22px;
		font-weight: 600;
		margin-left: 25px;
		margin-top: 20px;
	}

	.listElems {
		margin-left: 25px;
		margin-right: 25px;

		.containerTicketImage {
			position: relative;
			width: 270px;
			margin-bottom: 10px;
			cursor: pointer;

			.ticketImage {
				width: 100%;
				height: auto;
				display: block;
				border-radius: 6px;
			}

			.imageOverlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.adder {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 13px;
				margin: 10px;
				background-color: #ffffffd3;
				border-radius: 5px;
				height: 20px;
				font-weight: 600;
				padding: 0 6px;
			}

			.trashIcon {
				width: 25px;
				opacity: 0.1;
				align-self: flex-end;
				margin: 0 10px 10px 0;
			}

			&:hover {
				.trashIcon {
					opacity: 1;
				}
			}
		}
	}

	.containerTicketImage:hover {
		.trashIcon {
			opacity: 1;
		}
	}

	.buttonAddItems {
		margin-left: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
		margin-top: 10px;
		width: 545px;
		margin-bottom: 0px;
		margin-right: 40px;
		background-color: #f8f8f8;
		height: 120px;
		border-radius: 10px;
		font-weight: 600;
		font-size: 14px;
		border: dashed 2px #e0e0e0;
		transition: all ease-in-out 0.2s;
		cursor: pointer;
		img {
			width: 15px;
			margin-right: 10px;
		}
	}

	.buttonAddItems:hover {
		transform: scale(1.02);
	}
}

.containerPDF {
	display: flex;
	border: solid 1px #e0e0e0;
	padding: 10px 0px 10px 10px;
	border-radius: 6px;
	margin-bottom: 13px;
	justify-content: space-between;

	img {
		width: 30px;
		margin-right: 10px;
	}

	.docName {
		font-size: 14px;
		font-weight: 600;
		margin: 0;
	}

	.authorName {
		font-size: 12px;
		font-weight: 400;
		margin: 0;
		margin-top: 2px;
	}

	.trashIcon {
		width: 25px;
		opacity: 0.1;
		margin-left: 10px;
	}

	&:hover {
		.trashIcon {
			opacity: 1;
		}
	}
}
