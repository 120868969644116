
  // Modal nouveau projet
  // Modal nouveau projet
  // Modal nouveau projet
  // Modal nouveau projet
  // Modal nouveau projet
  // Modal nouveau projet

.modalNewProjet {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 550px)/2);
  margin-left: calc((100vw - 520px)/2);
  width: 520px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  h2 {
    margin-left: 21.25px;
    font-size: 20px;
    margin-bottom: 0px;
  }

  .photoTitle {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: -10px;
    margin-left: 20px;
  }


  .photo {
    width: 483px;
    height: 250px;
    margin-bottom: 22px;
    background-color: #eeeeee;
    border-radius: 17px;
    margin-left: 19px;
    margin-top: 25.5px;

    #input[type="file"] {
      display: none;
    }

    .photos:hover {
      opacity: 0.8;
    }

    .photos{
      width: 483px;
      height: 250px;
      margin-bottom: 22px;
      background-color: rgba(222, 222, 222, 0);
      border-radius: 17px;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 0.9;

      .hidden {
        display: flex;
        position: absolute;
        margin-top: 17px;
      }

      .label{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .image-upload {
        margin-top: -13px;
        margin-left: -195px;
        display: flex;
        width: 119px;
        height: 21.25px;
        color: black;
        font-weight: bolder;
        font-size: 10px;
        background-color: #ffffff;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
      }

      .image-upload:hover {
        opacity: 0.7;
      }
    }
  }

  .informationsGenerales {

    width: 450px;
    min-height: 77px;
    border-radius: 13px;
    margin-left: 22px;
    margin-top: 0px;
    margin-bottom: 22px;

    .inputTitle {
      font-size: 14px;
      font-weight: 600;
      margin-top: 0px;
    }

    .inputName {
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      width: 455px;
      height: 35px;
      border-radius: 10px;
      font-size: 13px;
      font-weight: 500;
      border: solid 1px #eaeaea;
    }
  }

  .requireCreate {
    font-size: 14px;
    font-weight: 500;
    color: #9a9a9a;
    transition: all ease-in-out 300ms;
    margin: 0;
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      opacity: 0.7;
    }
    .choice {
      transform: translateX(-220px);
    }
  }

  .jeConfirmeButton {
    width: 480px;
    height: 35px;
    border-radius: 7px;
    background-color: #4867EC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
    transition: all ease-in-out 250ms;

    .textJeConfirme {
      font-size: 15px;
      font-weight: 600;
      color: #FFFFFF;
    }
  }

  .jeConfirmeButtonDisable {
    width: 460px;
    height: 33px;
    border-radius: 7px;
    background-color: #4867EC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;

    .textJeConfirme {
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}

.OverlayModalNewProjet {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

  // Modal félcitation
  // Modal félcitation
  // Modal félcitation
  // Modal félcitation
  // Modal félcitation
  // Modal félcitation


.modalFelicitation {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 440px)/2);
  margin-left: calc((100vw - 520px)/2);
  width: 520px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .message {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 30px;
    transition: all 1s;
    -webkit-transition: all 1s;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .imgProjet {
    object-fit: cover;
    width: 450px;
    margin-bottom: 30px;
    transition: all 1.5s;
    -webkit-transition: all 1.5s;
  }

  .jeConfirmeButton:hover {
    cursor: pointer;
    opacity: 0.7;
    .arrowWhite {
      margin-left: 20px;
    }
  }

  .jeConfirmeButton {
    width: 480px;
    height: 33px;
    border-radius: 7px;
    background-color: #4867EC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    margin-bottom: 20px;

    .textJeConfirme {
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }

    .arrowWhite {
      width: 11px;
      object-fit: contain;
      margin-left: 5px;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
    }
  }
}

.OverlayModalFelicitation {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}



