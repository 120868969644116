.item,
.itemSelected {
	border: solid 1px #e2e2e2;
	border-radius: 99px;
	padding: 3px 8px 4px 8px;
	font-size: 14px;
	color: #7c7c7c;
	white-space: nowrap;
	transition: opacity ease-in-out 0.2s;
	display: flex;
	align-items: center;
	cursor: pointer;
    gap: 5px;

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e2e2e2;
		border: solid 1px #c2c2c2;
	}
}

.itemSelected {
	background-color: rgba(28, 87, 221, 0.05);
	color: #1c57dd;
	border: solid 1px #1c56dd58;

	&:hover {
		background-color: rgba(28, 87, 221, 0.1);
	}

	&:active {
		background-color: rgba(28, 87, 221, 0.17);
		border: solid 1px #1c56dd78;
	}
}

.arrowDown,
.arrowDownSelected {
	width: 9px;
	object-fit: contain;
	opacity: 0.3;
	margin-top: 3px;
}

.arrowDownSelected {
	opacity: 1;
}

.dropDown,
.dropDownHidden {
	position: absolute;
	z-index: 1000;
    min-width: 250px;
	margin-top: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	border: solid 1px #e1e1e1;
	border-radius: 10px;
	width: fit-content;
	white-space: nowrap;
	opacity: 1;
	transition: all ease-in-out 0.2s;
}

.dropDownHidden {
	pointer-events: none;
	opacity: 0;
	transform: translateY(-5px);
}

.dropDownList {
	padding: 8px;
	gap: 8px;
	margin: 0;
	display: flex;
	flex-direction: column;

	li {
		list-style: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		padding: 0px;
		gap: 20px;
	}
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.sortOption {
	border: solid 1px #e2e2e2;
	border-radius: 6px;
	padding: 6px 8px;
	gap: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e2e2e2;
		border: solid 1px #c2c2c2;
	}

	p {
		margin: 0;
		font-size: 14px;
        color: #494949;
	}

	img {
		width: 10px;
		height: 10px;
		object-fit: contain;
		opacity: 0.3;
		margin-top: 2px;
	}
}

.crossContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px;
	cursor: pointer;
	border-radius: 6px;

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e2e2e2;
	}

	img {
		width: 9px;
		object-fit: contain;
		opacity: 0.4;
	}
}

.dropDownFooter {
	padding: 4px;
}

.manageSortOption {
	width: calc(100% - 12px);
	border-radius: 6px;
	padding: 6px 0px 6px 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e2e2e2;
	}

	p {
		margin: 0;
		font-size: 13px;
        color: #7c7c7c;
	}

	img {
		width: 12px;
		object-fit: contain;
		opacity: 0.45;
	}
}

.littleDropDown,
.littleDropDownHidden {
	position: absolute;
	z-index: 1000;
	margin-top: 5px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	border: solid 1px #e1e1e1;
	border-radius: 10px;
	width: fit-content;
	white-space: nowrap;
	opacity: 1;
	transition: all ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 4px;
    min-width: 150px;

    li {
		list-style: none;
		display: flex;
		align-items: center;
		border-radius: 6px;
		cursor: pointer;
		padding: 4px 30px 4px 10px;

		&:hover {
			background-color: #f2f2f2;
		}

		p {
			margin: 0;
			font-size: 14px;
			color: #494949;
		}
	}
}

.littleDropDownHidden {
	pointer-events: none;
	opacity: 0;
	transform: translateY(-5px);
}
