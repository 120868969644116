.premiereConnexion {
  display: flex;
  align-items: center;
  flex-direction: column;

  .headerTitle {
    margin-top: 40px;
    font-size: 25px;
    font-weight: 600;
  }

  .footerTitle {
    margin-top: 70px;
    font-size: 20px;
    font-weight: 600;
  }

  .footerText {
    margin-top: 0px;
    font-size: 16px;
    font-weight: 500;
    color: #9a9a9a;
    width: 290px;
    text-align: center;
  }

  .qrCode {
    transition: width 5s;
    width: 0px;
    object-fit: contain;
    margin-left: 20px;
    margin-right: 20px;
  }

  .qrCode.big-width {
    width: 200px;
  }

  .iPhone {
    margin-top: 40px;
    height: calc(100vh - 350px);
    object-fit: contain;
  }

  .iPhoneLittle {
    margin-top: 76px;
    height: 0px;
    object-fit: contain;
    transition: height 1s;
  }

  .container {
    height: calc(100vh - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iPhoneLittle.big-width {
    height: calc(100vh - 350px);
  }

  .opus {
    margin-top: 76px;
    height: 0px;
    object-fit: contain;
    transition: height 1s;
  }

  .opus.big-width {
    height: 100px
  }

  .presentation {
    position: absolute;
    height: 100vh;
    object-fit: contain;
    transition: opacity 2s;
    opacity: 0;
  }

  .presentation.fade {
    opacity: 1;
  }
}
