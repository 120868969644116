.locationsDropDown {
    .research {
        display: flex;
        justify-content: center;
        background-color: #f6f6f6;
        border-radius: 7px 7px  0px 0px;
        height: 30px;
        border-bottom: 1px solid #e0e0e0;
        padding: 0px 8px;

        input {
            border-radius: 7px 7px 0px 0px;
            outline: none;
            border: 0;
            font-size: 14px;
            width: 100%;
            background-color: transparent;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        max-height: 315px;
        overflow: scroll;
    }

    .header {
        display: flex;
        justify-content: space-between;
    }

    .subtitle {
        font-size: 13px;
        font-weight: 600;
        color: #727272;
        margin: 0;
        margin-bottom: 5px;
        margin-left: 5px;
    }

    .editButton {
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        margin-bottom: 5px;
        margin-right: 5px;
        color: #1c57dd;
        cursor: pointer;
        transition: opacity ease 0.2s;

        &:hover {
            opacity: 0.6;
        }
    }
    
    .itemLevel {
        display: flex;
        align-items: center;
        position: relative;
        padding: 6px 0px;
        border-radius: 5px;
        margin: 2px 0px 0px 0px;
        width: 100%;

        &:hover {
            background-color: #f3f3f3;
            cursor: pointer;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            margin-left: 5px;
        }
    }

    .triangle {
        margin-left: 6px;
        width: 8px;
        transition: transform ease-in-out 0.2s;
    }

    .checkVert {
        display: flex;
        position: absolute;
        right: 0;
        margin-right: 6px;
        width: 14px;
    }

    .itemLocation {
        display: flex;
        align-items: center;
        position: relative;
        padding: 6px 0px 6px 6px;
        margin: 2px 0px 1px 13px;
        font-size: 14px;
        border-radius: 5px;
        font-weight: 600;
        width: calc(100% - 19px);

        &:hover {
            background-color: #f3f3f3;
            cursor: pointer;
        }

        img {
            display: flex;
            position: absolute;
            right: 0;
            margin-right: 6px;
            width: 14px;
        }
    }
}
