.dashboardAgence {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 108px);
  overflow: scroll;
  padding-top: 50px;
  margin-top: -10px;

  .carteStat {
    background-color: #D2E7F8;
    border-radius: 10px;
    display: flex;
    margin-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    .nombre {
      font-size: 35px;
      font-weight: 700;
      margin-right: 25px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .nom {
      margin-top: 0px;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .sousTitre {
      margin-top: 2px;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  .logoAgence {
    margin-top: 0px;
    width: 450px;
    object-fit: contain;
    display: flex;
    transition: all ease-in-out 0.3s;
  }

  .listeLiens {
    width: 100vw;
  }

}

.designantPartie {
  display: flex;
  margin-left: 30px;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;


  .rondBleu {
    width: 10px;
    height: 10px;
    margin-right: 14px;
    background-color: #D6E6F6;
    border-radius: 20px;
  }

  .containerDesignant {
    padding: 8px 12px;
    font-size: 14px;
    background-color: #D6E6F6;
    color: #4B5FB0;
    font-weight: 700;
    border-radius: 10px;
  }
}

.image-clignote-rapidement  {
  animation-duration: 1.5s;
  animation-name: clignoterRapidement;
  animation-iteration-count: infinite;
  transition: none;
}
@keyframes clignoterRapidement {
  0%   { opacity:1; }
  20%   { opacity:1; }
  50%   {opacity: 0; }
  80%   {opacity: 0; }
  100% { opacity: 1; }
}


.image-clignote  {
  animation-duration: 2s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}

.carteLiens {
  width: 400px;
  margin-right: 48px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  border-radius: 15px;
  margin-bottom: 40px;
  cursor: pointer;

  .image-clignote  {
    animation-duration: 2s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
  }
  @keyframes clignoter {
    0%   { opacity:1; }
    40%   {opacity:0.5; }
    100% { opacity:1; }
  }

  .title {
    color: #000000;
    margin-left: 20px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 35px;
  }

  .integrerProjetVisuel {
    width: 340px;
    margin-top: -20px;
    object-fit: contain;
  }

  .buttonVoirPlus:hover {
    opacity: 0.7;
  }

  .buttonVoirPlus {
    margin-top: 18px;
    width: 370px;
    margin-left: 15px;
    height: 40px;
    border-radius: 5px;
    background-color: #EFF2FC;
    color: #4462C7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .buttonVoirPlusBleu {
    margin-top: 18px;
    width: 370px;
    margin-left: -5px;
    height: 40px;
    border-radius: 5px;
    background-color: #EFF2FC;
    color: #4462C7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .buttonManageSubscription {
    margin-top: 18px;
    width: 200px;
    margin-left: -5px;
    height: 40px;
    border-radius: 5px;
    background-color: #EFF2FC;
    color: #4462C7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .buttonClaimBills {
    margin-top: 18px;
    width: 140px;
    margin-left: 15px;
    height: 40px;
    border-radius: 5px;
    background-color: #f0f8ef;
    color: #3CA504;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 10px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .buttonVoirPlusVert {
    margin-top: 18px;
    width: 370px;
    margin-left: -5px;
    height: 40px;
    border-radius: 5px;
    background-color: #3CA504;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  .titleCard {
    margin-left: 15px;
    font-size: 15px;
    font-weight: 500;
  }

  .modify:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  .modify {
    margin-top: 16px;
    font-size: 14px;
    margin-left: 125px;
    font-weight: 500;
    color: #4B5FB0;
    opacity: 0.8;
    transition: opacity 0.3s;
    letter-spacing: 0.5px;
  }

  .abonnement {
    margin-top: 0px;
    width: 110px;
    height: 110px;
    background-color: #F6F9FC;
    margin-left: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 10px;

    .doubleOBleu {
      width: 65px;
      object-fit: contain;
    }

    .name {
      margin-top: 0px;
      color: #4B5FB0;
      font-weight: 500;
      letter-spacing: 0.4px;
      font-size: 13px;
    }
  }

  .chiffresUtilisation {
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    letter-spacing: 0.5px;
    word-spacing: 2px;
  }

  .offreCommerciale {
    margin-top: 6px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 300;
  }

  .manage:hover {
    opacity: 0.8;
  }

  .manage {
    margin-left: 10px;
    width: 70px;
    height: 24px;
    border: solid 2px #3E51A9;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;

    .text {
      color: #3E51A9;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .upgrade:hover {
    opacity: 0.8;
  }

  .upgrade {
    margin-left: 10px;
    width: 80px;
    height: 24px;
    border: solid 1px #3E51A9;
    background-color: #3E51A9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  }

  .text {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
  }
}

.itemAccount {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .profilePicture {

    width: 37px;
    height: 37px;
    border-radius: 40px;
    margin-left: 30px;
    margin-right: 20px;
    object-fit: cover;
  }

  .profilePictureBleu {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 30px;
    margin-right: 20px;
    object-fit: cover;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 700;
  }

  .profilePictureBleuClair {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 30px;
    margin-right: 20px;
    object-fit: cover;
    background-color: rgba(127,163,252,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5b84e8;
    font-weight: 700;
  }

  .profilePictureVert {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 30px;
    margin-right: 20px;
    object-fit: cover;
    background-color: #83d98a;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .nom {
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 15px;
    margin-top: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 160px;
  }

  .statut {
    display: flex;
    font-size: 12px;
    color: #4B5FB0;
    background-color: #F4F6FA;
    border-radius: 20px;
    border: solid #D2E7F8 1px;
    padding: 5px 10px;
    font-weight: 600;
    margin-top: 0px;
  }
}

.modalComptesLies {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  border-radius: 10px;
  outline: none;
  display: flex;

  .modalListe {
    background-color: #ffffff;
    border-radius: 10px;
    transition: width ease-in-out 0.2s;

    .crossIcon:hover {
      opacity: 0.8;
    }

    .crossIcon {
      width: 23px;
      object-fit: contain;
      right: 0;
      margin-top: -50px;
      margin-right: 10px;
      margin-left:  -20px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }

    .title {
      font-weight: 600;
      font-size: 23px;
      margin-top: 30px;
      margin-left: 30px;
      margin-bottom: 10px;
    }

    .subtitle {
      margin-top: 0px;
      margin-left: 30px;
      opacity: 0.5;
      font-weight: 600;
      font-size: 15px;
    }


    .headerListCollabs {
      display: flex;
      margin-top: 10px;

      .inputResearchAccounts {
        outline: none;
        border: solid 0px;
        color: #7A818C;
        font-size: 14px;
        font-weight: 600;
      }

      .itemHeadListCollab {
        color: #7A818C;
        font-size: 14px;
        font-weight: 600;
      }

      .repartitionHeaderListCollabs {
        display: flex;
      }
    }

    .bar {
      width: 1240px;
      margin-left: 30px;
      height: 1px;
      background-color: #eeeeee;
    }

    .listAccounts {
      padding-top: 15px;
      height: 579px;
      overflow: scroll;
    }

    .voileBlanc {
      height: 579px;
      width: 1300px;
      background-color: rgba(255, 255, 255, 0.68);
      border-radius: 20px;
      margin-top: -579px;
      z-index: 1;
      position: absolute;
      transition: width ease-in-out 0.2s;
    }

    .itemCollaborateur:hover {
      background-color: #F4F6FA;
    }

    .profilePictureBleu {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 15px;
      object-fit: cover;
      background-color: #7FA3FC;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 600;
    }

    .profilePictureBleuClair {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 15px;
      object-fit: cover;
      background-color: rgba(127,163,252,0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5b84e8;
      font-weight: 600;
    }

    .profilePictureVert {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 15px;
      object-fit: cover;
      background-color: #83d98a;
      color: #FFFFFF;      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }


    .itemCollaborateur {
      margin-left: 10px;
      height: 65px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0);
      margin-bottom: 8px;
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      -webkit-transition: all ease-in-out 0.2s;
      display: flex;
      align-items: center;

      .photoProfil {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 15px;
        object-fit: cover;
      }


      .nomCompte {
        margin: 0px;
        font-weight: 600;
        font-size: 15px;
      }

      .transitionItemAccount {
        transition: width ease-in-out 0.2s;
      }

      .dateCompte {
        margin: 0px;
        font-weight: 600;
        font-size: 12px;
      }

      .statutContainer {
        display: flex;
        font-size: 12px;
        color: #4B5FB0;
        background-color: #F4F6FA;
        border-radius: 20px;
        border: solid #D2E7F8 1px;
        padding: 5px 10px;
        font-weight: 600;
      }

      .connexionContainer {
        display: flex;
        font-size: 12px;
        color: #68A668;
        background-color: #F6FEF8;
        border-radius: 20px;
        border: solid #DDEEE1 1px;
        padding: 5px 10px;
        font-weight: 600;
      }

      .editAccountBleu {
        width: 40px;
        object-fit: contain;
      }

      .deuxPointsGris {
        width: 40px;
        object-fit: contain;
        margin-left: 5px;
      }
    }

    .contacts {
      transition: width ease-in-out 0.2s;
    }

    .infosContact {
      margin-top: 55px;
      width: 470px;
      height: 595px;
      overflow: scroll;
      border-left: solid 1px #eeeeee;
      display: flex;
      align-items: center;
      flex-direction: column;
      transition: opacity ease-in-out 0.2s;

      .photoProfilInfoContact {
        margin-top: 40px;
        width: 100px;
        height: 100px;
        border-radius: 100px;
      }

      .nomContact {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 600;
      }
      .statutContainer {
        display: flex;
        font-size: 12px;
        color: #4B5FB0;
        background-color: #F4F6FA;
        border-radius: 20px;
        border: solid #D2E7F8 1px;
        padding: 5px 10px;
        font-weight: 600;
        margin-bottom: 30px;
      }
    }

    .modifContact {
      margin-top: 55px;
      width: 470px;
      height: 595px;
      overflow: scroll;
      border-left: solid 1px #eeeeee;
      display: flex;
      align-items: center;
      flex-direction: column;
      transition: opacity ease-in-out 0.2s;

      .photoProfilInfoContact {
        margin-top: 40px;
        width: 100px;
        height: 100px;
        border-radius: 100px;
      }

      .nomContact {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 600;
      }

      .titleModal {
        font-size: 18px;
        font-weight: 600;
        margin-left: 20px;
        margin-bottom: 7px;
      }

      .buttonSend:hover {
        opacity: 0.8;
      }

      .buttonSend {
        margin-top: 10px;
        height: 30px;
        margin-left: -35px;
        width: 30px;
        border-radius: 10px;
        background-color: $color-1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        border: solid 0px;

        .imgButtonSend {
          width: 18px;
          object-fit: contain;
        }
      }

      .cardAutor {
        margin-top: 25px;
        width: 175px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
        display: flex;
        align-items: center;
        cursor: pointer;

        .noChoiceAutor {
          margin-left: 15px;
          height: 14px;
          width: 14px;
          border-radius: 20px;
          border: solid 0.3px #d0d0d0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .choiceAutor {
          width: 16px;
          object-fit: contain;
        }

        .titleAutor {
          margin-left: 10px;
          font-size: 14px;
          font-weight: 500;
        }

        .textAutor {
          width: 320px;
          font-size: 13px;
          font-weight: 400;
        }

        .choixAutorPersonalise {
          height: 150px;
          display: flex;
          flex-wrap: wrap;

          .autor:hover {
            opacity: 0.8;
          }

          .autor {
            border: solid 1px #F8F8F8;
            margin-left: 9px;
            height: 20px;
            padding: 4px 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            justify-content: center;
            cursor: pointer;

            .typeAutor {
              margin-top: 0px;
              margin-bottom: 0px;
              font-size: 13px;
            }
          }

          .autorActive {
            border: solid 1px $color-1;
            background-color: $color-1;
            margin-left: 9px;
            height: 20px;
            padding: 4px 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            justify-content: center;
            cursor: pointer;

            .typeAutor {
              margin-top: 0px;
              margin-bottom: 0px;
              font-size: 13px;
              color: #FFFFFF;
            }
          }

        }
      }

      .choice {
        margin-left: -30px;
        width: 25px;
        object-fit: contain;
        transition: all ease-in-out 250ms;
      }
    }
  }

  .modalCreationCompte {
    background-color: #FFFFFF;
    height: 600px;
    margin-left: 30px;
    border-radius: 10px;
    transition: all ease-in-out 0.2s;
    display: flex;
    flex-direction: column;
    overflow: scroll;


    .crossIcon:hover {
      opacity: 0.8;
    }

    .crossIcon {
      width: 23px;
      object-fit: contain;
      right: 0;
      margin-top: -52px;
      margin-right: 10px;
      margin-left:  0px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }
  }

  .contentCreationPartnerAgency {
    transition: opacity ease-in-out 0.2s;

    .titleModal {
      font-size: 18px;
      font-weight: 600;
      margin-left: 20px;
      margin-bottom: 7px;
    }

    .subtitleModal {
      margin-top: 0px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      margin-right: 10px;
    }

    .input {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 12px;
      width: 360px;
      height: 35px;
      border-radius: 10px;
      border: solid 1px #eaeaea;
    }

    .searchButton:hover {
      opacity: 0.8;
    }

    .searchButton {
      height: 33px;
      width: 33px;
      margin-top: 9px;
      margin-left: -36px;
      background-color: $color-1;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      .whiteSearchIcon {
        width: 15px;
        object-fit: contain;
      }
    }

    .profilePicture {
      margin-left: 40px;
      width: 60px;
      height: 60px;
      border-radius: 200px;
      background-color: #F8F8F8;
    }

    .blankName {
      width: 120px;
      background-color: #F8F8F8;
      border-radius: 20px;
      height: 10px;
      margin-left: 20px;
    }

    .blankMailAdress {
      margin-top: 10px;
      width: 100px;
      background-color: #F8F8F8;
      border-radius: 20px;
      height: 8px;
      margin-left: 20px;
    }

    .blankButtonAdd {
      width: 100px;
      margin-left: 10px;
      height: 25px;
      border-radius: 8px;
      background-color: #F8F8F8;
    }
  }

    .contentCreationClient {
    transition: opacity ease-in-out 0.2s;

    .titleModal {
      font-size: 18px;
      font-weight: 600;
      margin-left: 20px;
      margin-bottom: 7px;
    }

    .subtitleModal {
      margin-top: 0px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      margin-right: 10px;
    }

    .inputNom {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 13px;
      font-weight: 500;
      width: 159px;
      height: 35px;
      border-radius: 10px;
      border: solid 1.5px #eaeaea;
    }

    .inputEmail {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 500;
      outline: none;
      font-size: 13px;
      width: 363px;
      height: 35px;
      border-radius: 10px;
      border: solid 1.5px #eaeaea;
    }

    .input {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 13px;
      width: 360px;
      height: 35px;
      font-weight: 500;
      border-radius: 10px;
      border: solid 1px #eaeaea;
    }

    .buttonSuivant:hover {
      opacity: 0.8;
    }

    .buttonSuivant {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 28px;
      border-radius: 7px;
      background-color: #D2E7F8;
      margin-top: 70px;
      margin-bottom: 0px;
      margin-left: -90px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      .textSuivant {
        color: $color-1;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .searchButton:disabled {
      opacity: 0.3;
    }

    .searchButton:disabled:hover {
      opacity: 0.3;
    }

    .searchButton {
      height: 33px;
      width: 33px;
      margin-top: 9px;
      margin-left: -36px;
      background-color: #D2E7F8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      .whiteSearchIcon {
        width: 22px;
        object-fit: contain;
      }
    }

    .profilePicture {
      margin-left: 40px;
      width: 60px;
      height: 60px;
      border-radius: 200px;
      background-color: #F8F8F8;
    }

    .blankName {
      width: 120px;
      background-color: #F8F8F8;
      border-radius: 20px;
      height: 10px;
      margin-left: 20px;
    }

    .blankMailAdress {
      margin-top: 10px;
      width: 100px;
      background-color: #F8F8F8;
      border-radius: 20px;
      height: 8px;
      margin-left: 20px;
    }

    .blankButtonAdd {
      width: 100px;
      margin-left: 10px;
      height: 25px;
      border-radius: 8px;
      background-color: #F8F8F8;
    }
  }

  .contentCreationCollaborateur {
    transition: opacity ease-in-out 0.2s;

    .titleModal {
      font-size: 18px;
      font-weight: 600;
      margin-left: 20px;
      margin-bottom: 7px;
    }

    .subtitleModal {
      margin-top: 0px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 20px;
      margin-right: 10px;
    }

    .inputNom {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 13px;
      font-weight: 500;
      width: 159px;
      height: 35px;
      border-radius: 10px;
      border: solid 1.5px #eaeaea;
    }

    .inputEmail {
      margin-top: 6px;
      margin-left: 23px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 500;
      outline: none;
      font-size: 13px;
      width: 363px;
      height: 35px;
      border-radius: 10px;
      border: solid 1.5px #eaeaea;
    }

    .buttonSend:hover {
      opacity: 0.8;
    }

    .buttonSend {
      margin-top: 10px;
      height: 30px;
      margin-left: -35px;
      width: 30px;
      border-radius: 10px;
      background-color: $color-1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      border: solid 0px;

      .imgButtonSend {
        width: 18px;
        object-fit: contain;
      }
    }

    .cardAutor {
      margin-top: 25px;
      width: 530px;
      margin-left: 30px;
      border-radius: 10px;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      display: flex;
      align-items: center;
      cursor: pointer;

      .noChoiceAutor {
        margin-left: 15px;
        height: 14px;
        width: 14px;
        border-radius: 20px;
        border: solid 0.3px #d0d0d0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .choiceAutor {
        width: 16px;
        object-fit: contain;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }

      .titleAutor {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
      }

      .textAutor {
        width: 320px;
        font-size: 13px;
        font-weight: 400;
      }

      .choixAutorPersonalise {
        height: 150px;
        display: flex;
        flex-wrap: wrap;

        .autor:hover {
          opacity: 0.8;
        }

        .autor {
          border: solid 1px #F8F8F8;
          margin-left: 9px;
          height: 20px;
          padding: 4px 10px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          justify-content: center;
          cursor: pointer;

          .typeAutor {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 13px;
          }
        }

        .autorActive {
          border: solid 1px $color-1;
          background-color: $color-1;
          margin-left: 9px;
          height: 20px;
          padding: 4px 10px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          justify-content: center;
          cursor: pointer;

          .typeAutor {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 13px;
            color: #FFFFFF;
          }
        }

      }
    }

    .buttonGroupModifCollaborateur:hover {
      cursor: pointer;
      .jeConfirmeButtonModifCollaborateur {
        opacity: 0.7;
      }
      .choice {
        transform: translateX(-250px);
      }
    }

    .jeConfirmeButtonModifCollaborateur {
      width: 400px;
      height: 33px;
      border-radius: 7px;
      background-color: #D2E7F8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 90px;
      transition: all ease-in-out 250ms;

      .textJeConfirme {
        font-size: 14px;
        font-weight: 600;
        color: $color-1;
      }
    }

    .choice {
      margin-left: -30px;
      width: 25px;
      object-fit: contain;
      transition: all ease-in-out 250ms;
    }
  }

}

.petitModalTemplatePortfolio {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 650px;
  background-color: #FFFFFF;
  border-radius: 10px;
  outline: none;
  display: flex;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

}

.modalTemplatePortfolio {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  background-color: #FFFFFF;
  border-radius: 10px;
  outline: none;
  display: flex;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

}

.modalPortfolio {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  background-color: #FFFFFF;
  border-radius: 10px;
  outline: none;
  display: flex;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
}

.OverlayModalComptesLies {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

.modalFacturationChoice {
  position: absolute;
  top: 0;
  margin-top: 50px;
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: calc(100vh - 100px);
  background-color: #FFFFFF;
  border-radius: 10px;
  outline: none;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
}

.modalFacturation {
  position: absolute;
  top: 0;
  margin-left: calc((100vw - 1300px)/2);
  margin-top: calc((100vh - 800px)/2);
  width: 1300px;
  height: 800px;
  background-color: #FFFFFF;
  border-radius: 10px;
  outline: none;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;


  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
}
