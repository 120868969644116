.menuNavigation {
  width: calc(100vw - 400px);
  min-width: 300px;
  display: flex;
  justify-content: center;

  .inner {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    font-size: 16px;
    color: #676767;
    margin-top: 15px;
    height: 40px;

    &::-webkit-scrollbar {
      display: none;
    }

    .backgroundMenuItem,
    .backgroundMenuItemBleu,
    .backgroundMenuItemBleuClair {
      height: 26px;
      align-items: center;
      display: flex;
      padding-right: 10px;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 30px;
      margin-bottom: 15px;

      p {
        margin-left: 10px;
        font-size: 13px;
        margin-top: -1px;
        margin-bottom: 0px;
      }

      .contNotifsProjets {
        background-color: #F84A3F;
        border-radius: 10px;
        font-size: 11px;
        font-weight: 600;
        color: white;
        padding: 2px 5px;
        margin-left: 4px;
        margin-right: -5px;
      }
    }

    @keyframes blinkOpacity {
      0%, 100% { opacity: 1; }
      50% { opacity: 0.7; }
    }

    .daysChoosePlan {
      background-color: #3CA504;
      border-radius: 10px;
      font-size: 11px;
      font-weight: 700;
      color: white;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 7px;
      margin-left: 5px;
      margin-right: -6px;
      animation: blinkOpacity 2s infinite;

    }

    .backgroundMenuItem {
      &:hover {
        opacity: 0.5;
      }
    }

    .backgroundMenuItemBleu {
      background-color: #d8e0fa;
      p {
        color: #4F66E4;
        font-weight: 700;
      }
    }

    .backgroundMenuItemBleuClair {
      background-color: #D2E7F8;
      p {
        color: #3E51A9;
        font-weight: 600;
      }
    }
  }

  .icons {
    margin-top: 3.8px;
  }

  .dashboardIcon,
  .MessageIcon,
  .serviceIcon,
  .CalendrierIcon,
  .collaborateursIcon,
  .settingsIcon {
    object-fit: contain;
    opacity: 0.4;
    width: 18px;
    margin-top: 0px;
  }

  .serviceIcon,
  .CalendrierIcon,
  .collaborateursIcon,
  .settingsIcon {
    width: 17px;
    margin-top: -2px;
  }

  .MessageIcon {
    margin-top: -5px;
  }

  .navigation {
    display: flex;
    a {
      margin-left: 21px;
      font-size: 13px;
      font-weight: 600;
      color: #000000;
      transition: color 0.3s;

      &:hover,
      &.nav-active {
        font-weight: 600;
        color: #cbcbcb;
      }

      &.nav-active {
        color: #4B5FB0;
      }
    }
  }

  .icon {
    position: absolute;
    margin-left: -45.8px;
    width: 23px;
    height: 19px;
  }

  .pastilleOpus {
    position: absolute;
    margin-top: 3.5px;
    margin-right: 20px;
    margin-left: -28px;
    width: 8px;
    height: 8px;
    background-color: $color-1;
    border-radius: 20px;
  }
}
