.locationsList {
	display: flex;
	position: sticky;
	top: 0;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	background-color: #fff;
	width: calc(100% - 20px);
	padding: 10px;
	border-bottom: solid 1px #eeeeee;
	z-index: 20;

	.itemLocation,
	.itemLocationVise {
		border: solid 1px #eeeeee;
		padding: 3px 8px;
		border-radius: 99px;
		font-size: 14px;
		font-weight: 600;

		.mappin {
			width: 10px;
			opacity: 0.7;
			margin-right: 5px;
		}

		&:hover {
			cursor: pointer;
			background-color: rgba(28, 87, 221, 0.1);
			color: #1c57dd;
		}
	}

	.itemLocationVise {
		background-color: rgba(28, 87, 221, 0.1);
		color: #1c57dd;
	}
}
