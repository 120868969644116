.onBoardingCollaborateur {
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.entrerAdresseMailCollaborateur {
  width: 100vw;
  height: 100vh;
  display: flex;

  .leftPart {
    height: 100vh;
    width: 50vw;

    .firstPart {
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        margin-right: 10px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 10px;
        font-size: 30px;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(108, 108, 108, 0.66);
      }

      .inputTitle {
        font-size: 14px;
        font-weight: 500;
        margin-top: 35px;
      }

      .input {
        margin-top: 0px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        width: 400px;
        height: 35px;
        border-radius: 10px;
        border: solid 1px #eaeaea;
      }

      .buttonGroup {
        border: solid 0px;
        background-color: #FFFFFF;
      }

      .buttonGroup:hover {
        cursor: pointer;
        .jeConfirmeButton {
          .textJeConfirme {
            transform: scale(0);
          }
        }
        .choice {
          transform: translateX(-188px);
        }
      }

      .jeConfirmeButton {
        width: 420px;
        height: 33px;
        border-radius: 10px;
        background-color: #D2E7F8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: -8px;

        .textJeConfirme {
          font-size: 14px;
          font-weight: 500;
          color: #4B5FB0;
          transition: all ease-in-out 250ms;
        }
      }

      .choice {
        margin-top: 4px;
        margin-left: -30px;
        width: 25px;
        object-fit: contain;
        transition: all ease-in-out 250ms;

      }

      .client {
        font-size: 12px;
      }

      .connexion:hover {
        opacity: 0.7;
      }

      .connexion {
        margin-top: 11px;
        margin-left: 6px;
        font-size: 12px;
        color: #3E51A9;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

    .thirdPart {
      padding-left: 10px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

  }

  .rightPart {
    height: 100vh;
    width: 50vw;
  }
}

.definirMotDePasse {
  width: 100vw;
  height: 100vh;
  display: flex;

  .leftPart {
    height: 100vh;
    width: 50vw;

    .firstPart {
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .deconnexion {
        border: solid 0px;
        background-color: #D2E7F8;
        border-radius: 8px;
        height: 30px;
        display: flex;
        position: relative;
        .textButtonDeconnexion {
          margin: 0px;
          font-weight: 500;
          color: #4B5FB0;
        }
      }

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        margin-right: 10px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;

      .pathEtapes {

        .pathPasse:hover {
          opacity: 0.7;
        }

        .pathPasse {
          font-size: 13px;
          font-weight: 600;
          color: #4fa139;
          cursor: pointer;
        }

        .pathEnCours {
          font-size: 13px;
          font-weight: 600;
        }

        .pathAVenir {
          font-size: 13px;
          font-weight: 600;
          color: #9b9b9b;
        }

        .dotted {
          object-fit: cover;
          height: 16px;
          opacity: 1;
          transition: width 0.5s;
          -webkit-transition: width 0.5s;
        }

        .beigeBar {
          margin-top: 6px;
          margin-right: 1px;
          height: 4px;
          background-color: #4fa139;
          border-radius: 200px;
          transition: width 0.5s;
          -webkit-transition: width 0.5s;
        }

        .choicePath {
          margin-right: 190px;
          width: 18px;
          object-fit: cover;
        }

        .noChoice {
          margin-right: 180px;
          width: 16px;
          height: 16px;
          border-radius: 30px;
          border: 1px solid #bdbdbd;
          background-color: #FFFFFF;
        }
      }

      .title {
        margin-top: 10px;
        font-size: 30px;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(108, 108, 108, 0.66);
      }

      .inputTitle {
        margin-top: 25px;
        font-size: 14px;
        font-weight: 500;
      }

      .input {
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        width: 400px;
        height: 35px;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 500;
        border: solid 1px #eaeaea;
      }

      .requires {
        display: flex;
        width: 400px;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: 10px;

        .require {
          padding: 7px 10px;
          background-color: #F8F8F8;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 500;
          }
        }

        .requireValid {
          padding: 7px 10px;
          background-color: #D2E7F8;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 600;
            color: #4B5FB0;
          }
        }
      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 40px;
        width: 430px;
        height: 38px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;
        border: solid 0px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .buttonSuivant:disabled {
        background-color: #eeeeee;

        .titleButtonSuivant {
          color: #808080;

        }
      }


      .mdpIdentiques {
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .connexion:hover {
        opacity: 0.7;
      }

      .connexion {
        margin-top: 11px;
        margin-left: 6px;
        font-size: 12px;
        color: #3E51A9;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

    .thirdPart {
      padding-left: 10px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .rightPart {
    height: 100vh;
    width: 50vw;
  }
}

.informationsPersonnelles {
  width: 100vw;
  height: 100vh;
  display: flex;

  .leftPart {
    height: 100vh;
    width: 50vw;

    .firstPart {
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        margin-right: 10px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;

      .pathEtapes {

        .pathPasse:hover {
          opacity: 0.7;
        }

        .pathPasse {
          font-size: 13px;
          font-weight: 600;
          color: #4fa139;
          cursor: pointer;
          transition: color 0.5s;
          -webkit-transition: color 0.5s;
        }

        .pathEnCours {
          font-size: 13px;
          font-weight: 500;
        }

        .pathAVenir {
          font-size: 13px;
          font-weight: 500;
          color: #9b9b9b;
          transition: color 0.5s;
          -webkit-transition: color 0.5s;
        }

        .dotted {
          object-fit: cover;
          height: 16px;
          opacity: 1;
          transition: width 0.5s;
          -webkit-transition: width 0.5s;
        }

        .beigeBar {
          margin-top: 6px;
          margin-right: 1px;
          height: 4px;
          background-color: #4fa139;
          border-radius: 200px;
          transition: width 0.5s;
          -webkit-transition: width 0.5s;
        }

        .choicePath {
          margin-right: 190px;
          width: 18px;
          object-fit: cover;
          transition: opacity 0.5s;
          -webkit-transition: opacity 0.5s;
        }

        .noChoice {
          margin-right: 180px;
          width: 16px;
          height: 16px;
          border-radius: 30px;
          border: 1px solid #bdbdbd;
          background-color: #FFFFFF;
        }
      }

      .title {
        margin-top: 10px;
        font-size: 30px;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 14px;
        font-weight: 300;
        color: rgba(108, 108, 108, 0.66);
        width: 500px;
        line-height: 24px;
      }

      .inputTitle {
        margin-top: 25px;
        font-size: 14px;
        font-weight: 500;
      }

      .input {
        margin-top: 5px;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        width: 400px;
        height: 35px;
        font-size: 13px;
        border-radius: 10px;
        border: solid 1px #eaeaea;
      }

      input {
        padding-left: 10px;
        outline: none;
        width: 400px;
        height: 33px;
        font-size: 13px;
        border-radius: 10px;
        border: none;
      }

      .requires {
        display: flex;
        width: 400px;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: 10px;

        .require {
          padding: 7px 10px;
          background-color: #F8F8F8;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 400;
          }
        }

        .requireValid {
          padding: 7px 10px;
          background-color: $color-1;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 30px;
        width: 430px;
        height: 38px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;
        border: solid 0px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 500;
          font-size: 14px;
        }

        .client {
          font-size: 12px;
        }
      }

      .buttonSuivant:disabled {
        background-color: #eeeeee;

        .titleButtonSuivant {
          color: #808080;

        }
      }

      .connexion:hover {
        opacity: 0.7;
      }

      .connexion {
        margin-top: 11px;
        margin-left: 6px;
        font-size: 12px;
        color: #3E51A9;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .boutonEntrerOPUS {

        width: 500px;
        height: 38px;
        border: solid 0px;
        background-color: #4867EC;
        border-radius: 7px;

        .textBoutonEntrerOPUS {
          margin: 0px;
          font-size: 14px;
          font-weight: 600;
          color: #FFFFFF;

        }
      }
    }

    .thirdPart {
      padding-left: 10px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .rightPartInfos {
    width: 50vw;
    height: 100vh;
    background-color: #F4F9FF;
    overflow: hidden;

    .loginBlue {
      height: 100vh;
      object-fit: contain;
    }
  }
}
