.subStep {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  align-items: center;
  transition: opacity 0.3s ease-in-out;

  .subStepCircleNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 6px;

    .subStepCircle {
      width: 15px;
      height: 15px;
      border-radius: 100px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-top: 1px;
        width: 8px;
        height: 8px;
      }
    }

    .subStepName {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      line-height: 14px;
    }
  }

  .subStepReorganizeArrow {
    width: 11px;
    height: 11px;
    opacity: 0;
    margin-top: 1px;
    margin-left: 4px;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }

  .subStepDeleteIcon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-left: 15px;
  }
}

.subStep:hover {
  .subStepReorganizeArrow {
      opacity: 0.4;
  }
  .subStepReorganizeArrow:hover {
    opacity: 0.2;
  }
}
