.documentsPage {

  #inputFolder[type="file"] {
    display: none;
  }

  .containerContentDocuments {
    display: flex;
    flex-direction: row;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;

  }

}

