.listeTemplates {
  margin-bottom: 80px;
  display: flex;

  .bar {
    height: 639px;
    width: 1px;
    border-radius: 20px;
    background-color: #eeeeee;
    margin-top: 0px;
  }

  .containerTemplateType {
    width: 432px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .listTemplates {
      margin-top: 1px;

      overflow: scroll;
      padding-top: 30px;
    }
  }

  .textPartTemplate {
    display: flex;
    flex-direction: column;
    width: 432px;

    .titleTypeTemplate {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 0px;
      margin-left: 20px;
      margin-top: 10px;
    }

    .littleBar {
      width: 432px;
      height: 1px;
      border-radius: 20px;
      background-color: #eeeeee;
      margin-top: 15px;
    }
  }

  .containerIconPlus:hover {
    opacity: 0.7;
  }

  .containerIconPlus {
    margin-top: 105px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    .IconPlus {
      width: 17px;
      object-fit: contain;
    }
  }

  .typeListTemplate {
    display: flex;

    .newTemplate:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .newTemplate {
      height: 190px;
      width: 230px;
      margin-top: 35px;
      border-radius: 10px;
      background-color: #F8F8F8;
      margin-right: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      transition: opacity 0.2s ease-in-out;
    }

    .template:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .template {
      height: 190px;
      width: 230px;
      margin-top: 35px;
      border-radius: 10px;
      background-color: #FFFFFF;
      margin-right: 30px;
      margin-left: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      transition: opacity 0.2s ease-in-out;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    }

    .templateDemo {
      height: 190px;
      width: 230px;
      margin-top: 35px;
      border-radius: 10px;
      background-color: #FFFFFF;
      margin-right: 30px;
      margin-left: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      transition: opacity 0.2s ease-in-out;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    }
  }
}

.modalCreationTemplate {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 575px) / 2);
  margin-left: calc((100vw - 800px) / 2);
  width: 800px;
  height: 575px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .placeholderTemplateFichiers {
    display: flex;
    align-items: center;
    flex-direction: column;

    .titlePlaceholderTemplateFichiers {
      font-weight: 600;
      font-size: 25px;
      margin-top: 80px;
      margin-bottom: 0px;
    }

    .subtitlePlaceholderTemplateFichiers {
      font-size: 16px;
    }

    .placeholderPhotoFichiers {
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      width: 700px;
      margin-left: 15px;
    }

    .designantInputPlaceholderTemplateFichiers {
      font-size: 16px;
      font-weight: 600;
    }

    .inputPlaceholderTemplateFichiers {
      width: 250px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }

    .buttonCreerTemplate:hover {
      opacity: 0.7;
    }

    .buttonCreerTemplate {
      width: 300px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #D2E7F8;
      color: #2E507E;
      font-size: 15px;
      font-weight: 600;
      border-radius: 7px;
      margin-top: 7px;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
      border: 0px;
    }
  }

}

.petitModalTemplateFichiers {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px) / 2);
  margin-left: calc((100vw - 1300px) / 2);
  width: 1300px;
  height: 650px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .editIcon {
    opacity: 0.6;
    height: 17px;
    object-fit: contain;
    margin-top: 35px;
    margin-left: 10px;
  }


}

.overlayModalTemplateFichiers {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(199, 151, 151, 0);
}

.overlayModalCreateTemplate {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(208, 207, 207, 0.46);
}

.modalTemplateFichiers{
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .editIcon {
    opacity: 0.6;
    height: 17px;
    object-fit: contain;
    margin-top: 35px;
    margin-left: 10px;
  }
}

.petitModalTemplatePhotos {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px) / 2);
  margin-left: calc((100vw - 1300px) / 2);
  width: 1300px;
  height: 650px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .editIcon {
    opacity: 0.6;
    height: 17px;
    object-fit: contain;
    margin-top: 35px;
    margin-left: 10px;
  }

  .templateAlbumList {
    margin-left: 8.5px;
    margin-top: 1px;
    width: 1300px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    height: 546px;
    overflow: scroll;

    .littleInput {
      width: 160px;
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }

    .albumPhoto {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      margin-top: 55px;
      margin-right: 13px;
      .photoAlbum {
        width: 170px;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
        background-color: #F8F8F8;
      }
    }

    .containerIconPlus:hover {
      opacity: 0.7;
    }

    .containerIconPlus {
      margin-left: 40px;
      margin-top: 145px;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: #F8F8F8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
      .IconPlus {
        width: 17px;
        object-fit: contain;
      }
    }
  }


  .buttonCreerTemplate:hover {
    opacity: 0.7;
  }

  .buttonCreerTemplate {
    width: 300px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #2E507E;
    font-size: 15px;
    font-weight: 600;
    border-radius: 7px;
    margin-top: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border: 0px;
  }

}

.modalTemplatePhotos {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .editIcon {
    opacity: 0.6;
    height: 17px;
    object-fit: contain;
    margin-top: 35px;
    margin-left: 10px;
  }

  .templateAlbumList {
    margin-left: 8.5px;
    margin-top: 1px;
    width: 1300px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    height: 546px;
    overflow: scroll;

    .littleInput {
      width: 160px;
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }

    .albumPhoto {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      margin-top: 55px;
      margin-right: 13px;
      .photoAlbum {
        width: 170px;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
        background-color: #F8F8F8;
      }
    }

    .containerIconPlus:hover {
      opacity: 0.7;
    }

    .containerIconPlus {
      margin-left: 40px;
      margin-top: 145px;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: #F8F8F8;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;
      .IconPlus {
        width: 17px;
        object-fit: contain;
      }
    }
  }


  .buttonCreerTemplate:hover {
    opacity: 0.7;
  }

  .buttonCreerTemplate {
    width: 300px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #2E507E;
    font-size: 15px;
    font-weight: 600;
    border-radius: 7px;
    margin-top: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border: 0px;
  }
}

.littleModalTemplateProgression {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px) / 2);
  margin-left: calc((100vw - 1300px) / 2);
  width: 1300px;
  height: 650px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .placeholderTemplateProgression {
    width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .littleChoixPhase {
      width: 600px;
      object-fit: contain;
    }

    .titlePlaceholderTemplateProgression {
      font-weight: 600;
      font-size: 25px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .subtitlePlaceholderTemplateProgression {
      font-size: 16px;
    }

    .designantInputPlaceholderTemplateProgression {
      font-size: 16px;
      font-weight: 600;
    }

    .inputPlaceholderTemplateProgression {
      width: 250px;
      font-size: 13px;
      font-weight: 500;
      height: 25px;
      padding-left: 7px;
      outline: none;
      border: solid 1.5px #eeeeee;
      border-radius: 8px;
    }
  }

  .buttonCreerTemplate:hover {
    opacity: 0.7;
  }

  .buttonCreerTemplate {
    width: 300px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #2E507E;
    font-size: 15px;
    font-weight: 600;
    border-radius: 7px;
    margin-top: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border: 0px;
  }
}

.modalTemplateProgression {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1300px;
  height: 750px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModalTemplate {
    margin-left: 25px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    font-size: 33px;
  }

  .editIcon {
    opacity: 0.6;
    height: 17px;
    object-fit: contain;
    margin-top: 35px;
    margin-left: 10px;
  }
}

.modalTemplateFinances {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 700px)/2);
  margin-left: calc((100vw - 1300px)/2);
  width: 1285px;
  height: 687px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

}
