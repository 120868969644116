.modalFinEssaiGratuit {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 550px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    font-size: 27px;
    margin-left: 70px;
    margin-right: 70px;
    font-weight: 600;
    color: #4B5FB0;
    text-align: center;
    margin-bottom: 0px;
  }

  .subtitle {
    font-size: 16px;
    margin-left: 70px;
    margin-right: 70px;
    font-weight: 600;
    text-align: center;
  }

  .imageFonct {
    margin-top: 20px;
    width: 550px;
    border-radius: 15px;
    height: 300px;
    object-fit: cover;
  }

  .button:hover {
    opacity: 0.7;
  }

  .button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 520px;
    height: 45px;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #3CA504;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.2s;
    margin-bottom: 20px;
  }
}

.modalNoCredit {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 560px) / 2);
  margin-left: calc((100vw - 590px) / 2);
  width: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .nomAgence {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 10px;
  }

  .adresseAgence {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
  }
}

.modalNeedToPay {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 530px) / 2);
  margin-left: calc((100vw - 590px) / 2);
  width: 590px;
  height: 530px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .redCross {
    margin-top: 45px;
    margin-bottom: 20px;
    width: 65px;
    object-fit: contain;
  }

  .titleModal {
    margin-top: 20px;
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 10px;
    width: 350px;
    text-align: center;
  }

  .subtitleModal {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: 500;
    opacity: 0.7;
    text-align: center;
    width: 500px;
    line-height: 22px;
  }

  .redWidget {
    height: 55px;
    width: 450px;
    background-color: rgba(234, 51, 35, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ea3323;
    font-weight: 600;
    padding-right: 20px;
    .redPeople {
      margin-left: 20px;
      width: 40px;
      object-fit: contain;
      margin-right: 20px;

    }
  }

  .buttonModal {
    max-width: 90vw;
    margin-top: 30px;
    background-color: #4E66E4;
    height: 45px;
    width: 470px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
  }
}


.modalFelicitationCredit {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 365px) / 2);
  margin-left: calc((100vw - 400px) / 2);
  width: 400px;
  height: 365px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }
}

.modalChooseOpusPlus {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 500px) / 2);
  margin-left: calc((100vw - 800px) / 2);
  width: 800px;
  height: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .title {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 10px;
  }

}

.modalAccessDenied {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 420px) / 2);
  margin-left: calc((100vw - 580px) / 2);
  width: 580px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }


  .titleModal {
    font-weight: 700;
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .subtitleModal {
    font-size: 15.1px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .buttonModal, .buttonModalBlue {
    padding: 10px 20px;
    background-color: #1C57DD;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .buttonModalBlue {
    background-color: #3CA504;
  }

  .contact {
    font-weight: 700;
    font-size: 14px;
    color: #1C57DD;
    margin-bottom: 40px;
  }

  .whichRole {
    width: 420px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  select {
    width: 435px;
    max-width: calc(100vw - 45px);

    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none; /* Supprime le style par défaut sous Chrome, Safari */
    -moz-appearance: none; /* Supprime le style par défaut sous Firefox */
    appearance: none; /* Supprime le style par défaut pour les autres navigateurs */
    background-color: white;
    cursor: pointer; /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
  }

  .leftArrow {
    margin-left: -25px;
    margin-right: 17px;
    margin-top: 3px;
    width: 7px;
    transform: rotate(90deg);
  }

}

.modalJoinProject {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px) / 2);
  margin-left: calc((100vw - 580px) / 2);
  width: 580px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .clockLock {
    width: 100px;
    margin-top: 30px;
  }

  .titleModal {
    font-weight: 700;
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .subtitleModal {
    font-size: 15.1px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  .whichRole {
    width: 420px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  select {
    width: 435px;
    max-width: calc(100vw - 45px);

    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none; /* Supprime le style par défaut sous Chrome, Safari */
    -moz-appearance: none; /* Supprime le style par défaut sous Firefox */
    appearance: none; /* Supprime le style par défaut pour les autres navigateurs */
    background-color: white;
    cursor: pointer; /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
  }

  .leftArrow {
    margin-left: -25px;
    margin-right: 17px;
    margin-top: 3px;
    width: 7px;
    transform: rotate(90deg);
  }

  .containerButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid 0px;
    background-color: #FFFFFF;

    .blueButton:hover {
      opacity: 0.7;
    }

    .blueButton {
      max-width: 90vw;
      margin-top: 30px;
      background-color: #4E66E4;
      height: 45px;
      width: 440px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: opacity ease-in-out 0.2s;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 45px;
    }

    .subtitleButton {
      color: #2B4AD1;
      font-size: 13px;
      font-weight: 600;
    }
  }
}
