.subMenuPage,
.largeSubMenuPage {
	display: flex;
	flex-direction: column;
}

.containerItemsSubMenu {
	display: flex;
	align-items: center;
	gap: 15px;
}

/* Different margin for each variant */
.subMenuPage .containerItemsSubMenu {
	margin-left: -6px;
}

.largeSubMenuPage .containerItemsSubMenu {
	margin-left: 25px;
}

.itemSubMenuName,
.itemSubMenuNameActive {
	font-size: 14px;
	color: #596072;
	font-weight: 600;
	padding: 4px 6px;
	border-radius: 8px;
}

.itemSubMenuName:hover,
.itemSubMenuNameActive:hover {
	cursor: pointer;
}

.itemSubMenuName:hover {
	background-color: #f5f5f5;
}

.itemSubMenuNameActive:hover {
	background-color: #f4f5fd;
}

/* Only in large variant */
.iconItemSubMenu {
	width: 16px;
	margin-right: 6px;
	margin-bottom: -1px;
}

.barSubMenuPage {
	display: flex;
	height: 1px;
	background-color: #eeeeee;
	margin-top: 5px;
}

/* Different margin for regular variant */
.subMenuPage .barSubMenuPage {
	margin-right: 40px;
}

.blueBarSubMenu {
	background-color: #1c57dd;
	margin-top: -0.5px;
	transition: all 0.3s ease;
	border-radius: 10px;
}

/* Different height for each variant */
.subMenuPage .blueBarSubMenu {
	height: 1.5px;
}

.largeSubMenuPage .blueBarSubMenu {
	height: 2px;
}
