.ticketDescription {
	.titleDescription {
		margin-top: 10px;
		font-size: 22px;
		font-weight: 600;
		margin-left: 25px;
		margin-bottom: 0px;
		border: 0;
		outline: none;
		width: 545px;
	}

	.descriptionTicket {
		margin-left: 25px;
		margin-top: 5px;
		width: 545px;
		outline: none;
		font-size: 15px;
		font-weight: 500;
		border: solid 0px;
		margin-bottom: 10px;
		color: #3c3c3c;
	}
}
