
.headerStep {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  .titleHeaderStep {
    font-weight: 700;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 420px;
    text-align: center;
  }

  .subtitleHeaderStep {
    width: 420px;
    max-width: 85vw;
    text-align: center;
    font-size: 15px;
    color: #696B71;
  }
}

.containerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 0px;
  background-color: #FFFFFF;

  .blueButton:hover {
    opacity: 0.7;
  }

  .blueButton {
    max-width: 90vw;
    margin-top: 30px;
    background-color: #4E66E4;
    height: 45px;
    width: 430px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
  }

  .subtitleButton {
    color: #2B4AD1;
    font-size: 13px;
    font-weight: 600;
    max-width: 430px;
  }
}

.itemChoice:hover, .buttonCreateCompany:hover, .entrepriseItemChoice:hover, .buttonCreateCompanySelected:hover, .entrepriseItemChoiceSelected:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.itemChoice {
  display: flex;
  align-items: center;
  width: 410px;
  padding-left: 20px;
  height: 55px;
  border: solid 2px #EAEAE9;
  border-radius: 10px;
  gap: 20px;
  transition: all ease-in-out 0.2s;

  .choiceIcon {
    width: 16px;
    object-fit: contain;
  }

  .choiceName {
    font-weight: 600;
    margin: 0;
    font-size: 14px;
  }
}

.doubleInput {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -10px;

  .containerLittleInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .titleInput {
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }

    .littleInput {
      width: 195px;
      max-width: calc((100vw - 100px) / 2);
      height: 38px;
      border-radius: 8px;
      padding-left: 10px;
      border: solid 1px #EAEAEA;
      font-size: 14px;
      font-weight: 500;
      outline: none;
    }
  }
}

/* Style global du composant PhoneInput */
.phoneInput {
  margin-bottom: 9px;
  .titleInput {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    margin-bottom: 27px;
  }
}

/* Personnalisation de l'input */
.phoneInput input {
  width: 394px;
  max-width: calc(100vw - 60px);
  height: 38px;
  border-radius: 8px;
  border: solid 1px #EAEAEA;
  background-color: rgba(255, 255, 255, 0);
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding-left: 38px;

}

/* Personnalisation du sélecteur de pays */
.phoneInput .PhoneInputCountrySelect {
  display: none;
  padding: 10px;
  border-right: 1px solid #ccc;
}

/* Style appliqué à l'icône du drapeau dans le sélecteur */
.phoneInput .PhoneInputCountryIcon {
  z-index: 2;
  width: 20px;
  height: 15px;
  margin-left: 10px;
  margin-bottom: -28px;
}

/* Ajustements pour les écrans plus petits */
@media (max-width: 600px) {
  .phoneInput {
    flex-direction: column;
  }
  .phoneInput .PhoneInputCountrySelect {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  .phoneInput input {
    width: 100%;
  }
}

.singleInput {
  margin-bottom: 10px;

  .titleInput {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
  }

  .input {
    width: 420px;
    max-width: calc(100vw - 60px);
    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
  }

  select {
    width: 435px;
    max-width: calc(100vw - 45px);
    height: 38px;
    border-radius: 8px;
    padding-left: 10px;
    border: solid 1px #EAEAEA;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none; /* Supprime le style par défaut sous Chrome, Safari */
    -moz-appearance: none; /* Supprime le style par défaut sous Firefox */
    appearance: none; /* Supprime le style par défaut pour les autres navigateurs */
    background-color: white;
    cursor: pointer; /* Change le curseur en main pour indiquer qu'il s'agit d'un élément cliquable */
  }

  .leftArrow {
    margin-left: -25px;
    margin-right: 17px;
    margin-top: 3px;
    width: 7px;
    transform: rotate(90deg);
  }
}

.buttonCreateCompany, .buttonCreateCompanySelected {
  display: flex;
  align-items: center;
  width: 406px;
  border: solid 2px #EAEAE9;
  border-radius: 8px;
  padding-left: 20px;
  justify-content: space-between;
  height: 80px;
  transition: all ease-in-out 0.2s;
  margin-bottom: 20px;

  .containerCompanyLogo {
    width: 45px;
    height: 45px;
    border: solid 2px #EAEAE9;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .companyLogo {
      width: 18px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    margin-bottom: 6px;
  }

  .subtitle {
    font-size: 14px;
    color: #696B71;
    margin: 0;

  }

  .rightIcon {
    transform: rotate(180deg);
    width: 7px;
    margin-right: 20px;
    opacity: 0.3;
  }
}

.buttonCreateCompanySelected {
  border: solid 2px #1C57DD;
  background-color: rgba(28,87,221,0.05);

  .containerCompanyLogo {
    border: solid 2px #1C57DD;
  }
}

.entrepriseItemChoice, .entrepriseItemChoiceSelected {
  display: flex;
  align-items: center;
  width: 406px;
  border: solid 2px #EAEAE9;
  border-radius: 8px;
  padding-left: 20px;
  justify-content: space-between;
  height: 50px;
  transition: all ease-in-out 0.2s;
  margin-bottom: 20px;

  .containerName {
    font-size: 14px;
    font-weight: 600;

    span {
      color: #4E66E4;
      font-weight: 600;
    }
  }

  .rightIcon {
    transform: rotate(180deg);
    width: 7px;
    margin-right: 20px;
    opacity: 0.3;
  }
}

.entrepriseItemChoiceSelected {
  border: solid 2px #1C57DD;
  background-color: rgba(28,87,221,0.05);
}
