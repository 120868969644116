.header {
	width: calc(100vw - 229.4px);
	.leftPart {
		width: calc(100vw - 350px);
		height: 50px;
	}

	.rightPart {
		h2 {
			color: #000000;
			position: absolute;
			right: 0;
			margin-right: 100px;
			margin-top: -35px;
		}

		.profilePicture {
			right: 0;
			margin-right: 0px;
			margin-top: -37px;
			position: absolute;
			width: 32.3px;
			height: 32.3px;
			object-fit: cover;
			border-radius: 80px;
		}
	}
}

.menuFicheProjet {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	width: 184px;
	border-right: solid 1px #eeeeee;
	justify-content: space-between;
	height: calc(100vh - 55px);

	.menuName {
		margin-top: 30px;
		margin-left: 30px;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 0;
	}

	.retourIcon {
		margin-top: 16px;
		width: 15px;
		height: 11px;
		object-fit: contain;
		opacity: 0.7;
	}

	.containerMenu {
		margin-left: 13px;
		display: flex;
		align-items: center;

		.inner {
			padding-left: 20px;
			margin-top: 18px;
			border-radius: 10px;
			display: flex;
			flex-direction: row;
			overflow-x: scroll;
			overflow-y: hidden;

			.containerPastilles {
				display: flex;
				position: absolute;
				flex-direction: column;
				align-items: center;
				margin-left: -41px;
				gap: 16px;
				margin-top: 4px;
				width: 40px;

				.pastilleWorkspace {
					background-color: #fcf0e8;
					color: #d9620c;
					font-weight: 700;
					font-size: 13px;
					min-width: 10px;
					padding: 0px 5px;
					height: 20px;
					display: flex;
					align-items: center;
					border-radius: 99px;
					justify-content: center;
				}
			}

			.backBarMenu {
				margin-top: 5px;
				border-radius: 20px;
				width: 2.5px;
				background-color: #f8f8f8;

				.frontBarMenu {
					width: 2.5px;
					border-radius: 20px;
					background-color: #4867ec;
				}
			}

			.active:hover {
				opacity: 0.7;
			}

			.active {
				font-feature-settings: 'pnum';
				padding-left: 14px;
				padding-right: 14px;
				margin-right: 10px;
				font-weight: 700;
				margin-bottom: 7.5px;
				font-size: 14px;
				border-radius: 8px;
				color: #4867ec;
				background-color: #ffffff;
				border: 0px;
				height: 28px;
				display: flex;
				align-items: center;
				cursor: pointer;
			}

			.unactive:hover {
				opacity: 0.4;
			}

			.unactive {
				-webkit-font-smoothing: antialiased;
				font-feature-settings: 'pnum';
				padding-left: 14px;
				padding-right: 14px;
				margin-right: 10px;
				font-weight: 400;
				margin-bottom: 7.5px;
				font-size: 14px;
				border-radius: 8px;
				color: #000;
				height: 28px;
				background-color: rgba(248, 248, 248, 0);
				border: 0px;
				display: flex;
				align-items: center;
				cursor: pointer;
			}

			.unactiveDisable {
				-webkit-font-smoothing: antialiased;
				font-feature-settings: 'pnum';
				padding-left: 14px;
				padding-right: 14px;
				margin-right: 10px;
				font-weight: 400;
				margin-bottom: 7.5px;
				font-size: 14px;
				border-radius: 8px;
				color: #000;
				height: 28px;
				background-color: rgba(248, 248, 248, 0);
				border: 0px;
				display: flex;
				align-items: center;
				opacity: 0.4;
				cursor: pointer;
				p {
					letter-spacing: 0;
					margin: 0;
				}
			}
		}

		.inner::-webkit-scrollbar {
			display: none;
		}
	}

	.widgetContributors {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px 10px 0px 10px;
		width: 150px;
		height: 135px;
		border-radius: 10px;

		.containerProfiles {
			display: flex;
			margin-top: 10px;
			margin-bottom: 15px;
			margin-left: 15px;

			.photoProfilBleu {
				margin-left: -15px;
				width: 35px;
				height: 35px;
				border-radius: 25px;
				object-fit: cover;
				background-color: #7fa3fc;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-weight: 600;
				border: solid 2px #ffffff;
			}
		}

		.buttonInvite:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		.buttonInvite {
			background-color: #5fba2f;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 700;
			padding: 7px 0px;
			width: 155px;
			font-size: 12px;
			color: #ffffff;
			transition: opacity ease-in-out 0.2s;
		}

		.buttonSettings:hover {
			opacity: 0.7;
			cursor: pointer;
		}

		.buttonSettings {
			padding: 13px;
			font-size: 13px;
			font-weight: 600;
			display: flex;
			align-items: center;
			gap: 5px;
			margin-bottom: 10px;
			transition: opacity ease-in-out 0.2s;
			.settingsIcon {
				width: 13px;
			}
		}
	}
}

.modalEtapesIntegrationProjet {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 700px) / 2);
	margin-left: calc((100vw - 590px) / 2);
	width: 590px;
	height: 687px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
	display: flex;
	flex-direction: column;
	overflow: scroll;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}

	.title {
		margin-left: 20px;
		font-size: 22px;
		font-weight: 600;
		color: #4b5fb0;
		margin-bottom: 0px;
	}

	.subtitle {
		margin-left: 20px;
		margin-right: 20px;
		margin-top: 10px;
	}

	.todo {
		.containerCheckBleu {
			width: 25px;
			height: 25px;
			border-radius: 30px;
			margin-left: 30px;
			background-color: #eeeeee;
			transition: all 0.2s ease-in-out;

			.checkBleu {
				width: 25px;
				height: 25px;
				object-fit: contain;
				opacity: 0;
			}
		}

		.todoName {
			font-size: 16px;
			margin-top: 3px;
			margin-left: 20px;
			font-weight: 600;
		}

		.barBleu {
			margin-top: 7px;
			width: 6px;
			height: 100px;
			border-radius: 20px;
			margin-right: 10px;
			margin-left: 40px;
		}

		.dureeEtape {
			margin-top: -5px;
			margin-left: 20px;
			font-size: 14px;
			font-weight: 600;
			color: #4b5fb0;
		}

		.descriptionEtape {
			margin-top: -5px;
			margin-left: 20px;
			font-size: 14px;
			font-weight: 600;
			margin-right: 20px;
		}

		.validEtape:hover {
			opacity: 0.7;
		}

		.validEtape {
			margin-left: 20px;
			cursor: pointer;
			padding: 5px 10px;
			border-radius: 99px;
			background-color: #d2e7f8;
			color: #4b5fb0;
			font-weight: 600;
			font-size: 13px;
			transition: opacity 0.2s ease-in-out;
		}
	}
}

.modalDecouverte {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 657px) / 2);
	margin-left: calc((100vw - 1100px) / 2);
	width: 1100px;
	height: 657px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
	display: flex;
	flex-direction: column;
	overflow: scroll;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}

	.title {
		margin-top: 25px;
		margin-left: 35px;
		font-size: 32px;
		margin-bottom: 0px;
		font-weight: 600;
	}

	.subtitle {
		font-size: 17px;
		margin-left: 35px;
	}

	.widgetModalDecouverte {
		width: 502.5px;
		margin-left: 30px;
		height: 223px;
		box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
		border-radius: 10px;
		margin-bottom: 30px;

		.titleWidgetDecouverte {
			font-size: 18px;
			font-weight: 600;
			color: #4b5fb0;
			margin-left: 20px;
			margin-bottom: 0px;
		}

		.subtitleWidgetDecouverte {
			font-size: 15px;
			font-weight: 500;
			margin-left: 20px;
			margin-top: 10px;
			margin-right: 20px;
		}

		.imageWidgetDecouverte {
			width: 502.5px;
			object-fit: contain;
		}
	}
}

.modalProjetEnCoursIntegration {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 657px) / 2);
	margin-left: calc((100vw - 590px) / 2);
	width: 590px;
	height: 657px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: scroll;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}

	.title {
		font-size: 28px;
		font-weight: 600;
		color: #4b5fb0;
		margin-bottom: 0px;
	}

	.subtitle {
		margin-left: 40px;
		margin-right: 40px;
		margin-top: 20px;
		font-size: 15px;
		text-align: center;
	}

	.dotted {
		width: 520px;
		object-fit: cover;
		height: 20px;
		opacity: 0.7;
	}

	.confie {
		margin-top: 15px;
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 0px;
	}

	.profilePicture {
		width: 60px;
		height: 60px;
		border-radius: 70px;
		margin-right: 20px;
	}

	.nomReferent {
		margin: 0px;
		font-size: 17px;
		font-weight: 600;
	}

	.button:hover {
		opacity: 0.7;
		cursor: pointer;
	}

	.button {
		width: 265px;
		height: 32px;
		background-color: #d2e7f8;
		color: #4b5fb0;
		font-weight: 600;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 7px;
		transition: opacity 0.2s ease-in-out;
	}
}
