.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    width: 500px;
    height: fit-content;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;

    h2 {
        margin: -3px 0 0 0;
        font-weight: 600;
        font-size: 22px;
    }

    h3 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }
    
    p {
        margin: 7px 0 0 0;
        opacity: 0.6;
        font-size: 13px;
    }
}

.overlayModal {
    position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(217, 217, 217, 0.7);
}

.cross {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.newNameContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 20px;

    input {
        border: none;
        border-bottom: solid 1px #eeeeee;
        padding: 10px;
        font-size: 14px;
        border: solid 1px #eeeeee;
        border-radius: 5px;
        background-color: #f5f5f5;

        &:focus {
            outline: none;
        }
    }
}

.choices {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
}

.choice, .choiceSelected {
    display: flex;
    outline: solid 1px #eeeeee;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    align-items: center;

    &:hover {
        background-color: #f5f5f5;
    }

    &:active {
        background-color: #e5e5e5;
        outline: solid 1px #d6d6d6;
    }

    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
        opacity: 0.7;
        margin-right: 10px;
    }
}

.choiceSelected {
    outline: solid 2px rgb(52, 120, 246);
    color: rgb(52, 120, 246);

    &:active {
        outline: solid 2px rgb(52, 120, 246);
    }

    img {
        opacity: 1;
    }
}

.submitContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    button {
        border: none;
        background-color: rgb(52, 120, 246);
        color: #fff;
        padding: 8px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;

        &:hover {
            opacity: 1;
            background-color: rgb(50, 112, 227);
        }

        &:active {
            background-color: rgb(48, 104, 209);
        }

        &:disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
