.modalBienvenueLectureSeule {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 550px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    font-size: 27px;
    margin-left: 70px;
    margin-right: 70px;
    font-weight: 600;
    color: #4B5FB0;
    text-align: center;
    margin-bottom: 0px;
  }

  .subtitle {
    font-size: 16px;
    margin-left: 70px;
    margin-right: 70px;
    font-weight: 600;
    text-align: center;
  }

  .imageFonct {
    margin-top: 30px;
    width: 550px;
    border-radius: 15px;
    height: 300px;
    object-fit: cover;
  }

  .button:hover {
    opacity: 0.7;
  }

  .button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 520px;
    height: 35px;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #4867EC;
    border-radius: 7px;
    cursor: pointer;
    transition: opacity 0.2s;
    margin-bottom: 20px;
  }
}
