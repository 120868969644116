
.mesCollaborateurs {
  transition: all 0.3s ease-in-out;

  .departements {

    margin-top: 0px;
    width: calc( 100vw - 135px);
    height: calc( 100vh - 104.7px);
    padding-top: 15.3px;

    .titlePage {
      margin-top: 10px;
      margin-left: 30px;
      font-size: 20px;
      font-weight: 600;
    }

    .barOptions {
      width: calc(100vw - 200px);
      min-width: 1010px;
      height: 1px;
      border-radius: 20px;
      background-color: #F8F8F8;
    }

    .buttonNouvCompte:hover {
      opacity: 0.8;
    }

    .buttonNouvCompte {
      margin-top: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: #D2E7F8;
      border-radius: 7px;
      height: 25px;
      padding-left: 10px;
      padding-right: 10px;

      .textNouvCompte {
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        color: #3E51A9;
      }

      .iconPlus {
        margin-left: 23px;
        width: 10px;
        object-fit: contain;
      }
    }

    .triOptions {
      margin-top: -2px;
      width: calc(100vw - 120px);
      height: 35px;
      border-radius: 8px;
      margin-left: 30px;
      min-width: 1000px;

      .textOption {
        font-size: 12px;
        font-weight: 600;
        margin: 0px;
      }

      .repartition {
        height: 28px;
        margin-left: 47px;
        width: calc(100vw - 700px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 80px;
        min-width: 600px;
      }
    }

    .placeHolderComptesLies {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 150px);
      min-height: 500px;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      .placeHolderPhotoComptesLies {
        margin-top: -35px;
        width: 800px;
        object-fit: contain;
        margin-bottom: 20px;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
      }

      .title {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 10px;
        transition: all 0.7s;
        -webkit-transition: all 0.7s;
      }

      .subtitle {
        font-weight: 500;
        color: #7a7a7a;
        margin: 0;
        transition: all 0.5s;
        width: 800px;
        line-height: 22px;
        -webkit-transition: all 0.5s;
      }

      .button:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      .button {
        padding-top: -1px;
        margin-top: 20px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background-color: #D2E7F8;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        opacity: 1;

        .textButton {
          color: #3E51A9;
          margin: 0;
          font-size: 13px;
          font-weight: 600;
        }
      }

    }


    .noComptesLies {
      position: absolute;
      margin-top: 0px;
      margin-left: 0px;
      overflow-x: scroll;
      width: calc( 100vw - 310px);
      height: calc( 100vh - 200.7px);
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .img {
        object-fit: cover;
        width: 600px;
        height: 600px;
        border-radius: 5px;
      }

      .title {
        font-size: 30px;
        margin-left: 25px;
        font-weight: 600;
        margin-bottom: 0px;
      }

      .subtitle {
        margin-top: 5px;
        font-size: 18px;
        margin-left: 25px;
        font-weight: 600;
        color: #525252;
      }

      .text {
        margin-left: 30px;
        width: 300px;
      }

      .bar {
        width: 310px;
        height: 2px;
        margin-left: 30px;
        margin-bottom: 20px;
        border-radius: 20px;
        background-color: #eeeeee;
      }

      button {
        width: 310px;
        height: 40px;
        border: 0px solid;
        background-color: #e8c0a6;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        margin-left: 30px;
      }
    }

    .nouvCollaborateur {
      position: absolute;
      right: 0;
      margin-right: 40px;
      width: 100px;
      height: 25px;
      border: 0px solid;
      border-radius: 8px;
      background-color: $color-1;
      color: #ffffff;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
    }

    .header1 {
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f8f8f8;
      margin-left: 28.2px;
      width: calc(100vw - 502px);
      height: 37px;

      .count {

        margin-left: 10px;
        font-size: 13px;
        font-weight: bold;
        color: #8b8b8b;
      }

      h1 {
        margin-left: 15px;
        font-size: 14px;
        letter-spacing: 0.4px;

      }

      .triNom {
        border: 0px;
        background-color: rgba(29, 17, 17, 0);
        font-weight: bold;
        color: #000000;
        font-size: 12.2px;
      }

      .triDate {
        margin-right: 265px;
        border: 0px;
        background-color: rgba(255, 255, 255, 0);
        margin-left: 15px;
        font-weight: bold;
        color: #8b8b8b;
        font-size: 12.2px;
      }
    }

    .header2 {
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #f8f8f8;
      margin-left: 20px;
      width: 200px;
      height: 37px;

      .nouveau {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 37px;
        border: 0px solid;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 5px;
        outline: none;

        .title {
          margin-top: 0px;
          margin-bottom: 0px;
          font-weight: bold;
          color: #000000;
          font-size: 12.2px;
        }
      }
    }

    .header2:hover {
      opacity: 0.5;
    }

    .nouvCollaborateur:hover {
      opacity: 0.6;
    }

    .globalList {
      margin-top: 7px;
      margin-left: 30px;
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: calc(100vh - 163px);
      width: calc(100vw - 180px);
      overflow-x: scroll;

      .liste {
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        flex-wrap: wrap;
        margin-bottom: 10px;
        overflow: hidden;


      }
    }


    .voileBlancComptesLiés {
      display: flex;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.85);
      width: calc(100vw - 160px);
      height: calc(100vh - 168px);
      max-height: 800px;
      margin-top: 8px;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .widget {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #FFFFFF;
        width: 700px;
        height: 300px;
        border-radius: 15px;

        .text1 {
          margin-top: 0px;
          font-size: 18px;
          font-weight: 600;
          color: #3E51A9;
        }

        .text2 {
          margin-top: 0px;
          font-size: 15px;
          font-weight: 600;
          text-align: center;
        }

        .buttonAllerIntegration:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .buttonAllerIntegration {
          margin-top: 10px;
          width: 300px;
          height: 35px;
          background-color: #D2E7F8;
          color: #3E51A9;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }

    .bouton {
      margin-left: 7.6px;
      width: 130px;
      height: 23px;
      border: 0px;
      border-radius: 7.6px;
      background-color: rgba(252, 139, 103, 0.82);
      font-weight: bold;
      color: white;
      cursor: pointer;
    }
  }

  .rechercheCompteLies {
    margin-left: 0px;
    border: solid 1px #eeeeee;
    background-color: #fff;
    padding-left: 30px;
    margin-top: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 100px;
    outline: none;
    width: 230px;
    font-weight: 500;
    font-size: 12px;
    height: 21px;
  }

  .searchIcon {
    margin-top: 8px;
    margin-left: -175px;
    height: 22px;
    object-fit: contain;
    margin-right: 230px;
  }

  .combleCollaborateur {
    width: calc(100vw - 595px);
  }

  .combleClient {
    width: calc(100vw - 546px);

  }

  .combleAgencePartenaire {
    width: calc(100vw - 620px);

  }
}

// Modal New Collaborateur

.modalNewCollaborateur {
  position: absolute;
  top: 0;
  margin-top: calc((100vh  - 600px)/2);
  margin-left: calc((100vw - 425px)/2);
  width: 425px;
  height: 600px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;

  h2 {
    margin-left: 25px;
    font-size: 27px;
    margin-bottom: 20px;
  }

  .annuler {
    background-color: rgba(248, 248, 248, 0);
    border: 0px solid;
    margin-left: 556px;
    margin-top: 5px;
    font-weight: 600;
  }

  .enregistrer {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 110px;
    background-color: $color-1;
    color: #FFFFFF;
    border-radius: 7px;
    border: 0px solid;
    margin-top: 20px;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: -10px;
  }

  .enregistrer:disabled {
    background-color: #7d7d7d;
  }

  .bigBar {
    width: 445px;
    height: 3px;
    background-color: #eeeeee;
    border-radius: 20px;
    margin-top: 13px;
    margin-left: 25px;
  }



  .globalList {

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: calc(100vh - 80px);
      overflow-x: scroll;

      // Image Upload

      .imgUploadNewCollab{
        margin-top: 0px;
        width: 270px;
        height: 270px;
        border-radius: 1000px;

        .container{
          width: 270px;
          height: 270px;
          border-radius: 1000px;
        }

        .heading{
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
        }

        .img-holder{
          width: 270px;
          height: 270px;
          border-radius: 1000px;
        }

        .img{
          cursor: pointer;
          object-fit: cover;
          margin-top: 0px;
          width: 270px;
          height: 270px;
          border-radius: 1000px;
          margin-bottom: 15.3px;
        }

        .img:hover {
          opacity: 0.7;
        }

        #input[type="file"] {
          display: none;
        }

        .label{
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .image-upload{
          margin-top: -80px;
          margin-left: 0px;
          padding-top: 5px;
          width: 120px;
          height: 23px;
          color: black;
          font-size: 12px;
          font-weight: bolder;
          background-color: #fff;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }

        .image-upload:hover {
          opacity: 0.7;
        }
      }

      .select1 {
        display: flex;
        align-items: center;
        justify-content: center;
        select {
          text-align: center;
          appearance: none;
          background-color: $color-1;
          border: none;
          width: 200px;
          height: 30px;
          padding-left: 10px;
          color: #F8F8F8;
          font-family: inherit;
          font-size: inherit;
          cursor: inherit;
          line-height: inherit;
          border-radius: 20px;
          outline: none;
        }
      }

      select {
        appearance: none;
        background-color: $color-1;
        border: none;
        width: 200px;
        height: 30px;
        padding-left: 10px;
        color: #F8F8F8;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        border-radius: 20px;
        outline: none;
      }

      //

      .IdAccount {
        width: 390px;
        height: 30px;
        border: solid 2px #F8F8F8;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding-left: 10px;
        outline: none;
        font-size: 13px;
      }

      .researchId {
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 10px;
        height: 35px;
        border: solid 0px;
        background-color: $color-1;
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 500;
      }

      .title {
        margin-top: 30px;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 0px;
      }

      .bar {
        width: 500px;
        height: 2px;
        background-color: #eeeeee;
        border-radius: 20px;
        margin-top: 13px;
      }

      .annuler {
        height: 25px;
        width: 90px;
        background-color: #e8a6a6;
        color: #FFFFFF;
        border-radius: 7px;
        border: 0px solid;
        margin-left: 90px;
        margin-top: 32px;
        font-weight: 600;
      }

        input {
          font-weight: 500;
          outline: none;
        }

        .designantInput {
          margin-top: 18px;
          font-weight: 500;
          margin-left: 5px;
          font-size: 13px;
          margin-bottom: 9px;
          color: #808080;
        }

        .eventName {
          width: 228px;
          height: 30px;
          border: solid 1px #F8F8F8;
          background-color: #F8F8F8;
          border-radius: 10px;
          padding-left: 10px;
          font-size: 13px;
          outline: none;
        }

      }
    }

  .inner::-webkit-scrollbar {
    display: none;
  }
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

// Ajouter un projet au collaborateur

.modalAjouterProjet {
  position: absolute;
  top: 0;
  margin-top: 40px;
  margin-left: calc((100vw - 600px)/2);
  width: 600px;
  height: calc(100vh - 80px);
  min-height: 500px;
  border-radius: 20px;
  background-color: #ffffff;
  outline: none;

  h2 {
    margin-left: 25px;
    font-size: 27px;
    margin-bottom: 0px;
  }

  .annuler {
    height: 25px;
    width: 90px;
    background-color: $color-1;
    color: #FFFFFF;
    border-radius: 7px;
    border: 0px solid;
    margin-left: 110px;
    margin-top: 27px;
    font-weight: 600;
  }

  .enregistrer {
    height: 30px;
    width: 120px;
    background-color: $color-1;
    color: #FFFFFF;
    border-radius: 7px;
    border: 0px solid;
    margin-left: -120px;
    margin-bottom: 20px;
    margin-top: -35px;
    font-weight: 600;
  }

  .bigBar {
    width: 540px;
    height: 3px;
    background-color: #eeeeee;
    border-radius: 20px;
    margin-top: 13px;
    margin-left: 25px;
  }

  .globalList {

    .inner {
      width: 600px;
      min-height: 500px;
      max-height: calc(100vh - 150px);
      overflow-y: scroll;
      overflow-x: hidden;

      .choisir {
        height: 25px;
        width: 65px;
        background-color: $color-1;
        color: #fff;
        font-weight: 600;
        fontSize: 14px;
        border-radius: 7px;
        border: 0px solid;
        margin-left: -80px;
        margin-top: 35px;
      }

      .bar {
        width: 500px;
        height: 2px;
        background-color: rgba(238, 238, 238, 0);
        border-radius: 20px;
        margin-top: 13px;
        margin-left: 25px;
      }

    }
  }

  .inner::-webkit-scrollbar {
    display: none;
  }
}

button {
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

.modalNewCollaborateur {
  position: absolute;
  top: 0;
  margin-top: 40px;
  margin-left: calc((100vw - 510px)/2);
  width: 510px;
  height: calc(100vh - 80px);
  min-height: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .listeInformationsCompte {
    .inner {
      display: flex;
      min-height: 500px;
      height: calc(100vh - 80px);
      overflow-x: scroll;
      flex-direction: column;
    }

    .inner::-webkit-scrollbar {
      display: none;
    }
  }
}

.modalMotDePasseCreation {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 300px)/2);
  margin-left: calc((100vw - 480px)/2);
  width: 480px;
  height: 300px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .inputModifNomProjet {
    margin-top: 30px;
    width: 380px;
    height: 35px;
    border: solid 1px rgba(159, 159, 159, 0.42);
    background-color: #FFFFFF;
    border-radius: 8px;
    outline: none;
    font-size: 20px;
    padding-left: 20px;
    text-align: center;
  }

  .enregistrerButton {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 400px;
    height: 35px;
    background-color: $color-1;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 500;
    border: solid 0px;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    letter-spacing: 0.6px;
  }

  .enregistrerButton:hover {
    opacity: 0.7;
  }

  .enregistrerButton:disabled {
    background-color: #F8F8F8;
  }
}

.modalAddCollab {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 760px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 760px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 7px;
  }

  .subtitleModal {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 10px;
  }

  .inputNom {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 13px;
    font-weight: 500;
    width: 237px;
    height: 35px;
    border-radius: 10px;
    border: solid 1.5px #eaeaea;
  }

  .inputEmail {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    outline: none;
    font-size: 13px;
    width: 520px;
    height: 35px;
    border-radius: 10px;
    border: solid 1.5px #eaeaea;
  }

  .buttonSend:hover {
    opacity: 0.8;
  }

  .buttonSend {
    margin-top: 10px;
    height: 30px;
    margin-left: -35px;
    width: 30px;
    border-radius: 10px;
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    border: solid 0px;

    .imgButtonSend {
      width: 18px;
      object-fit: contain;
    }
  }

  .cardAutor {
    margin-top: 25px;
    width: 530px;
    min-height: 80px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    display: flex;
    align-items: center;
    cursor: pointer;

    .noChoiceAutor {
      margin-left: 15px;
      height: 14px;
      width: 14px;
      border-radius: 20px;
      border: solid 0.3px #d0d0d0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .choiceAutor {
      width: 16px;
      object-fit: contain;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    .titleAutor {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    .textAutor {
      width: 320px;
      font-size: 13px;
      font-weight: 400;
    }

    .choixAutorPersonalise {
      height: 150px;
      display: flex;
      flex-wrap: wrap;

      .autor:hover {
        opacity: 0.8;
      }

      .autor {
        border: solid 1px #F8F8F8;
        margin-left: 9px;
        height: 20px;
        padding: 4px 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        .typeAutor {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 13px;
        }
      }

      .autorActive {
        border: solid 1px $color-1;
        background-color: $color-1;
        margin-left: 9px;
        height: 20px;
        padding: 4px 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        .typeAutor {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 13px;
          color: #FFFFFF;
        }
      }

    }
  }

  .buttonGroupModifCollaborateur:hover {
    cursor: pointer;
    .jeConfirmeButtonModifCollaborateur {
      opacity: 0.7;
    }
    .choice {
      transform: translateX(-250px);
    }
  }

  .jeConfirmeButtonModifCollaborateur {
    width: 400px;
    height: 33px;
    border-radius: 7px;
    background-color: #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 90px;
    transition: all ease-in-out 250ms;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}

.modalModifAddCollab {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 650px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 7px;
  }

  .subtitleModal {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 10px;
  }

  .inputNom {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 13px;
    font-weight: 500;
    width: 237px;
    height: 35px;
    border-radius: 10px;
    border: solid 1.5px #eaeaea;
  }

  .inputEmail {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    outline: none;
    font-size: 13px;
    width: 520px;
    height: 35px;
    border-radius: 10px;
    border: solid 1.5px #eaeaea;
  }

  .buttonSend:hover {
    opacity: 0.8;
  }

  .buttonSend {
    margin-top: 10px;
    height: 30px;
    margin-left: -35px;
    width: 30px;
    border-radius: 10px;
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    border: solid 0px;

    .imgButtonSend {
      width: 18px;
      object-fit: contain;
    }
  }

  .cardAutor {
    margin-top: 25px;
    width: 530px;
    min-height: 80px;
    margin-left: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    display: flex;
    align-items: center;
    cursor: pointer;

    .noChoiceAutor {
      margin-left: 15px;
      height: 14px;
      width: 14px;
      border-radius: 20px;
      border: solid 0.3px #d0d0d0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .choiceAutor {
      width: 16px;
      object-fit: contain;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    .titleAutor {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    .textAutor {
      width: 320px;
      font-size: 13px;
      font-weight: 400;
    }

    .choixAutorPersonalise {
      height: 150px;
      display: flex;
      flex-wrap: wrap;

      .autor:hover {
        opacity: 0.8;
      }

      .autor {
        border: solid 1px #F8F8F8;
        margin-left: 9px;
        height: 20px;
        padding: 4px 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        .typeAutor {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 13px;
        }
      }

      .autorActive {
        border: solid 1px #D2E7F8;
        background-color: #D2E7F8;
        margin-left: 9px;
        height: 20px;
        padding: 4px 10px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        .typeAutor {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 13px;
          font-weight: 600;
          color: #4B5FB0;
        }
      }

    }
  }

  .buttonGroupModifCollaborateur:hover {
    cursor: pointer;
    .jeConfirmeButtonModifCollaborateur {
      opacity: 0.7;
    }
    .choice {
      transform: translateX(-250px);
    }
  }

  .jeConfirmeButtonModifCollaborateur {
    width: 400px;
    height: 33px;
    border-radius: 7px;
    background-color: #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 90px;
    transition: all ease-in-out 250ms;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}

.modalConfirmationCreationCollab {

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .explicationPrelevement {
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
    width: 370px;
    height: 180px;
    background-color: #F6F9FC;
    border-radius: 15px;

    .abonnement {
      margin-top: 0px;
      width: 110px;
      height: 130px;
      background-color: #F6F9FC;
      margin-left: 20px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;

      .doubleOBleu {
        margin-top: 25px;
        width: 80px;
        object-fit: contain;
      }

      .name {
        margin-top: 0px;
        color: #4B5FB0;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 15px;
      }
    }

    .chiffresUtilisation {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      word-spacing: 2px;
    }

    .price {
      margin-top: 15px;
      font-size: 45px;
      font-weight: 200;
      margin-right: 15px;
      margin-bottom: 0px;
    }

    .montant {
      margin-top: 25px;
      margin-bottom: -10px;
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #4B5FB0;
      font-weight: 500;
    }

    .detailPrice {
      margin: -5px;
      font-size: 14px;
      color: #8d8d8d;
    }
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      opacity: 0.8;
    }
  }

  .jeConfirmeButton {
    margin-top: 0px;
    width: 370px;
    height: 37px;
    border-radius: 7px;
    background-color: #4F66E3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 30px;
    transition: opacity 0.3s;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }
  }

}

.modalProrataTemporis {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 690px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 690px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 15px;
    letter-spacing: 0.6px;
    margin-left: 20px;
    margin-right: 30px;
    text-align: justify;
  }

  .textTitle {
    margin-left: 47px;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
    letter-spacing: 0.5px;
  }

  .creditCard {
    border-radius: 10px;
    width: 175px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  }

  .numeroCarte {
    font-size: 14px;
    margin-left: 20px;
    font-weight: 400;
    color: #595959;
    letter-spacing: 0.6px;
  }

  .expDate {
    font-size: 14px;
    margin-left: 20px;
    color: #595959;
    font-weight: 400;
    letter-spacing: 0.6px;
  }

  .explicationPrelevement {
    display: flex;
    margin-left: 45px;
    margin-top: 20px;
    width: 470px;
    height: 145px;
    background-color: #F6F9FC;
    border-radius: 15px;

    .abonnement {
      margin-top: 0px;
      width: 160px;
      height: 110px;
      background-color: #F6F9FC;
      margin-left: 20px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;

      .doubleOBleu {
        width: 80px;
        object-fit: contain;
      }

      .name {
        margin-top: 0px;
        color: #4B5FB0;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 16px;
      }
    }

    .chiffresUtilisation {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      margin-left: 20px;
      letter-spacing: 0.5px;
      word-spacing: 2px;
    }

    .price {
      margin-top: 15px;
      font-size: 45px;
      font-weight: 200;
      margin-right: 15px;
      margin-bottom: 0px;
    }

    .montant {
      margin-top: 25px;
      margin-bottom: -10px;
      font-size: 15px;
      letter-spacing: 0.5px;
      color: #4B5FB0;
      font-weight: 500;
    }

    .detailPrice {
      margin: -5px;
      font-size: 14px;
      color: #8d8d8d;
    }
  }


  .inputGroup {
    margin-left: 30px;

    .inputName {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 16px;
      letter-spacing: 0.5px;
    }

    .input {
      width: 495px;
      height: 32px;
      border: solid 1px #d7d7d7;
      border-radius: 6px;
      padding-left: 15px;
      padding-right: 10px;
      font-size: 12px;
      outline: none;
    }

    .littleInput {
      margin-top: 15px;
      width: 226.5px;
      height: 32px;
      border: solid 1px #d7d7d7;
      border-radius: 6px;
      padding-left: 15px;
      padding-right: 10px;
      font-size: 12px;
      outline: none;
    }
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      opacity: 0.8;
    }
  }

  .jeConfirmeButton {
    margin-top: 0px;
    width: 530px;
    height: 33px;
    border-radius: 10px;
    background-color: #4B5FB0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 30px;
    transition: opacity 0.3s;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.6px;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }
  }
}

.modalFelicitationCreationCollab {
  width: 430px;


  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
  }

  .felicitation {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .detailFelicitation {
    font-size: 15px;
    font-weight: 500;
  }

  .emailContainer:hover {
    opacity: 0.7;
  }

  .emailContainer {
    margin-top: 20px;
    background-color: #CCF3CA;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .email {
      margin: 0px;
      font-size: 18px;
      font-weight: 400;
      color: #146146;
    }
  }

  .ajouterProjet:hover {
    opacity: 0.8;
  }

  .ajouterProjet {
    width: 370px;
    height: 35px;
    border-radius: 7px;
    background-color: #D2E7F8;
    border: solid 1.5px #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
    margin-bottom: 10px;

    .textAjouterProjet {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }
}

.modalAddClient {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 620px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 620px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 22px;
    font-weight: 700;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .subtitleModal {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 23px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .littleInput {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 13px;
    width: 240px;
    height: 35px;
    border-radius: 10px;
    font-weight: 500;
    border: solid 1px #eaeaea;
  }

  .input {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 13px;
    width: 520px;
    height: 35px;
    font-weight: 500;
    border-radius: 10px;
    border: solid 1px #eaeaea;
  }

  .searchButton:hover {
    opacity: 0.8;
  }

  .searchButton:disabled {
    opacity: 0.3;
  }

  .searchButton:disabled:hover {
    opacity: 0.3;
  }

  .searchButton {
    height: 33px;
    width: 33px;
    margin-top: 9px;
    margin-left: -36px;
    background-color: #D2E7F8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .whiteSearchIcon {
      width: 22px;
      object-fit: contain;
    }
  }

  .buttonSuivant:hover {
    opacity: 0.8;
  }

  .buttonSuivant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 28px;
    border-radius: 7px;
    background-color: #D2E7F8;
    margin-top: 60px;
    margin-bottom: 20px;
    margin-left: -90px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textSuivant {
      color: $color-1;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .choix {
    margin-left: 23px;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .barChoix {
    margin-left: 23px;
    height: 3px;
    background-color: $color-1;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .profilePicture {
    margin-left: 40px;
    width: 50px;
    height: 50px;
    border-radius: 200px;
    background-color: #F8F8F8;
  }

  .blankName {
    width: 150px;
    background-color: #F8F8F8;
    border-radius: 20px;
    height: 10px;
    margin-left: 20px;
  }

  .blankMailAdress {
    margin-top: 10px;
    width: 200px;
    background-color: #F8F8F8;
    border-radius: 20px;
    height: 8px;
    margin-left: 20px;
  }

  .blankButtonAdd {
    width: 100px;
    margin-left: 10px;
    height: 25px;
    border-radius: 8px;
    background-color: #F8F8F8;
  }

  .nomClient {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .emailClient {
    margin-top: 3px;
    margin-left: 15px;
    font-size: 13px;
    font-weight: 400;
  }

  .buttonAdd:hover {
    opacity: 0.8;
  }

  .buttonAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    margin-left: 10px;
    height: 25px;
    border-radius: 8px;
    background-color: $color-1;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textButtonAdd {
      font-size: 13px;
      color: #FFFFFF;
    }
  }
}

.modalFelicitationAddClient {
  width: 480px;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
  }

  .felicitation {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
  }


  .detailFelicitation {
    margin-left: 30px;
    margin-right: 30px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
    text-align: center;
  }

  .emailContainer:hover {
    opacity: 0.7;
  }

  .emailContainer {
    margin-top: 20px;
    background-color: #CCF3CA;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 40px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .email {
      margin: 0px;
      font-size: 18px;
      font-weight: 400;
      color: #146146;
    }
  }

  .terminer:hover {
    opacity: 0.8;
  }

  .terminer {
    width: 370px;
    height: 35px;
    border-radius: 7px;
    background-color: #D2E7F8;
    border: solid 1.5px #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
    margin-bottom: 10px;

    .textTerminer {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }
}

.modalAddAgencePartenaire {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 620px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 620px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 28px;
  }

  .subtitleModal {
    margin-top: 0px;
    font-size: 14px;
    margin-left: 23px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .littleInput {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 12px;
    width: 240px;
    height: 35px;
    border-radius: 10px;
    border: solid 1px #eaeaea;
  }

  .input {
    margin-top: 6px;
    margin-left: 23px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 12px;
    width: 520px;
    height: 35px;
    border-radius: 10px;
    border: solid 1px #eaeaea;
  }

  .searchButton:hover {
    opacity: 0.8;
  }

  .searchButton {
    height: 33px;
    width: 33px;
    margin-top: 9px;
    margin-left: -36px;
    background-color: $color-1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .whiteSearchIcon {
      width: 15px;
      object-fit: contain;
    }
  }

  .buttonSuivant:hover {
    opacity: 0.8;
  }

  .buttonSuivant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 28px;
    border-radius: 7px;
    background-color: $color-1;
    margin-top: 60px;
    margin-bottom: 20px;
    margin-left: -110px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textSuivant {
      color: #FFFFFF;
      font-size: 13px;
    }
  }

  .choix {
    margin-left: 23px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 7px;
  }

  .profilePicture {
    margin-left: 40px;
    width: 50px;
    height: 50px;
    border-radius: 200px;
    background-color: #F8F8F8;
  }

  .blankName {
    width: 200px;
    background-color: #F8F8F8;
    border-radius: 20px;
    height: 10px;
    margin-left: 20px;
  }

  .blankMailAdress {
    margin-top: 10px;
    width: 260px;
    background-color: #F8F8F8;
    border-radius: 20px;
    height: 8px;
    margin-left: 20px;
  }

  .blankButtonAdd {
    width: 100px;
    margin-left: 10px;
    height: 25px;
    border-radius: 8px;
    background-color: #F8F8F8;
  }

  .nomClient {
    margin-left: 15px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .emailClient {
    margin-top: 3px;
    margin-left: 15px;
    font-size: 13px;
    font-weight: 400;
  }

  .buttonAdd:hover {
    opacity: 0.8;
  }

  .buttonAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    margin-left: 10px;
    height: 25px;
    border-radius: 8px;
    background-color: $color-1;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textButtonAdd {
      font-size: 13px;
      color: #FFFFFF;
    }
  }
}

.modalFelicitationAddAgence {

  width: 430px;


  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 430px;
  }

  .felicitation {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .detailFelicitation {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .emailContainer:hover {
    opacity: 0.7;
  }

  .emailContainer {
    margin-top: 20px;
    background-color: #CCF3CA;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .email {
      margin: 0px;
      font-size: 18px;
      font-weight: 400;
      color: #146146;
    }
  }

  .terminer:hover {
    opacity: 0.8;
  }

  .terminer {
    width: 370px;
    height: 35px;
    border-radius: 7px;
    background-color: #D2E7F8;
    border: solid 1.5px #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
    margin-bottom: 10px;

    .textTerminer {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }
}

.modalInterdictionAgencePartenaire {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 490px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 490px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 20px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 15px;
  }

  .subtitleModal {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 23px;
    margin-right: 20px;
    margin-bottom: 20px;
    line-height: 20px;
    letter-spacing: 0.6px;
  }

  .comparaison {

    display: flex;

    .part {
      width: 293px;
      height: 360px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 40px;
      }

      .choice {
        margin-right: 10px;
        width: 18px;
        object-fit: contain;
      }

      .textOption {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .explicationPrelevement {
    display: flex;
    margin-left: 95px;
    width: 400px;
    height: 145px;
    background-color: #F6F9FC;
    border-radius: 15px;
    margin-bottom: 20px;

    .abonnement {
      margin-top: 0px;
      width: 160px;
      height: 110px;
      background-color: #F6F9FC;
      margin-left: 20px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;

      .doubleOBleu {
        width: 80px;
        object-fit: contain;
      }

      .name {
        margin-top: 0px;
        color: #4B5FB0;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-size: 16px;
      }
    }
  }

  .buttonOffres:hover {
    opacity: 0.8;
  }

  .buttonOffres {
    width: 400px;
    margin-left: 95px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: $color-1;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textButtonOffres {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      letter-spacing: 0.5px;
    }
  }
}

.modalConfirmationSuppressionCompte {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 360px)/2);
  margin-left: calc((100vw - 500px)/2);
  width: 500px;
  height: 360px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
  }

  .felicitation {
    margin-top: 30px;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .detailFelicitation {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 420px;
    color: #6c6c6c;
  }

  .emailContainer:hover {
    opacity: 0.7;
  }

  .emailContainer {
    margin-top: 10px;
    background-color: #f3caca;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .email {
      margin: 0px;
      font-size: 18px;
      font-weight: 400;
      color: #611414;
    }
  }

  .terminer:hover {
    opacity: 0.8;
  }

  .terminer {
    width: 225px;
    height: 33px;
    border-radius: 8px;
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;

    .textTerminer {
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      opacity: 0.7;
    }
    .choice {
      transform: translateX(-180px);
    }
  }

  .jeConfirmeButton {
    width: 400px;
    height: 33px;
    border-radius: 7px;
    background-color: #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 250ms;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 600;
      color: $color-1;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}

.OverlayModal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

.modalAjouterCreditCollab {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 500px) / 2);
  margin-left: calc((100vw - 600px) / 2);
  width: 600px;
  height: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
}
