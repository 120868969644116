.tagComponentNotSelected, .tagComponentSelected {
  background-color: rgba(168, 168, 168, 0.1);
  border: solid 1px #a2a2a2;
  border-radius: 99px;
  padding: 4px 8px;
  font-size: 13px;
  color: #4b4b4b;
  white-space: nowrap;
  transition: opacity ease-in-out 0.2s;
  display: flex;
  align-items: center;
  width: fit-content;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  p {
      margin: 0;
  }
}

.tagComponentSelected {
  background-color: rgba(28, 87, 221, 0.1);
  color: #1C57DD;
  border: solid 1px #1C57DD;
}
