.ticketsColumn {
	width: calc(100vw - 240px);
	border-radius: 10px;
	margin-top: 10px;
	margin-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;

	.ticketList {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 10px;
	}

	.buttonNewTicket {
		width: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		margin-top: 10px;
		background-color: #f8f8f8;
		height: 60px;
		border-radius: 10px;
		font-weight: 600;
		font-size: 14px;
		border: dashed 2px #e0e0e0;
		transition: all ease-in-out 0.2s;
		cursor: pointer;
		img {
			width: 15px;
			margin-right: 10px;
		}
	}

	.buttonNewTicket:hover {
		transform: scale(1.02);
	}

	.ticketBoard {
		display: flex;
		gap: 20px;

		.board {
			display: flex;
			flex: 1;
			flex-direction: column;
			background-color: #f4f6fe;
			border-radius: 10px;
			min-width: 270px;

			.containerHeaderBoard {
				position: sticky;
				top: 0px;
				background-color: #ffffff;
				padding-top: 20px;
				margin-top: -10px;

				.headerBoard {
					padding: 8px;
					display: flex;
					border-radius: 10px 10px 0px 0px;
					height: 30px;
					background-color: #f4f6fe;

					.tagBoard {
						display: flex;
						align-items: center;
						align-self: flex-start;
						padding: 3px 10px;
						background-color: rgba(28, 87, 221, 0.15);
						border-radius: 99px;
						font-size: 14px;
						font-weight: 600;

						.rond {
							width: 10px;
							height: 10px;
							border-radius: 10px;
							background-color: #1c57dd;
							margin-right: 5px;
							margin-left: -3px;
						}
					}
				}
			}

			.containerCards {
				display: flex;
				flex-direction: column;
				padding: 8px;
				gap: 10px;

				.placeholderNoTickets {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #777777;
					font-size: 14px;
					margin-bottom: 20px;
					margin-top: 20px;
				}
			}
		}
	}
}
