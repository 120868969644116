.etapePhaseContainer {
	display: flex;
	flex-direction: row;
	align-items: center;

	&:hover {
		.duplicateIcon {
			opacity: 0.4;
		}
	}

	.duplicateIcon {
		width: 16px;
		height: 16px;
		object-fit: contain;
		align-self: flex-start;
		margin-left: -10px;
		margin-right: 10px;
		margin-top: 3px;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
}

.etapePhase {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex: 1;
	margin-left: 0px;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;

	.stepCircle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 23px;
		height: 23px;
		background-color: #5e74d0;
		border-radius: 20px;

		img {
			margin-top: 2px;
			width: 12px;
			height: 12px;
		}
	}

	.titleEtapePhase {
		font-weight: 600;
		font-size: 15px;
		margin: 0 10px 0 15px;
	}

	.stepDeleteIcon {
		width: 22px;
		height: 22px;
		object-fit: contain;
		margin-right: 20px;
	}

	.stepReorganizeContainer {
		display: flex;
		flex-direction: column;
		margin-left: -9px;
		margin-right: 11px;
		gap: 5px;

		.stepReorganizeArrow {
			width: 14px;
			height: 14px;
			opacity: 0.5;
			transition: opacity 0.3s ease-in-out;
			cursor: pointer;

			&:hover {
				opacity: 0.4;
			}
		}
	}
}

.stepContent {
	display: flex;
	flex: 1;

	.barSubStep {
		display: flex;
		border-radius: 10px;
		width: 5px;
		background-color: #eff2fe;
		margin: 11px 0 11px 9px;

		.frontBarSubSteps {
			width: 5px;
			background-color: #4867ec;
			border-radius: 20px;
			transition: height 0.3s;
		}
	}

	.stepSubStepsContainer {
		flex: 1;
	}
}

.addSubStep {
	display: flex;
	align-items: center;
	flex: 1;
	opacity: 0;
	height: 27px;

	.barAddSubStep {
		width: 200px;
		height: 5px;
		background-color: rgba(28, 87, 221, 0.11);
		margin-left: 32px;
		border-radius: 10px;
	}

	img {
		width: 18px;
		margin-left: 8px;
	}

	p {
		font-size: 12px;
		font-weight: 700;
		color: #1c57dd;
		margin-left: 6px;
		text-wrap: nowrap;
	}

	&:hover {
		opacity: 1;
	}
}
