.eventLogs {
	display: flex;
	flex-direction: column;
	height: 591px;
	max-height: calc(100vh - 133px);
	gap: 30px;
	overflow: scroll;
	padding-top: 25px;
	padding-bottom: 25px;
	position: relative;

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.7); // semi-transparent white
		backdrop-filter: blur(5px);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10;
		border-bottom-right-radius: 10px;
	}

	&__coming-soon {
		font-size: 24px;
		font-weight: bold;
		color: #333;
	}
}
