.invitationLinkManager {
  width: 530px;
  height: calc(100vh - 167px);
  display: flex;
  flex-direction: column;
  align-items: center;

  .option {
    margin: 0;
    font-weight: 700;
    color: #64a64a;
  }

  .rightIconGreen {
    width: 12px;
  }

  .widgetSendLink {
    background-color: #F6F8FA;
    border: solid #EAEBED 1px;
    width: 528px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 7px;

    .containerEmailSent {
      display: flex;
      margin-top: 50px;
      margin-bottom: 50px;

      .inviteSentIcon {
        width: 55px;
      }

      .infosEmailSent {
        margin-left: 20px;

        .title {
          margin: 0;
          font-weight: 700;
        }

        .email {
          margin: 0;
          margin-top: 4px;
          font-size: 15px;
          opacity: 0.7;
        }
      }
    }

    .bar {
      width: 500px;
      height: 1px;
      background-color: #D1D1D1;
    }

    .titleWidget {
      font-weight: 700;
      font-size: 15px;
      margin-top: 25px;
      margin-bottom: 0px;
    }

    .containerInputEmail {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 15px;

      .icon {
        width: 18px;
        opacity: 0.3;
        margin-left: 10px;
      }

      .inputEmail {
        margin-left: -30px;
        width: 430px;
        padding-left: 37px;
        height: 35px;
        border: solid 1px #D1D1D1;
        border-radius: 7px;
        font-size: 14px;
        font-weight: 500;
        outline: none;
      }
    }

    .linkType {
      margin-left: 35px;
      margin-right: 35px;
      font-size: 14px;
      margin-top: 30px;
    }

    .containerLink {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 464px;
      height: 35px;
      border-radius: 7px;
      background-color: #FFFFFF;
      margin-bottom: 20px;
      border: solid 1.5px #5FBA2F;

      .link {
        margin-left: 10px;
        font-size: 14px;
        opacity: 0.4;
        cursor: pointer;
        max-width: 380px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .buttonCopy {
        height: 35px;
        font-size: 13px;
        font-weight: 700;
        color: #5FBA2F;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        cursor: pointer;
      }
    }

    .sendLink {
      background-color: #5FBA2F;
      width: 467px;
      height: 40px;
      border-radius: 5px;
      margin-bottom: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 15px;
      border: solid 0px;
      margin-left: -6px;
    }
  }

  .projectCard {
    width: 470px;
    height: 120px;
    background-color: #FFFFFF;
    border: solid 1px #DADBDB;
    border-radius: 15px;
    margin-bottom: 30px;
    display: flex;

    .projectPicture {
      height: 120px;
      width: 140px;
      object-fit: cover;
      border-radius: 14px 0px 0px 14px;
    }

    .containerInfosProject {
      margin-top: 10px;
      margin-left: 15px;

      .flexSpace {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .projectName {
          font-weight: 700;
          font-size: 15px;
          margin: 0;
        }

        .percentageProject {
          font-size: 12px;
          font-weight: 600;
          opacity: 0.7;
          margin: 0;
        }
      }

      .barProject {
        margin-top: 8px;
        width: 297px;
        height: 2px;
        background-color: #F6F6F6;
      }

      .progressBars {

        .firstBar {
          width: 298px;
          height: 5px;
          margin-top: 12px;
          background-color: #f3f3f3;
          margin-left: 2px;
          border-radius: 15.3px;
        }
        .secondBar {
          height: 5px;
          border-radius: 15.3px;
          transition: width ease-in-out 1.5s;
        }
      }


    }
  }

  .listLinksSent {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .itemListLinkSent, .itemListLinkOpened {
      display: flex;
      align-items: center;

      .containerEmail {
        display: flex;
        align-items: center;
        width: 290px;
        gap: 10px;
        height: 15px;

        .icon {
          width: 16px;
          height: 16px;
        }

        .email {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          color: #585858;

        }
      }

      .containerStatus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        gap: 3px;

        .status {
          background-color: #F2F2F2;
          font-size: 13px;
          font-weight: 600;
          padding: 3px 8px;
          border-radius: 10px;
          color: #7B7B7B;
        }

        .resendIcon {
          width: 23px;
        }
      }

      .crossIcon {
        width: 12px;
        opacity: 0.4;
        margin-left: 70px;
        margin-right: 20px;
      }

      .tooltip {
        height: 30px;
        display: flex;
        align-items: center;
        .tooltipText {
          position: absolute;
          display: inline-block;
          cursor: pointer;
          visibility: hidden;
          padding: 4px 8px;
          background-color: #5FBA2F;
          color: white;
          text-align: center;
          border-radius: 99px;
          font-size: 13px;
          font-weight: 600;
          z-index: 1;
          opacity: 0;
          margin-top: 0px;
          transition: opacity 0.3s;
        }
      }

      .tooltip:hover .tooltipText {
        visibility: visible;
        opacity: 1;
        margin-left: 0px;
      }
    }

    .itemListLinkOpened {

      .containerEmail {
        .email {
          color: #5FBA2F;
        }
      }

      .containerStatus {
        .status {
          background-color: #EFF8EA;
          color: #5FBA2F;
        }
      }
    }
  }


}

