.ticketsPage {
	flex: 1;
	height: calc(100vh - 55px);
	overflow: scroll;

	.containerInfoLectureSeule {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 100px;
		font-size: 14px;
		gap: 20px;
	}

	.containerTicketsColumns {
		padding-left: 25px;
		display: flex;
		align-items: flex-start;
		gap: 25px;
		height: calc(100vh - 153px);
		overflow: scroll;
		width: calc(100vw - 220px);

		&.filterSortOpened {
			height: calc(100vh - 209px);
		}

		.buttonCreateNewTicketsCategory {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-top: 25px;
			width: 300px;
			margin-right: 40px;
			background-color: #f8f8f8;
			height: 150px;
			border-radius: 10px;
			font-weight: 600;
			font-size: 14px;
			border: dashed 2px #e0e0e0;
			transition: all ease-in-out 0.2s;
			cursor: pointer;

			img {
				width: 15px;
				margin-bottom: 10px;
			}
		}

		.buttonCreateNewTicketsCategory:hover {
			transform: scale(1.02);
		}
	}
}
