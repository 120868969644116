.joinProjectPage {
  display: flex;

  .buttonBack:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .buttonBack {
    white-space: nowrap;
    border: solid 1px #4E66E4;
    color: #4E66E4;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 99px;
    transition: opacity ease-in-out 0.2s;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: -40px;

    .leftIcon {
      width: 10px;
      object-fit: contain;
      margin-right: 5px;
    }
  }


  .containerLeftPart {
    width: 50vw;
    height: 100vh;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;

    .animationJoinProject {
      transition: opacity 0.8s ease-in-out;
    }

    .headerJoinProject {
      display: flex;
      padding-left: 30px;
      padding-top: 20px;
      align-items: center;

      .opus {
        margin-top: 0px;
        font-size: 30px;
        font-weight: 100;
        margin-bottom: 0px;
      }

      .projectName {
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    .footerJoinProject {
      padding-left: 10px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .containerRightPart {
    width: 50vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #F2F6FC;

    .calqueInfosProjet {
      height: 850px;
      width: 50vw;
      position: absolute;

      .firstSlide {
        display: flex;
        margin-top: 73px;
        align-items: center;
        padding-left: 63px;

        .projectImg {
          width: 22px;
          height: 22px;
          border-radius: 4px;
          margin-right: 8px;
          object-fit: cover;
        }

        .projectName {
          font-size: 11px;
          font-weight: 700;
          margin: 0;
        }
      }

      .secondSlide {
        display: flex;
        margin-top: 47px;
        align-items: center;
        padding-left: 289px;

        .agencyProject {
          background-color: #F6F6F6;
          padding: 2px 7px;
          border: solid 1px #A2A2A2;
          border-radius: 99px;
          color: #4B4B4B;
          font-size: 9px;
        }
      }

      .thirdSlide {
        display: flex;
        margin-top: 66px;
        align-items: center;
        padding-left: 420px;

        .projectName {
          font-size: 15px;
          font-weight: 700;
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .fourthSlide {
        display: flex;
        margin-top: 37px;
        align-items: center;
        padding-left: 417px;

        .projectImg {
          width: 226px;
          height: 175px;
          object-fit: cover;
          border-radius: 5px;

        }
      }

      .containerQrCode {
        width: 170px;
        height: 400px;
        margin-left: 170px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .backGroundQrCode {
          background-color: #FFFFFF;
          padding: 10px;
          border-radius: 10px;
          box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
        }

        .leftIcon {
          transform: rotate(90deg);
          margin-top: 13px;
          width: 18px;
        }

        .scanThisQrCode {
          font-weight: 600;
          margin-top: 10px;
          font-size: 14px;
          text-align: center;
          color: #1C57DD;
        }
      }
    }

    .rightPartJoinProject {
      height: 850px;
      object-fit: contain;
    }
  }

  @media(max-width: 1100px) {
    .containerRightPart {
      display: none;
    }
  }
}

@media(max-width: 700px) {
  .joinProjectPage {
    display: none;
  }
}

.yourScreenIsToShort {

}

@media(min-width: 700px) {
  .yourScreenIsToShort {
    display: none;
  }
}
