.parametresProjet {

  .innerParametresProjet {
    margin-top: 1px;
    margin-left: 0px;
    padding-left: 0px;
    padding-top: 30px;
    width: calc(100vw - 245px);
    max-height: calc(100vh - 197px);
    overflow-x: scroll;
    display: flex;
    flex-direction: column;

    .intervenantItem {
      padding-left: 25px;
      margin-bottom: 50px;
      margin-right: 20px;
      padding-top: 20px;
      border-radius: 15px;
      border: solid 1.5px #eeeeee;

      .titreIntervenant {
        margin-top: 0px;
        font-weight: 600;
        font-size: 20px;
        color: #4B5FB0;
      }

      .leftBleu {
        width: 13px;
        object-fit: contain;
        margin-top: -17px;
        margin-left: 2px;
      }

      .mandataire {
        margin-top: 2px;
        margin-left: 15px;
        height: 22px;
        background-color: #DD702C;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        border-radius: 20px;
        color: #FFFFFF;
        padding-left: 8px;
        padding-right: 8px;
      }

      .vous {
        margin-top: 2px;
        margin-left: 10px;
        height: 22px;
        background-color: #4f9352;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        border-radius: 20px;
        color: #FFFFFF;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 20px;
      }

      .client {
        margin-top: 2px;
        margin-left: 15px;
        height: 22px;
        background-color: #D2E7F8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 600;
        border-radius: 20px;
        color: #4B5FB0;
        padding-left: 8px;
        padding-right: 8px;
      }

      .logoAgence {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 200px;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      }

      .nomAgence {
        margin-left: 20px;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 6px;
        color: #4B5FB0;
      }

      .adresseAgence {
        margin-top: -12px;
        margin-left: 0px;
        font-weight: 600;
        font-size: 13px;
      }

      .itemCompteAgence {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: -15px;

        .photoProfil {
          margin-top: -3px;
          width: 40px;
          height: 40px;
          border-radius: 25px;
          object-fit: cover;
        }

        .photoProfilBleu {
          margin-left: 15px;
          margin-top: -3px;
          width: 40px;
          height: 40px;
          border-radius: 25px;
          object-fit: cover;
          background-color: #7FA3FC;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-weight: 600;
        }

        .nomDuCompte {
          font-size: 14px;
          font-weight: 600;
          margin-left: 20px;
          width: 200px;
        }

        .repartitionIntervenants {
          height: 20px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          margin-left: 5px;
          align-items: center;
          width: calc(100vw - 740px);
          min-width: 400px;

          .posteAgence {
            display: flex;
            justify-content: center;
            background-color: #D2E7F8;
            padding: 3px 10px;
            border-radius: 5px;
            margin: 0px;
            font-size: 12px;
            font-weight: 600;
            color: #4B5FB0;
          }

          .email {
            display: flex;
            justify-content: center;
            background-color: #CCF3CA;
            padding: 3px 10px;
            border-radius: 5px;
            margin: 0px;
            font-size: 12px;
            font-weight: 600;
            color: #146146;
          }

        }

        .telephone {
          font-size: 13px;
          font-weight: 600;
        }
      }

      .buttonAjouterCollaborateurProjet:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .buttonAjouterCollaborateurProjet {
        margin-top: 30px;
        margin-left: 15px;
        background-color: #D2E7F8;
        color: #4B5FB0;
        border-radius: 7px;
        font-weight: 600;
        width: 230px;
        height: 25px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s ease-in-out;

      }
    }

    .enregistre {

      display: flex;
      position: absolute;
      margin-left: calc(50vw - 260px);
      margin-top: calc(50vh - 130px);
      background-color: #D2E7F8;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      transition: opacity 0.5s;

      .checkBlanc {
        width: 40px;
        object-fit: contain;
      }

      .textEnregistre {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.6px;
        margin-bottom: 0px;
      }
    }

    .title {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .imageWidget {
      width: 200px;
      object-fit: contain;
    }

    .designant {
      margin-top: 10px;
      font-size: 27px;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .information {
      font-size: 13px;
      color: #8b8b8b;
      font-weight: 500;
    }

    .longBar {
      width: calc(100vw - 215px);
      margin-left: 0px;
      height: 1px;
      margin-top: 20px;
      background-color: #eeeeee;
    }

    .rechercheIntervenant {
      border: solid 0px #eeeeee;
      background-color: #fff;
      padding-left: 30px;
      margin-top: 8px;
      padding-top: 2px;
      font-weight: 500;
      padding-bottom: 2px;
      border-radius: 60px;
      outline: none;
      width: 170px;
      font-size: 13px;
      height: 20px;
    }

    .searchIconRechercheIntervenant {
      margin-top: 10px;
      margin-left: -206px;
      height: 22px;
      object-fit: contain;
      margin-right: 180px;
    }

    .tagSelect:hover {
      opacity: 0.7;
    }

    .tagSelect {
      cursor: pointer;
      margin-top: 15px;
      padding: 5px 10px;
      font-size: 13px;
      color: #FFFFFF;
      border-radius: 20px;
      margin-right: 10px;
      height: 17px;
      transition: opacity 0.2s ease-in-out;
    }

    .buttonPage:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .buttonPage {
      margin-right: 22px;
      font-size: 13px;
      font-weight: 600;
      transition: all 0.2s ease-in-out;
    }

    .barBleue {
      margin-top: -1.5px;
      height: 3px;
      border-radius: 20px;
      background-color: #4B5FB0;
      margin-bottom: 20px;
      transition: all 0.4s ease-in-out;
    }

    .photoProfil {
      margin-top: 13px;
      width: 50px;
      height: 50px;
      margin-left: 15px;
      border-radius: 100px;
      background-color: #F8F8F8;
    }

    .designantInput {
      margin-top: 30px;
      font-size: 13px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 13px;
      opacity: 0.7;
      width: 310px;
      margin-bottom: 24px;
    }

    .choiceAcces {
      margin-top: 10px;
      width: 45px;
      height: 25px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      .buttonChoice:hover {
        opacity: 0.9;
        cursor: pointer;
      }

      .buttonChoice {
        width: 21px;
        height: 21px;
        border-radius: 99px;
        background-color: #ffff;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
        transition: all 0.3s;
        -webkit-transition: all 0.3s;

      }
    }

    .statutProjetActuel:hover {
      opacity: 0.8;
    }

    .statutProjetActuel {
      margin-left: 20px;
      padding: 1px 85px;
      background-color: #4867EC;
      border: solid 1.5px #4867EC;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 30px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      p {
        font-size: 13px;
        font-weight: 600;
        color: #FFFFFF;
        margin: 0;
      }
    }

    .statutProjetNonChoisi:hover {
      opacity: 0.8;
    }

    .statutProjetNonChoisi {
      margin-left: 20px;
      padding: 1px 85px;
      background-color: #FFFFFF;
      border: solid 1.5px #4867EC;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 30px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      p {
        font-size: 13px;
        font-weight: 600;
        color: #4867EC;
        margin: 0;
      }
    }

    .widgetProjet {
      margin-left: 185px;
      width: 540px;
      height: 130px;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      border-radius: 15px;
      display: flex;
      align-items: center;

      .photoProjet:hover {
        opacity: 0.8;
      }

      .photoProjet {
        margin-top: 3px;
        height: 130px;
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
        width: 130px;
        object-fit: cover;
        transition: opacity 0.3s;
        cursor: pointer;
      }

      .nomProjet {
        margin-top: 0px;
        margin-left: 15px;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .bar {
        margin-left: 15px;
        width: 380px;
        height: 2px;
        background-color: #F8F8F8;
        border-radius: 20px;
        margin-bottom: 10px;
      }

      .percentage {
        margin-top: -20px;
        margin-left: -35px;
        font-size: 13px;
        font-weight: 600;
      }

      .backBar {
        width: 380px;
        margin-left: 15px;
        height: 6px;
        background-color: #F8F8F8;
        border-radius: 20px;
        margin-bottom: 13px;

        .frontBar {
          height: 6px;
          border-radius: 20px;
        }
      }
    }

    input {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      width: 520px;
      height: 35px;
      border-radius: 10px;
      border: solid 1px #eaeaea;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      font-size: 13px;
      font-weight: 500;
    }

    .supprimerButton:hover {
      opacity: 0.8;
    }

    .supprimerButton {
      margin-top: 30px;
      width: 200px;
      padding: 10px 8px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: #F8F8F8;
      margin-bottom: 30px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;

      .textSupprimer {
        font-weight: 600;
        color: #f30000;
        margin: 0px;
        font-size: 13px;

      }
    }

    .cancelButton {
      margin-left: -218px;
      margin-right: 10px;
      height: 31px;
      margin-top: -40px;
      padding: 6px 15px;
      border-radius: 8px;
      border: solid 1.5px #4867EC;
      background-color: #FFFFFF;

      p {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.6px;
        margin: 0;
        color: #4867EC;
      }
    }

    .enregistrerButton {
      margin-top: -40px;
      padding: 6px 15px;
      height: 31px;
      background-color: #4867EC;
      border: solid 2px #4867EC;
      border-radius: 8px;

      p {
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        color: #FFFFFF;
        letter-spacing: 0.6px;
      }
    }

    .enregistrerButton:disabled {
      opacity: 0.3;
    }

    .enregistrerButton:hover:disabled {
      opacity: 0.3;
    }
  }

  .innerParametresProjet::-webkit-scrollbar {
    display: none;
  }

}

.modalStatut {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 400px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 450px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .subtitleModal {
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .barSubtitle {
    margin-left: 20px;
    height: 3px;
    border-radius: 20px;
    background-color: $color-1;
  }

  .choiceOptions {
    margin-right: 15px;
    width: 18px;
    object-fit: contain;
  }

  .textOption {
    font-size: 14px;
    font-weight: 400;
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      .textJeConfirme {
        transform: scale(0);
      }
    }
    .choice {
      transform: translateX(-180px);
    }
  }

  .jeConfirmeButton {
    width: 400px;
    height: 33px;
    border-radius: 10px;
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 90px;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}

.modalConfirmationSuppressionProjet {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 400px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 320px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .titleModal {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .inputModifNomProjet {
    margin-top: 40px;
    width: 380px;
    height: 35px;
    margin-bottom: 25px;
    border: solid 1px rgba(159, 159, 159, 0.42);
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-left: -10px;
    outline: none;
    font-size: 13px;
    padding-left: 10px;
  }

  .subtitleModal {
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .barSubtitle {
    margin-left: 20px;
    height: 3px;
    border-radius: 20px;
    background-color: $color-1;
  }

  .choiceOptions {
    margin-right: 15px;
    width: 18px;
    object-fit: contain;
  }

  .textOption {
    font-size: 14px;
    font-weight: 400;
  }

  .buttonGroup:hover {
    cursor: pointer;
    .jeConfirmeButton {
      .textJeConfirme {
        transform: scale(0);
      }
    }
    .choice {
      transform: translateX(-180px);
    }
  }

  .jeConfirmeButton {
    width: 400px;
    height: 33px;
    border-radius: 10px;
    background-color: $color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 90px;

    .textJeConfirme {
      font-size: 13px;
      font-weight: 500;
      color: #FFFFFF;
      transition: all ease-in-out 250ms;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}
