.printPagePhotos {
	.statusBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 99px;
		padding: 5px 10px;
		background-color: rgba(28, 87, 221, 0.1);
		color: #1c57dd;
		font-weight: 600;
		font-size: 14px;

		.ticketNameWebkit {
			display: flex;
			white-space: nowrap;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.mappinBlue {
			width: 12px;
			object-fit: contain;
			margin-right: 5px;
		}
	}

	.containerPictures {
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		height: 1060px;
		margin-top: 30px;
		flex-wrap: wrap;

		.row {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
			gap: 20px;
		}

		.containerPicture {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: calc(50% - 10px);
			height: calc(50% - 10px);
			max-height: 500px;

			.hashtag {
				font-weight: 600;
				margin-bottom: 10px;
				display: flex;
				margin-top: -29px;
			}

			.picture {
				width: 100%;
				max-height: 85%;
				display: flex;
				object-fit: contain;
			}

			.legend {
				margin-top: 20px;
				font-weight: 600;
			}

			.corpsLegend {
				margin-top: 0px;
				font-size: 14px;
			}
		}
	}
}
