.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	position: relative;
	width: 100vw;
	max-height: 100vh;
	background-color: #fff;
	border-radius: 8px;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: scroll;
}

.buttonPrint {
	border: solid 1px #e0e0e0;
	background-color: #eeeeee;
	display: flex;
	align-items: center;
	padding: 0px 10px;
	border-radius: 5px;
	gap: 10px;
	font-weight: 600;
	max-height: 33px;
	font-size: 14px;

	img {
		width: 15px;
	}
}

.crossIcon {
	width: 25px;
	object-fit: contain;
}

.largeBar {
	height: 1px;
	background-color: #eeeeee;
	display: flex;
	width: 210mm;
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0 10mm;
}

.printSnagReportPage {
	width: 210mm;
	margin: 0 auto;
}

.containerPage {
	.pdfPage {
		width: 210mm;
		height: 297mm; // Hauteur A4
		background: white;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		padding: 10mm;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border: solid 1px #eeeeee;

		// Styles pour l'impression
		@media print {
			box-shadow: none;
			margin: 0;
			padding: 10mm;
			border: solid 0px #eeeeee;

			.divider {
				display: none;
			}
		}

		.titleReport {
			font-weight: 700;
			font-size: 40px;
			margin-bottom: 10px;
		}

		.reportDate {
			font-weight: 600;
		}

		.snagNumber {
			margin-top: -20px;
			font-weight: 600;
			width: 200px;
		}
	}

	.numberSnags {
		margin-top: -122px;
		text-align: center;
		font-weight: 700;
		font-size: 25px;
		color: #0c0c0c;
	}

	.snagPage {
	}
}

.containerStakeholders {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-height: 470px;
	margin-top: 30px;
	border: solid 1px #eeeeee;
	border-radius: 10px;
	padding: 20px;
	gap: 15px;
	margin-bottom: 30px;

	.itemCompany {
		display: flex;
		align-items: center;
		font-weight: 600;

		.containerBuildingIcon {
			background-color: rgba(28, 87, 221, 0.1);
			width: 25px;
			height: 25px;
			border-radius: 99px;
			margin-right: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			.buildingIcon {
				width: 13px;
			}
		}

		.companyName {
			display: inline-block; /* ou block selon votre besoin */
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.chartNumbers {
	display: flex;
	gap: 10px;

	.containerChartNumber {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		font-size: 0.875rem;
		font-weight: 500;

		p {
			margin: 0;
          font-weight: 700;
		}

		.chartLargeBar {
			width: 100%;
			height: 20px;
			border-radius: 4px;
			transition: width 0.3s ease-in-out;
		}
	}
}

.agencyLogo {
	height: 70px;
}
.projectPicture {
	max-width: 100%;
	margin-top: 0px;
	height: 300px;
	object-fit: cover;
	border-radius: 15px;
}
