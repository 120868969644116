
.modalBienvenue {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 620px)/2);
  margin-left: calc((100vw - 750px)/2);
  width: 750px;
  height: 620px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow: scroll;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .imagePart {
    height: 348px;
    display: flex;
    justify-content: center;

    .imageFonct {
      width: 690px;
      margin-top: 30px;
      height: 318px;
      object-fit: cover;
      border-radius: 20px;
    }

    .step2Bienvenue {
      height: 318px;
      margin-top: 30px;
      object-fit: contain;
      border-radius: 20px;
    }

    .step3Bienvenue {
      height: 318px;
      margin-top: 30px;
      object-fit: contain;
      border-radius: 20px;
    }
  }

  .textPart {
    width: 780px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;

    .title {
      margin-top: 29px;
      font-weight: 700;
      font-size: 19px;
    }

    .subtitle {
      margin-top: 2px;
      opacity: 0.7;
      font-size: 14px;
      text-align: center;
      width: 490px;
    }
  }

  .buttonPart {
    width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    .points {
      margin-top: 10px;
      display: flex;
      .point {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background-color: #E0E0E0;
        transition: background-color ease-in-out 0.2s;
        cursor: pointer;
      }
    }

    .button {
      width: 309px;
      height: 40px;
      background-color: #4867EC;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 600;
      margin-top: 37px;
    }
  }

  .templateModalBienvenue {
    width: 750px;
    object-fit: contain;
    position: absolute;
    opacity: 0.5;
  }
}

.modalEntrerModeDemo {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 610px)/2);
  margin-left: calc((100vw - 1100px)/2);
  width: 1100px;
  height: 610px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: scroll;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    font-weight: 600;
    font-size: 33px;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
  }

  .bigWidget {
    height: 285px;
    width: 480px;
    border-radius: 15px;
    margin-right: 40px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    padding-top: 0.1px;

    .title {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #4B5FB0;
    }

    .widgetDemoModal {
      margin-top: 25px;
      margin-left: 20px;
      width: 160px;
      object-fit: cover;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      border-radius: 10px;
    }

    .textWidget2 {
      margin-top: 27px;
      padding-left: 30px;
      width: 220px;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .littleWidget {
    height: 145px;
    margin-bottom: 40px;
    width: 480px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    padding-top: 15px;

    .title {
      margin-top: 0px;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #4B5FB0;
    }

    .textWidget2 {
      padding-left: 20px;
      width: 280px;
      font-size: 15px;
      line-height: 22px;
    }

    .headIphone {
      margin-left: -30px;
      margin-top: 17px;
      width: 180px;
    }

    .modeDemoButton {
      margin-top: 30px;
      margin-left: -22px;
      width: 160px;
      height: 45px;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  .buttonEntrerModeDemo:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .buttonEntrerModeDemo {
    margin-top: 30px;
    margin-left: 30px;
    width: 420px;
    height: 35px;
    background-color: #D2E7F8;
    color: #4B5FB0;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
  }

  .buttonDemarrerConfiguration:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .buttonDemarrerConfiguration {
    width: 400px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: #D2E7F8;
    color: #4B5FB0;
    font-size: 14px;
    font-weight: 600;
    transition: opacity 0.2s ease-in-out;
  }

}

.modalDemarrerIntegration {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 620px)/2);
  margin-left: calc((100vw - 1100px)/2);
  width: 1100px;
  height: 620px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: scroll;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
  .title {
    font-weight: 600;
    font-size: 33px;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-left: 100px;
    margin-right: 100px;
    text-align: center;
  }

  .bigWidget {
    height: 285px;
    width: 480px;
    border-radius: 15px;
    margin-right: 40px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    padding-top: 0.1px;

    .title {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #4B5FB0;
    }

    .widgetDemo {
      margin-top: 25px;
      margin-left: 20px;
      width: 160px;
      object-fit: contain;
      box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
      border-radius: 10px;
    }

    .textWidget2 {
      margin-top: 27px;
      padding-left: 30px;
      width: 220px;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .littleWidget {
    height: 145px;
    margin-bottom: 40px;
    width: 480px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    padding-top: 15px;

    .title {
      margin-top: 0px;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #4B5FB0;
    }

    .textWidget2 {
      padding-left: 20px;
      width: 280px;
      font-size: 15px;
      line-height: 22px;
    }

    .headIphone {
      margin-left: -30px;
      margin-top: 17px;
      width: 180px;
    }

    .modeDemoButton {
      margin-top: 30px;
      margin-left: -22px;
      width: 160px;
      height: 45px;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  .buttonEntrerModeDemo:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .buttonEntrerModeDemo {
    margin-top: 30px;
    margin-left: 30px;
    width: 420px;
    height: 35px;
    background-color: #D2E7F8;
    color: #4B5FB0;
    border-radius: 7px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
  }
}

.widgetChoixAbonnement {
  height: auto;
  border-radius: 10px;
  background-color: #D2E7F8;
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 0;
  right: 0;
  margin-bottom: 25px;
  margin-right: 85px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);


  .littleTitle {
    margin: 0;
    color: #3E51A9;
    font-size: 13px;
    font-weight: 600;
    margin-top: 11px;
    margin-left: 15px;
    margin-right: 10px;
  }

  .triangle {
    width: 11px;
    object-fit: contain;
    margin-top: 15px;
  }

  .triangleReturn {
    width: 11px;
    object-fit: contain;
    margin-left: 10px;
    margin-top: 15px;
    transform: rotate(180deg);
  }

  .subtitle {
    margin: 0;
    color: #3E51A9;
    font-size: 14px;
    font-weight: 500;
    width: 299px;
    margin-top: 11px;
    margin-left: 15px;
    transition: all 0.2s ease-in-out;
  }

  .buttonSelectForfait:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .buttonSelectForfait {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4F66E3;
    color: #FFFFFF;
    font-weight: 600;
    margin-top: 25px;
    border-radius: 7px;
    height: 40px;
    font-size: 14px;
    margin-right: 15px;
    transition: opacity 0.2s;
    margin-bottom: 15px;
  }


}

.widgetChoixAbonnementClignotte {
  height: auto;
  border-radius: 10px;
  background-color: rgba(221, 112, 44, 0.13);
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 0;
  right: 0;
  margin-bottom: 25px;
  margin-right: 85px;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);


  .littleTitle {
    margin: 0;
    color: #DD702C;
    font-size: 13px;
    font-weight: 600;
    margin-top: 11px;
    margin-left: 15px;
    margin-right: 10px;

  }

  .triangle {
    width: 11px;
    object-fit: contain;
    margin-top: 15px;
  }

  .triangleReturn {
    width: 11px;
    object-fit: contain;
    margin-left: 10px;
    margin-top: 15px;
    transform: rotate(180deg);
  }

  .subtitle {
    margin: 0;
    color: #DD702C;
    font-size: 14px;
    font-weight: 500;
    width: 299px;
    margin-top: 11px;
    margin-left: 15px;
    transition: all 0.2s ease-in-out;
  }

  .buttonSelectForfait:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .buttonSelectForfait {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DD702C;
    color: #FFFFFF;
    font-weight: 600;
    margin-top: 25px;
    border-radius: 7px;
    height: 40px;
    font-size: 14px;
    margin-right: 15px;
    transition: opacity 0.2s;
    margin-bottom: 15px;

  }


}

.modalPasAccesFonct {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 560px) / 2);
  margin-left: calc((100vw - 590px) / 2);
  width: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .nomAgence {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 10px;
  }

  .adresseAgence {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
  }
}

.modalPartenaire {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 423px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .logoAgence {
    width: 270px;
    object-fit: contain;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    border-radius: 10px;
    margin-top: 20px;
  }

  .nomAgence {
    margin-top: 30px;
    font-size: 30px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 10px;
  }

  .adresseAgence {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .designantInput {
    margin-left: 5px;
    margin-right: -22px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 27px;
  }

  .subtitleInput {
    margin-left: 5px;
    margin-top: -10px;
    font-size: 14px;
    opacity: 0.7;
    margin-right: 20px;
  }

  .littleInput {
    width: 250px;
    font-size: 13px;
    font-weight: 500;
    height: 25px;
    padding-left: 7px;
    outline: none;
    border: solid 1.5px #eeeeee;
    border-radius: 8px;
  }

  .bigInput {
    width: 536px;
    font-size: 13px;
    font-weight: 500;
    height: 25px;
    padding-left: 7px;
    outline: none;
    border: solid 1.5px #eeeeee;
    border-radius: 8px;
  }

  .enregistrer:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .enregistrer {
    width: 263px;
    height: 37px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D2E7F8;
    color: #4B5FB0;
    font-size: 13px;
    font-weight: 600;
    transition: opacity 0.2s ease-in-out;
    margin-bottom: 23px;
  }

  .enregistrerGris {
    width: 263px;
    height: 37px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 23px;
  }

  .supprimer {
    width: 263px;
    height: 37px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(180, 17, 17, 0.11);
    color: #b41111;
    font-size: 13px;
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 23px;
  }
}

.modauxAjoutIntervenantsProjets {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 650px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 650px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .title {
    font-size: 24px;
    margin-left: 28px;
    font-weight: 600;
    color: #4B5FB0;
    margin-bottom: 0px;
  }

  .subtitle {
    margin-top: 8px;
    margin-left: 28px;
    font-size: 15px;
    margin-bottom: 30px;
  }

  .listeComptes {
    margin-top: -20px;
    width: 560px;
    height: 522px;
    margin-left: 15px;
    overflow: scroll;

    .infos {
      margin-top: 0px;
      display: flex;
      align-items: center;
      margin-left: 0px;
      flex-direction: row;
      width: 540px;
      height: 80px;

      .avatar {
        width: 51px;
        height: 51px;
        border-radius: 51px;
        margin-right: 14.45px;
        object-fit: cover;
        margin-left: 13px;
      }

      .avatarBleu {
        width: 51px;
        height: 51px;
        border-radius: 51px;
        margin-right: 14.45px;
        object-fit: cover;
        margin-left: 13px;
        margin-top: -3px;
        background-color: #7FA3FC;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: 600;
      }

      .name {
        margin-left: 8.5px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 500;
        color: #333333;
      }
    }

    .ajouterActeur:hover {
      opacity: 0.7;
    }

    .ajouterActeur {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -53px;
      margin-left: -26.5px;
      height: 25px;
      width: 85px;
      font-size: 10px;
      font-weight: 600;
      border-radius: 6px;
      background-color: #CCF3CA;
      border: solid 0px;
      color: #146146;
      cursor: pointer;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      opacity: 1;

      p {
        font-size: 12px;
      }
    }

    .supprimerActeur:hover {
      opacity: 0.7;
    }

    .supprimerActeur {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -53px;
      margin-left: -26.5px;
      height: 25px;
      width: 85px;
      font-size: 9.35px;
      font-weight: 600;
      border-radius: 6px;
      background-color: rgba(178, 34, 34, 0.13);
      border: solid 0px;
      color: firebrick;
      cursor: pointer;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      opacity: 1;

      p {
        font-size: 12px;
      }
    }

    .ajouterActeur:disabled {
      background-color: #c7c7c7;
    }

    .ajouterActeur:disabled:hover {
      background-color: #c7c7c7;
    }

    .barActeur {
      border-radius: 14.45px;
      margin-left: 76.5px;
      width: 382.5px;
      height: 2px;
      margin-top: 0px;
      background-color: rgba(227, 227, 227, 0);
      margin-right: 0px;
    }
  }

  .rechercheNulle {
    width: 540px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    .searchIconRecherche {
      width: 30px;
      margin-bottom: 20px;
      object-fit: contain;
    }

    p {
      margin-top: 0px;
      font-size: 13px;
      opacity: 0.6;
      text-align: center;
    }
  }
}

.modalGererAutorisations {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 750px) / 2);
  margin-left: calc((100vw - 800px) / 2);
  width: 800px;
  height: 750px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .imageWidget {
    width: 200px;
    object-fit: contain;
  }

  .designantPage {
    font-weight: 700;
    margin-top: 0px;
    margin-left: 20px;
  }

  .designantIFonct {
    font-size: 14px;
    font-weight: 600;
  }

  .imageWidget {
    width: 150px;
    object-fit: contain;
  }

  .longBar {
    height: 1px;
    background-color: #eeeeee;
  }
}

.conseilsUtilisation {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  background-color: #F1F5FF;
  border: solid 1.5px #E4E8F6;
  border-radius: 10px;

  .petitRond {
    width: 6px;
    height: 6px;
    border-radius: 8px;
    background-color: #B5BDDE;
    margin-left: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }

  .textConseil {
    font-size: 13px;
    margin: 0px;
    opacity: 0.7;
  }
}
