
.imgUpload{

  width: 382.3px;
  height: 458.8px;
  border-radius: 15.3px;
  box-shadow: 0px 0px 5px 4px rgba(51, 51, 51, 0.1);
  padding: 15.3px;

  .container{
    height: 229.4px;
    width: 229.4px;
  }

  .heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .img-holder{
    margin: auto;
    width: 382.3px;
    height: 305.8px;
    border-radius: 20px;
    position: absolute;
  }

  .img{
    width: 382.3px;
    height: 458.8px;
    object-fit: cover;
    border-radius: 25.3px;
    position: absolute;
  }

  #input[type="file"] {
    display: none;
  }

  .label{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .image-upload{
    position: absolute;
    margin-top: 305.8px;
    padding-top: 5px;
    width: 130px;
    height: 23px;
    color: black;
    font-weight: bolder;
    background-color: white;
    text-align: center;
    border-radius: 15.3px;
    cursor: pointer;
  }
}


