.logo {
  display: flex;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%);
  height: 60px;
  width: 200px;
  align-items: center;
  justify-content: center;
  position: absolute;

  img {
    height: 50px;
    width: 100px;
    object-fit: cover;
  }
}
