.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	position: relative;
	width: 100vw;
	max-height: 100vh;
	background-color: #fff;
	border-radius: 8px;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: scroll;
}

.buttonPrint {
	border: solid 1px #e0e0e0;
	background-color: #eeeeee;
	display: flex;
	align-items: center;
	padding: 0px 10px;
	border-radius: 5px;
	gap: 10px;
	font-weight: 600;
	max-height: 33px;
	font-size: 14px;

	img {
		width: 15px;
	}
}

.crossIcon {
	width: 25px;
	object-fit: contain;
}

.largeBar {
	height: 1px;
	background-color: #eeeeee;
	display: flex;
	width: 210mm;
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0 10mm;
}

.containerPage {
	.pdfPage {
		width: 210mm;
		height: 297mm; // Hauteur A4
		background: white;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		padding: 10mm;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border: solid 1px #eeeeee;
	}

	.titleReport {
		font-weight: 700;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.reportDate {
		font-weight: 600;
	}

	.snagNumber {
		margin-top: -20px;
		font-weight: 600;
		width: 200px;
	}

	.largeBar {
		display: flex;
		height: 1px;
		background-color: #eeeeee;
		width: 90%;
	}

	.creator {
		display: flex;
		align-items: center;
		gap: 6px;
		font-size: 13px;
		margin-bottom: -5px;
	}

	.titleGeneralDescription {
		font-weight: 700;
		font-size: 24px;
		margin-top: 0px;
		margin-bottom: 20px;
	}

	.reportContainer {
		width: 100%;
		margin: 0 auto;
		font-size: 15px;
		font-family: Arial, sans-serif;

		.report-header {
			border-bottom: 2px solid #eee;
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		.report-title {
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		.report-metadata {
			display: flex;
			gap: 20px;
			color: #666;
			font-size: 14px;
		}

		.report-section {
			margin-bottom: 30px;
		}

		.section-title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;
			color: #333;
		}

		.description {
			line-height: 1.6;
			white-space: pre-wrap;
		}

		.report-footer {
			margin-top: 40px;
			padding-top: 20px;
			border-top: 2px solid #eee;
			font-size: 14px;
			color: #666;
		}
	}

	.statusBar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 99px;
		padding: 5px 10px;
		background-color: rgba(28, 87, 221, 0.1);
		color: #1c57dd;
		font-weight: 600;
		font-size: 14px;

		.ticketNameWebkit {
			display: flex;
			white-space: nowrap;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.mappinBlue {
			width: 12px;
			object-fit: contain;
			margin-right: 5px;
		}
	}

	.levelPage {
		width: calc(100% - 120px);
		height: calc(100% - 120px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 60px;

		h2 {
			font-weight: 700;
			font-size: 40px;
			margin: 0px;
		}

		.separator {
			width: 50px;
			height: 3px;
			background-color: #000;
			opacity: 0.2;
			margin-top: 20px;
			margin-bottom: 20px;
			border-radius: 99px;
		}

		p {
			font-weight: 500;
			font-size: 16px;
			margin: 0px;
			opacity: 0.8;
			text-align: center;
		}
	}
}
