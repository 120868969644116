.parametresProfil {

  .contenu {
    .inner {
      padding-left: 25px;
      margin-left: 0.5px;
      margin-top: -10px;
      width: calc(100vw - 250px);
      max-height: calc(100vh - 60px);
      overflow-x: scroll;
      display: flex;
      flex-direction: column;

      .enregistre {

        display: flex;
        position: absolute;
        margin-left: calc(50vw - 260px);
        margin-top: calc(50vh - 130px);
        background-color: #D2E7F8;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        transition: opacity 0.5s;

        .checkBlanc {
          width: 40px;
          object-fit: contain;
        }

        .textEnregistre {
          color: #FFFFFF;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 0px;
        }
      }

      .statutProjetActuel:hover {
        opacity: 0.8;
      }

      .statutProjetActuel {
        margin-left: 20px;
        padding: 1px 85px;
        background-color: #4867EC;
        border: solid 1.5px #4867EC;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 30px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        opacity: 1;

        p {
          font-size: 13px;
          font-weight: 600;
          color: #FFFFFF;
          margin: 0;
        }
      }

      .statutProjetNonChoisi:hover {
        opacity: 0.8;
      }

      .statutProjetNonChoisi {
        margin-left: 20px;
        padding: 1px 85px;
        background-color: #FFFFFF;
        border: solid 1.5px #4867EC;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 30px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        opacity: 1;

        p {
          font-size: 13px;
          font-weight: 600;
          color: #4867EC;
          margin: 0;
        }
      }
    }

    .inner::-webkit-scrollbar {
      display: none;
    }

    .banniere {
      width: calc(100vw - 270px);
      border-radius: 15px;
      background-color: #F8F8F8;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      opacity: 1;
      overflow: hidden;

      .title {
        font-size: 16px;
        margin-top: 0px;
        margin-left: 20px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 20px;
        font-weight: 500;
        color: #8c8c8c;
      }
    }

    .title {
      margin-top: 20px;
      font-size: 27px;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .designant {
      font-weight: 500;
      margin-top: -7.6px;
      margin-bottom: -5px;
    }

    .information {
      font-size: 13px;
      color: #8b8b8b;
      font-weight: 400;
    }

    .copier {
      border: 0px;
      background-color: #D2E7F8;
      color: #4867EC;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      height: 25px;
      width: 70px;
      margin-left: 10px;
    }

    #input[type="file"] {
      display: none;
    }

    #inputLogo[type="file"] {
      display: none;
    }

    .label {
      align-self: center;
      margin-left: 15px;
    }

    .profilePicture {
      margin-top: 0px;
      width: 150px;
      height: 150px;
      border-radius: 100px;
      object-fit: cover;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .modifier {
      margin-top: 22.9px;
      margin-left: 15.2px;
      height: 35px;
      width: 80px;
      background-color: white;
      border: 1px solid #8b8b8b;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    .supprimer {
      margin-top: 22.9px;
      margin-left: 15.2px;
      height: 35px;
      width: 80px;
      background-color: white;
      border: 0px solid #8b8b8b;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    button:hover {
      opacity: 0.5;
    }

    .profilePicture:hover {
      opacity: 0.8;
    }

    .longBar {
      width: calc(100vw - 200px);
      margin-left: 0px;
      height: 2px;
      background-color: #F8F8F8;
    }

    .designantInput {
      margin-top: 30px;
      font-size: 13px;
      font-weight: 500;
    }

    .requires {
      display: flex;
      width: 600px;
      flex-wrap: wrap;
      margin-top: 0px;
      margin-left: 120px;

      .require {
        padding: 7px 10px;
        background-color: #F8F8F8;
        border-radius: 30px;
        margin-right: 25px;
        margin-bottom: 10px;

        .textRequire {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }
      }

      .requireValid {
        padding: 7px 10px;
        background-color: #C0FA9277;
        border-radius: 30px;
        margin-right: 25px;
        margin-bottom: 10px;

        .textRequire {
          margin: 0px;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    input {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 13px;
      letter-spacing: 0.5px;
      font-weight: 500;
      color: #41434B;
      width: 520px;
      height: 35px;
      border-radius: 9px;
      border: solid 1px #E2E3E7;
      box-shadow: 0px 0.5px 2px 0px rgba(51, 51, 51, 0.06);
      transition: all 0.2s;
      -webkit-transition: all 0.2s;
    }

    .mettreAJourMotDePasse {
      margin-top: 30px;
      padding: 6px 15px;
      background-color: #D2E7F8;
      border: solid 2px #D2E7F8;
      border-radius: 8px;

      p {
        font-weight: 600;
        font-size: 13px;
        margin: 0;
        color: $color-1;
      }
    }

    .notes {
      padding-top: 10px;
      margin-right: 50px;
      margin-top: 12.2px;
      margin-bottom: 13px;
      border: 1px  solid #e2e2e2;
      border-radius: 5px;
      height: 25px;
      width: 500px;
      padding-left: 7.6px;
      font-size: 13px;
      font-weight: 500;
      color: #868686;
      outline: none;
    }

    .agencePicture {
      object-fit: contain;
      width: 183.5px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .enregistrerButton {
      margin-top: 30px;
      margin-left: -100px;
      padding: 6px 15px;
      background-color: #D2E7F8;
      border: solid 1px #D2E7F8;
      border-radius: 7px;
      height: 30px;

      p {
        font-size: 13px;
        font-weight: 600;
        margin: 0;
        color: $color-1;
      }
    }

    .enregistrerButton:disabled {
      opacity: 0.3;
    }

    .enregistrerButton:hover:disabled {
      opacity: 0.3;
    }
  }

  .modalMotDePasseCreation {
    position: absolute;
    top: 0;
    margin-top: calc((100vh - 300px)/2);
    margin-left: calc((100vw - 460px)/2);
    width: 460px;
    height: 300px;
    border-radius: 20px;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .crossIcon:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .inputModifNomProjet {
      margin-top: 20px;
      width: 380px;
      height: 45px;
      border: solid 1px rgba(159, 159, 159, 0.42);
      background-color: #FFFFFF;
      border-radius: 10px;
      outline: none;
      font-size: 20px;
      padding-left: 20px;
    }

    .enregistrerButton {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 400px;
      height: 40px;
      background-color: $color-1;
      border-radius: 10px;
      color: #FFFFFF;
      font-weight: 500;
      border: solid 0px;
      font-size: 14px;
      font-weight: 500;
    }

    .enregistrerButton:disabled {
      background-color: #F8F8F8;
    }
  }
}

#input[type="file"] {
  display: none;
}

.parametresSecurite {

  .contenu {

    background-color: #ffffff;
    margin-left: 275.2px;
    width: calc(100vw - 313.5px);
    height: calc(100vh - 107px);

    form {
      .title {
        margin-top: 0px;
        font-size: 20px;
        font-weight: 600;
      }

      .subtitle {
        font-size: 15.2px;
        font-weight: 600;
        margin-bottom: -7.6px;
      }

      .designant {
        font-weight: 600;
        margin-top: -7.6px;
        margin-bottom: -5px;
      }

      .information {
        font-size: 13px;
        color: #8b8b8b;
      }

      .bar {
        width: calc(100vw - 313.5px);
        height: 3px;
        background-color: #ececec;
        border-radius: 15.2px;
        margin-bottom: 15.2px;
      }

      .row {
        display: flex;
        flex-direction: row;
      }

      .sms {
        margin-top: 0px;
        font-weight: 600;
        font-size: 18px;
      }

      .phoneNumber {
        margin-top: -7.6px;
        font-weight: 400;
        color: #8b8b8b;

      }

      .mettreAJour {
        position: absolute;
        right: 0;
        margin-right: 50px;
        width: 155px;
        height: 22.9px;
        background-color: #e2e2e2;
        border: 0px solid;
        border-radius: 7.6px;
        color: black;
        font-weight: 600;
        font-size: 14px;
      }

    }

  }

}

.chargement {
  object-fit: contain;
  width: 20px;
}

.grosChargement {
  object-fit: contain;
  width: 50px;
  margin-left: 300px;
}

@keyframes rotate360 {
  to { transform: rotate(360deg); }
}
.chargement {
  animation: 1s rotate360 infinite linear;
  width: 20px;
}

@keyframes rotate360 {
  to { transform: rotate(360deg); }
}
.grosChargement { animation: 1s rotate360 infinite linear; }

.barParametres {
  width: calc(100vw - 313.5px);
  height: 2px;
  border-radius: 15.2px;
  background-color: #efefef;
  margin-top: 15.2px;
  margin-bottom: 30.5px;
}

  .contenu {
    .inner {
      padding-left: 5px;
      margin-left: 30px;
      margin-top: 9px;
      width: calc(100vw - 200px);
      background-color: #F6F9FC;
      max-height: calc(100vh - 60px);
      overflow-x: scroll;
      display: flex;
      flex-direction: column;

      .enregistre {

        display: flex;
        position: absolute;
        margin-left: calc(50vw - 260px);
        margin-top: calc(50vh - 130px);
        background-color: rgba(233, 204, 182, 0.54);
        border-radius: 20px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        transition: opacity 0.5s;

        .checkBlanc {
          width: 40px;
          object-fit: contain;
        }

        .textEnregistre {
          color: #FFFFFF;
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 0px;
        }
      }
    }

    .inner::-webkit-scrollbar {
      display: none;
    }

    .title {
      margin-top: 30px;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .banniere {
      width: calc(100vw - 270px);
      border-radius: 15px;
      background-color: #F8F8F8;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      opacity: 1;
      overflow: hidden;

      .title {
        margin-top: 20px;
        font-size: 27px;
        font-weight: 700;
        margin-bottom: 0px;
      }

      .subtitle {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 20px;
        font-weight: 500;
        color: #8c8c8c;
      }
    }

    .designant {
      font-weight: 500;
      margin-top: -7.6px;
      margin-bottom: -5px;
    }

    .longBar {
      width: calc(100vw - 250px);
      margin-left: 0px;
      height: 2px;
      background-color: #F8F8F8;
    }

    .designantInput {
      margin-top: 30px;
      font-size: 13px;
      font-weight: 500;
    }

    input {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      outline: none;
      font-size: 12px;
      width: 520px;
      height: 35px;
      border-radius: 10px;
      border: solid 2px #E2E3E7;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    .information {
      font-size: 13px;
      color: #8b8b8b;
      font-weight: 400;
    }

    .agencePicture {
      object-fit: contain;
      width: 400px;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    #inputLogo[type="file"] {
      display: none;
    }

    .label:hover {
      opacity: 0.8;
    }

    .label {
      margin-bottom: 20px;
      padding: 5px 15px;
      background-color: $color-1;
      color: #FFFFFF;
      font-size: 13px;
      font-weight: 500;
      border-radius: 8px;
      cursor: pointer;
      transition: opacity 0.2s;
      -webkit-transition: opacity 0.2s;
      opacity: 1;
    }

    .cancelButton {
      margin-top: 30px;
      margin-left: -210px;
      margin-right: 10px;
      padding: 6px 15px;
      border-radius: 8px;
      border: solid 1.5px $color-1;
      background-color: #FFFFFF;

      p {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        color: $color-1;
      }
    }

    .enregistrerButton {
      margin-top: 30px;
      padding: 6px 15px;
      background-color: $color-1;
      border: solid 1.5px $color-1;
      border-radius: 8px;

      p {
        font-size: 13px;
        font-weight: 500;
        margin: 0;
        color: #FFFFFF;
      }
    }

    .enregistrerButton:disabled {
      opacity: 0.3;
    }

    .enregistrerButton:hover:disabled {
      opacity: 0.3;
    }
  }

  .copier {
    border: 0px;
    background-color: $color-1;
    color: #FFFFFF;
    border-radius: 8px;
    height: 25px;
    width: 70px;
    margin-left: 10px;
  }


.mentionsLegales {
  padding-left: 5px;
  background-color: #fff;
  margin-left: 0px;
  margin-top: 9px;
  width: calc(100vw - 180px);
  max-height: calc(100vh - 60px);
  overflow-x: scroll;
  display: flex;
  flex-direction: column;

  .inner::-webkit-scrollbar {
    display: none;
  }

  .title {
    margin-top: 0px;
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .designant {
    margin-top: 20px;
    font-weight: 600;
    margin-bottom: -3px;
  }

  .information {
    font-size: 13px;
    color: #8b8b8b;
    font-weight: 500;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  .longBar {
    width: calc(100vw - 210px);
    margin-left: 0px;
    height: 2px;
    background-color: #F8F8F8;
  }

  .designantInput {
    margin-top: 30px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .leftBleu {
    margin-top: 2px;
    width: 11px;
    margin-left: 2px;
    object-fit: contain;
  }

  input {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    font-size: 12px;
    width: 520px;
    height: 35px;
    border-radius: 10px;
    border: solid 2px #E6E6E8;

    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }

  input:hover {
    border: 1px  solid #4F66E3;
  }
}


.modalCodeDoubleAuth {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 430px)/2);
  margin-left: calc((100vw - 570px)/2);
  border-radius: 10px;
  outline: none;
  width: 570px;
  height: 400px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .title {
    margin-top: 60px;
    font-size: 22px;
    font-weight: 600;
  }

  .subtitle {
    width: 450px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  input:focus {
    border-bottom: solid 2px #98a9fa;
  }

  input {
    margin-top: 20px;
    border-bottom: solid 2px #d9d9d9;
    height: 30px;
    width: 200px;
    margin-left: 10px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    outline: none;
    font-size: 20px;
    margin-bottom: 20px;
    letter-spacing: 2px;
    transition: all ease 0.2s;
  }

  .inputCode:focus {
    border-bottom: solid 2px #4F66E3 ;
  }

  .inputCode {
    margin-top: 20px;
    border-bottom: solid 2px #d9d9d9;
    height: 60px;
    width: 300px;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    outline: none;
    font-size: 50px;
    margin-bottom: 50px;
    letter-spacing: 25px;
  }

  .inputCode::-webkit-input-placeholder {
    color: black; /*Change the placeholder color*/
    opacity: 0.2; /*Change the opacity between 0 and 1*/
  }

  .buttonContinuer:hover {
    opacity: 0.7;
  }

  .buttonContinuer {
    border: 0px;
    width: 450px;
    height: 40px;
    border-radius: 5px;
    background-color: #4F66E3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: opacity ease-in-out 0.2s;

  }

  .buttonAnnuler {
    font-size: 14px;
    color: #4F66E3;
    font-weight: 600;
  }
}
