.languageSelector {
    .languageSelectorMenu {
        position: absolute;
        display: none;
        z-index: 100;
        margin-top: -140px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 95px;
        padding-bottom: 10px;
        padding-top: 10px;
        transition: 0.3s ease-in-out;
        background-color: #eeeeee;
        border-bottom: 1px solid #ffffff;
    }

    .menuBottom {
        margin-top: 40px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom: 0;
        border-top: 1px solid #ffffff;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .menuOpened {
        display: flex;
        flex-direction: column;
    }

    .languageSelectorItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        padding: 0 15px 0 10px;
        cursor: pointer;
        width: 50px;
        margin-left: 10px;

        &:hover {
            background-color: #ffffff;
        }
    }

    .languageSelectorPreview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 75px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .previewOpened {
        background-color: #eeeeee;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .previewOpenedBottom {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
