.creerNouvCollaborateur {

  margin-top: 71.1px;
  margin-left: 275.3px;
  font-size: 15.3px;
}

.nouvCollaborateur {


  display: flex;
  flex-direction: row;
  margin-left: 275.3px;

  .infos {

    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 458.8px;

    .head {

      display: flex;
      flex-direction: row;

      h3 {
        margin-top: 15.3px;
        font-size: 17.5px;
      }

      .poste {
        margin-top: 18.3px;
        margin-left: 244.7px;
        width: 110.8px;
        height: 26.7px;
        padding-left: 11.4px;
        color: #000000;
        outline: none;
        font-size: 11.4px;
        font-family: $font-1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        font-weight: bolder;

      }

      .departement {
        margin-top: 18.3px;
        margin-left: 23px;
        width: 110.8px;
        height: 26.7px;
        padding-left: 11.4px;
        color: #000000;
        outline: none;
        font-size: 11.4px;
        font-family: $font-1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        font-weight: bolder;
        -moz-text-align-last: center;
      }

    }

    .ligne1 {

      width: 688.2px;
      border-bottom: 0.5px solid #C4C4C4;
      margin-top: 7.6px;

    }

    .inputs {

      display: flex;
      flex-direction: column;

      .firstInputs {

        display: flex;
        flex-direction: row;

        .inputNom {
          margin-top: 15.3px;
          width: 325px;
          height: 35px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.4px;
        }

        .inputPrenom {
          margin-top: 15.3px;
          margin-left: 15.3px;
          width: 325px;
          height: 26.7px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.4px;
        }

      }

      .secondInputs {

        display: flex;
        flex-direction: row;

        .inputEmail {
          margin-top: 15.3px;
          width: 325px;
          height: 26.7px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.4px;
        }

        .inputTelephone {
          margin-top: 15.3px;
          margin-left: 15.3px;
          width: 325px;
          height: 26.7px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.3px;
        }

      }

      .thirdInputs {

        display: flex;
        flex-direction: row;

        .inputmdp {
          margin-top: 15.3px;
          width: 325px;
          height: 26.7px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.3px;
        }

        .inputVerifmdp {
          margin-top: 15.3px;
          margin-left: 15.3px;
          width: 325px;
          height: 26.7px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 5px;
          padding-left: 12.2px;
          outline: none;
          font-size: 11.3px;
        }

      }

      .ligne2 {
        width: 688.2px;
        border-bottom: 0.5px solid #C4C4C4;
        margin-top: 15.3px;

      }

      .inputNote {
        margin-top: 15.3px;
        width: 676.7px;
        height: 191.1px;
        background-color: #EAEAEA;
        border-width: thin;
        border: 0px;
        border-radius: 5px;
        padding-top: 5px;
        padding-left: 12.2px;
        outline: none;
        font-size: 11.5px;
        font-family: $font-1;
      }

      .bouton {
        margin-top: 22.8px;
        margin-left: 0px;
        width: 137.6px;
        height: 23px;
        border-radius: 15px;
        font-family: $font-1;
        font-size: 10.7px;
        color: #FFFFFF;
        background-color: #6493d0;
        border: #2E507E;
        outline: none;
        font-weight: bolder;
        cursor: pointer;
      }
    }
  }
}


.optionSelect {
  display: flex;
 text-align-last: center;
}


