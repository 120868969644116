.onboardingStep {
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: margin-top ease-in-out 0.8s;


  .checkbox-container {
    margin-top: 20px;
  }
  .checkbox-container div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

  }
  .checkbox-container input[type='checkbox'] {
    margin-right: 10px;
    cursor: pointer;
  }
  .checkbox-container label {
    margin: 0;
    font-weight: 600;
    color: #333;
    font-size: 13px;
    cursor: pointer;
  }

  .consult {
    margin: 0;
    margin-left: 5px;
    font-size: 13px;
    font-weight: 600;
    color: #4E66E4;
  }


  .buttonGoBack {
    height: 30px;
    cursor: pointer;
    font-weight: 600;
  }

  .buttonNext:hover {
    transform: scale(1.04);
    cursor: pointer;
  }

  .buttonNext {
    margin-top: 50px;
    cursor: pointer;
    width: 470px;
    height: 50px;
    background-color: #1C57DD;
    color: white;
    font-weight: 600;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform ease-in-out 0.2s;
  }

  .notIdenticalPassword {
    width: 420px;
    color: red;
    font-size: 13px;
    font-weight: 500;
    margin-top: 0px;
    transition: opacity ease-in-out 0.2s;
    margin-bottom: 0px;
  }

  .listEnterprises {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20Px;
  }

  .canScroll {
    color: #1C57DD;
    font-weight: 600;
    margin-top: -20px;
    font-size: 14px;
  }
  .leftIconScroll {
    margin-top: -32px;
    margin-left: 8px;
    transform: rotate(270deg);
    width: 13px;
    object-fit: contain;
  }
}

.projectToJoin {

  .petitTitre {
    margin-top: -10px;
    font-size: 14px;
    font-weight: 600;
    width: 430px;
  }

  .cardAgencyProject {
    width: 443px;
    max-width: 90vw;
    background-color: #F5F6FA;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .textPart {
      margin-left: 20px;
      font-size: 14px;
      font-weight: 600;

      span {
        color: #4E66E4;
        font-weight: 600;
      }
    }
  }

  .projectCard {
    max-width: 90vw;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
    height: 120px;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
    img {
      width: 135px;
      height: 120px;
      object-fit: cover;
      border-radius: 15px 0px 0px 15px;
    }

    .content {
      margin-top: 38.25px;
      width: 305px;
      margin-left: 3px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .head {
        display: flex;
        flex-direction: column;
        margin-left: 13px;

        .name {
          display: flex;
          position: absolute;
          margin-left: 0px;
          font-size: 14px;
          font-weight: bold;
          margin-top: -28px;
          width: calc((100vw - 600px) / 2);
          min-width: 230px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .clientName {
          color: #4867EC;
          margin-left: 4px;
        }

        .row {
          display: flex;
          flex-direction: row;

          .pourcentage {
            margin-top: -27px;
            margin-bottom: 6.5px;
            margin-left: -30px;
            font-size: 12px;
            font-weight: 600;
            color: #656565;
          }

          .bar {
            margin-top: -2px;
            width: 278px;
            max-width: calc(90vw - 150px);
            height: 2px;
            background-color: #efefef;
            margin-left: 0px;
            border-radius: 15.3px;
            margin-bottom: -17px;
          }
        }
      }

      .progressBars {

        .firstBar {
          width: 278px;
          max-width: calc(90vw - 150px);
          height: 5px;
          margin-top: 12px;
          background-color: #f3f3f3;
          margin-left: 13px;
          border-radius: 15.3px;
        }
        .secondBar {
          height: 5px;
          border-radius: 15.3px;
          transition: width ease-in-out 1.5s;
        }
      }
    }
  }

  .needToBeOnLargerScreen {
    margin-top: 30px;
    width: 80vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-bottom: -20px;

    .containerTitlePoint {
      display: flex;
      align-items: center;

      .number {
        font-size: 14px;
        font-weight: 600;
        background-color: #4E66E4;
        color: #FFFFFF;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 99px;
      }

      .titlePoint {
        color: #4E66E4;
        font-weight: 600;
        margin-left: 10px;
      }
    }

    .containerStores {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      .store {
        width: calc((80vw - 20px) / 2);
      }
    }

    .buttonOpenProject {
      background-color: #4E66E4;
      color: #FFFFFF;
      margin-top: 10px;
      padding: 15px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.choiceList {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  .buttonSalarie {
    margin-top: 10px;
    font-size: 14px;
    color: #4E66E4;
    font-weight: 600;
    width: 420px;
    margin-bottom: -20px;
  }
}


.multiStepLoading {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  height: 400px;
  margin-bottom: -400px;

  .itemMultiStepLoading {
    display: flex;
    align-items: center;
    gap: 10px;

    .icon {
      width: 17px;
      height: 17px;
      object-fit: contain;
    }

    .titleItem {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
