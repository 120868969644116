.modalNewAccount {
  position: absolute;
  top: 0;
  margin-top: 34px;
  margin-left: calc((100vw - 510px) / 2);
  width: 510px;
  height: calc(100vh - 68px);
  min-height: 425px;
  border-radius: 15px;
  background-color: #ffffff;
  outline: none;

  .rechercheParticipants {
    margin-left: 25px;
    border: solid 1.5px #eeeeee;
    background-color: #fff;
    padding-left: 30px;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
    outline: none;
    width: 425px;
    font-size: 13px;
    font-weight: 500;
    height: 23px;
  }

  .searchIcon {
    margin-top: 5px;
    margin-left: -453px;
    height: 22px;
    object-fit: contain;
    margin-right: 230px;
  }

  .enregistrerText {
    position: absolute;
    right: 0;
    margin-top: 19.5px;
    margin-right: 51px;
    font-size: 14.45px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: $color-1;
    cursor: pointer;
  }

  .enregistrerText:hover {
    opacity: 0.5;
  }

  .crossIconModalNewAccount:hover {
    opacity: 0.8;
  }

  .crossIconModalNewAccount {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .typesComptes {
    display: flex;
    flex-direction: row;
    position: absolute;

    .typeCompte1 {
      margin-left: 25.5px;

      button {
        border: solid 0px;
        background-color: rgba(255, 255, 255, 0);
        font-size: 12.75px;
        font-weight: 600;
        color: #4A4A4A;
      }
    }

    .typeCompte2 {
      margin-left: 76.5px;

      button {
        border: solid 0px;
        background-color: rgba(255, 255, 255, 0);
        font-size: 12.75px;
        font-weight: 600;
        color: #4A4A4A;
      }
    }

    .typeCompte3 {
      margin-left: 76.5px;

      button {
        border: solid 0px;
        background-color: rgba(255, 255, 255, 0);
        font-size: 12.75px;
        font-weight: 600;
        color: #4A4A4A;
      }
    }
  }

  .barTypeCompte {
    margin-top: 25px;
    height: 3px;
    background-color: #4B5FB0;
    border-radius: 14.45px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    opacity: 1;
  }

  .typeCompte1:hover {

    button {
      color: #000000;
    }

  }

  .bigBar {
    margin-top: 85px;
    width: 459px;
    height: 3px;
    border-radius: 14.45px;
    background-color: rgba(227, 227, 227, 0);
    margin-left: 25.5px;
  }

  .title {
    margin-left: 25.5px;
    margin-top: 12.75px;
    margin-bottom: 25.5px;
  }

  .littleBar {
    height: 25.5px;
    width: 2px;
    background-color: #dedede;
    border-radius: 14.45px;
    margin-left: 14.45px;
    margin-top: 25.5px;
  }

  .littleAvatar {
    width: 25.5px;
    height: 25.5px;
    border-radius: 51px;
    margin-top: 22.1px;
    margin-right: 0px;
    margin-left: 14.45px;
  }

  .enregistrer {
    margin-left: -18.5px;
    margin-top: 8.5px;
    height: 21.25px;
    width: 76.5px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 6.8px;
    background-color: $color-1;
    border: solid 0px;
    color: #FFFFFF;
    cursor: pointer;
  }

  .enregistrer:hover {
    background-color: #BAE9B6;
  }

  .listeModal {

    .listModalItemDashboard {
      margin-top: -20px;
      padding-top: 20px;
      min-height: 150px;
      width: 510px;
      max-height: calc(100vh - 175px);
      overflow-x: hidden;
      overflow-y: scroll;

      .infos {
        margin-top: 0px;
        display: flex;
        margin-left: 25.5px;
        flex-direction: row;
        width: calc(100vw - 391px);
        min-width: 595px;

        .avatar {
          margin-bottom: 14.45px;
          width: 51px;
          height: 51px;
          border-radius: 51px;
          margin-right: 14.45px;
          object-fit: cover;
          margin-left: 10px;
        }

        .name {
          margin-left: 8.5px;
          margin-top: 18.7px;
          font-size: 13px;
          font-weight: 500;
          color: #333333;
        }
      }

      .ajouterActeur:hover {
        opacity: 0.7;
      }

      .ajouterActeur {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -55px;
        margin-left: -76.5px;
        height: 25px;
        width: 85px;
        font-size: 10px;
        font-weight: 600;
        border-radius: 6px;
        background-color: #CCF3CA;
        border: solid 0px;
        color: #146146;
        cursor: pointer;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        opacity: 1;

        p {
          font-size: 12px;
        }
      }

      .supprimerActeur:hover {
        opacity: 0.7;
      }

      .supprimerActeur {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -55px;
        margin-left: -76.5px;
        height: 25px;
        width: 85px;
        font-size: 9.35px;
        font-weight: 600;
        border-radius: 6px;
        background-color: rgba(178, 34, 34, 0.13);
        border: solid 0px;
        color: firebrick;
        cursor: pointer;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        opacity: 1;

        p {
          font-size: 12px;
        }
      }

      .ajouterActeur:disabled {
        background-color: #c7c7c7;
      }

      .ajouterActeur:disabled:hover {
        background-color: #c7c7c7;
      }

      .barActeur {
        border-radius: 14.45px;
        margin-left: 76.5px;
        width: 382.5px;
        height: 2px;
        margin-top: 0px;
        background-color: rgba(227, 227, 227, 0);
        margin-right: 0px;
      }
    }

    .listModalItemDashboard::-webkit-scrollbar {
      display: none;
    }
  }


}

.modalAgencePartenaire {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 510px) / 2);
  margin-left: calc((100vw - 510px) / 2);
  width: 510px;
  height: 510px;
  min-height: 425px;
  border-radius: 15px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .tagAgencePartenaire {
    margin-top: 30px;
    background-color: #f27b32;
    color: #FFF;
    padding: 7px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
  }

  .title {
    margin-top: 30px;
    font-size: 23px;
    width: 400px;
    text-align: center;
    color: #4F66E3;
    font-weight: 600;
  }

  .corps {
    width: 430px;
    text-align: justify;
    line-height: 22px;
    color: #212d4b;
  }

  .IDAgence {
    margin-top: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: 600;
    background-color: rgba(242, 123, 50, 0.15);
    color: #f27b32;
    margin-bottom: 20px;
  }

  .contact {
    margin-top: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    font-weight: 600;
    background-color: rgba(79, 102, 227, 0.13);
    color: #4F66E3;
  }
}
