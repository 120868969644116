.modalTicket {
	position: relative;
	display: flex;
	width: 1000px;
	height: 700px;
	max-height: 95vh;
	background-color: #fff;
	border-radius: 8px;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-top: 0px;

	.crossIcon {
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.leftPartModalTicket {
		position: relative;
		border-right: solid 1px #e0e0e0;
		height: 100%;
		width: 600px;
		overflow: auto;

		.headerTicket {
			width: 100%;
			height: 90px;
			border-top-left-radius: 8px;
			object-fit: cover;
			padding-top: 15px;
			background-size: cover;
			display: flex;
			flex-direction: column;

			.buttonPrint {
				align-self: flex-end;
				display: flex;
				background-color: #ffffff;
				border-radius: 5px;
				gap: 5px;
				padding: 4px 5px;
				font-weight: 600;
				font-size: 13px;
				margin-right: 14px;
				width: fit-content;
				height: fit-content;

				img {
					width: 14px;
				}
			}

			.print-message {
				align-self: flex-end;
				color: #000;
				font-size: 14px;
				z-index: 1000;
				margin-right: 16px;
				font-weight: 700;
				margin-top: 5px;
			}
		}
		.ticketName {
			margin-top: 15px;
			font-weight: 700;
			font-size: 28px;
			margin-left: 25px;
			width: 565px;
			border: 0;
			margin-bottom: 0px;
			outline: none;
		}

		.dataStatusTicket {
			font-size: 13px;
			color: #6b6b6b;
			align-self: flex-start;
			margin-left: 25px;
			opacity: 0.6;
			font-weight: 600;
			margin-top: 0px;
			margin-bottom: 15px;
		}

		.deleteTicket {
			border: solid 2px #d9d9d9;
			margin-left: 25px;
			border-radius: 5px;
			padding: 5px 10px;
			font-weight: 600;
			font-size: 13px;
			color: #b4b4b4;
			margin-bottom: 10px;
			transition: all ease-in-out 0.2s;
		}

		.deleteTicket:hover {
			color: #ff0000;
			border: solid 2px rgba(255, 0, 0, 0.58);
			cursor: pointer;
			background-color: rgba(255, 0, 0, 0.05);
		}

		.deleteTicket,
		.archived {
			border: solid 2px #d9d9d9;
			margin-left: 25px;
			border-radius: 5px;
			padding: 5px 10px;
			font-weight: 600;
			font-size: 13px;
			color: #b4b4b4;
			margin-bottom: 10px;
			transition: all ease-in-out 0.2s;
		}

		.deleteTicket:hover {
			color: #ff0000;
			border: solid 2px rgba(255, 0, 0, 0.58);
			cursor: pointer;
			background-color: rgba(255, 0, 0, 0.05);
		}

		.archived:hover {
			color: #3ca504;
			border: solid 2px rgba(60, 165, 4, 0.58);
			cursor: pointer;
			background-color: rgba(60, 165, 4, 0.05);
		}
	}

	.rightPartModalTicket {
		width: 400px;
		height: 100%;

		.partName {
			margin-top: 18px;
			font-weight: 700;
			font-size: 22px;
			margin-left: 25px;
			margin-bottom: 0px;
			display: flex;
			align-items: center;
		}

		.pastille {
			color: #ffffff;
			width: 20px;
			height: 20px;
			border-radius: 99px;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
			margin-left: 5px;
		}

		.barModal {
			width: 400px;
			height: 1px;
			background-color: #e0e0e0;
			margin-left: 0px;
			margin-top: 14px;
		}
	}
}

.dropzone-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	height: 100%;
	width: 600px;

	p {
		color: white;
		font-size: 24px;
		text-align: center;
	}
}
