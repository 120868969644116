.progressionHeader {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 30px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  .progressionTitle {
    font-size: 27px;
    font-weight: 700;
    margin: 0 20px 0 0;
    line-height: 27px;
  }

  .progressionButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.progressionHeaderLine {
  flex: 1;
  height: 1px;
  background-color: #eeeeee;
}

.progressionTagsList {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  padding-left: 25px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: scroll;
  gap: 12px;

  .progressionTagsTitle {
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  .progressionTagsVerticalBar {
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;
    width: 1px;
    background-color: #d5d5d5;
  }
}
