.followUp {
	display: flex;
	flex-direction: column;
	height: 596px;
	max-height: calc(100vh - 133px);
	gap: 30px;
	overflow: scroll;
	padding-top: 20px;
	padding-bottom: 25px;

	.bar {
		display: flex;
		width: 5px;
		border-radius: 99px;
		margin-left: 15px;
		margin-right: 15px;
		background-color: #dededeff;

		.frontBarFollowUp {
			display: flex;
			border-radius: 99px;
			width: 5px;
		}
	}

	.buttonInTicket {
		background-color: #1c57dd;
		color: #ffffff;
		font-weight: 600;
		font-size: 13px;
		border-radius: 4px;
		padding: 8px 10px;
		margin-bottom: 8px;
		margin-top: 5px;
	}

	.formulaireSubmitTicket {
		display: flex;
		flex-direction: column;

		.labelInput {
			margin-top: 10px;
			margin-right: 10px;
			font-weight: 600;
			font-size: 14px;
			margin-left: 10px;
		}

		input {
			background-color: #f8f8f8;
			border: solid 0px #f1f1f1;
			border-radius: 5px;
			padding-left: 10px;
			font-size: 14px;
			font-weight: 500;
			outline: none;
			margin-top: 10px;
			height: 30px;
		}

		textarea {
			background-color: #f8f8f8;
			border: solid 0px #f1f1f1;
			padding-top: 7px;
			height: 200px;
			border-radius: 5px;
			margin-bottom: 5px;
			padding-left: 10px;
			font-size: 14px;
			font-weight: 400;
			outline: none;
			margin-top: 10px;
		}

		.buttonAddItems {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 10px;
			margin-top: 5px;
			margin-bottom: 12px;
			background-color: #f8f8f8;
			height: 80px;
			border-radius: 10px;
			font-weight: 600;
			font-size: 14px;
			border: dashed 2px #e0e0e0;
			transition: all ease-in-out 0.2s;
			cursor: pointer;

			img {
				width: 15px;
				margin-right: 10px;
			}

			.dropzone-overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(0, 0, 0, 0.5);
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1000;
				height: 100%;
				width: 100%;
				border-radius: 10px;

				p {
					color: white;
					font-size: 18px;
					text-align: center;
				}
			}
		}

		.buttonAddItems:hover {
			transform: scale(1.02);
		}
	}

	.buttonApproval {
		display: flex;
		gap: 10px;
		margin-top: 10px;
		margin-bottom: 15px;

		.buttonResponse {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			background-color: #3ca504;
			color: #ffffff;
			font-size: 14px;
			font-weight: 600;
			padding: 8px 0px;
			border-radius: 7px;
			border: solid 2px;
		}
	}

	.resolutionSubmitted {
		border: solid 1px #e0e0e0;
		border-radius: 10px;
		padding: 0px 10px;
		padding-top: 5px;
		width: 325px;
		margin-top: 10px;
		margin-bottom: 10px;

		.whoPropos {
			font-size: 14px;

			.boldBlue {
				color: #1c57dd;
				font-weight: 700;
			}
		}
		.date {
			margin-top: 5px;
			font-size: 13px;
			opacity: 0.7;
		}
		.titleResolution {
			margin-top: 10px;
			font-weight: 600;
			font-size: 14px;
			margin-bottom: 0px;
			width: 320px;
		}

		.descriptionResolution {
			margin-top: 10px;
			width: 320px;
			font-size: 14px;
			opacity: 0.7;
		}


	}

	.followUpStep {
		display: flex;
		padding-right: 15px;

		.titleStep {
			font-weight: 600;
			margin-top: 5px;
			margin-bottom: 5px;
			width: 350px;
		}

		.subtitleStep {
			font-size: 13px;
			font-weight: 400;
			margin-top: 0;
			margin-bottom: 5px;
			width: 340px;
		}

		.uploadedFiles {
			display: flex;
			flex-direction: column;
			margin-top: 10px;
		}
	}

	.containerPDF {
		.authorName {
			display: none;
		}
	}

	.validationButtons {
		display: flex;
		gap: 10px;
		margin-top: 15px;
		margin-left: 15px;
		margin-bottom: 10px;

		.buttonApprove,
		.buttonRefuse {
			background-color: #59c425;
			color: #ffffff;
			font-weight: 600;
			font-size: 13px;
			border-radius: 5px;
			padding: 4px 9px;
			border: none;
			cursor: pointer;
			transition: all ease-in-out 0.2s;
		}

		.buttonApprove {
			background-color: #59c425;
		}

		.buttonRefuse {
			background-color: #d92525;
		}
	}
}
