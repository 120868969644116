$width: calc((100vw - 120px)/2);

.blankProjetCard {
  background-color: #eeeeee;
  margin-top: 12.75px;
  margin-left: 16px;
  margin-bottom: 16px;
  height: 136px;
  width: $width;
  min-width: 470px ;
  border-radius: 15px;
}

.projetCardFreeTrial {
  margin: 12.75px 16px 16px;
  display: flex;
  flex-direction: row;
  height: 136px;
  min-width: 650px;
  width: 650px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  background-color: #FFFFFF;
  opacity: 1;

  img {
    width: 162px;
    height: 136px;
    object-fit: cover;
    border-radius: 15px 0px 0px 15px;
  }

  .content {
    margin-top: 38.25px;
    width: 305px;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .head {
      display: flex;
      flex-direction: column;
      margin-left: 13px;

      .name {
        display: flex;
        margin-left: 0px;
        font-size: 14px;
        font-weight: bold;
        margin-top: -28px;
        width: calc((100vw - 600px) / 2);
        min-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .clientName {
        color: #4867EC;
        margin-left: 4px;
      }

      .sharedProject {
        color: #328536;
        margin-left: 0px;
      }

      .lockIcon {
        margin-left: 5px;
        width: 18px;
        object-fit: contain;
        height: 15px;
      }

      .row {
        display: flex;
        flex-direction: row;

        .containerPercentage {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 200px;
          margin-left: -200px;
          justify-content: flex-end;


          .lectureSeule {
            margin-right: 40px;
            margin-bottom: 6.5px;
            margin-top: -37px;
            font-size: 14px;
            font-weight: 600;
            color: #1C57DD;
          }

          .pourcentage {
            margin-top: -37px;
            margin-bottom: 6.5px;
            margin-left: -30px;
            font-size: 12px;
            font-weight: 600;
            color: #656565;
          }
        }



        .bar {
          margin-top: -4px;
          min-width: 272px;
          width: 455px;
          height: 2px;
          background-color: #efefef;
          margin-left: 0px;
          border-radius: 15.3px;
          margin-bottom: -17px;
        }
      }
    }

    .progressBars {

      .firstBar {
        min-width: 272px;
        width: 455px;
        height: 5px;
        margin-top: 15px;
        background-color: #f3f3f3;
        margin-left: 13px;
        border-radius: 15.3px;
      }
      .secondBar {
        height: 5px;
        border-radius: 15.3px;
        transition: width ease-in-out 1.5s;
      }
    }
  }
}

.projetCard {
  margin: 12.75px 16px 16px;
  display: flex;
  flex-direction: row;
  height: 136px;
  min-width: $width;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  background-color: #FFFFFF;
  opacity: 1;

  img {
    width: 162px;
    height: 136px;
    object-fit: cover;
    border-radius: 15px 0px 0px 15px;
  }

  .content {
    margin-top: 38.25px;
    width: 305px;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .head {
      display: flex;
      flex-direction: column;
      margin-left: 13px;

      .name {
        display: flex;
        position: absolute;
        margin-left: 0px;
        font-size: 14px;
        font-weight: bold;
        margin-top: -28px;
        width: calc((100vw - 600px) / 2);
        min-width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .clientName {
        color: #4867EC;
        margin-left: 4px;
      }

      .sharedProject {
        color: #328536;
        margin-left: 0px;
      }

      .lockIcon {
        margin-left: 5px;
        width: 18px;
        object-fit: contain;
        height: 15px;
      }

      .row {
        display: flex;
        flex-direction: row;

        .containerPercentage {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-top: -100x;
          width: 200px;
          margin-left: -200px;
          justify-content: flex-end;


          .lectureSeule {
            margin-right: 40px;
            margin-bottom: 6.5px;
            margin-top: -28px;
            font-size: 13px;
            font-weight: 700;
            color: #1C57DD;
          }

          .pourcentage {
            margin-top: -27px;
            margin-bottom: 6.5px;
            margin-left: -30px;
            font-size: 12px;
            font-weight: 600;
            color: #656565;
          }
        }



        .bar {
          margin-top: -2px;
          min-width: 272px;
          width: calc((100vw - 510px)/2);
          height: 2px;
          background-color: #efefef;
          margin-left: 0px;
          border-radius: 15.3px;
          margin-bottom: -17px;
        }
      }
    }

    .progressBars {

      .firstBar {
        min-width: 272px;
        width: calc((100vw - 510px)/2);
        height: 5px;
        margin-top: 15px;
        background-color: #f3f3f3;
        margin-left: 13px;
        border-radius: 15.3px;
      }
      .secondBar {
        height: 5px;
        border-radius: 15.3px;
        transition: width ease-in-out 1.5s;
      }
    }
  }
}

.projetCard:hover .projetCardFreeTrial:hover {
  opacity: 0.8;
}
.cardRenderFile {
  transition: ease-in-out 0.3s;
}

.cardRenderFile:hover {
  cursor: pointer;
  opacity: 0.7;
}

.notifBadgeProjet {
  margin-left: 10px;
  height: 20px;
  border-radius: 99px;
  background-color: #FF0000;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
}
