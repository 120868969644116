.folderPath {
	display: flex;

	.inner {
		margin: 0px 0 25px 0px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		min-width: 315px;
		border-bottom: 1px solid #eeeeee;
		height: 60px;
		width: calc(100vw - 221px);
		padding-left: 25px;
		padding-top: 3px;
		padding-bottom: 6px;
		overflow: hidden;

		.containerListPath {
			display: flex;
			width: calc(100vw - 565px);
			overflow: hidden;
		}

		.icon {
			opacity: 0.4;
			width: 8px;
			object-fit: contain;
			margin: -5px 8.5px 0 8.5px;
			transform: rotate(180deg);
		}

		.nav,
		.nav-active {
			margin-top: 12px;
			font-weight: bold;
			font-size: 18px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 200px;
		}

		.nav {
			color: #989898;
		}

		.nav-active {
			color: #000000;
		}

		.researchContainer {
			margin-top: 10px;
			height: 40px;
			border: solid 1px #e0e0e0;
			width: 290px;
			display: flex;
			align-items: center;
			overflow: hidden;

			.searchIconResearch {
				width: 13px;
				opacity: 0.4;
				margin-left: 10px;
			}

			input {
				height: 40px;
				border: none;
				font-size: 13px;
				font-weight: 500;
				outline: none;
				padding-left: 7px;
			}
		}
	}

	.inner::-webkit-scrollbar {
		display: none;
	}

	.buttonProgression {
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 5px;
		height: 30px;
		align-items: center;
		gap: 3px;
		margin-top: 17px;
		background-color: #eeeeee;

		.iconReplace {
			object-fit: contain;
			margin-right: 5px;
			max-width: 13px;
		}

		.titleButton {
			display: flex;
			margin: 0;
			font-size: 12px;
			font-weight: 600;
			white-space: nowrap;
		}
	}
}
