.ficheProjet {
  display: flex;
  flex-direction: column;

  .notificationsButton:hover {
    transform: scale(1.07);
    cursor: pointer;
  }

  .notificationsButton {
    background-color: #0057FE;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    width: 47px;
    height: 47px;
    border-radius: 100px;
    margin-bottom: 20px;
    transition: transform ease-in-out 0.2s;

    .notifBadge {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -5px;
      margin-right: -5px;
      height: 20px;
      border-radius: 10px;
      background-color: #FF0000;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 600;
    }

    .wraning {
      background-color: #FFFFFF;
      display: flex;
      position: absolute;
      padding: 6px;
      border-radius: 99px;
      margin-top: -40px;
      margin-right: -40px;
      img  {
        width: 17px;
      }
    }

    .whiteBell {
      width: 24px;
    }
  }

  .notificationContainer {
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
    bottom: 0;
    right: 0;
    margin-bottom: 85px;
    margin-right: 20px;
    width: 418px;
    max-height: 680px;
    height: calc(100vh - 160px);
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.21);
  }

  .notificationContainer {
    /* Style de base du conteneur, y compris invisible initialement */
    visibility: hidden;
    opacity: 1;
    transform: scale(0);
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    display: flex;
    align-items: flex-start;

    .headerContainerNotifs {
      padding-top: 0px;
      width: 370px;
      padding-left: 25px;
      padding-right: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #E5E5E5;

      .title {
        font-size: 22px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .buttonRead:hover {
        cursor: pointer;
        opacity: 0.5;
      }

      .buttonRead {
        text-decoration: underline;
        font-size: 14px;
        opacity: 0.7;
        transition: opacity ease-in-out 0.2s;
      }
    }

    .notificationsList {
      overflow: scroll;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;

      .noNotifLecture {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
        margin-top: 25px;

        .becomeStockholderButton {
          background-color: #1C57DD;
          color: #FFFFFF;
          border: solid 0px;
          padding: 10px 15px;
          font-size: 15px;
          font-weight: 600;
          border-radius: 5px;
          margin-top: 15px;
        }
      }

      .placeholderNotifications {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 418px;

        .ONotifications {
          margin-top: 40px;
          width: 60px;
        }

        .titleNotifications {
            font-size: 25px;
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        .subtitlePlaceholderNoNotif {
          text-align: center;
          padding-left: 20px;
          margin-bottom: 0px;
          padding-right: 20px;
          line-height: 24px;
          opacity: 0.7;
        }
      }
    }


  }

  .animate {
    /* Style quand le widget est visible */
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

.notificationItem, .notificationItemBlue {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;

  .pastille {
    background-color: #1C57DD;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 5px;
  }

  .notificationItemContent {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E9EBED; /* Barre grise en bas de chaque élément */
    width: 378px;
    display: flex;

    .profileComponent {
      background-color: #7FA3FC;
      color: #FFFFFF;
      width: 41px;
      height: 41px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
    }

    .containerButtons {
      display: flex;
      gap: 15px;
      margin-top: 15px;

      .acceptButton {
        background-color: rgba(60, 165, 4, 0.1);
        color: #47b60e;
        border: solid 0px;
        padding: 7px 15px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
      }

      .refuseButton {
        background-color: rgba(243, 0, 0, 0.1);
        color: #f30000;
        border: solid 0px;
        padding: 7px 15px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
      }
    }

    .detailNotif {
      font-size: 15px;
      .blueText {
        color: #4E66E4;
        font-weight: 600;
      }
    }

    .rightPartNotification {
      width: 320px;
      margin-left: 15px;

      .textContentNotification {
        margin-top: 0px;
        font-size: 15px;
      }

      .notifDate {
        font-size: 14px;
        margin: 0;
        margin-top: -11px;
        opacity: 0.5;
        margin-bottom: 12px;
      }
    }

    .buttonItem {
      width: 23px;
      height: 23px;
      border-radius: 30px;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;

      .blackCrossIcon {
        width: 11px;
        height: 11px;
        opacity:0.5;
      }

      .searchIconItem {
        height: 18px;
        margin-left: 10px;
        opacity:0.5;
      }
    }

    .documentItemNotification {
      display: flex;
      font-size: 13px;
      align-items: center;
      font-weight: 600;

      img {
        width: 26px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    .newDocAdded {

      .folderContainer {
        display: flex;
        font-size: 13px;
        align-items: center;
        font-weight: 600;

        img {
          width: 33px;
          object-fit: contain;
          margin-right: 10px;
        }
      }

      .documentsList {
        display: flex;
        align-items: center;

        .blueBar {
          display: flex;
          flex-direction: column;
          width: 5px;
          margin-left: 14px;
          margin-right: 26px;
          background-color: #89D8FA;
          border-radius: 20px;
        }

        .documentsListContent {
          margin-top: 5px;
          display: flex;
          flex-direction: column;
          gap: 1px;
        }
      }
    }

    .blueBar {
      width: 5px;
      height: 50px;
      margin-left: 8px;
      margin-top: 10px;
      border-radius: 20px;
      background-color: #C3CDF8;
      margin-right: 20px;
    }

    .validPhase:hover {
      opacity: 0.7;
    }

    .validPhase {
      display: flex;
      align-items: center;
      font-size: 14px;
      align-items: center;
      font-weight: 600;
      transition: opacity ease-in-out 0.2s;

      .checkBleu {
        margin-right: 8px;
        width: 22px;
        object-fit: contain;
      }
    }

    .percentage {
      font-size: 40px;
      margin: 0;
      font-weight: 100;
    }

    .upBlue {
      width: 18px;
      margin-left: 10px;
    }

    .newAlbumCreated {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      .albumPicture {
        width: 130px;
        height: 130px;
        border-radius: 10px;
        object-fit: cover;
        margin-right: 15px;
      }

      .mosaiquePicture {
        border-radius: 10px;
        overflow: hidden;
        width: 130px;
        max-height: 130px;
        display: flex;
        flex-wrap: wrap;
        margin-right: 15px;
        .pictureMosaique {
          width: 43.3px;
          height: 43.3px;
          object-fit: cover;
        }
      }
    }

    .newVisitCreated {
      background-image: linear-gradient(to bottom, #2D59EA, #629FEF);

    }

    .listPhotos {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .photoList {
        width: 73px;
        object-fit: cover;
        height: 73px;
      }
    }
  }
}

.notificationItemBlue:hover {
  background-color: #FFFFFF;
}

.notificationItemBlue {
  background-color: #F7F9FF;
  transition: background-color ease-in-out 0.2s;
}
