.modalModifGlobalProgression{
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 365px)/2);
  margin-left: calc((100vw - 520px)/2);
  width: 520px;
  height: 260px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .widgetPourcentage{

    .title {
      margin-top: 12.75px;
      margin-left: 18.7px;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0px;
      outline: none;
    }

    .message {
      margin-top: 20px;
      font-size: 14px;
      color: #9d9d9d;
      width: 470px;
      margin-left: 25px;
      text-align: center;
    }

    input {
      margin-top: 20px;
      margin-left: 59px;
      width: 395px;
      height: 29px;
      border: solid 1.5px #eeeeee;
      background-color: #fff;
      text-align: center;
      padding-top: 2px;
      border-radius: 5px;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    input:hover {
      border-color: #eeeeee;
    }
  }

  .jeConfirmeButton {
    width: 400px;
    height: 33px;
    border-radius: 7px;
    background-color: #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 59px;
    margin-top: 10px;
    transition: all ease-in-out 250ms;

    .textJeConfirme {
      font-size: 14px;
      font-weight: 600;
      color: #4867ECFF;
    }
  }

  .choice {
    margin-left: -30px;
    width: 25px;
    object-fit: contain;
    transition: all ease-in-out 250ms;
  }
}
