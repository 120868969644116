.ticketCard {
	display: flex;
	flex-direction: column;
	border: solid 1px #e0e0e0;
	border-radius: 7px;
	padding: 12px;
	transition: all ease-in-out 0.2s;
	cursor: pointer;
	width: 274px;
	gap: 15px;
	margin-bottom: 5px;

	.pastilleOrange {
		margin-top: -18px;
		margin-right: -18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 99px;
		background-color: #fcf0e8;
		color: #ff6900;
		border: solid 2px #ffa261ff;
		font-weight: 700;
		height: 16px;
		width: 16px;
		font-size: 13px;
	}

	.profileOnTicket {
		color: #1c57dd;
		font-weight: 600;
		font-size: 14px;
		margin-bottom: -7px;
	}

	.tagStatus {
		color: #1c57dd;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		padding: 8px 0px;
		border-radius: 5px;
	}

	.containerPastilles {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: -2px;
		gap: 5px;

		.pastilleBlue {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-radius: 99px;
			font-size: 13px;
			background-color: #1c57dd;
			color: #ffffff;
			font-weight: 600;
		}

		.pastilleRed {
			background-color: #ff0000;
		}
	}

	.ticketTagContainer {
		display: flex;
		gap: 8px;

		.ticketTag {
			border: solid 1px #e0e0e0;
			border-radius: 99px;
			padding: 2px 8px;
			font-size: 13px;
		}
	}

	.ticketName {
		font-weight: 600;
		margin: 0;
		line-height: 21px;
	}

	.ticketDescription {
		margin: 0;
		margin-top: -8px;
		font-size: 15px;
		opacity: 0.7;
		font-weight: 400;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-height: 4.5em;
		line-height: 1.5em;
	}

	.locationOnSite {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: flex-start;
		img {
			margin-top: 1px;
			width: 14px;
			opacity: 0.5;
			margin-right: 7px;
			object-fit: contain;
		}
	}

	.numberPhotos {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		width: 24px;
		height: 21px;
		font-size: 14px;
		border-radius: 20px;
		margin-top: 10px;
		margin-bottom: -44px;
		margin-left: 241px;
		font-weight: 700;
		color: #1c57dd;
	}

	.ticketImage {
		width: 274px;
		object-fit: contain;
		border-radius: 6px;
	}

	.containerTiming {
		display: flex;
		gap: 7px;
		.timingItem {
			background-color: #f2f3f4;
			padding: 4px 8px;
			border-radius: 5px;
			font-size: 13px;
			font-weight: 600;
		}

		.timingItemOverdue {
			background-color: #f4e0e9;
			color: #b41111;
		}
	}

	.progressTitle {
		font-size: 14px;
		font-weight: 500;
		margin: 0;
		margin-bottom: 10px;
	}

	.progressContainer {
		display: flex;
		gap: 10px;

		.progressElem {
			background-color: #e8e8e8;
			height: 6px;
			border-radius: 99px;
			width: 275px;
			object-fit: cover;
		}
	}

	.footerTicket {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: -10px;

		.numbers {
			display: flex;
			gap: 5px;

			.numberItem {
				display: flex;
				align-items: center;
				gap: 4px;
				border: solid 1px #e0e0e0;
				font-weight: 600;
				font-size: 13px;
				border-radius: 3px;
				padding: 3px 5px;
			}

			.numberIcon {
				height: 11px;
				width: 11px;
				object-fit: contain;
			}
		}
	}

	&:hover {
		transform: scale(1.02);
	}
}

.ticketCardGallery {
	flex: 1;
	width: 300px;
	border-radius: 10px;
	border: solid 1px #e9e9e9;
	box-shadow: 0px 0px 5px 0px rgba(51, 51, 51, 0.1);
	transition:
		box-shadow,
		filter ease-in-out 0.2s;
	background-color: #fdfdfd;

	.ticketImage {
		width: 300px;
		height: 150px;
		border-radius: 9px 9px 0px 0px;
		object-fit: cover;
		background-color: #eeeeee;
	}

	.ticketName {
		margin: 0;
		font-size: 14px;
		margin-left: 15px;
		margin-top: 8px;
		font-weight: 600;
		margin-bottom: 15px;
	}

	&:hover {
		box-shadow: 0px 0px 5px 0px rgba(51, 51, 51, 0);
		cursor: pointer;
		filter: brightness(95%);
	}
}

.ticketCardTable {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: solid 1px #eeeeee;
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 20px;
	height: 45px;

	.ticketIndex {
		display: flex;
		align-items: center;
		height: 45px;
		width: 25px;
		position: sticky;
		left: -25px;
		background-color: #fff;
		padding-right: 10px;
		margin-right: 5px;
		font-size: 14px;
		font-weight: 600;
		color: #878787;
		padding-left: 10px;
		margin-left: -10px;
		border-top: solid 1px #fff;
		border-bottom: solid 1px #fff;
	}

	.ticketName {
		width: 600px;
		margin: 0;
		font-size: 14px;
		font-weight: 600;
	}

	.tagStatus {
		border-radius: 99px;
		color: #1c57dd;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		padding: 3px 10px;
	}

	.containerTiming {
		display: flex;
		gap: 7px;
		.timingItem {
			background-color: #f2f3f4;
			padding: 4px 8px;
			border-radius: 5px;
			font-size: 13px;
			font-weight: 600;
		}

		.timingItemOverdue {
			background-color: #f4e0e9;
			color: #b41111;
		}
	}

	.progressBar {
		background-color: #e8e8e8;
		height: 6px;
		border-radius: 99px;
		width: 130px;
		object-fit: cover;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&:hover {
		background-color: #f8f8f8;
		cursor: pointer;

		.ticketIndex {
			background-color: #f8f8f8;
			border-top: solid 1px #eeeeee;
			border-bottom: solid 1px #eeeeee;
			z-index: 1;
		}
	}
}

.ticketCardBoard {
	display: flex;
	flex-direction: column;
	border: solid 1px #eeeeee;
	background-color: #ffffff;
	border-radius: 10px;
	gap: 15px;
	padding: 12px;

	.ticketName {
		font-weight: 600;
		margin: 0;
		line-height: 21px;
		font-size: 14px;
	}

	.ticketDescription {
		margin: 0;
		margin-top: -8px;
		font-size: 15px;
		font-weight: 400;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		max-height: 4.5em;
		line-height: 1.5em;
	}

	.locationOnSite {
		font-size: 14px;
		font-weight: 600;
		display: flex;
		align-items: flex-start;
		img {
			margin-top: 1px;
			width: 14px;
			margin-right: 7px;
			object-fit: contain;
		}
	}

	.containerTiming {
		display: flex;
		gap: 7px;
		.timingItem {
			background-color: #f2f3f4;
			padding: 4px 8px;
			border-radius: 5px;
			font-size: 13px;
			font-weight: 600;
		}

		.timingItemOverdue {
			background-color: #f4e0e9;
			color: #b41111;
		}
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.01);
		cursor: pointer;
	}
}
