.pageProjets {

  .projectsDuringFreeTrial {
    background-color: #F6F8FA;
    width: calc(100vw - 154px);
    min-width: 910px;
    border-radius: 10px;
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .leftPartDuringFreeTrial {
      overflow: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 680px;

      .clicToAccess {
        font-weight: 600;
        opacity: 0.7;
        margin-top: 50px;
        margin-bottom: 35px;
        font-size: 14px;
      }
    }

    .rightPartFreeTrial {
      width: 700px;
      align-items: center;
      display: flex;
      flex-direction: column;

      .appFreeTrial {
        max-height: calc(100vh - 750px);
        width: 550px;
        object-fit: contain;
        margin-bottom: 10px;
      }

      .title {
        font-size: 37px;
        font-weight: 700;
        text-align: center;
        letter-spacing: -1.5px;
        margin-bottom: 10px;
      }

      .subtitle {
        width: 600px;
        text-align: center;
        color: #7E7E7E;
        font-size: 14px;
        line-height: 22px;
      }

      .buttonBecomeMember {
        background-color: #44A814;
        color: #FFFFFF;
        margin-top: 15px;
        margin-bottom: 10px;
        padding: 15px 60px;
        font-size: 14px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
      }

      .price {
        font-size: 13px;
        color: #44A814;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 30px;
      }

      .petitesTetes {
        width: 80px;
        object-fit: contain;
      }

      .joinMembers {
        font-size: 13px;
        font-weight: 600;
        color: #7E7E7E;

      }
    }
  }

  @media(min-width: 1475px) {
    .projectsDuringFreeTrial {

      height: calc(100vh - 255px);

      .leftPartDuringFreeTrial {
        height: calc(100vh - 255px);

      }

    }
  }

  @media(max-width: 1475px) {
    .projectsDuringFreeTrial {
      flex-direction: column-reverse;
      overflow: scroll;

      .rightPartFreeTrial {
        padding-top: 20px;

        .appFreeTrial {
          display: none;
        }
      }

      .leftPartDuringFreeTrial {
        margin-bottom: 70px;
        margin-top: -20px;
      }

    }
  }

  .corpsProjets {
    height: calc(100vh - 90px);
    display: flex;

    .liste {
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      .inner {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        overflow-x: hidden;
        margin-left: 2px;
        margin-top: -9px;
        padding-top: 20px;
        max-height: calc(100vh - 77px);

        .bigWidgetSlectionForfait {
          max-width: 1400px;
          border: solid 1px rgba(60, 165, 4, 0.26);
          border-radius: 10px;
          background-color: #ffffff;
          min-width: 950px;
          display: flex;
          align-items: center;
          padding-left: 20px;
          font-size: 14px;
          color: #414141;
          margin-bottom: 25px;
          box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

          .titleWidget {
            color: #000000;
            font-size: 14px;
          }

          .buttonSelectForfait:hover {
            opacity: 0.7;
            cursor: pointer;
          }

          .buttonSelectForfait {
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3CA504;
            color: #FFFFFF;
            font-weight: 600;
            border-radius: 7px;
            height: 40px;
            font-size: 13px;
            margin-right: 15px;
            transition: opacity 0.2s;
            margin-top: 11px;
            margin-bottom: 11px;
            white-space: nowrap;
            padding-left: 18px;
            padding-right: 18px;
          }

          .blueCrossIcon:hover {
            opacity: 0.7;
            cursor: pointer;
          }

          .blueCrossIcon {
            width: 13px;
            margin-right: 20px;
            object-fit: contain;
          }
        }

        .headerProjectList {
          width: 100vw;
          min-width: 960px;
          margin-bottom: 18px;
          margin-top: 5px;

          .titleProjects {
            margin-top: 0px;
            font-size: 27px;
            font-weight: 700;
            margin-bottom: 5px;
            margin-right: 25px;
          }

          .projectWrapper {
            height: 50px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;


            .rechercheProjetEnCour {
              margin-top: -2px;
              border: solid 1px #eeeeee;
              background-color: #f8f8f8;
              padding-left: 29px;
              padding-top: 5px;
              padding-bottom: 6px;
              font-weight: 500;
              border-radius: 60px;
              outline: none;
              width: 155px;
              font-size: 14px;
              height: 20px;
              transition: width ease-in-out 0.2s;
            }

            .searchIconRechercheProjet {
              margin-right: -27px;
              margin-top: 0px;
              height: 20px;
              object-fit: contain;
            }

            .containerChoiceRow {
              height: 32px;
              border: solid 1px #f8f8f8;
              background-color: #f8f8f8;
              border-radius: 99px;
              margin-left: 15px;
              display: flex;
              padding-left: 5px;
              padding-right: 5px;
              transition: transform ease-in-out 0.2s;
            }

            .containerChoiceRow:hover {
              transform: scale(1.02);
            }


            .buttonListProjets {
              font-size: 13px;
              margin-top: -2px;
              height: 35px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-left: 10px;
              padding-right: 10px;
              font-weight: 600;
              transition: opacity 0.2s ease-in-out;
              white-space: nowrap;

              .blueBar {
                display: none;
                width: 100%;
                height: 3px;
                margin-top: 8px;
                margin-bottom: -12px;
                background-color: #4867EC;
                border-radius: 20px;

              }
            }
          }

          .containerNewProjectButtonAndBar {
            display: flex;
            width: 570px;

            .containerNewProjectButton {
              width: 540px;
              margin-right: 44px;
              display: flex;
              justify-content: flex-end;
              gap: 15px;
              white-space: nowrap;

              .markAllAsRead {
                margin-left: 3px;
                font-size: 12px;
                margin-top: 8px;
                font-weight: 600;
                color: #000000;
              }

              .newProjectButton {
                display: flex;
                margin-right: 0;
                background-color: #eeeeee;
                padding: 0px 10px;
                border-radius: 5px;
                height: 30px;

                .plusSquare {
                  margin-left: 0;
                  margin-top: 8px;
                  height: 14px;
                  margin-right: 5px;
                  object-fit: contain;
                }

                p {
                  margin-left: 3px;
                  font-size: 12px;
                  margin-top: 8px;
                  font-weight: 600;
                  color: #000000;
                }
              }
            }
          }
        }

        .placeholderProjetAcheve {
          width: calc(100vw - 100px);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 30px;
          flex-direction: column;

          .integrerProjetVisuel {
            height: 200px;
            margin-bottom: 50px;
          }
        }

        .bar {
          margin-top: 20px;
          width: calc(100vw - 90px);
          height: 1px;
          background-color: #eeeeee;
        }

        .titleInProjects {
          width: calc(100vw - 90px);
          font-weight: 700;
          margin-top: 10px;
        }

        .containerLogoAgency {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;

          .logoAgency {
            width: 300px;
            object-fit: contain;
          }
        }

        .noResultResearchComponent {
          display: flex;
          width: calc(100vw - 100px);
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 50px;
          margin-top: 0px;

          img {
            margin-left: -5px;
            margin-top: 50px;
            width: 35px;
            object-fit: contain;
          }

          div {
            background-color: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin-top: 30px;
            border-radius: 10px;
            margin-bottom: 15px;

            p {
              margin: 0;
            }
          }

          p {
            &.primaryText {
              font-size: 17px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            &.secondaryText {
              margin-top: 0;
              font-size: 15px;
              font-weight: 500;
              color: #737373;
              letter-spacing: 0.6px;
            }
          }
        }
      }

      .inner::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.loadingCreateProject {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .barGrise {

    margin-top: 20px;
    margin-bottom: 20px;
    width: 300px;
    height: 6px;
    border-radius: 20px;
    background-color: #f5f5f5;

    .barBeige {
      width: 100px;
      height: 6px;
      background-color: #4867EC;
      border-radius: 20px;
      transition: all 10s;

    }
  }

}
