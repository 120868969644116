.containerContainerFolder:hover {
  .containerPlusIcon {
    opacity: 0.4;
  }

  .containerPlusIcon:hover {
    opacity: 1;
  }
}

.containerContainerFolder {
  display: flex;
  position: relative;
  flex-direction: column;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  align-content: center;
  align-items: center;

  .dropZone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #4259FE;
    display: flex;
    justify-content: center;
    align-items: center;

    .containerTextDropZone {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 300px;
    }
  }
}

.resizeHandle:hover {
  background-color: #F8F8F8;
}

.resizeHandle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px; /* Largeur de la poignée pour une saisie facile */
  cursor: ew-resize;
  background-color: #FFF;
  border-left: solid 1px #eeeeee;
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 2px;


  .dots {
    width: 3px;
    height: 3px;
    border-radius: 10px;
    background-color: #afafaf;
  }
}

.containerFolder {
  height: calc(100vh - 190px);
  overflow-x: hidden;
  overflow-y: scroll;
  border-right: solid 1px #eeeeee;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  .recentFavorisAccess {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    margin-left: 35px;
    gap: 30px;

    .access:hover {
      opacity: 0.7;
    }

    .access {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: opacity ease-in-out 0.2s;


      .folderIcon {
        width: 100px;
        object-fit: contain;
      }

      .titleAccess {
        font-weight: 600;
        font-size: 14px;
      }
    }


    .accessActive {
      display: flex;
      flex-direction: column;
      align-items: center;


      .folderIcon {
        width: 100px;
        object-fit: contain;
      }

      .titleAccess {
        margin-top: 9px;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 14px;
        background-color: #1C57DD;
        border-radius: 5px;
        padding: 4px 10px;
        color: #FFFFFF;
      }
    }
  }

  .horizontalBar {
    height: 1px;
    margin-bottom: -1px;
    width: calc(100% - 25px);
    margin-left: 25px;
    background-color: #eeeeee;
  }

  .documentListItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .barreGrise {
      height: 1px;
      width: 100%;
      justify-self: flex-end;
    }

    .placeholderFirst {
      margin-top: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 380px;
        object-fit: contain;
        opacity: 0.7;
      }

      .textPlaceholderFirst {
        display: flex;
        width: 320px;
        margin-bottom: 30px;
        margin-top: 10px;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
        font-weight: 500;
        font-size: 14px;
        color: #6b6b6b;

        .bar {
            width: 320px;
            height: 1px;
            background-color: #e5e5e5;
            margin-bottom: 20px;
        }

      }
    }

    .placeholder {
      width: 100%;
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      opacity: 0.5;

      p {
        margin-top: 0px;
        width: 300px;
        text-align: center;
      }
    }

    .accessRecentDeletion, .accessRecentDeletionSelected {
      width: 100%;

      .bar {
        margin-top: 10px;
        margin-bottom: 0px;
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
      }

      .access:hover {
        background-color: #eeeeee;
      }

      .access {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        height: 50px;

        .leftPart {
          display: flex;
          align-items: center;

          .trashIcon {
            width: 16px;
            margin-left: 32px;
            margin-right: 20px;
            opacity: 0.4;
          }

          .text {
            font-weight: 600;
            font-size: 14px;
            margin: 0;
            opacity: 0.5;
          }
        }

        .rightPart {
          margin-right: 25px;
          display: flex;

          .elements {
            font-weight: 600;
            opacity: 0.4;
            font-size: 12px;
          }
          .rightIcon {
            width: 6px;
            margin-left: 10px;
            transform: rotate(180deg);
            opacity: 0.2;
          }
        }
      }
    }

    .accessRecentDeletionSelected {

      .access:hover {
        background-color: #1C57DD;
      }

      .access {
        background-color: #1C57DD;
        color: #FFFFFF;

        .leftPart {
          .trashIcon {
            opacity: 1;
          }
          .text {
            opacity: 1;
          }
        }

        .rightPart {
          .elements {
            opacity: 1;
          }
          .rightIcon {
            opacity: 1;
          }
        }


      }
    }

    .comble {
      width: calc(100% - 10px);
      margin-right: 10px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      opacity: 0.7;
    }
  }

  .filterGris {
    position: absolute;
    width: 400px;
    margin-top: -10px;
    height: calc(100vh - 126px);
    background-color: rgba(0, 0, 0, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity ease-in-out 1s;

    .widgetOptionsCrea {
      width: 250px;
      height: 250px;
      background-color: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);

      .crossIcon {
        position: absolute;
        right: 0;
        margin-right: 78px;
        width: 23px;
        height: 23px;
        margin-top: 3px;
        object-fit: contain;
      }
    }
  }
}

.containerPlusIcon:hover {
  opacity: 1;
}

.containerPlusIcon {
  width: 400px;
  margin-top: -100px;
  height: 100px;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .plusIcon:hover {
    transform: scale(1.3);
    cursor: pointer;
  }

  .plusIcon {
    margin-left: 15px;
    border-radius: 99px;
    border: solid 2px #FFFFFF;
    width: 30px;
    transition: all ease-in-out 0.2s;
  }

  .containerButtons {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 400px;
    justify-content: center;

    .buttonAddDocuments:hover, .buttonFilesOrFolders:hover {
      transform: scale(1.03);
      cursor: pointer;
    }

    .buttonAddDocuments {
      background-color: #4967ED;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      border-radius: 20px;
      font-size: 13px;
      font-weight: 600;
      height: 30px;
      padding-right: 10px;
      overflow: hidden;
      white-space: nowrap;
      transition: transform ease-in-out 0.2s;

      .plus {
        width: 22px;
        margin-left: 4px;
      }
    }

    .whiteSquare {
      margin-top: -18px;
      position: absolute;
      width: 180px;
      background-color: #ffffff;
      height: 20px;
    }

    .filesOrFoldersContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      position: absolute;
      transition: margin-top ease-in-out 0.1s;

      .buttonFilesOrFolders {
        background-color: #4967ED;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
        padding-right: 10px;
        overflow: hidden;
        white-space: nowrap;
        transition: transform ease-in-out 0.2s;

        .whiteFilePlus {
          width: 12px;
          margin-left: 10px;
          margin-right: 5px;
        }

        .whiteFolderPlus {
          width: 15px;
          margin-left: 10px;
          margin-right: 5px;
        }
      }
    }

    .containerImportElements:hover {
      .filesOrFoldersContainer {
        margin-top: -40px;
      }
    }

  }
}




