.settingsProjet {
  width: calc(100vw - 195px);

  .titlePage {
    font-size: 27px;
    font-weight: 700;
    margin-top: 20px;
    white-space: nowrap;
    margin-bottom: 14px;
  }


  .printButton {
    display: flex;
    gap: 7px;
    font-weight: 600;
    font-size: 13px;
    background-color: #eeeeee;
    padding: 7px 10px;
    border-radius: 5px;

    .printer {
      width: 16px;
    }
  }

  .subtitlePage {
    margin-top: 0px;
    font-size: 15px;
    opacity: 0.7;
  }

  .bar {
    margin-top: 20px;
    background-color: #eeeeee;
    height: 1px;
    width: calc(100vw - 267px);
  }

  .menuSettingsProject {
    display: flex;

    .containerButtons {
      display: flex;
      gap: 20px;

      .buttonSetting:hover {
        opacity: 1;
        cursor: pointer;
      }

      .buttonSetting, .buttonSettingSelected {
        font-size: 15px;
        opacity: 0.7;
        transition: all ease-in-out 0.2s;
        font-weight: 600;
        p {
          margin: 0px 5px 11px;
        }

        .blueBar {
          width: 100%;
          border-radius: 20px;
          height: 3px;
        }
      }

      .buttonSettingSelected {

        opacity: 1;

        .blueBar {
          background-color: #1C57DD;
        }
      }
    }

    .bar {
      width: calc(100vw - 265px);
      min-width: 1170px;
      height: 1px;
      margin-top: -2px;
      background-color: #eeeeee;
    }
  }

}
