.modalPartnerPermissions {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 700px;
    height: 650px;
    margin-left: calc((100vw - 700px)/2);
    margin-top: calc((100vh - 650px)/2);
    background-color: #FFFFFF;
    outline: none;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
    z-index: 1000;
}

.overlayModalPartnerPermissions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;

    &.withOverlay {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.modalPartnerPermissions__crossIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 23px;
    height: 23px;
}

.modalPartnerPermissions__container {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;

    h1 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .modalPartnerPermissions__description {
        font-size: 13px;
        font-weight: 500;
        color: #6b6b6b;
        margin: 0;

        .modalPartnerPermissions__description__partner {
            font-weight: 600;
            color: #000000;
        }
    }

    .modalPartnerPermissions__line {
        width: 100%;
        height: 1px;
        background-color: #E0E0E0;
        margin: 20px 0 25px 0;
    }
}

.modalPartnerPermissions__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.modalPartnerPermissions__item {
    height: 120px;
    background-color: #f7f7f7;
    border: 1px solid #E0E0E0;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .modalPartnerPermissions__item__header {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 10px;

        h2 {
            font-size: 18px;
            font-weight: 700;
            margin: 0;
        }

        img {
            width: 35px;
            height: 35px;
        }
    }

    .modalPartnerPermissions__saved {
        font-size: 11px;
        font-weight: 500;
        color: #757575;
        margin: 15px 0 0 0;
    }

    .modalPartnerPermissions__customSelect {
        position: relative;
        width: 100%;
    }

    .modalPartnerPermissions__selectedOption {
        height: 25px;
        border-radius: 8px;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        background-color: white;
        border: 1px solid #E0E0E0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin-left: 15px;
            margin-right: 10px;
            font-size: 13px;
            font-weight: 600;
            color: #000000;
        }

        &.opened {
            border-radius: 10px 10px 0 0;
            border-bottom: 1px solid #E0E0E0;

            img {
                transform: rotate(180deg);
            }
        }

        img {
            margin-right: 10px;
            width: 12px;
            height: 12px;
        }
    }

    .modalPartnerPermissions__options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        border-radius: 0 0 10px 10px;
        border: 1px solid #E0E0E0;
        border-top: none;
        padding: 5px;
        margin: 0;
        list-style-type: none;
        overflow-y: auto;
        z-index: 1;
    }

    .modalPartnerPermissions__option {
        position: relative;
        padding: 8px 10px;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        p {
            font-size: 13px;
            font-weight: 600;
            color: #484848;
            margin: 0;
        }

        &:hover {
            background-color: #F5F5F5;
            
            p {
                color: #000000;
            }
        }

        &:first-child {
            padding-top: 10px;
        }

        &:last-child {
            padding-bottom: 10px;
        }

        .modalPartnerPermissions__optionInfo {
            color: #616161;
            margin: 0;
            margin-top: 3px;
            font-size: 11px;
            font-weight: 400;
        }
    }
}

.modalPartnerPermissions__button {
    width: calc(100% - 60px);
    height: 40px;
    background-color: #f7f7f7;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    cursor: pointer;
    margin: 30px;

    p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
    }
}