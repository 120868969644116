.containerAnnotation {
	width: 400px;
	border-radius: 10px;
	margin-top: 0px;
	margin-bottom: 20px;
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.littleTitle {
		font-weight: 600;
		font-size: 16px;
	}

	.textarea {
		width: 350px;
		border: solid 0px;
		margin-bottom: 15px;
		outline: none;
		padding: 0px;
		font-weight: 500;
		margin-top: 10px;
		font-size: 14px;
	}

	.listAnnotations {
		padding-top: 5px;
		margin-bottom: 20px;
		overflow: scroll;
		width: 100%;

		.placeholderAnnotations {
			text-align: center;
			margin-top: 20px;
			line-height: 25px;
			font-size: 14px;
			opacity: 0.7;
		}
	}

	.annotationItem {
		width: 340px;
		margin-left: 30px;

		.name {
			font-weight: 700;
			color: #1c57dd;
			font-size: 15px;
			margin-bottom: 0px;
			margin-top: 10px;
		}

		.annotationText {
			margin-top: 5px;
			font-size: 14px;
			margin-bottom: 0px;
		}
	}

	.publishAnnotation {
		background-color: #1c57dd;
		color: #ffffff;
		border: none;
		padding: 15px;
		height: 35px;
		margin-top: 5px;
		margin-bottom: 10px;
		justify-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		font-size: 13px;
		font-weight: 600;
	}

	.editAnnotation {
		background-color: #f1f1f1;
		color: #000;
		border: none;
		padding: 15px;
		height: 35px;
		margin-top: 15px;
		margin-bottom: 10px;
		justify-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		font-size: 13px;
		font-weight: 600;

		.bigEditIcon {
			margin-left: 10px;
			width: 16px;
			object-fit: contain;
		}
	}
}
