.ticketProperties {
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 20px;

	.propertyIcon {
		margin-left: 25px;
		width: 16px;
		max-height: 16px;
		opacity: 0.5;
	}

	.designantProperty {
		width: 200px;
		margin-left: 7px;
		font-size: 14px;
		color: #787774;
	}

	.containerPeopleOrCompany {
		margin-left: 5px;
	}

	.containerValueItem,
	.containerValueItemEmpty,
	.containerValueItemFixed {
		font-size: 14px;
		min-height: 33px;
		display: flex;
		align-items: center;
		border-radius: 5px;
		width: 320px;
		flex-wrap: wrap;
		padding: 6px 0px 0px 6px;
	}

	.containerValueItemEmpty {
		padding: 0px 6px;
	}

	.containerValueItemEmpty {
		color: #9a9a97;
	}

	.containerValueItem:hover,
	.containerValueItemEmpty:hover {
		background-color: #f3f3f3;
		cursor: pointer;
	}

	.valueItemissueDate,
	.valueItemdueDate,
	.valueItemexpectingDuration {
		background-color: #eeeeee;
		padding: 4px 5px;
		border-radius: 5px;
		font-weight: 600;
		font-size: 13px;
	}

	.valueItemType {
		border-radius: 5px;
		padding: 3px 7px;
		font-size: 13px;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.typeissue {
		background-color: #f4e0e9;
		color: #4c2337;
	}

	.typemission {
		background-color: #d3e5ef;
		color: #183346;
	}

	.typeprivate {
		background-color: #eaeaea;
		color: #3f3f3f;
	}

	.typecollaborative {
		background-color: #e4f4e0;
		color: #2d4c23;
	}

	.valueItemTags {
		border: solid 1px #e0e0e0;
		border-radius: 99px;
		padding: 2px 8px;
		font-size: 13px;
		margin-right: 10px;
		margin-bottom: 6px;
	}
}
