.greyButton {
  display: flex;
  padding-left: 10px;
  border-radius: 5px;
  height: 30px;
  align-items: center;
  gap: 3px;
  background-color: #eeeeee;
  cursor: pointer;
  transition: background-color ease-in-out 0.2s;

  .icon {
    object-fit: contain;
    margin-right: 5px;
    max-width: 13px;
  }

  .titleButton {
    display: flex;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 10px;
  }
}


.greyButton:hover {
  background-color: #f5f5f5;
}
