.itemEventLog {
  display: flex;

  .obliqueBar {
    display: flex;
    background-color: #eeeeee;
    border-radius: 99px;
    width: 3px;
    margin-left: 25px;
    margin-right: 10px;
  }

  .nameEventLog {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }

  .dateEventLog {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.7;
    margin: 0;
  }

}
