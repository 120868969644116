.progressBarProgression {

  .detailP {

    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 15px;
    color: #6B84A7;
  }

  .firstBarP {

    width: calc(100vw - 140px - 77vw);
    min-width: 305px;
    height: 5px;
    background-color: #F8F8F8;
    border-radius: 20px;
  }

  .secondBarP {

    height: 5px;
    border-radius: 20px;
    background-color: #56759F;
  }
}

.ficheAgenceInfos {
  margin-top: 50px;
  margin-left: 360px;
  display: flex;
  flex-direction: column;

  .header {

    display: flex;
    flex-direction: row;

    .container{
      margin-top: 20px;
      height: 600px;
      width: 500px;

      .img-holder{
        width: 500px;
        height: 400px;
        border-radius: 20px;

      }

      .img{
        width: 500px;
        height: 600px;
        object-fit: cover;
        border-radius: 20px;
      }

      #input[type="file"] {
        display: none;
      }

      .label{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .image-upload{
        position: absolute;
        margin-top: -100px;
        padding-top: 9px;
        width: 170px;
        height: 30px;
        color: black;
        font-weight: bolder;
        background-color: white;
        text-align: center;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .rigthPart {

      .top {

        display: flex;
        flex-direction: row;

        .offreCommerciale {

          margin-top: 20px;
          margin-left: 60px;
          width: 500px;
          height: 240px;
          border-radius: 20px;
          box-shadow: 0px 0px 5px 4px rgba(51, 51, 51, 0.1);

          p {
            margin-left: 20px;
            margin-top: 20px;
            font-size: 18px;
            color: #616161;
          }

          .resultat {

            margin-top: 13px;
            margin-left: 20px;
            font-weight: 100;
            font-size: 45px;

          }

          .projetsFactures {

            margin-top: -20px;
            margin-left: 20px;
            font-weight: 100;
            font-size: 30px;

          }

          .projetsOfferts {

            margin-top: -10px;
            margin-left: 20px;
            font-weight: 100;
            font-size: 30px;

          }

        }

        .descriptionOffre {
          margin-left: 50px;
          padding-top: 9px;
          margin-top: 13px;
          width: calc( 100vw - 1560px);
          min-width: 370px;
          height: 240px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 10px;
          padding-left: 16px;
          outline: none;
          font-size: 14px;

        }
      }
    }
  }





  .projetImportantCard {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 200px;
    margin-right: 60px;


    .card {
      width: 300px;
      height: 140px;
      border-radius: 20px;
    }

    .name {
      margin-top: 20px;
      text-align: center;
      width: 260px;
      height: 30px;
      background-color: #EAEAEA;
      border-width: thin;
      border: 0px;
      border-radius: 9px;
      padding-left: 16px;
      outline: none;
      font-size: 14px;
    }

  }

  .CreateProjetImportantCard {

    display: flex;
    flex-direction: column ;
    align-items: center;
    width: 300px;
    height: 140px;
    border-radius: 20px;

    .container{
      width: 300px;
      height: 140px;
    }

    .heading{
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
    }

    .img-holder{
      width: 300px;
      height: 140px;
      border-radius: 20px;
    }

    .img{
      width: 300px;
      height: 140px;
      object-fit: cover;
      border-radius: 20px;
    }

    #input[type="file"] {
      display: none;
    }

    .label{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .image-upload{
      margin-top: -40px;
      padding-top: 3px;
      width: 150px;
      height: 20px;
      color: black;
      font-weight: bolder;
      background-color: white;
      font-size: 14px;
      text-align: center;
      border-radius: 10px;
      cursor: pointer;
    }

    .name {

      padding-top: 3px;
      padding-bottom: 10px;
      margin-top: 13px;
      width: 260px;
      height: 100px;

      border-top: 3px;
      border-left: 3px;
      border-right: 3px;
      border-color: #4A4A4A;
      outline: none;
      font-size: 14px;
      text-align: center;
    }

  }

  h1 {
    font-size: 37px;
  }

  .firstQuestion {
    margin-top: 50px;
  }

  .question {
    margin-top: 50px;
  }

  p {

    font-weight: bold;
    font-size: 14px;
    color: #8b8b8b;
  }

  .inputInfos {
    padding-top: 9px;
    margin-top: 13px;
    width: calc( 100vw - 435px);
    min-width: 710px;
    height: 100px;
    background-color: #EAEAEA;
    border-width: thin;
    border: 0px;
    border-radius: 9px;
    padding-left: 16px;
    outline: none;
    font-size: 14px;
  }

  .projetsImportantsList {

    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-left: 10px;
    margin-top: 40px;
    width: calc( 100vw - 435px);
    min-width: 710px;
    height: 230px;

  }

  h3 {
    margin-top: 70px;
    font-size: 25px;
  }

  .agenceFiles {

    margin-top: 20px;
    height: 600px;
    width: calc( 100vw - 435px);
    min-width: 710px;
    margin-bottom: 100px;
    border: 3px dashed #D6D6D6;
    border-radius: 20px;

    #input[type="file"] {
      display: none;
    }

    .label{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .image-upload{
      margin-top: 0px;
      padding-top: 9px;
      width: 170px;
      height: 30px;
      color: black;
      font-weight: bolder;
      background-color: #f3a6a6;
      text-align: center;
      border-radius: 20px;
      cursor: pointer;
    }
  }

}

.ficheAgenceProgression {

  margin-top: -10px;
  margin-left: 360px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 37px;
  }

  .informations {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 20px;

    .pourcentage {
      position: absolute;
      margin-left: 740px;
    }
  }

  .firstBar {

    background: linear-gradient(to right, #FD749B, #FC9453);
    border-radius: 8px;
    height: 7px;
    width: 800px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;

    .slider:hover {
      opacity: 1; }
  }

  .communications {

    display: flex;
    flex-direction: row;
    margin-top: 30px;

    .nouvelleCommunication {

      display: flex;
      flex-direction: column;
      width: 577px;
      height: calc( 100vh - 300px);
      border: 4px solid #eeeeee;
      border-radius: 20px;

      .header {

        display: flex;
        flex-direction: row;

        .date {

          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 40px;

          .numero {

            margin-top: 20px;
            margin-bottom: 0px;
            font-size: 60px;
            font-weight: 100;

          }

          .mois {

            margin-top: 0px;
            font-size: 20px;
            font-weight: 400;
            color: #FC9453;

          }
        }

        .inputs {

          display: flex;
          flex-direction: column;
          margin-top: 35px;
          margin-left: 30px;
          width: 440px;

          .input {
            height: 25px;
            margin-bottom: 20px;
            background-color: #EAEAEA;
            border-width: thin;
            border: 0px;
            border-radius: 9px;
            padding-left: 12px;
            outline: none;
            font-size: 14px;
          }
        }
      }

      .inputNote {
        margin-left: 20px;
        width: 510px;
        height: calc( 100vh - 530px);
        background-color: #EAEAEA;
        border-width: thin;
        border: 0px;
        border-radius: 20px;
        padding: 15px;
        font-size: 14px;
        outline: none;

      }

      .submit {

        button{

          margin-left: 220px;
          margin-top: 20px;
          background-image: linear-gradient(to right, #FD749B,#FC9453);
          width: 160px;
          height: 30px;
          border-radius: 20px;
          border-width: thin;
          border: 0px;
          font-weight: 400;
          font-size: 15px;
          color: white;
        }
      }

    }

    .allCommunications {

      margin-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .apercu {

        width: calc( 100vw - 1060px);
        height: 350px;
        border: 4px solid #eeeeee;
        border-radius: 20px;

        .head {

          display: flex;
          flex-direction: row;

          .numero {

            margin-left: 30px;
            margin-top: 20px;
            margin-bottom: 0px;
            font-size: 60px;
            font-weight: 100;
          }

          .mois {

            margin-top: 57px;
            margin-left: 10px;
            font-size: 20px;
            font-weight: 400;
            color: #FC9453;

          }

          .contenu {

            margin-left: 40px;
            margin-top: 60px;

          }
        }

        .inputNote {
          margin-top: 0px;
          margin-left: 20px;
          width: calc( 100vw - 1130px);
          height: 200px;
          background-color: #EAEAEA;
          border-width: thin;
          border: 0px;
          border-radius: 20px;
          padding: 15px;
          font-size: 14px;
          outline: none;
        }
      }

      .list {

        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 300px;
        width: calc( 100vw - 1050px);
        height: calc( 100vh - 650px);
        border-radius: 20px;

        .card {

          width: 220px;
          margin-left: 50px;
          margin-top: 30px;
          height: 100px;
          border-radius: 20px;
          box-shadow: 0px 0px 5px 4px rgba(51, 51, 51, 0.1);

          .leftPart {

            margin-left: 20px;

            .numero {


              margin-top: 15px;
              margin-bottom: 0px;
              font-size: 45px;
              font-weight: 100;

            }

            .mois {

              margin-top: 0px;
              font-size: 16px;
              font-weight: 400;
              color: #FC9453;

            }
          }



        }

      }
    }
  }

}
