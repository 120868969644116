.threeDots {
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	width: 20px;
	justify-content: center;
	align-items: center;
	gap: 3px;
	margin-top: 13px;
	height: 40px;

	.dot {
		width: 5px;
		height: 5px;
		background-color: #000;
		border-radius: 10px;
	}
}

.containerMenuBigPhoto {
	display: flex;
	position: absolute;
	margin-right: 20px;
	margin-top: 18px;
	top: 0;
	right: 0;
	align-items: flex-start;

	.menuBigPhoto {
		background-color: #fff;
		border-radius: 7px;
		transition: opacity 0.3s ease-in-out;
		overflow: hidden;
		box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);

		.crossIconMenu {
			width: 20px;
			object-fit: contain;
			display: flex;
			margin-top: -20px;
		}

		.buttonMenuBigPhoto:hover {
			background-color: #f3f3f3;
		}

		.buttonMenuBigPhoto {
			height: 40px;
			display: flex;
			align-items: center;
			margin-left: 10px;
			padding-left: 10px;
			margin-right: 10px;
			margin-top: 8px;
			background-color: #ffffff;
			font-size: 15px;
			font-weight: 500;
			border-radius: 7px;
			cursor: pointer;
		}
	}
}
