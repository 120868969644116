.containerMenuContextMessagerie {
  display: flex;
  position: absolute;
  flex-direction: column;
  border-radius: 10px;
  color: #000;
  padding: 10px;
  overflow: hidden;

  .menuContextMessagerie {
    transition: all 0.1s ease-in-out;
    width: 200px;
    background-color: #FBFBFB;
    border-radius: 13px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.18);

    .titleMenu {
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 10px;
      margin-left: 15px;
    }

    .crossIcon:hover {
      opacity: 0.8;
    }

    .crossIcon {
      width: 20px;
      object-fit: contain;
      position: absolute;
      right: 0;
      margin-top: 0px;
      margin-right: 18px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }

    .crossIconMess:hover {
      opacity: 0.8;
    }

    .crossIconMess {
      width: 20px;
      object-fit: contain;
      margin-top: 10px;
      margin-bottom: -15px;
      margin-right: 18px;
      margin-left: 170px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }

    .buttonMenu:hover {
      background-color: #eeeeee;
    }

    .buttonMenu {
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      width: 175px;
      margin-right: 10px;
      padding-left: 7px;
      margin-left: 10px;
      border-radius: 7px;
      transition: background-color ease-in-out;
      font-size: 13px;
    }

  }
}
