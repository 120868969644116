.messagerie {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

  .page {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: calc(100vh - 56px);
    margin-top: -10px;

    .title {
      margin-left: 0px;
      font-size: 18px;
      font-weight: 600;
      color: black;
    }

    .placeholderMessagerie {
      margin-top: 250px;
      position: absolute;
      width: calc(100vw - 160px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      .PlaceholderPhotoMessages {
        margin-top: -20px;
        width: 800px;
        object-fit: contain;
        margin-bottom: 20px;
      }

      .title {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 10px;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }

      .subtitle {
        color: #9d9d9d;
        margin: 0;
        transition: all 0.5s;
        line-height: 23px;
        font-size: 16px;
        font-weight: 500;
        -webkit-transition: all 0.5s;
      }

      .button:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      .button {
        padding-top: -1px;
        margin-left: 16px;
        margin-top: 20px;
        width: 210px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background-color: #D2E7F8;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        opacity: 1;

        .textButton {
          color: #3E51A9;
          margin: 0;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }

    .conversations {
      height: calc(100vh - 255px);
      flex-direction: column;
      flex-wrap: wrap;
      width: 372px;
      min-width: 239px;
      transition: margin-top 0.3s ease-in-out;

      .barConv {
        height: 1px;
        background-color: #eeeeee;
        width: 290px;
        margin-left: 85px;
      }

      .headerConversations {
        border-bottom: #eeeeee 1px solid;
        padding-bottom: 10px;
        margin-left: 12px;
        .rechercheMessagerie {
          margin-top: 20px;
          margin-left: 5px;
          border: solid 0px #ffffff;
          background-color: #f8f8f8;
          padding-left: 30px;
          padding-top: 2px;
          padding-bottom: 2px;
          border-radius: 8px;
          outline: none;
          width: 325px;
          font-size: 14px;
          height: 32px;
          margin-right: 0px;
          margin-bottom: 10px;
          font-weight: 500;
        }

        .searchIcon {
          margin-top: 28px;
          margin-left: -350px;
          height: 22px;
          object-fit: contain;
        }

        .itemNewSalon:hover {
          opacity: 0.7;
        }

        .itemNewSalon {
          display: flex;
          align-items: center;
          color: #4967EC;
          font-size: 13px;
          font-weight: 600;
          height: 50px;
          margin-left: 5px;
          margin-right: 5px;
          cursor: pointer;
          transition: opacity ease-in-out 0.2s;

          .containerPeople {
            margin-left: 10px;
            width: 35px;
            height: 35px;
            border-radius: 99px;
            background-color: #f3f3f3;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            img {
              width: 20px;
              object-fit: contain;

            }
          }

          .blueRight {
            width: 8px;
            display: flex;
            position: absolute;
            margin-left: 330px;
            opacity: 0.6;
          }
        }
      }

        .inner {
          padding-top: 4px;
          margin-top: 0px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: calc(100vh - 42px);
          width: 392px;
          overflow-x: scroll;
        }

      .inner::-webkit-scrollbar {
        display: none;
      }
    }

    .box {
      position: absolute;
      width: calc(100vw - 360px);
      min-width: 561px;
      margin-top: 10px;
      height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;

      .head {
        align-items: center;
        justify-content: center;
        min-width: 459px;
        display: flex;
        flex-direction: column;

        .linkInfoConv {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 7.5px;
          padding-right: 8.5px;
          padding-bottom: 0px;
          border-radius: 8.5px;
          cursor: pointer;
          transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
          background-color: #ffffff;

          .imageContactGroup1 {
            width: 28px;
            height: 28px;
            border-radius: 42px;
            background-color: #7FA3FC;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .imageContactGroup2 {
            width: 14px;
            height: 14px;
            border-radius: 42px;
            background-color: #7FA3FC;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1px;
            margin-left: 17px;
          }

          .imageContactGroup3 {
            width: 19px;
            height: 19px;
            border-radius: 42px;
            background-color: #7FA3FC;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -29px;
            margin-left: 28px;
          }
        }

        .linkInfoConv:hover {
          background-color: #F8F8F8;
        }

        .profilePicture {
          margin-top: 6.3px;
          margin-bottom: 6.3px;
          width: 34px;
          height: 34px;
          border-radius: 32px;
          object-fit: cover;
        }

        .profilePictureBleu {
          margin-top: 6.3px;
          margin-bottom: 6.3px;
          width: 34px;
          height: 34px;
          border-radius: 32px;
          object-fit: cover;
          background-color: #7FA3FC;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .destinataire {
          margin-left: 12px;
          margin-top: 15px;
          text-align: center;
          font-weight: 500;
        }

        .bar {
          margin-left: 0px;
          height: 1px;
          border-radius: 13px;
          margin-top: 13px;
          background-color: #eeeeee;
        }
      }

      .messages {
        .inner {
          margin-left: 0px;
          min-width: 500px;
          overflow-y: auto;
          padding-left: 17px;
          padding-right: 17px;
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          overflow-x: hidden;

          .placeholderConversation {
            height: calc(100vh - 0px);
            min-height: 300px;
            display: flex;
            justify-content: center;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;

            .PlaceholderPhotoMessages {
              margin-left: 15px;
              width: 850px;
              object-fit: contain;
            }

            .LockIcon {
              height: 13px;
              object-fit: contain;
              margin-top: 18px;
              opacity: 0.3;
            }

            .textPlaceholder {
              width: 220px;
              font-size: 12px;
              font-weight: 500;
              color: #969696;
            }
          }

          .innerBlank {
            margin-left: -17px;
            width: calc(100vw - 624px);
            min-width: 410px;
            height: calc(100vh - 178px);
            min-height: 340px;
            background-color: #F8F8F8;
            overflow-y: auto;
            padding-left: 17px;
            padding-right: 17px;
            display: flex;
            flex-direction: column;
            border-radius: 17px;
          }

          .bytesTransfered {
            margin-left: 10px;
            border-radius: 17px;
            margin-top: 60px;
            width: calc(100vw - 600px);
            min-width: 412px;
            height: 5px;
            background-color: #F8F8F8;
            display: flex;
            position: absolute;
            top: 0;
          }

          .message {
            display: flex;
          }

          .sent {
            flex-direction: row-reverse;
          }

          p {
            max-width: 324px;
            margin-bottom: 7.5px;
            line-height: 15px;
            padding: 6px 13px;
            border-radius: 10px;
            position: relative;
            color: white;
          }

          .sent p {
            color: white;
            background-color: #e8c0a6;
            align-self: flex-end;
            border-radius: 17px;
          }

          .received p {
            background: #EAEAEA;
            color: black;
            border-radius: 17px;
          }
        }
        .inner::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .sendMessage {
    border-top: solid #eeeeee 1px;
    display: flex;

    .reponseMessage {
      margin-top: 12px;
      display: flex;
      align-items: center;

      .replyIcon {
        height: 20px;
        object-fit: contain;
        margin-top: -20px;
        margin-left: 45px;
        margin-right: 19px;
      }

      .blueBar {
        display: flex;
        width: 3px;
        height: 50px;
        background-color: #4676EE;
        border-radius: 20px;
        margin-right: 20px;
      }

      .repondreText {
        margin-top: 0px;
        font-weight: 600;
        color: #4676EE;
        margin-bottom: 0px;
        font-size: 15px;
      }

      .textToResponse {
        margin-top: 5px;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }

    .camera {
      opacity: 0.3;
      position: absolute;
      bottom: 9px;
      left: 0;
      margin-bottom: 3px;
      background-color: rgba(255, 255, 255, 0);
      font-size: 10px;
      font-weight: 600;
      color: #ffffff;
      padding-top: 0px;
      margin-left: 17px;
      border-width: 0px;
      border-radius: 8px;
      .icon {
        width: 21px;
        height: 21px;
        cursor: pointer;
      }
    }

    .file {
      margin-left: 45px;
      opacity: 0.3;
      position: absolute;
      bottom: 9px;
      left: 0;
      margin-bottom: 3px;
      background-color: rgba(255, 255, 255, 0);
      font-size: 11.4px;
      font-weight: 600;
      color: #ffffff;
      padding-top: 0px;
      border-width: 0px;
      border-radius: 9px;

      .icon {
        width: 21px;
        height: 21px;
        cursor: pointer;
      }
    }

    .icon:hover {
      opacity: 0.3;
    }


    .input-sizer {
      box-shadow: 4px 4px 0px #000;
      > span {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
        text-shadow: 2px 2px 0 rgba(0,0,0,.15);
      }
    }

    .input {
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;
      max-height: calc(100vh - 400px);
      margin-left: 73px;
      padding-left: 13px;
      padding-top: 6px;
      padding-bottom: 0;
      font-weight: 500;
      padding-right: 45px;
      min-width: 427px;
      border: solid 1px #eeeeee;
      color: #252525;
      border-radius: 17px;
      outline: none;
      font-size: 13px;
      background-color: #FFFFFF;
    }

    .send {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 26px;
      margin-bottom: 8.5px;
      background-color: rgba(255, 255, 255, 0);
      color: #ffffff;
      padding-top: 0px;
      margin-left: 7.6px;
      border-width: 0px;
      border-radius: 8px;
    }

    .sendIcon:hover {
      opacity: 0.8;
    }

    .sendIcon {
      width: 26px;
      height: 26px;
      cursor: pointer;
      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      opacity: 1;
    }
  }
}

.conversationCard:hover {
  background-color: rgba(28, 87, 221, 0.06);
}

.conversationCard, .conversationCardActive {

  display: flex;
  flex-direction: row;
  height: 74px;
  padding-left: 20.5px;
  padding-right: 12px;
  width: 360px;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  background-color: #ffffff;
  overflow: hidden;

  .imageContactBleu {
    margin-top: 15px;
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 42px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    margin-right: 1px;
  }

  .imageContactGroup1 {
    width: 33px;
    height: 33px;
    border-radius: 42px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageContactGroup2 {
    width: 20px;
    height: 20px;
    border-radius: 42px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    margin-left: 14px;
  }

  .imageContactGroup3 {
    width: 22px;
    height: 22px;
    border-radius: 42px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -34px;
    margin-left: 32px;
  }

  .infosConversations {
    margin-left: 0px;
    width: 300px;
    background-color: rgba(255, 255, 255, 0);

    .conversationName {
      margin-top: 10px;
      margin-left: 13px;
      font-size: 14px;
      font-weight: 600;
    }

    .lastMessage {
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      width: 260px;
      margin-top: -12px;
      margin-left: 13px;
      font-size: 13px;
      font-weight: 500;
      color: #9a9a9a;

      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limite à deux lignes */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* Ajoute des points de suspension si le texte est tronqué */
      white-space: normal; /* Assure que le texte respecte la limitation des lignes */
    }
  }

  .dateConv {
    margin-top: 10px;
    font-size: 12px;
    opacity: 0.5;
  }
  .pastilleNewMessages {
    background-color: #3772ee;
    border-radius: 99px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-left: -20px;
  }
}

.conversationCardActive {
  background-color: rgba(28, 87, 221, 0.06);
}

.labelEnvoiImage {
  input[type="file"] {
    display: none;
  }
}

.modalInfoConv {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 500px)/2);
  margin-left: calc((100vw - 450px)/2);
  width: 450px;
  border-radius: 15px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .crossIconModalInfoConv:hover {
    opacity: 0.7;
  }

  .crossIconModalInfoConv {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .imageContactGroupModal1 {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .imageContactGroupModal2 {
    width: 96px;
    height: 96px;
    border-radius: 100px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .imageContactGroupModal3 {
    width: 112px;
    height: 112px;
    border-radius: 112px;
    background-color: #7FA3FC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -130px;
    margin-left: 105px;
  }

  .modifNameSalon:hover {
    opacity: 0.7;
  }

  .modifNameSalon {
    display: flex;
    cursor: pointer;
    margin-top: -35px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;
  }

  .modifParticipantsSalon:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .modifParticipantsSalon {
    margin-top: 20px;
    display: flex;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;
  }

  .modifParticipantsSalonClient {
    margin-top: 20px;
    display: flex;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;
  }
}

.OverlayModalInfoConv {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

.modalNouveauSalon {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 225px)/2);
  margin-left: calc((100vw - 450px)/2);
  width: 450px;
  height: 250px;
  border-radius: 15px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .crossIconModalNouveauSalon:hover {
    opacity: 0.8;
  }

  .crossIconModalNouveauSalon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .inputModifNomProjet {
    margin-top: 20px;
    width: 380px;
    height: 35px;
    border: solid 0px rgba(159, 159, 159, 0.42);
    background-color: #F8F8F8;
    border-radius: 5px;
    outline: none;
    font-size: 13px;
    font-weight: 500;
    padding-left: 15px;
  }

  .enregistrerButtonModalNouveauSalon:hover {
    opacity: 0.7;
  }

  .enregistrerButtonModalNouveauSalon {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 400px;
    height: 40px;
    background-color: $color-1;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 500;
    border: solid 0px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .enregistrerButton:hover {
    opacity: 0.7;
  }

}

.OverlayModalNouveauSalon {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.38);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.modalAddAccountSalon {
  position: absolute;
  top: 0;
  margin-top: 34px;
  margin-left: calc((100vw - 510px) / 2);
  width: 510px;
  height: calc(100vh - 68px);
  min-height: 425px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);

  .enregistrerText {
    position: absolute;
    right: 0;
    margin-top: 19.5px;
    margin-right: 51px;
    font-size: 14.45px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: $color-1;
    cursor: pointer;
  }

  .enregistrerText:hover {
    opacity: 0.5;
  }

  .crossIconModalAddAccount:hover {
    opacity: 0.8;
  }

  .crossIconModalAddAccount {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .typeCompte1:hover {

    button {
      color: #000000;
    }
  }

  .listModalItemSalon {
    .inner {
      padding-top: 25px;
      max-height: calc(100vh - 259px);
      overflow-x: scroll;
    }

    .inner::-webkit-scrollbar {
      display: none;
    }

    .goToGroup {
      width: 510px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .buttonGoToGroup {
        background-color: #1C57DD;
        padding: 10px 0px;
        width: 480px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;

      }
    }
  }

}

.overlayModalAddAccountSalon {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(217, 217, 217, 0.7);
}

.compteListItemSalon {

  .infos {
    margin-bottom: 18px;
    display: flex;
    margin-left: 15px;
    flex-direction: row;
    width: calc(100vw - 391px);
    min-width: 595px;
    align-items: center;

    .avatarBleu {
      width: 45px;
      height: 45px;
      border-radius: 51px;
      margin-right: 16px;
      object-fit: cover;
      margin-left: 10px;
      background-color: #7FA3FC;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 16px;
    }

    .name {
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      color: #333333;
    }
  }


  .ajouterActeur:hover {
    opacity: 0.7;
  }

  .ajouterActeur {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -55px;
    margin-left: -60px;
    height: 25px;
    width: 85px;
    font-size: 10px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #CCF3CA;
    border: solid 0px;
    color: #328536;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;

    p {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .supprimerActeur:hover {
    opacity: 0.7;
  }

  .supprimerActeur {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    margin-left: -60px;
    height: 25px;
    width: 85px;
    font-size: 9.35px;
    font-weight: 500;
    border-radius: 6px;
    background-color: rgba(178, 34, 34, 0.13);
    border: solid 0px;
    color: firebrick;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    opacity: 1;

    p {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .ajouterActeur:disabled {
    background-color: #c7c7c7;
  }

  .ajouterActeur:disabled:hover {
    background-color: #c7c7c7;
  }

  .barActeur {
    border-radius: 14.45px;
    margin-left: 76.5px;
    width: 382.5px;
    height: 2px;
    margin-top: 0px;
    background-color: rgba(227, 227, 227, 0);
    margin-right: 0px;
  }
}

.messagePlusDots:hover {
  .dots {
    opacity: 0.5;
  }
}

.messagePlusDots {

  .dots:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .dots {
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
  }
}

.ditributeComponent {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}

.containerBuilding {
  display: flex;
  position: absolute;
  top: 0;
  margin-top: 55px;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 55px);
  font-weight: 600;
  color: #363636;
  line-height: 5px;
  font-size: 14px;
}
