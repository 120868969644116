.ticketsHeader {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	width: calc(100vw - 240px);

	.ticketsTitle {
		font-size: 27px;
		font-weight: 700;
		margin: 0 20px 0 0;
		line-height: 27px;
	}

	.privateBeta {
		color: #d9620b;
		margin-left: -5px;
		background-color: rgba(217, 98, 11, 0.1);
		border-radius: 99px;
		font-weight: 600;
		font-size: 14px;
		padding: 5px 10px;
		white-space: nowrap;
		//border: solid 1px #d9620b ;
	}

	.ticketsButtonContainer {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}
}

.sortFilterCreate {
	display: flex;
	margin-right: 30px;
	gap: 5px;
	align-items: center;
	margin-top: -13px;

	.containerIcon {
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;

		.sortIcon {
			width: 17px;
			object-fit: contain;
		}

		&:hover {
			background-color: #f2f2f2;
			cursor: pointer;
		}

		&:active {
			background-color: #dfdfdf;
		}
	}
}

.ticketsHeaderLine {
	flex: 1;
	height: 1px;
	background-color: #eeeeee;
}

.ticketsTagsList {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 15px;
	justify-content: space-between;
	user-select: none;
	overflow: hidden;
	padding-bottom: 15px;

	.containerTags {
		display: flex;
		align-items: center;
		gap: 6px;
		overflow-x: scroll;
		margin-left: 30px;
		margin-right: 12px;
	}

	.resetFilterButton {
		margin-right: 35px;
		font-size: 14px;
		color: #757575;
		cursor: pointer;
		padding: 4px 8px;
		border-radius: 5px;

		&:hover {
			background-color: #f2f2f2;
		}

		&:active {
			background-color: #dfdfdf;
		}
	}

	.ticketsTagsVerticalBar {
		height: 25px;
		margin-left: 5px;
		margin-right: 5px;
		width: 1px;
		background-color: #e7e7e7;
	}
}
