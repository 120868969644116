.containerAboutPicture {
  height: 100vh;
  background-color: #FFFFFF;
  overflow-x: hidden;

  .containerAnnotationIcon {
    background-color: #FFFFFF;
    padding: 0px 8px;
    padding-bottom: 16px;
    margin-top: 15px;
    height: 35px;
    width: 400px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #1C57DD;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 1px #e3e3e3 solid;

    .containerScreenInfoIcon {
      background-color: #FFFFFF;
      display: flex;
      position: absolute;
      z-index: 2;
      width: 45px;
      height: 45px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      .screenInfoIcon {
        width: 25px;
        opacity: 0.7;
        cursor: pointer;
        left: 0;
      }
    }
  }

  .addedBy {
    margin-top: 25px;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-left: 20px;
    font-size: 15px;

    .initiales {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7FA3FC;
      color: #FFFFFF;
      font-weight: 600;
      border-radius: 99px;
      width: 34px;
      height: 34px;
      font-size: 14px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .nameAdder {
      color: #1C57DD;
    }
  }

}
