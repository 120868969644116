.OnBoarding {
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.secteurActivite {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  .leftPart {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    min-width: 600px;
    flex-direction: column;
    transition: width 0.6s ease-in-out;

    .firstPart {
      height: 50px;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        margin-right: 10px;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 10px;
        font-size: 30px;
        transition: all 0.6s ease-in-out;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(61, 61, 61, 0.66);
        transition: all 0.6s ease-in-out;
      }

      .littleTitle {
        font-size: 14px;
        font-weight: 500;
      }

      .cardSecteur {
        width: 405px;
        height: 100px;
        border-radius: 15px;
        box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
        display: flex;
        align-items: center;
        margin-right: 25px;
        margin-top: 30px;
        cursor: pointer;
        transition: all 0.6s ease-in-out;
        padding-left: 20px;

        .blueLeftIcon:hover {
          opacity: 0.7;
        }

        .blueLeftIcon {
          margin-top: 20px;
          width: 20px;
          object-fit: contain;
          cursor: pointer;
        }

        .littleTitleSecteur {
          margin: 0;
          color: #4960A7;
          font-weight: 600;
          font-size: 13px;
        }

        .titleSecteur {
          font-size: 26px;
          font-weight: 600;
          color: #214371;
          margin: 3px 0px 3px 0px;
        }

        .blueRightIcon {
          width: 11px;
          object-fit: contain;
        }

        .contentClientCardBig {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          width: 1300px;

          .titleComposant {
            font-size: 22px;
            font-weight: 600;
            margin-top: 40px;
            margin-bottom: 0px;
          }

          .subttitleComposant {
            margin-top: 6px;
            margin-bottom: 50px;
            opacity: 0.7;

          }

          .flecheBleuBig {
            width: 120px;
            object-fit: contain;
            margin-top: -440px;
          }

          .composantOnboardinClient {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .titleOnboardingClient {
              text-align: center;
              font-weight: 600;
              font-size: 18px;
              margin-top: 0px;
            }

            .photoOnbaording {
              width: 210px;
              object-fit: contain;
            }
          }
        }

      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 40px;
        height: 35px;
        width: 430px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .client {
        font-size: 12px;
      }

      .connexion:hover {
        opacity: 0.7;
      }

      .connexion {
        margin-top: 11px;
        margin-left: 6px;
        font-size: 12px;
        color: #4867EC;
        font-weight: 500;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

    .thirdPart {
      padding-left: 10px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .onBoardingPhoto {
    margin-left: -50vw;
    height: 90vh;
    margin-top: calc(5vh);
  }

  .rightPart {
    width: 50vw;
    height: 100vh;
    background-color: #F4F9FF;
    overflow: hidden;
    transition: width 0.6s ease-in-out;

    .loginBlue {
      height: 100vh;
      object-fit: contain;
    }
  }

  .barSecteur {
    width: calc(100vw - 100px);
    min-width: 820px;
    height: 2px;
    background-color: #F8F8F8;
  }

  .opus {
    margin-top: -100px;
    margin-left: -50px;
    width: 50px;
    object-fit: contain;
  }
}

.infosEntreprise {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  .leftPart {
    width: 50vw;
    height: 100vh;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .firstPart {
      display: flex;
      height: 50px;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      width: 50vw;

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart {
      width: 50vw;
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
      justify-content: center;

      .pathEtapes {
        transition: opacity 0.7s ease-in-out;

        .pathPasse:hover {
          opacity: 0.7;
        }

        .pathPasse {
          font-size: 13px;
          font-weight: 600;
          color: #4fa139;
          cursor: pointer;
        }

        .pathEnCours {
          font-size: 13px;
          font-weight: 600;
        }

        .pathAVenir {
          font-size: 13px;
          font-weight: 600;
          color: #9b9b9b;

        }

        .dotted {
          object-fit: cover;
          height: 16px;
          opacity: 1;
          transition: width 1s;
          -webkit-transition: width 1s;
        }

        .beigeBar {
          margin-top: 6px;
          margin-right: 1px;
          width: 75px;
          height: 4px;
          background-color: #69A556;
          border-radius: 200px;
          transition: all 1s;
          -webkit-transition: all 1s;
        }

        .choicePath {
          margin-right: 116px;
          width: 18px;
          object-fit: cover;
        }

        .noChoice {
          margin-right: 116px;
          width: 16px;
          height: 16px;
          border-radius: 30px;
          border: 1px solid #bdbdbd;
          background-color: #FFFFFF;
        }
      }

      .opacityForm {
        transition: opacity 0.5s;
        -webkit-transition: opacity 0.5s;
      }

      .title {
        font-size: 30px;
      }

      .subtitle {
        margin-top: -15px;
        font-size: 15px;
        font-weight: 500;
        color: rgba(61, 61, 61, 0.66);
      }

      .inputGroup {

        .inputTitle {
          font-size: 14px;
          font-weight: 500;
          margin-top: 25px;
        }

        .input {
          padding-left: 10px;
          padding-right: 10px;
          outline: none;
          width: 400px;
          height: 35px;
          border-radius: 10px;
          border: solid 1px #eaeaea;
        }

        .choiceNombre:hover {
          opacity: 0.7;
        }

        .choiceNombre {
          height: 35px;
          width: 120px;
          border: solid 1px #eaeaea;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          -webkit-transition: all 0.3s;
          cursor: pointer;

          .nombreChoice {
            font-size: 14px;
            font-weight: 500;
            transition: all 0.3s;
          }
        }
      }

      .buttonBack:hover {
        opacity: 0.8;
      }

      .buttonBack {
        margin-left: 10px;
        width: 50px;
        height: 20px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        margin-top: 10px;

        .retourIcon {
          margin-top: 1px;
          width: 8px;
          object-fit: contain;
          opacity: 0.8;
        }

        .titleButtonBack {
          font-weight: 500;
          font-size: 13px;
          color: #4d4d4d;
          margin-left: 5px;
        }
      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 40px;
        width: 430px;
        height: 38px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;
        border: solid 0px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .thirdPart {
      padding-left: 20px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;
      width: 50vw;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }
  }

  .rightPart {
    width: 50vw;
    height: 100vh;
    background-color: #F4F9FF;
    overflow: hidden;

    .loginBlue {
      height: 100vh;
      object-fit: contain;
    }
  }
}

.infosPersonnelles {

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  .leftPart {
    width: 50vw;
    height: 100vh;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .firstPart {
      display: flex;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      width: 50vw;

      .opus:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .opus {
        margin-top: 8px;
        font-size: 30px;
        font-weight: 100;
        margin-left: 30px;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 20px;
        margin-left: 30px;
        margin-right: 10px;
        margin-top: 25px;
      }

      .onBoarding {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .secondPart  {
      width: 50vw;
      height: calc(100vh - 100px);
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .pathEtapes {
        transition: opacity 0.7s ease-in-out;

        .pathPasse:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .pathPasse {
          font-size: 13px;
          font-weight: 600;
          color: #4fa139;
        }

        .pathEnCours {
          font-size: 13px;
          font-weight: 600;
          transition: color 0.3s;
          -webkit-transition: color 0.3s;
        }

        .pathAVenir {
          font-size: 13px;
          font-weight: 600;
          color: #9b9b9b;
          transition: color 0.3s;
          -webkit-transition: color 0.3s;
        }

        .dotted {
          width: 343px;
          object-fit: cover;
          height: 16px;
          opacity: 1;
          transition: width 1s;
          -webkit-transition: width 1s;
        }

        .beigeBar {
          margin-top: 7px;
          margin-right: 1px;
          width: 75px;
          height: 4px;
          background-color: #69A556;
          border-radius: 200px;
          transition: width 1s;
          -webkit-transition: width 1s;
        }

        .choicePath {
          margin-right: 116px;
          width: 18px;
          object-fit: cover;
          transition: opacity 0.3s;
          -webkit-transition: opacity 0.3s;
        }

        .noChoice {
          margin-right: 116px;
          width: 16px;
          height: 16px;
          border-radius: 30px;
          border: 1px solid #bdbdbd;
          background-color: #FFFFFF;
        }
      }

      .title {
        font-size: 30px;
      }

      .subtitle {
        margin-top: -15px;
        font-size: 15px;
        font-weight: 500;
        color: rgba(61, 61, 61, 0.66);
      }

      .littleInputGroup {
        .inputTitle {
          margin-left: 9px;
          font-size: 14px;
          font-weight: 500;
        }

        .input {
          padding-left: 10px;
          padding-right: 10px;
          outline: none;
          width: 180px;
          margin-right: 8px;
          margin-left: 8px;
          height: 35px;
          border-radius: 10px;
          border: solid 1px #eaeaea;
        }
      }

      .inputGroup {
        .inputTitle {
          font-size: 14px;
          font-weight: 500;
        }

        .input {
          padding-left: 10px;
          padding-right: 10px;
          outline: none;
          width: 400px;
          height: 35px;
          border-radius: 10px;
          border: solid 1px #eaeaea;
        }
      }

      .requires {
        display: flex;
        width: 400px;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: 10px;

        .require {
          padding: 7px 10px;
          background-color: #F8F8F8;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 500;
          }
        }

        .requireValid {
          padding: 7px 10px;
          background-color: #D2E7F8;
          border-radius: 30px;
          margin-right: 15px;
          margin-bottom: 15px;

          .textRequire {
            margin: 0px;
            font-size: 13px;
            font-weight: 600;
            color: #4B5FB0;
          }
        }
      }

      .mdpIdentiques {
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }

      .buttonSuivant:hover {
        opacity: 0.8;
      }

      .buttonSuivant {
        margin-top: 36px;
        height: 38px;
        width: 430px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;
        border: solid 0px;

        .titleButtonSuivant {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .buttonSuivant:disabled {
        background-color: #eeeeee;

        .titleButtonSuivant {
          color: #808080;

        }
      }


      .title {
        margin-top: 0px;
        font-size: 30px;
      }

      .subtitle {
        margin-top: -18px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(61, 61, 61, 0.66);
        width: 450px;
        line-height: 24px;
      }

      .inputTitle {
        margin-top: 25px;
        font-size: 14px;
        font-weight: 500;
      }


      .boutonEntrerOPUS {
        height: 38px;
        width: 430px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4867EC;
        cursor: pointer;
        margin-right: 20px;
        border: solid 0px;

        .textBoutonEntrerOPUS {
          color: #FFFFFF;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .boutonEntrerOPUS:disabled {
        opacity: 0.3;
      }
    }

      .sousBouton {
        font-size: 12px;
        font-weight: 400;
        color: #9a9a9a;
        margin-bottom: 7px;
      }

      .privacyPolicy:hover {
        opacity: 0.8;
      }

      .privacyPolicy {
        cursor: pointer;
        margin-top: 0px;
        font-size: 12px;
        font-weight: 500;
        color: #4867EC;
      }
    }

    .thirdPart {
      padding-left: 20px;
      height: 50px;
      background-color: #FFF;
      transition: width 1s;
      -webkit-transition: width 1s;
      min-width: 500px;
      display: flex;
      width: 50vw;

      .textThirdPart:hover {
        opacity: 0.7;
      }

      .textThirdPart {
        font-size: 11px;
        font-weight: 500;
        margin-left: 20px;
        cursor: pointer;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
      }
    }

  .middlePart {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    height: 30px;
  }

  .rightPart {
    width: 50vw;
    height: 100vh;
    background-color: #F4F9FF;
    .loginBlue {
      height: 100vh;
      object-fit: contain;
    }
  }
}




