.financePage {
	padding-left: 30px;
	width: calc(100vw - 226px);
	height: calc(100vh - 55px);
	overflow: scroll;

	.blurDiv {
		display: flex;
		position: absolute;
		align-items: center;
		justify-content: center;
		width: calc(100vw - 240px);
		height: calc(100vh - 100px);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px); /* Pour la compatibilité Safari */
		background-color: rgba(255, 255, 255, 0.3);
		padding: 20px;
		border-radius: 10px;

		.widgetNoAccess {
			width: 400px;
			height: 160px;
			box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
			background-color: #fff;
			border: solid 1px #f8f8f8;
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding-left: 10px;
			padding-right: 10px;

			.firstLine {
				text-align: center;
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 0;
			}

			.secondLine {
				text-align: center;
			}
		}
	}

	.innerFinance {
		.titlePage {
			font-size: 27px;
			font-weight: 700;
			margin-top: 20px;
			margin-bottom: 0;
			width: 200px;
		}

		.containerButtonsFinances {
			display: flex;
			justify-content: flex-end;
			width: calc(100vw - 465px);
			min-width: 800px;
			margin-top: 20px;
			gap: 10px;

			.containerButton {
				background-color: #eeeeee;
				display: flex;
				flex-direction: row;
				border-radius: 5px;
			}

			.buttonFinances {
				display: flex;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 5px;
				height: 30px;
				align-items: center;
				gap: 3px;
				background-color: #eeeeee;

				.icon {
					object-fit: contain;
					margin-right: 5px;
					max-height: 12px;
				}

				.titleButton {
					margin: 0;
					font-size: 12px;
					font-weight: 600;
					white-space: nowrap;
				}

				.containerCurrencies {
					display: flex;
					width: 0;
					gap: 5px;
					overflow: hidden;
					transition: width 0.2s ease-in-out;
					border-left: solid 2px rgba(119, 119, 119, 0.3);

					.currency:hover {
						color: #1c57dd;
						cursor: pointer;
						background-color: #ffffff;
					}

					.currency {
						font-weight: 700;
						font-size: 14px;
						border-radius: 5px;
						padding: 3px 7px;
					}

					.currencyActive {
						color: #1c57dd;
						cursor: pointer;
						background-color: #ffffff;
					}
				}
			}
		}

		.horizontalBarFinances {
			margin-bottom: 10px;
			width: calc(100vw - 265px);
			min-width: 1070px;
			height: 1px;
			background-color: #eeeeee;
		}
	}

	.innerFinance::-webkit-scrollbar {
		display: none;
	}

	.globalFinanceProgression {
		margin-top: -10px;
		background-color: rgba(255, 255, 255, 0);
		width: calc(100vw - 246px);
		height: 111.4px;
		display: flex;

		.containerGlobalPercentage {
			width: 190px;

			.globalPercentage {
				margin-left: 10px;
				font-size: 70px;
				font-weight: 100;
				margin-top: 20px;
				margin-bottom: 0;
			}
		}

		.containerProgressBarGlobalPercentage {
			display: flex;
			margin-top: 80px;

			.containerPayed {
				margin-left: 45px;
				margin-top: -45px;
				width: 330px;
				display: flex;
				justify-content: flex-start;

				.payed {
					font-weight: 600;
					font-size: 14px;
					color: #569f59;
				}
			}

			.backBarFinances {
				width: calc(100vw - 510px);
				min-width: 760px;
				background-color: #eff2fe;
				border-radius: 20px;
				height: 5px;
				margin-left: -330px;

				.frontBarFinances {
					max-width: calc(100vw - 510px);
					border-radius: 20px;
					height: 5px;
					background-color: #569f59;
				}
			}

			.containerTotal {
				margin-top: -45px;
				margin-left: -330px;
				width: 330px;
				display: flex;
				justify-content: flex-end;
				.total {
					font-weight: 600;
					font-size: 14px;
					color: #000000;
				}
			}
		}
	}

	.categories {
		background-color: #fff;
		width: calc(100vw - 240.5px);
		height: calc(100vh - 286.7px);
		min-width: 995px;

		.list {
			width: calc(100vw - 250px);
			min-width: 995px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding-bottom: 40px;
			margin-top: 10px;
			overflow-x: hidden;

			.placeholderCategoriesFinance {
				width: calc(100vw - 260px);
				min-width: 995px;
				height: 500px;
				margin-left: 10px;
				align-items: center;
				justify-content: center;
				display: flex;
				flex-direction: column;

				.imgAucunePhase {
					margin-top: -70px;
					width: 45px;
					object-fit: contain;
					border-radius: 10px;
				}

				.title {
					margin-top: 14px;
					font-size: 25px;
					font-weight: 700;
					margin-bottom: 10px;
				}

				.subtitle {
					font-size: 14px;
					font-weight: 500;
					margin-top: 6px;
					text-align: center;
					opacity: 0.7;
				}

				.buttonAddCategory {
					background-color: #e8fde0;
					border: solid 0px;
					margin-top: 15px;
					border-radius: 7px;
					color: #3ea940;
					font-weight: 600;
					font-size: 13px;
					width: 220px;
					height: 28px;
				}
			}
		}

		.categoryFinances {
			.headerCategory {
				display: flex;
				min-width: 995px;
				width: calc(100vw - 260px);

				.titleCategory {
					font-weight: 700;
					font-size: 22px;
					margin-bottom: 30px;
					margin-left: 10px;
				}

				.containerButtonsCategory {
					display: flex;
					margin-top: 21px;
					margin-left: 30px;
					gap: 10px;

					.buttonCategory {
						display: flex;
						align-items: center;
						background-color: #eeeeee;
						padding: 0px 10px;
						border-radius: 5px;
						height: 30px;
						gap: 7px;

						img {
							height: 12px;
							object-fit: contain;
						}

						.title {
							margin: 0;
							font-size: 12px;
							font-weight: 600;
						}
					}
				}
			}

			.emptyCateg {
				font-size: 14px;
				opacity: 0.7;
				margin-left: 10px;
				margin-bottom: 30px;
			}

			.expensesList {
				display: flex;
				flex-wrap: wrap;
				margin-left: 7px;
				overflow: hidden;
				width: calc(100vw - 255px);
			}
		}
	}
}

.posteDepense {
	padding-left: 10px;
	margin-bottom: 30px;
	margin-right: 25px;
	border-radius: 15px;
	padding-top: 8.5px;
	width: 400px;
	background-color: #f8f8f8;
	border: solid 1px #eaebed;
	padding-bottom: 8px;

	.containerNameExpense {
		display: flex;

		.confidentialIcon {
			margin: 13px -4px 0px 13px;
			width: 12px;
			height: 12px;
			object-fit: contain;
		}

		.nomPosteDepense {
			margin: 10px 0 10px 10px;
			font-size: 15px;
			font-weight: 600;
			max-width: 300px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.confidentialText {
			font-size: 14px;
			margin: 10.5px 0 0 8px;
			color: #ff0000;
			font-weight: 600;
		}
	}

	.containerBarPercentage {
		display: flex;

		.backBarPosteDepense {
			width: 370px;
			margin-left: 10px;
			background-color: #eeeeee;
			height: 5px;
			border-radius: 20px;
		}

		.frontBarPosteDepense {
			max-width: 370px;
			background-color: #569f59;
			height: 5px;
			border-radius: 20px;
		}

		.pourcentagePosteDepense {
			margin-top: -40px;
			margin-left: -100px;
			font-weight: 600;
			font-size: 13px;
			width: 100px;
			display: flex;
			justify-content: flex-end;

			.containerHiddenPercentage {
				display: flex;
				.hiddenPercentage {
					height: 8px;
					width: 20px;
					background-color: #dedede;
					margin-top: 18px;
					margin-right: 5px;
				}
			}
		}

		.modifierPoste {
			margin-left: -80px;
			border: solid 0px;
			font-weight: 600;
			border-radius: 6px;
			padding: 4.5px 12px;
			cursor: pointer;
			transition: opacity 0.3s;
			-webkit-transition: opacity 0.3s;
			opacity: 1;
			margin-top: 25px;
			margin-bottom: -70px;

			.folderEditIcon {
				width: 50px;
				object-fit: contain;
			}
		}
	}

	.containerExpenseInfos {
		.previsionPosteDepense {
			margin-top: 18px;
			margin-left: 10px;
			font-weight: 600;
			font-size: 13px;
		}

		.payePosteDepense {
			margin-top: 18px;
			margin-left: 10px;
			font-weight: 600;
			font-size: 13px;
			color: #307235;
		}

		.containerHiddenInfo {
			display: flex;
			.hiddenInfo {
				height: 8px;
				width: 100px;
				background-color: #dedede;
				margin-top: 23px;
				margin-left: 10px;
			}
		}
	}

	.dragComponent:hover {
		background-color: #f8f8f8;
	}

	.dragComponent:focus {
		cursor: grabbing;
		background-color: #f8f8f8;
		.dragDot {
			opacity: 1;
		}
	}

	.dragComponent {
		background-color: #ffffff;
		margin-left: -25px;
		padding: 4px;
		height: 22px;
		border-radius: 4px;
		cursor: grab;
		margin-right: 0px;
		margin-top: 10px;

		.dragDot {
			width: 4px;
			height: 4px;
			background-color: #d7d7d7;
			border-radius: 20px;
			opacity: 0;
		}
	}
}

.modalModifExpenseItem {
	position: absolute;
	top: 0;
	margin-top: 100px;
	margin-left: calc((100vw - 600px) / 2);
	width: 600px;
	max-height: calc(100vh - 200px);
	min-height: 0px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;

	.headerModalEditExpenseItem {
		display: flex;

		.expenseName {
			font-size: 22px;
			font-weight: 700;
			margin-left: 20px;
			margin-bottom: 20px;
		}
	}

	.crossIconModalEditExpenseItem {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}

	.backBarExpenseItem {
		width: 550px;
		margin-left: 25px;
		height: 5px;
		background-color: #f8f8f8;
		border-radius: 20px;
		margin-bottom: 10px;

		.frontBarExpenseItem {
			height: 5px;
			background-color: #569f59;
			border-radius: 20px;
		}
	}

	.scrollViewModifPhase::-webkit-scrollbar {
		display: none;
	}

	.scrollViewModifPhase {
		overflow-x: hidden;
		overflow-y: scroll;
		max-height: calc(100vh - 283px);

		.containerEditExpenseItemInput {
			display: flex;

			.choiceAcces {
				margin-top: 10px;
				width: 45px;
				height: 25px;
				border-radius: 100px;
				display: flex;
				align-items: center;
				transition: all 0.3s;
				-webkit-transition: all 0.3s;

				.buttonChoice:hover {
					opacity: 0.9;
					cursor: pointer;
				}

				.buttonChoice {
					width: 21px;
					height: 21px;
					border-radius: 99px;
					background-color: #ffff;
					box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
					transition: all 0.3s;
					-webkit-transition: all 0.3s;
				}
			}

			.withoutTaxContainer {
				display: flex;
				justify-content: flex-end;
				width: 150px;
				margin-left: -152px;

				p {
					font-size: 13px;
					font-weight: 600;
					margin-top: 5px;
					margin-right: 7px;
				}
			}
		}

		.designant {
			font-size: 14px;
			font-weight: 600;
			margin-left: 25px;
		}

		.finances_no_files {
			font-size: 14px;
			font-weight: 600;
			color: #808080;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 600px;
			margin-top: -20px;
			margin-bottom: 25px;
		}

		.inputFirst {
			margin-left: 25px;
			border: solid 1.5px #eeeeee;
			background-color: #fff;
			padding-left: 10px;
			margin-top: 0px;
			margin-bottom: 10px;
			padding-top: 2px;
			font-weight: 500;
			padding-bottom: 2px;
			border-radius: 6px;
			outline: none;
			width: 250px;
			font-size: 13px;
			height: 21px;
		}

		.cliquezPourDoc:hover {
			background-color: rgba(248, 248, 248, 0.48);
		}

		.cliquezPourDoc {
			margin-top: 20px;
			margin-left: 25px;
			margin-bottom: 20px;
			width: 550px;
			height: 70px;
			background-color: rgba(248, 248, 248, 0.56);
			border: dashed 1px #c0c0c0;
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 13px;
			font-weight: 500;
			color: #808080;
			cursor: pointer;
		}

		.docItem {
			margin-top: 10px;
			display: flex;
			margin-bottom: 30px;
			flex-direction: column;

			.pdfIcon {
				width: 40px;
				object-fit: contain;
				margin-left: 30px;
				margin-right: 20px;
			}

			.nomDoc {
				margin: 0px;
				font-size: 14px;
				font-weight: 500;
				margin-bottom: 3px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: 370px;
			}

			.dateDoc {
				margin: 0px;
				font-size: 12px;
				font-weight: 500;
				color: #8d8d8d;
			}

			.buttonSupprimerDoc:hover {
				opacity: 0.7;
			}

			.buttonSupprimerDoc {
				margin-top: -20px;
				margin-left: -100px;
				background-color: rgba(248, 210, 210, 0.51);
				color: #a93e3e;
				font-size: 13px;
				padding: 5px 12px;
				border-radius: 5px;
				margin-right: 30px;
				transition: opacity 0.3s;
				cursor: pointer;
			}
		}

		.buttonSupprimer:hover {
			opacity: 0.7;
		}

		.buttonSupprimer {
			margin-top: 50px;
			display: flex;
			width: 245px;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			margin-left: 20px;
			border: solid 0px;
			background-color: rgba(248, 210, 210, 0.51);
			color: #a93e3e;
			font-weight: 600;
			border-radius: 6px;
			padding: 10px 12px;
			font-size: 13px;
			cursor: pointer;
			transition: opacity 0.3s;
			-webkit-transition: opacity 0.3s;
			opacity: 1;
		}
	}
}
