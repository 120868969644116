.noResult {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .noResultFound {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .tryAgain {
        font-size: 15px;
        opacity: 0.5;
        margin-top: 10px;
    }

    img {
        width: 30px;
    }
}
