/* LanguageSelector.css */
@keyframes loadingColorChange {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.loading-div {
    background: linear-gradient(270deg, #eeeeee, #f8f8f8, #f8f8f8, #eeeeee);
    background-size: 1000% 100%;
    animation: loadingColorChange 6s infinite linear;
}
