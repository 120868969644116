$itemWidth: 400px;
$itemHeight: 50px;
$innerItemWidth: 350px;
$iconWidth: 30px;
$fontSize: 14px;
$padding: 8px 22px;

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin itemIcon {
  object-fit: contain;
  width: $iconWidth;
  margin-right: 15px;
  max-height: 35px;
}

@mixin itemName {
  white-space: nowrap;
  overflow: hidden;
  width: calc(100%);
  margin: 0;
  font-size: $fontSize;
  font-weight: 600;
  color: #343434;
  text-overflow: ellipsis;
}

.documentItem, .documentItemSelected, .documentItemInPath, .documentItemLoading {
  width: 100%;
  height: $itemHeight;
  @include flexCenter;

  .eye {
    width: 18px;
    height: 15px;
    margin-right: 0px;
    margin-top: 3px;
  }

  .folderItem, .fileItem {
    display: flex;
    align-items: center;
    width: calc(100% - 50px);
    padding: $padding;
    border-radius: 10px;

    .folderIcon, .fileIcon {
      @include itemIcon;
    }

    .name {
      @include itemName;
    }

    .pastilles {
      display: flex;
      gap: 5px;
      .clients, .partners {
        width: 7px;
        height: 7px;
        border-radius: 10px;
        background-color: #eeeeee;
      }

      .clients.active {
        background-color: #4A833F;
      }

      .partners.active {
          background-color: #D77534;
      }
    }

    .righticon {
      width: 6px;
      margin-left: 10px;
      transform: rotate(180deg);
      opacity: 0.2;
    }

    .containerIndex {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #5dbd52;
      font-weight: 700;
    }
  }

  .diminutif {
    font-weight: 700;
    font-size: 14px;
    color: #4c58a3;
    margin-left: 5px;
  }
}

.documentItem:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.documentItemInPath {
  background-color: #eeeeee;
}

.documentItemSelected {
  background-color: #1C57DD;

  .folderItem, .fileItem {

    .name {
      color: #FFFFFF;
    }
  }
}

.documentItemLoading {
  opacity: 0.5;
}
