.menu {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  background-color: #F6F8FA;
  border-bottom: solid 1px #f3f3f3;
  margin-bottom: 10px;
  height: 55px;
  transition: border-bottom-color 0.3s ease-in-out;

  .barMenu {
    display: flex;
    position: absolute;
    width: 0px;
    margin-left: 194px;
    background-color: #ffffff;
  }

  .menuWidget {
    display: flex;
    height: 55px;
    border-radius: 17px;

    .containerChoice {
      border-radius: 99px;
      width: 28px;
      height: 15px;
      border: solid 1px #d7d7d7;
      background-color: #F8F8F8;
      cursor: pointer;
      margin-top: 13px;
      margin-left: 10px;
      transition: all 0.3s ease-in-out;
      .choice {
        transition: all 0.3s ease-in-out;
        border-radius: 99px;
        width: 15px;
        background-color: #FFFFFF;
        margin-top: -1px;
        margin-left: -1px;
        height: 15px;
        border: solid 1px #b2b2b2;
      }
    }

    .notifsMessages {
      margin-top: -14px;
      background-color: #F84A3F;
      border-radius: 10px;
      font-size: 11px;
      font-weight: 600;
      color: white;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 5px;
      margin-left: -8px;
    }

  }

  .backgroundImage {
    display: flex;
    flex-direction: row;
    background-color: #F8F8F8;
    margin-left: 28px;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-top: 13px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;

    .img {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .etatDesLieux {
    object-fit: cover ;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin-top: 12px;
    color: #F8F8F8;

    .img {
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      background-color: #F8F8F8;
    }
  }

  .titleProject {
    margin-left: -15px;
    margin-top: 18px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15.3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .backBarMenu {
    margin-left: 14px;
    width: 149px;
    background-color: #eeeeee;
    border-radius: 14.5px;
    height: 5px;
    margin-top: 10.5px;

    .frontBarP {
      background-color: #56759F;
      border-radius: 20px;
      height: 4px;
    }

    .frontBarF {
      background-color: #62964D;
      border-radius: 20px;
      height: 4px;
    }
  }
  .combleMenu {
    width: 100px;
    height: calc(100vh - 457px);
  }

  .combleMenuEssaiGratuit {
    width: 100px;
    height: calc(100vh - 610px);
  }

  .combleMenuEssaiGratuitProjet {
    width: 100px;
    height: calc(100vh - 185px);
  }

  .deconnexion:hover {
    opacity: 0.7;
  }

  .rappelEssaiGratuit {
    width: 155px;
    height: 143px;
    margin-left: 11px;
    background-color: #FFFFFF;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    .count {
      margin-top: 0px;
      font-size: 40px;
      font-weight: 200;
      margin-bottom: 0px;
    }

    .jours {
      margin-top: 25px;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
    }

    .restant {
      font-size: 12px;
      font-weight: 400;
      color: #9a9a9a;
      margin-top: 5px;
    }

    .backBar {

      width: 135px;
      background-color: #F8F8F8;
      height: 5px;
      border-radius: 20px;

      .frontBar {
        background-color: #E9CCB6;
        height: 5px;
        border-radius: 20px;
        min-width: 10px;
        max-width: 135px;
      }
    }

    .offre {
      margin-top: 5px;
      margin-left: 5px;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .greenButton:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .greenButton {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #CCF3CA;
      padding: 5px 10px;
      border-radius: 5px;
      width: 120px;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      .testGreenButton {
        margin: 0px;
        font-size: 13px;
        width: 100px;
        font-weight: 500;
        color: #146146;
        text-align: center;
      }
    }
  }

  .deconnexion {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 155px;
    height: 25px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 7px;
    background-color: $color-1;
    border-width: 0px;
    cursor: pointer;
    bottom: 0;
    margin-left: 12px;
    margin-bottom: 35px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  .powered {
    margin-top: 6px;
    font-size: 8px;
    font-weight: 100;
    bottom: 0;
    margin-bottom: 5px;
    margin-left: 62px;
  }
}

.modalDemarrerIntegration {
  position: absolute;
  top: 0;
  margin-top: calc((100vh - 450px)/2);
  margin-left: calc((100vw - 590px)/2);
  width: 590px;
  height: 450px;
  border-radius: 10px;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .crossIcon:hover {
    opacity: 0.8;
  }

  .crossIcon {
    width: 23px;
    object-fit: contain;
    position: absolute;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }


  .title {
    font-size: 30px;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    color: #144371;
    margin-bottom: 15px;
  }

  .subtitle {
    text-align: center;
    margin-left: 40px;
    margin-right: 40px;
    color: #4B5FB0;
  }

  .buttonIntegration:hover {
    opacity: 0.7;
  }

  .buttonIntegration {
    margin-top: 20px;
    background-color: #D2E7F8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    .textButtonIntegration {
      font-weight: 600;
      font-size: 15px;
      color: #4B5FB0;
      margin: 0px;
    }
  }

  .imageFonct {
    margin-top: 45px;
    width: 590px;

  }
}

.readOnly {
  background-color: #1C57DD;
  display: flex;
  align-items: center;
  top: 0;
  color: #FFFFFF;
  margin-top: -10px;
  padding: 8px 11px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
